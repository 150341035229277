import React from "react";
import { ImWhatsapp } from "react-icons/im";

const Whatsapp = () => {
  return (
    <a href="https://wa.me/918894412884" target="_blank" rel="noreferrer">
      <div className="fixed w-16 h-16 bottom-5 left-5 z-40 bg-[#25D366] p-2 rounded-full shadow-md  cursor-pointer flex justify-center items-center">
        <ImWhatsapp size={30} className="text-slate-300" />
      </div>
    </a>
  );
};

export default Whatsapp;
