import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyCfw3d2DPWTML28fuHr2LirGJ51mEnIRTE",
  authDomain: "white-expedition.firebaseapp.com",
  projectId: "white-expedition",
  storageBucket: "white-expedition.appspot.com",
  messagingSenderId: "171233212841",
  appId: "1:171233212841:web:607a80073b9e70dbb26cb8",
};

const app = initializeApp(firebaseConfig);

export const authentication = getAuth(app);
export const firestore = getFirestore(app);
