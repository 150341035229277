import React, { useEffect, useState } from "react";

// Components for page structure
import Footer from "../components/Footer";
import HeroSection from "../components/HeroSection";
import InstaFeeds from "../components/InstaFeeds";
import NavigationBar from "../components/NavigationBar";
import Whatsapp from "../components/Whatsapp";

// Data for HeroSection
import { WhoWeAreHeroSection } from "../data/Home/dataStorage";

// Asset for footer background
import expedition from "../assets/home/hero/expedition.webp";

// Animation library (if actively used)
import AOS from "aos";
import "aos/dist/aos.css";
import { Helmet } from "react-helmet";
import UpcomingTours from "../components/UpcomingTours";
import UpcomingToursIcon from "../components/UpcomingToursIcon";

const WhoWeAre = () => {
  useEffect(() => {
    // Initialize AOS library for animations
    AOS.init();
  }, []);

  const [isVisible, setIsVisible] = useState(false);

  return (
    <>
      <Helmet>
        <title>White Expedition - Who We Are ?</title>
        <meta
          name="description"
          content="Thrill seekers, meet your guides!  White Expedition, led by the acclaimed Ricky Mountaineer, is Himachal Pradesh's premier climbing company. We're passionate about helping adventurers conquer challenging peaks and reach new heights. Visit our Who We Are page to learn more about our team and how we can make your mountain dream a reality."
        />
      </Helmet>

      {/* Hero Section with Carousel Data from WhoWeAreHeroSection */}
      <HeroSection carouselData={WhoWeAreHeroSection} />

      {/* Navigation Bar */}
      <section className="center">
        <NavigationBar />
      </section>

      {/* About Us section */}
      <section className="center">
        <div className="container">
          <div className="grid grid-cols-12 gap-2">
            {/* Company introduction */}
            <div className="col-start-1 col-span-12">
              <h1
                className="paragraph text-start margin"
                data-aos="fade-up"
                data-aos-duration="800"
              >
                At White Expeditions, we are more than just a team; we are a
                community driven by passion, innovation, and integrity. White
                Expedition, led by the seasoned mountaineer Ricky, is dedicated
                to crafting unforgettable adventures in the majestic Himalayas.
                We offer a diverse range of experiences, from thrilling
                expeditions and challenging treks to adrenaline-pumping
                adventure tours and immersive wildlife photography
                opportunities.
              </h1>
            </div>

            {/* Mission statement */}
            <div className="col-start-1 col-span-12">
              <h1
                className="paragraph text-start margin"
                data-aos="fade-up"
                data-aos-duration="800"
              >
                With a dedication to excellence, we bring together a diverse
                team of experts who share a common goal. Our team, led by
                Ricky's extensive experience and passion for the mountains, aims
                to empower you to explore the Himalayas in a way that is safe,
                rewarding, and deeply personal. We go beyond just reaching the
                summit; we strive to create lasting memories and foster a
                connection with the region's breathtaking landscapes, rich
                culture, and unique wildlife.
              </h1>
            </div>

            {/* Core Values */}
            <div className="col-start-1 col-span-12">
              <h1
                className="paragraph text-start margin"
                data-aos="fade-up"
                data-aos-duration="800"
              >
                Our Core Values:
              </h1>
              <ul
                className="list col-start-1 col-span-12"
                data-aos="fade-up"
                data-aos-duration="800"
              >
                <li>
                  <h1 className="paragraph">
                    <strong>Safety:</strong> Your well-being is our top
                    priority. We prioritize meticulous planning, experienced
                    guides, and top-notch equipment to ensure a safe and
                    enjoyable adventure.
                  </h1>
                </li>
                <li>
                  <h1 className="paragraph">
                    <strong>Sustainability:</strong> We are committed to
                    responsible tourism practices that minimize our
                    environmental impact and support local communities.
                  </h1>
                </li>
                <li>
                  <h1 className="paragraph">
                    <strong>Adventure:</strong> We believe in pushing boundaries
                    and creating exhilarating experiences that ignite your
                    spirit of adventure.
                  </h1>
                </li>
                <li>
                  <h1 className="paragraph">
                    <strong>Expertise:</strong> Our team possesses a deep
                    understanding of the Himalayas, its challenges, and its
                    hidden gems.
                  </h1>
                </li>
              </ul>
            </div>

            {/* Company vision */}
            <div className="col-start-1 col-span-12">
              <h1
                className="paragraph text-start margin"
                data-aos="fade-up"
                data-aos-duration="800"
              >
                We believe that adventure is not just about reaching the
                destination; it's about the journey itself, the challenges
                overcome, and the connections forged along the way.
              </h1>
            </div>

            {/* Team and experience highlights */}
            <div className="col-start-1 col-span-12">
              <h1
                className="paragraph text-start margin"
                data-aos="fade-up"
                data-aos-duration="800"
              >
                Ricky, with over 20 peaks conquered and 50 successful
                expeditions led, has a proven track record of guiding
                adventurers to unforgettable Himalayan experiences. Since its
                inception, White Expedition has been committed to providing
                exceptional adventure experiences in the Himalayas, fostering a
                love for exploration, and promoting responsible tourism
                practices.
              </h1>
            </div>

            {/* Call to action */}
            <div className="col-start-1 col-span-12">
              <h1
                className="paragraph text-start margin"
                data-aos="fade-up"
                data-aos-duration="800"
              >
                Join us on our journey as we strive to create a positive impact
                by promoting sustainable tourism, supporting local communities,
                and inspiring a sense of adventure and environmental
                responsibility in our clients. Together, let's explore the
                awe-inspiring beauty of the Himalayas, challenge ourselves, and
                create memories that will last a lifetime.
              </h1>
            </div>
          </div>
        </div>
      </section>

      {/* Instagram Section  */}
      <section className="center bg-black">
        {/* Container for Instagram feed section */}
        <div className="container">
          <div className="grid grid-cols-12 gap-2">
            {/* Section Header (remove commented-out animations) */}
            <h1 className="header col-start-1 col-span-12 text-center">
              Our Instagram
            </h1>

            {/* Instagram Username Link */}
            <a
              href="https://www.instagram.com/white.expedition/"
              target="_blank"
              rel="noopener noreferrer"
              className="col-start-1 col-span-12 text-center"
            >
              <h1 className="tagline text-primary">@white.expedition</h1>
            </a>

            {/* Instagram Feed (replace with your actual Instagram feed component) */}
            <div className="col-start-1 col-span-12 rounded-md overflow-hidden margin">
              <InstaFeeds />
              {/* Comment: Replace InstaFeeds with your actual Instagram feed component */}
            </div>
          </div>
        </div>
      </section>

      {/* Footer section with background image */}
      <section
        className="relative bg-center bg-cover"
        style={{ backgroundImage: `url(${expedition})` }}
      >
        {/* Dark overlay on top of background image */}
        <div className="absolute inset-0 bg-primary opacity-80 z-10"></div>

        {/* Container for footer content */}
        <div className="container relative z-20">
          <div className="grid grid-cols-12 gap-2">
            {/* Footer component (replace with your actual footer component) */}
            <div className="col-start-1 col-span-12">
              <Footer />
              {/* Comment: Replace Footer with your actual footer component */}
            </div>
          </div>
        </div>
      </section>

      {/* WhatsApp integration (replace with your implementation) */}
      <Whatsapp />
      {/* Comment: Replace Whatsapp with your actual WhatsApp integration */}

      {isVisible && <UpcomingTours close={() => setIsVisible(false)} />}

      <UpcomingToursIcon
        open={() => {
          setIsVisible(true);
        }}
      />
    </>
  );
};

export default WhoWeAre;
