import React from "react";

const AffiliateCard = (props) => {
  return (
    <div className="flex-none w-[134px] h-20 bg-white rounded-md overflow-hidden flex-shrink-0 transition-transform duration-500 ease-linear transform hover:opacity-90 ">
      <img
        src={props.image}
        alt="Affiliate Card"
        className="w-full h-full object-contain bg-blend-overlay transition-opacity duration-500 ease-in-out opacity-100 hover:opacity-90"
      />
    </div>
  );
};

export default AffiliateCard;
