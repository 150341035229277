import {
  expedition5000MHeroSection,
  expedition6000MHeroSection,
  expedition7000MHeroSection,
  expeditionPureAdrenalineHeroSection,
  expeditionTrekkingHeroSection,
} from "../../data/Home/dataStorage";

export const thingstocarray = [
  {
    sectionTitle: "Layered Clothing",
    content: [
      "Thermal innerwear: Base layers to keep you warm.",
      "Insulating layers: Fleece or down jackets for insulation.",
      "Waterproof and windproof outer shell: Protects against snow and wind.",
    ],
  },
  {
    sectionTitle: "Headgear",
    content: [
      "Insulated hat or balaclava: Covers the head and ears.",
      "Sunglasses with UV protection: Protects eyes from snow glare.",
    ],
  },
  {
    sectionTitle: "Hand Protection",
    content: [
      "Insulated gloves or mittens: Keep hands warm and dry.",
      "Hand warmers: Disposable heat packs for extra warmth.",
    ],
  },
  {
    sectionTitle: "Footwear",
    content: [
      "Insulated and waterproof boots: Provide warmth and protection.",
      "Gaiters: Cover the lower leg and prevent snow from entering the boots.",
      "Extra pairs of moisture-wicking socks: Keep feet dry and warm.",
    ],
  },
  {
    sectionTitle: "Backpack",
    content: [
      "Large, sturdy backpack: To carry all your essentials.",
      "Rain cover or waterproof liners: Protects gear from snow and moisture.",
      "Day bag (20-30 ltr)",
    ],
  },
  {
    sectionTitle: "Trekking Poles",
    content: [
      "Adjustable trekking poles: Assist in maintaining balance in snow-covered terrain.",
    ],
  },
  {
    sectionTitle: "Nutrition",
    content: [
      "High-energy snacks: Nuts, energy bars, and chocolate.",
      "Hydration system: Insulated water bottles or a hydration pack.",
    ],
  },
  {
    sectionTitle: "Emergency Equipment",
    content: ["Personal medicines if required."],
  },
  {
    sectionTitle: "Lighting",
    content: [
      "Headlamp or flashlight: Essential for visibility in the dark.",
      "Extra batteries: Ensure you have enough power.",
    ],
  },
  {
    sectionTitle: "Communication",
    content: [
      "Fully charged cell phone: Keep it in a warm place to preserve battery life.",
      "Portable charger: Extra power for electronic devices.",
    ],
  },
  {
    sectionTitle: "Personal Items",
    content: [
      "Sunscreen: Even in winter, UV rays can be intense at high altitudes.",
      "Lip balm: Prevents chapped lips in cold, dry conditions.",
      "Personal hygiene items: Toothbrush, toothpaste, etc.",
    ],
  },
  {
    sectionTitle: "Documentation & Cash",
    content: [
      "ID, permits, and emergency contact information.",
      "Remote areas don’t have ATMs, so please carry enough cash.",
    ],
  },
];

export const individualExpedition = [
  {
    individualExpeditionType: "friendshipPeak",
    individualExpeditionName: "Friendship Peak",
    carouselData: expedition5000MHeroSection,
    location: "Manali",
    duration: "7 Days",
    grade: "Beginners",
    maxAltitude: "5289 m or 17, 349 ft",
    bestSeason: "May, June, September and October",
    experienceRequired:
      "An enthusiast should have trekked between 4000 m to 5000 m at least once.",
    mainExpeditionContent:
      "Friendship Peak, a mountain in Manali, Himachal Pradesh stands tall at 5,289 meters. It's like a magnet for folks who love trekking. Though it’s a trekking peak, the duration to reach the top, the gradient, and the efforts required, make it a worthy first climb for those who wish to enter the world of climbing. Picture this: snow all around, stunning views, and glaciers showing off. So, if you're into trekking with awesome scenery and good vibes, Friendship Peak in the Himalayas is your kind of adventure – no fancy words needed. Just a solid mountain and a great time!",
    aboutExpeditionExperienced:
      "Climb Friendship Peak with our seasoned climbing team with over a decade of experience in the Indian Himalayas. We pride ourselves on upholding the highest safety standards throughout our operations. With a track record of more than 10 years, we've honed our skills and know these mountains like the back of our hands. Our commitment to safety goes beyond words—we have meticulous rescue plans in place, ready to be executed if the need arises. When you embark on an adventure with us, rest assured that you're in the hands of professionals who prioritize your safety above all else. Welcome to a team that's as prepared as it gets.",
    itineraryData: [
      {
        day: "1",
        title: "Arrival in Manali",
        details: [
          "Arrive in Manali (elevation: 2,050 meters)",
          "Transfer to the accommodation",
          "Settle in and rest",
          "Evening briefing and gear check by the trek leader",
        ],
      },
      {
        day: "2",
        title: "Drive to Dhundi and trek to Lady Leg base camp.",
        details: [
          "Drive to Dhundi",
          "Trek to Lady leg (5-6 hours)",
          "Elevation gain 3800m",
        ],
      },
      {
        day: "3",
        title: "Short trek for acclimatisation",
        details: [
          "Acclimatisation and briefing",
          "Trekking time: Approximately 3-4 hours",
        ],
      },
      {
        day: "4",
        title: "Trek to Advanced Base Camp (ABC)",
        details: [
          "Trek to Advanced Base Camp (elevation: 4,300 meters)",
          "Elevation gain 4300m",
          "Enjoy scenic views",
        ],
      },
      {
        day: "5",
        title: "Summit Day - Friendship Peak",
        details: [
          "Early morning start for Friendship Peak summit (elevation: 5,289 meters)",
          "Summit and descend to ABC or Lady Leg based on conditions",
          "Trekking time: Approximately 10-12 hours",
        ],
      },
      {
        day: "6",
        title: "Reserve Day / Descend to Manali",
        details: [
          "Keep the day as a reserve for contingencies or gradual descent",
          "If the summit was on Day 5, descend to Manali",
          "Overnight stay in Manali or at a suitable location on the descent",
        ],
      },
      {
        day: "7",
        title: "Departure from Manali",
        details: [
          "Drive back to Manali",
          "Arrive in Manali and conclude the trek",
          "Depart from Manali with memories of the Friendship Peak expedition",
        ],
      },
    ],
    inclusion: [
      {
        sectionTitle: "Accommodation",
        content: [
          "Accommodation in Manali on Day 1 and Day 6 (as applicable)",
          "Camping arrangements during the trek",
        ],
      },
      {
        sectionTitle: "Meals",
        content: [
          "Nutritious and hygienic meals throughout the expedition",
          "Breakfast, lunch, and dinner are provided during the expedition days",
          "Special arrangements for dietary preferences if communicated in advance",
        ],
      },
      {
        sectionTitle: "Transportation",
        content: ["Transportation to and from climbing locations."],
      },
      {
        sectionTitle: "Guides and Support",
        content: [
          "Experienced trek leader and support staff",
          "Local guides familiar with the terrain",
          "Support staff for camping and logistics",
        ],
      },
      {
        sectionTitle: "Permits and Entrance Fees",
        content: [
          "All necessary permits and entry fees",
          "Permissions for climbing in the region",
          "Indian Mountaineering Foundation (IMF) registration",
        ],
      },
      {
        sectionTitle: "Safety and Medical Assistance",
        content: [
          "First aid kit and basic medical assistance",
          "Emergency evacuation plans in place",
          "Experienced guides trained in high-altitude rescue operations",
        ],
      },
      {
        sectionTitle: "Equipment",
        content: [
          "Tents, sleeping bags, and other camping equipment",
          "Safety equipment for the climb",
          "Personal gear check and assistance",
        ],
      },
      {
        sectionTitle: "Climbing Assistance",
        content: [
          "Guidance and support during the summit day",
          "Assistance with technical aspects of the climb",
        ],
      },
      {
        sectionTitle: "Documentation",
        content: [
          "Detailed itinerary and information before the climb",
          "Completion certificates for the participants",
        ],
      },
      {
        sectionTitle: "Communication",
        content: [
          "Communication equipment for emergencies",
          "Regular updates and briefings",
        ],
      },
      {
        sectionTitle: "Environment and Leave No Trace",
        content: [
          "Commitment to environmentally responsible trekking/climbing",
          "Practices to minimize environmental impact",
        ],
      },
    ],
    exclusion: [
      {
        sectionTitle: "Travel Insurance",
        content: [
          "Personal travel insurance covering medical expenses, evacuation, and other unforeseen circumstances.",
        ],
      },
      {
        sectionTitle: "Personal Gear",
        content: [
          "Individual trekking equipment and personal clothing.",
          "Any additional gear required for specific preferences or comfort.",
        ],
      },
      {
        sectionTitle: "Meals in Manali",
        content: [
          "Meals in Manali on arrival and departure days.",
          "Expenses for additional meals beyond the provided trek meals.",
        ],
      },
      {
        sectionTitle: "Beverages",
        content: [
          "Expenses for additional beverages or snacks not included in the provided meals.",
        ],
      },
      {
        sectionTitle: "Personal Expenses",
        content: [
          "Any expenses of a personal nature, including souvenirs, phone calls, laundry, and tips.",
        ],
      },
      {
        sectionTitle: "Transport beyond Itinerary",
        content: [
          "Transportation or expenses beyond the specified itinerary or additional travel arrangements.",
        ],
      },
      {
        sectionTitle: "Medical Expenses",
        content: [
          "Costs related to pre-existing medical conditions or personal medication.",
        ],
      },
      {
        sectionTitle: "Changes in Itinerary",
        content: [
          "Any additional costs incurred due to changes in the itinerary caused by factors beyond the control of the climb organizers.",
        ],
      },
      {
        sectionTitle: "Unforeseen Circumstances",
        content: [
          "Expenses arising from unforeseen circumstances such as natural disasters, political unrest, or flight cancellations.",
        ],
      },
      {
        sectionTitle: "Additional Services",
        content: [
          "Any services or facilities not explicitly mentioned in the inclusions.",
        ],
      },
      {
        sectionTitle: "Porter Services",
        content: [
          "Personal porter services for carrying individual backpacks, if required.",
        ],
      },
      {
        sectionTitle: "Optional Activities",
        content: [
          "Costs associated with optional activities or excursions not included in the itinerary.",
        ],
      },
      {
        sectionTitle: "Government Fees",
        content: [
          "Any additional fees imposed by government authorities during the climb.",
        ],
      },
    ],
    youtubeLink:
      "https://www.youtube.com/embed/csCpWrakV-M?si=dxCL8jIq4lOIOW0v",
    expeditionDate: [
      {
        id: 1,
        duration: "7 Days",
        startDate: "",
        endDate: "",
        bookedSeat: "",
        availableSeat: "",
      },
      {
        id: 2,
        duration: "7 Days",
        startDate: "",
        endDate: "",
        bookedSeat: "",
        availableSeat: "",
      },
      {
        id: 3,
        duration: "7 Days",
        startDate: "",
        endDate: "",
        bookedSeat: "",
        availableSeat: "",
      },
      {
        id: 4,
        duration: "7 Days",
        startDate: "",
        endDate: "",
        bookedSeat: "",
        availableSeat: "",
      },
      {
        id: 5,
        duration: "7 Days",
        startDate: "",
        endDate: "",
        bookedSeat: "",
        availableSeat: "",
      },
    ],
  },
  {
    individualExpeditionType: "yunamPeak",
    individualExpeditionName: "Yunam Peak",
    carouselData: expedition6000MHeroSection,
    location: "Bharatpur, Himachal Pradesh",
    duration: "9 Days",
    grade: "Intermediate",
    maxAltitude: "6111 m or 20,013 ft",
    bestSeason: "June, July, August, September and October",
    experienceRequired:
      "An enthusiast should have trekked between 4000 m to 5000 m at least once.",
    mainExpeditionContent:
      "Standing proudly at 6,100 meters, Yunam is a formidable yet captivating mountain in the Indian Himalayas. Nestled in the Lahaul region, this peak calls the adventure enthusiasts with its challenging ascent and breathtaking panoramic views. The climb to Yunam Peak is a test of both skill and endurance, rewarding climbers with glimpses of pristine glaciers and the rugged beauty of the surrounding landscape. 200 km north of Manali, on the way to Leh, this peak is almost in the middle of nowhere. The vast expanse of stunning and unforgiving Baralacha La (4320 m) is known for its notoriety when it comes to low oxygen levels. Low oxygen levels test the mettle of both, amateur and experienced climbers. This barren, windy, and isolated Yunam Peak is a worthy challenge to begin or establish yourself as a mountaineer.",
    aboutExpeditionExperienced:
      "Climb Yunam Peak with our seasoned climbing team with over a decade of experience in the Indian Himalayas. We pride ourselves on upholding the highest safety standards throughout our operations. With a track record of more than 10 years, we've honed our skills and know these mountains like the back of our hands. Our commitment to safety goes beyond words—we have meticulous rescue plans in place, ready to be executed if the need arises. When you embark on an adventure with us, rest assured that you're in the hands of professionals who prioritize your safety above all else. Welcome to a team that's as prepared as it gets.",
    itineraryData: [
      {
        day: "1",
        title: "Arrival in Manali",
        details: [
          "Arrive in Manali",
          "Check into hotel",
          "Explore local surroundings and markets",
          "Enjoy scenic landscapes",
          "Acclimatize",
          "Evening meeting and briefing",
        ],
      },
      {
        day: "2",
        title: "Acclimatisation hike in Manali",
        details: [
          "Short hike to Lama dugh (4-5 hours)",
          "Elevation 2650m",
          "Enjoy scenic view",
        ],
      },
      {
        day: "3",
        title: "Manali to Bharatpur",
        details: [
          "63 km journey to Bharatpur Base Camp (3 hours)",
          "Cross Barlacha La (4230 m)",
          "Enjoy mesmerizing landscapes",
          "Arrive at base camp and spend the night",
        ],
      },
      {
        day: "4",
        title: "Acclimatisation day",
        details: [
          "load ferry to summit camp and back to base camp (5-6 hours)",
          "This process is crucial for adjusting to higher altitudes and ensuring a safer climb.",
        ],
      },
      {
        day: "5",
        title: "Climb and Set up Summit Camp (5300 m)",
        details: [
          "4-hour climb for summit camp",
          "Elevation gain 5300m",
          "Prepare for summit attempt the following night",
        ],
      },
      {
        day: "6",
        title: "Summit Day - Yunam Peak (6100 m)",
        details: [
          "Early morning ascent to Yunam Peak (around 2 am)",
          "Enjoy the sunrise from the top",
          "Summit the peak and descend (10-11 hours)",
          "Descend to Summit Camp/Base Camp (depending on conditions)",
        ],
      },
      {
        day: "7",
        title: "Bharatpur Base Camp to Manali Drive",
        details: [
          "6-7 hour drive back to Manali (200 km)",
          "Spend the night in a comfortable hotel",
        ],
      },
      {
        day: "8",
        title: "Departure from Manali",
        details: ["Bid farewell to Manali and conclude the expedition"],
      },
      {
        day: "Buffer",
        title: "Buffer Day for Summit",
        details: [
          "Flexibility for unexpected conditions or additional acclimatization",
        ],
      },
    ],
    inclusion: [
      {
        sectionTitle: "Accommodation",
        content: [
          "Hotel accommodation in Manali on Day 1 and Day 7.",
          "Camp accommodation during the trek days.",
        ],
      },
      {
        sectionTitle: "Meals",
        content: [
          "Nutritious and hygienic meals throughout the climb.",
          "Breakfast, lunch, and dinner are provided during the climbing days.",
          "Special arrangements for dietary preferences if communicated in advance.",
        ],
      },
      {
        sectionTitle: "Transportation",
        content: ["Transportation to and from climbing locations."],
      },
      {
        sectionTitle: "Guides and Support",
        content: [
          "Experienced climb leader and support staff.",
          "Local guides familiar with the terrain.",
          "Support staff for camping and logistics.",
        ],
      },
      {
        sectionTitle: "Permits and Entrance Fees",
        content: [
          "All necessary permits and entry fees.",
          "Permissions for climbing in the region.",
          "Indian Mountaineering Foundation (IMF) registration.",
        ],
      },
      {
        sectionTitle: "Safety and Medical Assistance",
        content: [
          "First aid kit and basic medical assistance.",
          "Emergency evacuation plans in place.",
          "Experienced guides trained in high-altitude rescue operations.",
        ],
      },
      {
        sectionTitle: "Equipment",
        content: [
          "Tents, sleeping bags, and other camping equipment.",
          "Safety equipment for the climb.",
          "Personal gear check and assistance.",
        ],
      },
      {
        sectionTitle: "Climbing Assistance",
        content: [
          "Guidance and support during the summit day.",
          "Assistance with technical aspects of the climb.",
        ],
      },
      {
        sectionTitle: "Documentation",
        content: [
          "Detailed itinerary and information before the climb.",
          "Completion certificates for the participants.",
        ],
      },
      {
        sectionTitle: "Communication",
        content: [
          "Communication equipment for emergencies.",
          "Regular updates and briefings.",
        ],
      },
      {
        sectionTitle: "Environment and Leave No Trace",
        content: [
          "Commitment to environmentally responsible trekking.",
          "Practices to minimize environmental impact.",
        ],
      },
    ],
    exclusion: [
      {
        sectionTitle: "Travel Insurance",
        content: [
          "Personal travel insurance covering medical expenses, evacuation, and other unforeseen circumstances.",
        ],
      },
      {
        sectionTitle: "Personal Gear",
        content: [
          "Individual trekking equipment and personal clothing.",
          "Any additional gear required for specific preferences or comfort.",
        ],
      },
      {
        sectionTitle: "Meals in Manali",
        content: [
          "Meals in Manali on arrival and departure days.",
          "Expenses for additional meals beyond the provided trek meals.",
        ],
      },
      {
        sectionTitle: "Beverages",
        content: [
          "Expenses for additional beverages or snacks not included in the provided meals.",
        ],
      },
      {
        sectionTitle: "Personal Expenses",
        content: [
          "Any expenses of a personal nature, including souvenirs, phone calls, laundry, and tips.",
        ],
      },
      {
        sectionTitle: "Transport beyond Itinerary",
        content: [
          "Transportation or expenses beyond the specified itinerary or additional travel arrangements.",
        ],
      },
      {
        sectionTitle: "Medical Expenses",
        content: [
          "Costs related to pre-existing medical conditions or personal medication.",
        ],
      },
      {
        sectionTitle: "Changes in Itinerary",
        content: [
          "Any additional costs incurred due to changes in the itinerary caused by factors beyond the control of the climb organizers.",
        ],
      },
      {
        sectionTitle: "Unforeseen Circumstances",
        content: [
          "Expenses arising from unforeseen circumstances such as natural disasters, political unrest, or flight cancellations.",
        ],
      },
      {
        sectionTitle: "Additional Services",
        content: [
          "Any services or facilities not explicitly mentioned in the inclusions.",
        ],
      },
      {
        sectionTitle: "Porter Services",
        content: [
          "Personal porter services for carrying individual backpacks, if required.",
        ],
      },
      {
        sectionTitle: "Optional Activities",
        content: [
          "Costs associated with optional activities or excursions not included in the trek itinerary.",
        ],
      },
      {
        sectionTitle: "Government Fees",
        content: [
          "Any additional fees imposed by government authorities during the trek.",
        ],
      },
    ],
    youtubeLink: "",
    expeditionDate: [
      {
        id: 1,
        duration: "9 Days",
        startDate: "23/05/2024",
        endDate: "",
        bookedSeat: "",
        availableSeat: "",
      },
      {
        id: 2,
        duration: "9 Days",
        startDate: "",
        endDate: "",
        bookedSeat: "",
        availableSeat: "",
      },
      {
        id: 3,
        duration: "9 Days",
        startDate: "",
        endDate: "",
        bookedSeat: "",
        availableSeat: "",
      },
      {
        id: 4,
        duration: "9 Days",
        startDate: "",
        endDate: "",
        bookedSeat: "",
        availableSeat: "",
      },
      {
        id: 5,
        duration: "7 Days",
        startDate: "",
        endDate: "",
        bookedSeat: "",
        availableSeat: "",
      },
    ],
  },
  {
    individualExpeditionType: "hanumanTibba",
    individualExpeditionName: "Hanuman Tibba",
    carouselData: expedition6000MHeroSection,
    location: "Manali, Himachal Pradesh",
    duration: "13 Days",
    grade: "Advanced",
    maxAltitude: "5892 M or 19330 ft",
    bestSeason: "May, June, September and October",
    experienceRequired:
      "Prior experience of 5000m/6000m climbs or multiple challenging treks",
    mainExpeditionContent:
      "Hanuman Tibba, the tallest peak of the Dhauladhar ranges is one of the most challenging peaks in the Manali region. Standing at an impressive 5,982 meters, it challenges mountaineers with its technical prowess and awe-inspiring altitude. Named after the legendary Hindu deity Hanuman, the peak demands both skill and determination to conquer its slopes. What makes it so challenging is the steep and infamous “Tentu Gully” loaded with rock fall sections that’ll get your adrenaline pumping like never before. The peak is a mix of rocky faces and icy ridges and the ascent promises a thrilling adventure for seasoned mountaineers. The summit rewards with a panoramic vista that stretches across the Himalayan range, a visual feast for those who brave its heights. Hanuman Tibba isn't just a peak; it's a calling for mountaineers seeking a blend of technical challenge and breathtaking views, making it a revered icon in the world of alpine pursuits.",
    aboutExpeditionExperienced:
      "Climb Hanuman Tibba with our seasoned climbing team with over a decade of experience in the Indian Himalayas. We pride ourselves on upholding the highest safety standards throughout our operations. With a track record of more than 10 years, we've honed our skills and know these mountains like the back of our hands. Our commitment to safety goes beyond words—we have meticulous rescue plans in place, ready to be executed if the need arises. When you embark on an adventure with us, rest assured that you're in the hands of professionals who prioritize your safety above all else. Welcome to a team that's as prepared as it gets.",
    itineraryData: [
      {
        day: "01",
        title: "Arrive in Manali (2050m)",
        details: [
          "Welcome to the scenic town of Manali. Arrive and settle in at the designated accommodation.",
          "Day dedicated to acclimatization and rest, crucial for adjusting to the altitude.",
          "Team briefing and completion of paperwork with the Indian Mountaineering Foundation (IMF) for necessary permits.",
        ],
      },
      {
        day: "02",
        title: "Solang Nala to Bakarthach (3000m)",
        details: [
          "Early morning departure for a picturesque drive to Dhundi, the starting point of the trek.",
          "Trek to Bakarthach, soaking in stunning mountain views with the iconic Hanuman Tibba in the backdrop.",
          "Enjoy the changing landscapes and the serenity of the trail.",
        ],
      },
      {
        day: "03",
        title: "Bakarthatch to Beas Kund (3600m)",
        details: [
          "Continue the trek towards Beas Kund Glacier, a journey filled with natural wonders.",
          "Reach the base of Tentu Pass, set against the awe-inspiring beauty of the Himalayan landscape.",
          "Camp at a strategic location, preparing for the challenges ahead.",
        ],
      },
      {
        day: "04",
        title: "Rest Day at Base Camp",
        details: [
          "A well-deserved complete rest day at Base Camp to recharge both physically and mentally.",
          "Technical gear distribution to ensure everyone is well-equipped for the upcoming ascent.",
          "General meeting to discuss the intricacies of the route and ensure everyone is on the same page.",
        ],
      },
      {
        day: "05",
        title: "Base Camp to Advanced Camp (4000m)",
        details: [
          "Embark on a challenging ascent with a steep snow slope leading to Advanced Camp.",
          "Halfway up the mountain, pitch tents in a strategic location for optimal acclimatization.",
        ],
      },
      {
        day: "06",
        title: "Advanced Camp to Camp 1 (4800m)",
        details: [
          "Face the challenging climb to Tentu Pass (5000m), a critical point in the expedition.",
          "Reach Camp 1 after overcoming the difficulties, marking significant progress.",
        ],
      },
      {
        day: "07",
        title: "Camp 1 to Summit Camp (5000m)",
        details: [
          "Traverse a flat glacier, arriving at the pivotal Summit Camp.",
          "Devote the day to preparations for the summit attempt, including rest and mental readiness.",
        ],
      },
      {
        day: "08",
        title: "Summit Attempt - Camp 1",
        details: [
          "Embark on the ultimate challenge with a midnight summit attempt.",
          "Revel in the breathtaking 360-degree panoramic views from the summit.",
          "Descend to Camp 1 after the summit.",
        ],
      },
      {
        day: "09",
        title: "Camp 1 to Advanced Camp",
        details: [
          "Return to Advanced Camp, allowing for much-needed rest and recovery.",
        ],
      },
      {
        day: "10",
        title: "Advanced Camp to Base Camp",
        details: [
          "Descend from Tentu Pass, appreciating the changing landscapes.",
          "Celebrate and feast at Base Camp.",
        ],
      },
      {
        day: "11",
        title: "Rest at Base Camp/ Buffer Day",
        details: [
          "Utilize this day as an extra day or buffer day, providing flexibility in case of weather-related issues during the summit.",
          "Complete rest at Base Camp to ensure everyone is in top condition for the journey ahead.",
        ],
      },
      {
        day: "12",
        title: "Base Camp to Manali",
        details: [
          "Trek down to Dhundi and drive back to Manali.",
          "Enjoy the feeling of reaching back to civilization after so many days.",
        ],
      },
      {
        day: "13",
        title: "Departure",
        details: [
          "Conclude the memorable expedition with a heartfelt farewell party in the vibrant town of Manali.",
        ],
      },
    ],
    inclusion: [
      {
        sectionTitle: "Accommodation",
        content: [
          "Comfortable lodging in Manali upon arrival and during the expedition.",
          "Tented accommodation during the trek and at various campsites.",
        ],
      },
      {
        sectionTitle: "Meals",
        content: [
          "Nutritious and delicious meals throughout the expedition, designed to meet the energy demands of high-altitude trekking.",
          "Special provisions for dietary preferences and requirements.",
        ],
      },
      {
        sectionTitle: "Experienced Guides",
        content: [
          "Expert local guides well-versed in the terrain and conditions of the Hanuman Tibba region.",
          "Assistance and guidance to ensure a safe and enjoyable trekking experience.",
        ],
      },
      {
        sectionTitle: "Transportation",
        content: [
          "Transport from Manali to the trek starting points and back, ensuring a seamless and comfortable journey.",
          "Well-maintained vehicles and experienced drivers for safe travel.",
        ],
      },
      {
        sectionTitle: "Camping Equipment",
        content: [
          "High-quality camping gear, including tents, sleeping bags, and other essentials for a comfortable night's rest.",
          "Technical gear distribution and fitting for the summit attempt.",
        ],
      },
      {
        sectionTitle: "Permits and Paperwork",
        content: [
          "Assistance in completing necessary paperwork with the Indian Mountaineering Foundation (IMF).",
          "Coordination of permits required for the expedition.",
        ],
      },
      {
        sectionTitle: "Safety Measures",
        content: [
          "Emergency evacuation plans and access to experienced high-altitude rescue teams.",
          "First aid provisions and medical assistance throughout the expedition.",
        ],
      },
      {
        sectionTitle: "Expedition Certificate",
        content: [
          "Commemorative certificates recognizing the successful completion of the Hanuman Tibba Expedition",
        ],
      },
      {
        sectionTitle: "Communication",
        content: [
          "Communication equipment for emergencies",
          "Regular updates and briefings",
        ],
      },
      {
        sectionTitle: "Environment and Leave No Trace",
        content: [
          "Commitment to environmentally responsible trekking",
          "Practices to minimize environmental impact",
        ],
      },
    ],
    exclusion: [
      {
        sectionTitle: "Travel Insurance",
        content: [
          "Personal travel insurance covering medical expenses, evacuation, and other unforeseen circumstances.",
        ],
      },
      {
        sectionTitle: "Personal Gear",
        content: [
          "Individual trekking equipment and personal clothing.",
          "Any additional gear required for specific preferences or comfort.",
        ],
      },
      {
        sectionTitle: "Meals in Manali",
        content: [
          "Meals in Manali on arrival and departure days.",
          "Expenses for additional meals beyond the provided trek meals.",
        ],
      },
      {
        sectionTitle: "Beverages",
        content: [
          "Expenses for additional beverages or snacks not included in the provided meals.",
        ],
      },
      {
        sectionTitle: "Personal Expenses",
        content: [
          "Any expenses of a personal nature, including souvenirs, phone calls, laundry, and tips.",
        ],
      },
      {
        sectionTitle: "Transport beyond Itinerary",
        content: [
          "Transportation or expenses beyond the specified itinerary or additional travel arrangements.",
        ],
      },
      {
        sectionTitle: "Medical Expenses",
        content: [
          "Costs related to pre-existing medical conditions or personal medication.",
        ],
      },
      {
        sectionTitle: "Changes in Itinerary",
        content: [
          "Any additional costs incurred due to changes in the itinerary caused by factors beyond the control of the trek organizers.",
        ],
      },
      {
        sectionTitle: "Unforeseen Circumstances",
        content: [
          "Expenses arising from unforeseen circumstances such as natural disasters, political unrest, or flight cancellations.",
        ],
      },
      {
        sectionTitle: "Additional Services",
        content: [
          "Any services or facilities not explicitly mentioned in the inclusions.",
        ],
      },
      {
        sectionTitle: "Porter Services",
        content: [
          "Personal porter services for carrying individual backpacks, if required.",
        ],
      },
      {
        sectionTitle: "Optional Activities",
        content: [
          "Costs associated with optional activities or excursions not included in the trek itinerary.",
        ],
      },
      {
        sectionTitle: "Government Fees",
        content: [
          "Any additional fees imposed by government authorities during the trek.",
        ],
      },
    ],
    youtubeLink: "",
    expeditionDate: [
      {
        id: 1,
        duration: "7 Days",
        startDate: "",
        endDate: "",
        bookedSeat: "",
        availableSeat: "",
      },
      {
        id: 2,
        duration: "7 Days",
        startDate: "",
        endDate: "",
        bookedSeat: "",
        availableSeat: "",
      },
      {
        id: 3,
        duration: "7 Days",
        startDate: "",
        endDate: "",
        bookedSeat: "",
        availableSeat: "",
      },
      {
        id: 4,
        duration: "7 Days",
        startDate: "",
        endDate: "",
        bookedSeat: "",
        availableSeat: "",
      },
      {
        id: 5,
        duration: "7 Days",
        startDate: "",
        endDate: "",
        bookedSeat: "",
        availableSeat: "",
      },
    ],
  },
  {
    individualExpeditionType: "deoTibba",
    individualExpeditionName: "Deo Tibba",
    carouselData: expedition6000MHeroSection,
    location: "Manali, Himachal Pradesh",
    duration: "15 Days",
    grade: "Intermediate",
    maxAltitude: "6001 M or 19688 ft",
    bestSeason: "May, June, September and October",
    experienceRequired:
      "Prior experience of 5000m/6000m climbs or multiple challenging treks",
    mainExpeditionContent:
      "Mt. Deo Tibba, standing at 6,001 meters, is the second highest peak in the Pir Panjal range, located in Himachal Pradesh's Kullu valley. Locals believe it's a sacred gathering place for gods. Despite legends, the mountain is known for its unpredictable and extreme moods. The climb starts in Manali and is a serious mountaineering expedition with challenging features like vertical ice gullies and rocky sections. The journey to the base camp offers stunning landscapes, from meadows with colorful wildflowers to rocky terrain and snow-covered slopes.The climb includes navigating the challenging slope to Duhangan Col, known for its intimidating reputation. Mt. Deo Tibba's unique dome-shaped summit sets it apart, featuring a flat, plateau-like ice dome. The expedition demands technical skills, including using fixed ropes and mountaineering equipment for 60-70 degree gradients in vertical ice gullies. This expedition is for experienced trekkers with prior mountaineering knowledge, making it suitable for aspiring mountaineers aiming for heights beyond 6000 meters.",
    aboutExpeditionExperienced:
      "Climb Deo Tibba with our seasoned climbing team with over a decade of experience in the Indian Himalayas. We pride ourselves on upholding the highest safety standards throughout our operations. With a track record of more than 10 years, we've honed our skills and know these mountains like the back of our hands. Our commitment to safety goes beyond words—we have meticulous rescue plans in place, ready to be executed if the need arises. When you embark on an adventure with us, rest assured that you're in the hands of professionals who prioritize your safety above all else. Welcome to a team that's as prepared as it gets.",
    itineraryData: [
      {
        day: "1",
        title: "Arrival in Manali (2,050 M)",
        details: [
          "Arrive in Manali, the starting point.",
          "Participants settle into Manali hotels.",
          "Day dedicated to acclimatizing and group orientation.",
        ],
      },
      {
        day: "2",
        title: "Rest and Acclimatization in Manali (2,050M)",
        details: [
          "Spend a day in Manali for altitude acclimatization.",
          "Explore the town, review packing list, and purchase any needed gear.",
          "Test personal equipment, including snow boots and crampons.",
        ],
      },
      {
        day: "3",
        title: "Manali (2,050M) to Chikka (3,175M)",
        details: [
          "Distance: 20 kms (4 kms drive + 2 hrs trek)",
          "Drive to Khanol, start point for the trek.",
          "Trek through forested land to Chikka.",
          "Campsite at Chikka, offering scenic views and a stream.",
        ],
      },
      {
        day: "4",
        title: "Chikka (3,175M) to Seri (3,700M)",
        details: [
          "Distance: 9 kms",
          "Duration: 5 hrs trek",
          "Gradual ascent through forested land to Birch trees and grasslands.",
          "Pass freshwater spring at Panduropa.",
          "Camp at Seri, surrounded by Tainta cliffs and waterfalls.",
        ],
      },
      {
        day: "5",
        title: "Seri (3,700M) to Tainta - Base Camp (4,480M)",
        details: [
          "Distance: 4 kms",
          "Duration: 4-5 hrs",
          "Climb steep serpentine trail, cross glacier patch.",
          "Enter field with rocks, snow patches, and views of Deo Tibba.",
          "Campsite chosen based on snow conditions.",
        ],
      },
      {
        day: "6",
        title: "Rest and Acclimatization at Base Camp (4,480M)",
        details: [
          "Acclimatization to new altitude and terrain.",
          "Test climbing techniques and mountaineering equipment.",
        ],
      },
      {
        day: "7 - 12",
        title: "Expedition",
        details: [
          "Conditions dictate day-to-day adjustments.",
          "Camps: Base Camp (4,480M) to Camp 1 (5,100M).",
          "Vertical ice gullies, rock patches, knife ridges, and Summit Camp (5,600M).",
          "Summit attempt on Day 11.",
          "Descent to Base Camp by Day 12.",
        ],
      },
      {
        day: "13",
        title: "Base Camp (4,480M) to Manali (2,050M) via Khanol (2,200M)",
        details: [
          "Distance: 18 kms (20 kms drive)",
          "Duration: 5-6 hrs trek + 1 hr drive",
          "Retrace steps via Seri and Chikka to Khanol.",
          "Drive back to Manali.",
        ],
      },
      {
        day: "14 - 15",
        title: "Reserve Days",
        details: [
          "Reserved for unforeseen conditions.",
          "Only used if unexpected difficulties arise.",
        ],
      },
    ],
    inclusion: [
      {
        sectionTitle: "Accommodation",
        content: [
          "Comfortable lodging in Manali upon arrival and during the Deo Tibba expedition.",
          "Tented accommodation during the trek and at various campsites.",
        ],
      },
      {
        sectionTitle: "Meals",
        content: [
          "Nutritious and delicious meals throughout the expedition, designed to meet the energy demands of high-altitude trekking.",
          "Special provisions for dietary preferences and requirements.",
        ],
      },
      {
        sectionTitle: "Experienced Guides",
        content: [
          "Expert local guides well-versed in the terrain and conditions of the region.",
          "Assistance and guidance to ensure a safe and enjoyable trekking experience.",
        ],
      },
      {
        sectionTitle: "Transportation",
        content: [
          "Transport from Manali to the trek starting points and back, ensuring a seamless and comfortable journey.",
          "Well-maintained vehicles and experienced drivers for safe travel.",
        ],
      },
      {
        sectionTitle: "Camping Equipment",
        content: [
          "High-quality camping gear, including tents, sleeping bags, and other essentials for a comfortable night's rest.",
          "Technical gear distribution and fitting for the summit attempt.",
        ],
      },
      {
        sectionTitle: "Permits and Paperwork",
        content: [
          "Assistance in completing necessary paperwork with the Indian Mountaineering Foundation (IMF).",
          "Coordination of permits required for the expedition.",
        ],
      },
      {
        sectionTitle: "Safety Measures",
        content: [
          "Emergency evacuation plans and access to experienced high-altitude rescue teams.",
          "First aid provisions and medical assistance throughout the expedition.",
        ],
      },
      {
        sectionTitle: "Expedition Certificate",
        content: [
          "Commemorative certificates recognizing the successful completion of Deo Tibba Expedition",
        ],
      },
      {
        sectionTitle: "Communication",
        content: [
          "Communication equipment for emergencies",
          "Regular updates and briefings",
        ],
      },
      {
        sectionTitle: "Environment and Leave No Trace",
        content: [
          "Commitment to environmentally responsible trekking",
          "Practices to minimize environmental impact",
        ],
      },
    ],
    exclusion: [
      {
        sectionTitle: "Travel Insurance",
        content: [
          "Personal travel insurance covering medical expenses, evacuation, and other unforeseen circumstances.",
        ],
      },
      {
        sectionTitle: "Personal Gear",
        content: [
          "Individual trekking equipment and personal clothing.",
          "Any additional gear required for specific preferences or comfort.",
        ],
      },
      {
        sectionTitle: "Meals in Manali",
        content: [
          "Meals in Manali on arrival and departure days.",
          "Expenses for additional meals beyond the provided trek meals.",
        ],
      },
      {
        sectionTitle: "Beverages",
        content: [
          "Expenses for additional beverages or snacks not included in the provided meals.",
        ],
      },
      {
        sectionTitle: "Personal Expenses",
        content: [
          "Any expenses of a personal nature, including souvenirs, phone calls, laundry, and tips.",
        ],
      },
      {
        sectionTitle: "Transport beyond Itinerary",
        content: [
          "Transportation or expenses beyond the specified itinerary or additional travel arrangements.",
        ],
      },
      {
        sectionTitle: "Medical Expenses",
        content: [
          "Costs related to pre-existing medical conditions or personal medication.",
        ],
      },
      {
        sectionTitle: "Changes in Itinerary",
        content: [
          "Any additional costs incurred due to changes in the itinerary caused by factors beyond the control of the trek organizers.",
        ],
      },
      {
        sectionTitle: "Unforeseen Circumstances",
        content: [
          "Expenses arising from unforeseen circumstances such as natural disasters, political unrest, or flight cancellations.",
        ],
      },
      {
        sectionTitle: "Additional Services",
        content: [
          "Any services or facilities not explicitly mentioned in the inclusions.",
        ],
      },
      {
        sectionTitle: "Porter Services",
        content: [
          "Personal porter services for carrying individual backpacks, if required.",
        ],
      },
      {
        sectionTitle: "Optional Activities",
        content: [
          "Costs associated with optional activities or excursions not included in the trek itinerary.",
        ],
      },
      {
        sectionTitle: "Government Fees",
        content: [
          "Any additional fees imposed by government authorities during the trek.",
        ],
      },
    ],
    youtubeLink:
      "https://www.youtube.com/embed/KYbUv4NhYpw?si=bLBx88IRnAtj_0VP",
    expeditionDate: [
      {
        id: 1,
        duration: "7 Days",
        startDate: "",
        endDate: "",
        bookedSeat: "",
        availableSeat: "",
      },
      {
        id: 2,
        duration: "7 Days",
        startDate: "",
        endDate: "",
        bookedSeat: "",
        availableSeat: "",
      },
      {
        id: 3,
        duration: "7 Days",
        startDate: "",
        endDate: "",
        bookedSeat: "",
        availableSeat: "",
      },
      {
        id: 4,
        duration: "7 Days",
        startDate: "",
        endDate: "",
        bookedSeat: "",
        availableSeat: "",
      },
      {
        id: 5,
        duration: "7 Days",
        startDate: "",
        endDate: "",
        bookedSeat: "",
        availableSeat: "",
      },
    ],
  },
  {
    individualExpeditionType: "shitidhar",
    individualExpeditionName: "Shitidhar",
    carouselData: expedition5000MHeroSection,
    location: "Manali, Himachal Pradesh",
    duration: "9 Days",
    grade: "Beginners",
    maxAltitude: "5250 M or 17224 ft",
    bestSeason: "May, June, September and October",
    experienceRequired:
      "An enthusiast should have trekked between 4000 m to 5000 m at least once.",
    mainExpeditionContent:
      "Explore the beginner-friendly world of mountaineering with Shitidhar Peak in Himachal Pradesh, India. This climb offers breathtaking panoramic views of Hanuman Tibba and the Dhauladhar and Pir Panjal Range. Trek through the scenic meadows of Bakarthach and Dhundi, with a backdrop of the Beas Kund Glacier. While fitness is crucial, basic knowledge of equipment like ice axes and crampons is required, and a short training will be provided before the technical ascent. Many trekkers with high-altitude experience choose Shitidhar as an introductory climb to mountaineering. Embark on this unforgettable adventure, filled with awe-inspiring landscapes and a sense of accomplishment at the summit. Gear up for an exciting journey to Mount Shitidhar, an ideal starting point for your mountaineering endeavors.",
    aboutExpeditionExperienced:
      "Climb Shitidhar with our seasoned climbing team with over a decade of experience in the Indian Himalayas. We pride ourselves on upholding the highest safety standards throughout our operations. With a track record of more than 10 years, we've honed our skills and know these mountains like the back of our hands. Our commitment to safety goes beyond words—we have meticulous rescue plans in place, ready to be executed if the need arises. When you embark on an adventure with us, rest assured that you're in the hands of professionals who prioritize your safety above all else. Welcome to a team that's as prepared as it gets.",
    itineraryData: [
      {
        day: "1",
        title: "Arrival in Manali",
        details: [
          "Arrive in Manali and take some rest.",
          "Acclimatization walk in the area.",
        ],
      },
      {
        day: "2",
        title: "Dhundi to Bakarthach (3,300 meters)",
        details: [
          "Drive to Dhundi (road-head).",
          "Trek to Bakarthach (4-5 hours).",
        ],
      },
      {
        day: "3",
        title: "Bakarthach to Lady Leg (3,900 meters)",
        details: ["Trek to Lady Leg (4-5 hours)."],
      },
      {
        day: "4",
        title: "Acclimatization & Load Ferry",
        details: [
          "Acclimatization activities.",
          "Load ferry to summit camp.",
          "Overnight stay at Lady Leg.",
        ],
      },
      {
        day: "5",
        title: "Lady Leg to Summit Camp (4,400 meters)",
        details: ["Trek to Summit Camp (2-3 hours)."],
      },
      {
        day: "6",
        title: "Summit Day - Shitidhar Peak (5,250 meters)",
        details: ["Trek to Shitidhar Peak and return to Lady Leg (8-9 hours)."],
      },
      {
        day: "7",
        title: "Lady Leg to Road-head, Drive to Manali",
        details: [
          "Trek down to Road-head.",
          "Drive back to Manali.",
          "Night stay in a hotel with Expedition Celebration Party!",
        ],
      },
      {
        day: "8",
        title: "Departure",
        details: [],
      },
    ],
    inclusion: [
      {
        sectionTitle: "Accommodation",
        content: [
          "Comfortable lodging in Manali upon arrival and during the expedition.",
          "Tented accommodation during the trek and at various campsites.",
        ],
      },
      {
        sectionTitle: "Meals",
        content: [
          "Nutritious and delicious meals throughout the expedition, designed to meet the energy demands of high-altitude trekking.",
          "Special provisions for dietary preferences and requirements.",
        ],
      },
      {
        sectionTitle: "Experienced Guides",
        content: [
          "Expert local guides well-versed in the terrain and conditions of the region.",
          "Assistance and guidance to ensure a safe and enjoyable trekking experience.",
        ],
      },
      {
        sectionTitle: "Transportation",
        content: [
          "Transport from Manali to the trek starting points and back, ensuring a seamless and comfortable journey.",
          "Well-maintained vehicles and experienced drivers for safe travel.",
        ],
      },
      {
        sectionTitle: "Camping Equipment",
        content: [
          "High-quality camping gear, including tents, sleeping bags, and other essentials for a comfortable night's rest.",
          "Technical gear distribution and fitting for the summit attempt.",
        ],
      },
      {
        sectionTitle: "Permits and Paperwork",
        content: [
          "Assistance in completing necessary paperwork with the Indian Mountaineering Foundation (IMF).",
          "Coordination of permits required for the expedition.",
        ],
      },
      {
        sectionTitle: "Safety Measures",
        content: [
          "Emergency evacuation plans and access to experienced high-altitude rescue teams.",
          "First aid provisions and medical assistance throughout the expedition.",
        ],
      },
      {
        sectionTitle: "Expedition Certificate",
        content: [
          "Commemorative certificates recognizing the successful completion Expedition",
        ],
      },
      {
        sectionTitle: "Communication",
        content: [
          "Communication equipment for emergencies",
          "Regular updates and briefings",
        ],
      },
      {
        sectionTitle: "Environment and Leave No Trace",
        content: [
          "Commitment to environmentally responsible trekking",
          "Practices to minimize environmental impact",
        ],
      },
    ],
    exclusion: [
      {
        sectionTitle: "Travel Insurance",
        content: [
          "Personal travel insurance covering medical expenses, evacuation, and other unforeseen circumstances.",
        ],
      },
      {
        sectionTitle: "Personal Gear",
        content: [
          "Individual trekking equipment and personal clothing.",
          "Any additional gear required for specific preferences or comfort.",
        ],
      },
      {
        sectionTitle: "Meals in Manali",
        content: [
          "Meals in Manali on arrival and departure days.",
          "Expenses for additional meals beyond the provided trek meals.",
        ],
      },
      {
        sectionTitle: "Beverages",
        content: [
          "Expenses for additional beverages or snacks not included in the provided meals.",
        ],
      },
      {
        sectionTitle: "Personal Expenses",
        content: [
          "Any expenses of a personal nature, including souvenirs, phone calls, laundry, and tips.",
        ],
      },
      {
        sectionTitle: "Transport beyond Itinerary",
        content: [
          "Transportation or expenses beyond the specified itinerary or additional travel arrangements.",
        ],
      },
      {
        sectionTitle: "Medical Expenses",
        content: [
          "Costs related to pre-existing medical conditions or personal medication.",
        ],
      },
      {
        sectionTitle: "Changes in Itinerary",
        content: [
          "Any additional costs incurred due to changes in the itinerary caused by factors beyond the control of the trek organizers.",
        ],
      },
      {
        sectionTitle: "Unforeseen Circumstances",
        content: [
          "Expenses arising from unforeseen circumstances such as natural disasters, political unrest, or flight cancellations.",
        ],
      },
      {
        sectionTitle: "Additional Services",
        content: [
          "Any services or facilities not explicitly mentioned in the inclusions.",
        ],
      },
      {
        sectionTitle: "Porter Services",
        content: [
          "Personal porter services for carrying individual backpacks, if required.",
        ],
      },
      {
        sectionTitle: "Optional Activities",
        content: [
          "Costs associated with optional activities or excursions not included in the trek itinerary.",
        ],
      },
      {
        sectionTitle: "Government Fees",
        content: [
          "Any additional fees imposed by government authorities during the trek.",
        ],
      },
    ],
    youtubeLink:
      "https://www.youtube.com/embed/fQRdBveRZVE?si=uO5LxRKgPPSHcA0L",
    expeditionDate: [
      {
        id: 1,
        duration: "7 Days",
        startDate: "",
        endDate: "",
        bookedSeat: "",
        availableSeat: "",
      },
      {
        id: 2,
        duration: "7 Days",
        startDate: "",
        endDate: "",
        bookedSeat: "",
        availableSeat: "",
      },
      {
        id: 3,
        duration: "7 Days",
        startDate: "",
        endDate: "",
        bookedSeat: "",
        availableSeat: "",
      },
      {
        id: 4,
        duration: "7 Days",
        startDate: "",
        endDate: "",
        bookedSeat: "",
        availableSeat: "",
      },
      {
        id: 5,
        duration: "7 Days",
        startDate: "",
        endDate: "",
        bookedSeat: "",
        availableSeat: "",
      },
    ],
  },
  {
    individualExpeditionType: "ladakhi",
    individualExpeditionName: "Ladakhi",
    carouselData: expedition5000MHeroSection,
    location: "Manali, Himachal Pradesh",
    duration: "8 Days",
    grade: "Moderate",
    maxAltitude: "5577 m or 18300 ft",
    bestSeason: "May, June, September and October",
    experienceRequired:
      "An enthusiast should have trekked between 4000 m to 5000 m at least once.",
    mainExpeditionContent:
      "Ladakhi Peak, nestled in the Pir Panjal range of Himachal Pradesh, beckons adventure enthusiasts with its challenging trekking route. Named after the first Ladakhi climber, this moderate-level peak demands climbing experience, including the use of mountaineering equipment and traversing snow and ice. The journey begins with a 13 km drive to Solang Nallah, followed by a 2-hour trek to the picturesque meadow of Dhundi. From there, the route winds through green slopes to Bakar Thach, a shepherd encampment, offering a 2-3 hour scenic trek. Bakar Thach serves as the base for peaks like Shitidhar, Ladakhi, Friendship, Manali, and Hanuman Tibba. Continuing towards Beas Kund, a tiny religious lake, the base camp is established for peaks like Friendship, Ladakhi, and Hanuman Tibba. The ascent involves rock climbing, providing breathtaking views of Dhauladhar and Pir Panjal ranges, along with Indrasan and Deotibba peaks. After summiting Ladakhi Peak, the descent leads back to the base camp, followed by a return journey through Beas Kund, Dhundi, and Solang.",
    aboutExpeditionExperienced:
      "Climb Ladakhi with our seasoned climbing team with over a decade of experience in the Indian Himalayas. We pride ourselves on upholding the highest safety standards throughout our operations. With a track record of more than 10 years, we've honed our skills and know these mountains like the back of our hands. Our commitment to safety goes beyond words—we have meticulous rescue plans in place, ready to be executed if the need arises. When you embark on an adventure with us, rest assured that you're in the hands of professionals who prioritize your safety above all else. Welcome to a team that's as prepared as it gets.",
    itineraryData: [
      {
        day: "1",
        title: "Arrival in Manali (2050m)",
        details: [
          "Arrive in Manali and complete necessary paperwork and preparations.",
          "Get acquainted with the upcoming adventure.",
        ],
      },
      {
        day: "2",
        title: "Manali to Bakar Thach (3260m/10700ft)",
        details: [
          "Begin the trek with a relaxed walk-through highland landscapes.",
          "Ascend to Bakar Thach, a picturesque camping site in alpine meadows.",
          "Set up tents and spend the night surrounded by towering mountains.",
        ],
      },
      {
        day: "3",
        title: "Bakar Thach to Beas Kund Glacier (3900m/12800ft)",
        details: [
          "Hike through moraines towards Beas Kund Glacier.",
          "Traverse the moraines and cross the river to reach the glacier.",
          "Set up camps amidst breathtaking scenery for an overnight stay.",
        ],
      },
      {
        day: "4",
        title: "Load Ferry to ABC (4720m/15500ft)",
        details: [
          "Load essential equipment for the Advance Base Camp (ABC).",
          "Return to Base Camp via the same route.",
        ],
      },
      {
        day: "5",
        title: "ABC - Summit Attempts (5577m/18300ft)",
        details: [
          "Move to Advance Base Camp for summit attempts.",
          "Dedicated day for reaching the exhilarating summit at 5577m.",
        ],
      },
      {
        day: "6",
        title: "Reserve Day for Summit Attempts and Return to ABC",
        details: [
          "Use this day as a backup for summit attempts if needed.",
          "After attempts, return to the Advance Base Camp.",
        ],
      },
      {
        day: "7",
        title: "ABC to Bakar Thach",
        details: [
          "Begin the return journey to Bakar Thach, retracing steps.",
          "Celebrate achievements at the campsite and spend the night.",
        ],
      },
      {
        day: "8",
        title: "Bakar Thach to Solang Nala Trek and Return to Manali",
        details: [
          "Trek back to civilization through Solang Nala.",
          "Reach Manali and depart for respective destinations, concluding the mountain expedition.",
        ],
      },
    ],
    inclusion: [
      {
        sectionTitle: "Accommodation",
        content: [
          "Comfortable lodging in Manali upon arrival and during the expedition.",
          "Tented accommodation during the trek and at various campsites.",
        ],
      },
      {
        sectionTitle: "Meals",
        content: [
          "Nutritious and delicious meals throughout the expedition, designed to meet the energy demands of high-altitude trekking.",
          "Special provisions for dietary preferences and requirements.",
        ],
      },
      {
        sectionTitle: "Experienced Guides",
        content: [
          "Expert local guides well-versed in the terrain and conditions of the region.",
          "Assistance and guidance to ensure a safe and enjoyable trekking experience.",
        ],
      },
      {
        sectionTitle: "Transportation",
        content: [
          "Transport from Manali to the trek starting points and back, ensuring a seamless and comfortable journey.",
          "Well-maintained vehicles and experienced drivers for safe travel.",
        ],
      },
      {
        sectionTitle: "Camping Equipment",
        content: [
          "High-quality camping gear, including tents, sleeping bags, and other essentials for a comfortable night's rest.",
          "Technical gear distribution and fitting for the summit attempt.",
        ],
      },
      {
        sectionTitle: "Permits and Paperwork",
        content: [
          "Assistance in completing necessary paperwork with the Indian Mountaineering Foundation (IMF).",
          "Coordination of permits required for the expedition.",
        ],
      },
      {
        sectionTitle: "Safety Measures",
        content: [
          "Emergency evacuation plans and access to experienced high-altitude rescue teams.",
          "First aid provisions and medical assistance throughout the expedition.",
        ],
      },
      {
        sectionTitle: "Expedition Certificate",
        content: [
          "Commemorative certificates recognizing the successful completion Expedition",
        ],
      },
      {
        sectionTitle: "Communication",
        content: [
          "Communication equipment for emergencies",
          "Regular updates and briefings",
        ],
      },
      {
        sectionTitle: "Environment and Leave No Trace",
        content: [
          "Commitment to environmentally responsible trekking",
          "Practices to minimize environmental impact",
        ],
      },
    ],
    exclusion: [
      {
        sectionTitle: "Travel Insurance",
        content: [
          "Personal travel insurance covering medical expenses, evacuation, and other unforeseen circumstances.",
        ],
      },
      {
        sectionTitle: "Personal Gear",
        content: [
          "Individual trekking equipment and personal clothing.",
          "Any additional gear required for specific preferences or comfort.",
        ],
      },
      {
        sectionTitle: "Meals in Manali",
        content: [
          "Meals in Manali on arrival and departure days.",
          "Expenses for additional meals beyond the provided trek meals.",
        ],
      },
      {
        sectionTitle: "Beverages",
        content: [
          "Expenses for additional beverages or snacks not included in the provided meals.",
        ],
      },
      {
        sectionTitle: "Personal Expenses",
        content: [
          "Any expenses of a personal nature, including souvenirs, phone calls, laundry, and tips.",
        ],
      },
      {
        sectionTitle: "Transport beyond Itinerary",
        content: [
          "Transportation or expenses beyond the specified itinerary or additional travel arrangements.",
        ],
      },
      {
        sectionTitle: "Medical Expenses",
        content: [
          "Costs related to pre-existing medical conditions or personal medication.",
        ],
      },
      {
        sectionTitle: "Changes in Itinerary",
        content: [
          "Any additional costs incurred due to changes in the itinerary caused by factors beyond the control of the trek organizers.",
        ],
      },
      {
        sectionTitle: "Unforeseen Circumstances",
        content: [
          "Expenses arising from unforeseen circumstances such as natural disasters, political unrest, or flight cancellations.",
        ],
      },
      {
        sectionTitle: "Additional Services",
        content: [
          "Any services or facilities not explicitly mentioned in the inclusions.",
        ],
      },
      {
        sectionTitle: "Porter Services",
        content: [
          "Personal porter services for carrying individual backpacks, if required.",
        ],
      },
      {
        sectionTitle: "Optional Activities",
        content: [
          "Costs associated with optional activities or excursions not included in the trek itinerary.",
        ],
      },
      {
        sectionTitle: "Government Fees",
        content: [
          "Any additional fees imposed by government authorities during the trek.",
        ],
      },
    ],
    youtubeLink: "",
    expeditionDate: [
      {
        id: 1,
        duration: "7 Days",
        startDate: "",
        endDate: "",
        bookedSeat: "",
        availableSeat: "",
      },
      {
        id: 2,
        duration: "7 Days",
        startDate: "",
        endDate: "",
        bookedSeat: "",
        availableSeat: "",
      },
      {
        id: 3,
        duration: "7 Days",
        startDate: "",
        endDate: "",
        bookedSeat: "",
        availableSeat: "",
      },
      {
        id: 4,
        duration: "7 Days",
        startDate: "",
        endDate: "",
        bookedSeat: "",
        availableSeat: "",
      },
      {
        id: 5,
        duration: "7 Days",
        startDate: "",
        endDate: "",
        bookedSeat: "",
        availableSeat: "",
      },
    ],
  },
  {
    individualExpeditionType: "ladyOfKeylong",
    individualExpeditionName: "Lady Of Keylong",
    carouselData: expedition6000MHeroSection,
    location: "Lahaul, Himachal Pradesh",
    duration: "11 Days",
    grade: "Advanced",
    maxAltitude: "6061 M or 19886 ft",
    bestSeason: "June, July, August, September and October",
    experienceRequired:
      "Prior experience of 5000m/6000m climbs or multiple challenging treks",
    mainExpeditionContent:
      "The Lady of Keylong, a lesser-known peak in the challenging Lahaul and Spiti region, was named after British Lady Elashaingh during British rule. The Geological Survey of India also recognized it as the 'Lady of Keylong'. Despite its obscurity, the mountain's snow-covered terrain, interspersed with rocky patches, resembles a lady carrying a load.The climb is technically demanding, starting from Tinoo village near Keylong. The route involves campsites, strenuous terrain, severe weather, and high altitudes. After acclimatization, climbers face steep ascents, gullies with mixed terrain, glaciers, ice walls, and open crevasses. The final summit push, undertaken at night, involves traversing icy slopes, humps, and deep crevasses, reaching a height of approximately 6061m. The summit offers panoramic views of surrounding peaks and valleys. ",
    aboutExpeditionExperienced:
      "Climb Lady of Keylong with our seasoned climbing team with over a decade of experience in the Indian Himalayas. We pride ourselves on upholding the highest safety standards throughout our operations. With a track record of more than 10 years, we've honed our skills and know these mountains like the back of our hands. Our commitment to safety goes beyond words—we have meticulous rescue plans in place, ready to be executed if the need arises. When you embark on an adventure with us, rest assured that you're in the hands of professionals who prioritize your safety above all else. Welcome to a team that's as prepared as it gets.",
    itineraryData: [
      {
        day: "1",
        title: "Arrival in Manali (2050m)",
        details: [
          "Arrive in Manali and complete necessary documentation.",
          "Finalize preparations for the upcoming expedition.",
        ],
      },
      {
        day: "2",
        title: "Manali to Jispa (3200m)",
        details: [
          "Scenic drive to Jispa, reaching an altitude of 3200m.",
          "Enjoy picturesque landscapes along the journey.",
        ],
      },
      {
        day: "3",
        title: "Rest Day in Jispa",
        details: [
          "Acclimatize to the higher altitude.",
          "Mental and physical preparation for the challenges ahead.",
        ],
      },
      {
        day: "4",
        title: "Jispa to Base Camp",
        details: [
          "Begin the trek to the Base Camp, setting the stage for the ascent.",
        ],
      },
      {
        day: "5",
        title: "Load Ferry to Advanced Base Camp (ABC) (4720m)",
        details: [
          "Transport essential equipment to Advanced Base Camp.",
          "Prepare for the climb to higher altitudes.",
        ],
      },
      {
        day: "6",
        title: "Occupy Advanced Base Camp (ABC)",
        details: [
          "Settle into Advanced Base Camp.",
          "Allow the body to adjust to increased altitude.",
        ],
      },
      {
        day: "7",
        title: "Advanced Base Camp to Summit Camp",
        details: [
          "Commence the ascent to Summit Camp, a crucial step toward the pinnacle.",
        ],
      },
      {
        day: "8",
        title: "Summit Attempts (6061m)",
        details: [
          "Embark on the summit push to approximately 6061m.",
          "Enjoy breathtaking panoramic views from the summit.",
        ],
      },
      {
        day: "9",
        title: "Reserve Day for Summit Attempts and Return to Base Camp",
        details: [
          "Contingency day for summit attempts.",
          "Safely descend to Base Camp.",
        ],
      },
      {
        day: "10",
        title: "Base Camp to Jispa",
        details: ["Trek back from Base Camp to Jispa."],
      },
      {
        day: "11",
        title: "Jispa to Manali via Taxi",
        details: [
          "Conclude the expedition with a comfortable taxi ride to Manali.",
          "Depart with a sense of fulfillment and lasting memories.",
        ],
      },
    ],
    inclusion: [
      {
        sectionTitle: "Accommodation",
        content: [
          "Comfortable lodging in Manali upon arrival and during the expedition.",
          "Tented accommodation during the trek and at various campsites.",
        ],
      },
      {
        sectionTitle: "Meals",
        content: [
          "Nutritious and delicious meals throughout the expedition, designed to meet the energy demands of high-altitude trekking.",
          "Special provisions for dietary preferences and requirements.",
        ],
      },
      {
        sectionTitle: "Experienced Guides",
        content: [
          "Expert local guides well-versed in the terrain and conditions of the region.",
          "Assistance and guidance to ensure a safe and enjoyable trekking experience.",
        ],
      },
      {
        sectionTitle: "Transportation",
        content: [
          "Transport from Manali to the trek starting points and back, ensuring a seamless and comfortable journey.",
          "Well-maintained vehicles and experienced drivers for safe travel.",
        ],
      },
      {
        sectionTitle: "Camping Equipment",
        content: [
          "High-quality camping gear, including tents, sleeping bags, and other essentials for a comfortable night's rest.",
          "Technical gear distribution and fitting for the summit attempt.",
        ],
      },
      {
        sectionTitle: "Permits and Paperwork",
        content: [
          "Assistance in completing necessary paperwork with the Indian Mountaineering Foundation (IMF).",
          "Coordination of permits required for the expedition.",
        ],
      },
      {
        sectionTitle: "Safety Measures",
        content: [
          "Emergency evacuation plans and access to experienced high-altitude rescue teams.",
          "First aid provisions and medical assistance throughout the expedition.",
        ],
      },
      {
        sectionTitle: "Expedition Certificate",
        content: [
          "Commemorative certificates recognizing the successful completion Expedition",
        ],
      },
      {
        sectionTitle: "Communication",
        content: [
          "Communication equipment for emergencies",
          "Regular updates and briefings",
        ],
      },
      {
        sectionTitle: "Environment and Leave No Trace",
        content: [
          "Commitment to environmentally responsible trekking",
          "Practices to minimize environmental impact",
        ],
      },
    ],
    exclusion: [
      {
        sectionTitle: "Travel Insurance",
        content: [
          "Personal travel insurance covering medical expenses, evacuation, and other unforeseen circumstances.",
        ],
      },
      {
        sectionTitle: "Personal Gear",
        content: [
          "Individual trekking equipment and personal clothing.",
          "Any additional gear required for specific preferences or comfort.",
        ],
      },
      {
        sectionTitle: "Meals in Manali",
        content: [
          "Meals in Manali on arrival and departure days.",
          "Expenses for additional meals beyond the provided trek meals.",
        ],
      },
      {
        sectionTitle: "Beverages",
        content: [
          "Expenses for additional beverages or snacks not included in the provided meals.",
        ],
      },
      {
        sectionTitle: "Personal Expenses",
        content: [
          "Any expenses of a personal nature, including souvenirs, phone calls, laundry, and tips.",
        ],
      },
      {
        sectionTitle: "Transport beyond Itinerary",
        content: [
          "Transportation or expenses beyond the specified itinerary or additional travel arrangements.",
        ],
      },
      {
        sectionTitle: "Medical Expenses",
        content: [
          "Costs related to pre-existing medical conditions or personal medication.",
        ],
      },
      {
        sectionTitle: "Changes in Itinerary",
        content: [
          "Any additional costs incurred due to changes in the itinerary caused by factors beyond the control of the trek organizers.",
        ],
      },
      {
        sectionTitle: "Unforeseen Circumstances",
        content: [
          "Expenses arising from unforeseen circumstances such as natural disasters, political unrest, or flight cancellations.",
        ],
      },
      {
        sectionTitle: "Additional Services",
        content: [
          "Any services or facilities not explicitly mentioned in the inclusions.",
        ],
      },
      {
        sectionTitle: "Porter Services",
        content: [
          "Personal porter services for carrying individual backpacks, if required.",
        ],
      },
      {
        sectionTitle: "Optional Activities",
        content: [
          "Costs associated with optional activities or excursions not included in the trek itinerary.",
        ],
      },
      {
        sectionTitle: "Government Fees",
        content: [
          "Any additional fees imposed by government authorities during the trek.",
        ],
      },
    ],
    youtubeLink: "",
    expeditionDate: [
      {
        id: 1,
        duration: "7 Days",
        startDate: "",
        endDate: "",
        bookedSeat: "",
        availableSeat: "",
      },
      {
        id: 2,
        duration: "7 Days",
        startDate: "",
        endDate: "",
        bookedSeat: "",
        availableSeat: "",
      },
      {
        id: 3,
        duration: "7 Days",
        startDate: "",
        endDate: "",
        bookedSeat: "",
        availableSeat: "",
      },
      {
        id: 4,
        duration: "7 Days",
        startDate: "",
        endDate: "",
        bookedSeat: "",
        availableSeat: "",
      },
      {
        id: 5,
        duration: "7 Days",
        startDate: "",
        endDate: "",
        bookedSeat: "",
        availableSeat: "",
      },
    ],
  },
  {
    individualExpeditionType: "kangyatse1",
    individualExpeditionName: "Kangyatse 1",
    carouselData: expedition6000MHeroSection,
    location: "Markha Valley, Ladakh",
    duration: "13 Days",
    grade: "Intermediate",
    maxAltitude: "6401 m or 21,000 ft",
    bestSeason: "July, August, September and October",
    experienceRequired:
      "An enthusiast should have prior experience of 5000m/6000m climbs or multiple treks",
    mainExpeditionContent:
      "Kangyatse is a prominent mountain located in the Indian union territory of Ladakh. It is part of the Himalayas and specifically situated in the Markha Valley region. Kangyatse is a popular peak among mountaineers and trekkers due to its challenging ascent and stunning surrounding landscapes.Kangyatse has two main summits: Kangyatse I (6,401 meters or 21,000 feet) and Kangyatse II (6,250 meters or 20,505 feet). Climbers often aim to reach the higher Kangyatse I. The ascent involves technical climbing, and the trek to the base camp provides breathtaking views of the Himalayan range.Ladakh's harsh yet captivating terrain, with its high-altitude desert and snow-capped peaks, adds to the allure of Mount Kangyatse for adventure enthusiasts seeking a challenging climb and an immersive experience in the stunning landscapes of the region.",
    aboutExpeditionExperienced:
      "Climb Kangyatse 1 with our seasoned climbing team with over a decade of experience in the Indian Himalayas. We pride ourselves on upholding the highest safety standards throughout our operations. With a track record of more than 10 years, we've honed our skills and know these mountains like the back of our hands. Our commitment to safety goes beyond words—we have meticulous rescue plans in place, ready to be executed if the need arises. When you embark on an adventure with us, rest assured that you're in the hands of professionals who prioritize your safety above all else. Welcome to a team that's as prepared as it gets.",
    itineraryData: [
      {
        day: "1",
        title: "Arrival in Leh",
        details: [
          "Arrive in Leh and acclimate to the high altitude.",
          "Check-in at the hotel and take it easy to adjust to the surroundings.",
        ],
      },
      {
        day: "2",
        title: "Monastery Marvels and Stupa Serenity",
        details: [
          "Morning walk to Shanti Stupa.",
          "Visit Monastery, market and finish the shopping if you need anything for the expedition.",
          "Relax and unwind at the hotel.",
        ],
      },
      {
        day: "3",
        title:
          "Leh to Skiu drive and Trek To Markha (Drive 3-4 hours) (Trek 3-4 hours ) 3700M",
        details: [
          "Witness the mesmerising landscape along the journey.",
          "Embark on a trek from skiu to Markha, pass through the zanskar and Indus confluence.",
          "Overnight stay at markha homestay.",
        ],
      },
      {
        day: "4",
        title: "Markha To Hunkar Trek (4-5 hours) 4300 M",
        details: [
          "Encounter divers terrains, including river crossing and barley fields.",
          "Explore the beautiful village hunkar.",
          "Night stay at homestay.",
        ],
      },
      {
        day: "5",
        title: "Hunker to base camp Trek (7-8 hours) 5100 M",
        details: [
          "Marvel at the views of Kangyatse and its surroundings.",
          "Reach the base camp and experience its exposed beauty.",
        ],
      },
      {
        day: "6",
        title: "Rest And Acclimatisation walk (3-4 hours)",
        details: [
          "Explore surrounding base camp short hike.",
          "Practice essential skills for the upcoming climb.",
          "Return to base camp and rest.",
        ],
      },
      {
        day: "7",
        title: "Base Camp to Advance Camp 5400 M",
        details: [
          "Take a 2-3 hour hike for acclimatization and gear training.",
          "Practice essential skills for the upcoming climb.",
          "Return to base camp for a well-deserved rest.",
        ],
      },
      {
        day: "8-11",
        title: "Summit Expedition",
        details: [
          "Day 8: Advance Camp to Summit Camp 5700M",
          "Day 9: Summit and back to advance camp or base camp.",
          "Day 10: Base camp to Hunkar.",
          "Day 11: Hunkar to Leh drop.",
          "Buffer Days: 2 Extra buffer days for Summit.",
        ],
      },
      // {
      //   day: "12",
      //   title: "Trek to Chokdo (3960m) and Drive to Leh",
      //   details: [
      //     "Traverse Kongmaru la Pass (5200m) with breathtaking views.",
      //     "Descend to Chokdo, passing through Nimaling and Chusukarmo.",
      //     "Drive back to Leh after a challenging day.",
      //   ],
      // },
      // {
      //   day: "13",
      //   title: "Departure",
      //   details: [
      //     "Fly back, carrying unforgettable memories of the Leh adventure.",
      //   ],
      // },
    ],
    inclusion: [
      {
        sectionTitle: "Accommodation",
        content: [
          "Comfortable lodging in Leh upon arrival and during the expedition.",
          "Tented accommodation during the trek and at various campsites.",
        ],
      },
      {
        sectionTitle: "Meals",
        content: [
          "Nutritious and delicious meals throughout the expedition, designed to meet the energy demands of high-altitude trekking.",
          "Special provisions for dietary preferences and requirements.",
        ],
      },
      {
        sectionTitle: "Experienced Guides",
        content: [
          "Expert local guides well-versed in the terrain and conditions of the region.",
          "Assistance and guidance to ensure a safe and enjoyable trekking experience.",
        ],
      },
      {
        sectionTitle: "Transportation",
        content: [
          "Transport from Leh to the trek starting points and back, ensuring a seamless and comfortable journey.",
          "Well-maintained vehicles and experienced drivers for safe travel.",
        ],
      },
      {
        sectionTitle: "Camping Equipment",
        content: [
          "High-quality camping gear, including tents, sleeping bags, and other essentials for a comfortable night's rest.",
          "Technical gear distribution and fitting for the summit attempt.",
        ],
      },
      {
        sectionTitle: "Permits and Paperwork",
        content: [
          "Assistance in completing necessary paperwork with the Indian Mountaineering Foundation (IMF).",
          "Coordination of permits required for the expedition.",
        ],
      },
      {
        sectionTitle: "Safety Measures",
        content: [
          "Emergency evacuation plans and access to experienced high-altitude rescue teams.",
          "First aid provisions and medical assistance throughout the expedition.",
        ],
      },
      {
        sectionTitle: "Expedition Certificate",
        content: [
          "Commemorative certificates recognizing the successful completion Expedition",
        ],
      },
      {
        sectionTitle: "Communication",
        content: [
          "Communication equipment for emergencies",
          "Regular updates and briefings",
        ],
      },
      {
        sectionTitle: "Environment and Leave No Trace",
        content: [
          "Commitment to environmentally responsible trekking",
          "Practices to minimize environmental impact",
        ],
      },
    ],
    exclusion: [
      {
        sectionTitle: "Travel Insurance",
        content: [
          "Personal travel insurance covering medical expenses, evacuation, and other unforeseen circumstances.",
        ],
      },
      {
        sectionTitle: "Personal Gear",
        content: [
          "Individual trekking equipment and personal clothing.",
          "Any additional gear required for specific preferences or comfort.",
        ],
      },
      {
        sectionTitle: "Meals in Leh",
        content: [
          "Meals in Leh on arrival and departure days.",
          "Expenses for additional meals beyond the provided trek meals.",
        ],
      },
      {
        sectionTitle: "Beverages",
        content: [
          "Expenses for additional beverages or snacks not included in the provided meals.",
        ],
      },
      {
        sectionTitle: "Personal Expenses",
        content: [
          "Any expenses of a personal nature, including souvenirs, phone calls, laundry, and tips.",
        ],
      },
      {
        sectionTitle: "Transport beyond Itinerary",
        content: [
          "Transportation or expenses beyond the specified itinerary or additional travel arrangements.",
        ],
      },
      {
        sectionTitle: "Medical Expenses",
        content: [
          "Costs related to pre-existing medical conditions or personal medication.",
        ],
      },
      {
        sectionTitle: "Changes in Itinerary",
        content: [
          "Any additional costs incurred due to changes in the itinerary caused by factors beyond the control of the trek organizers.",
        ],
      },
      {
        sectionTitle: "Unforeseen Circumstances",
        content: [
          "Expenses arising from unforeseen circumstances such as natural disasters, political unrest, or flight cancellations.",
        ],
      },
      {
        sectionTitle: "Additional Services",
        content: [
          "Any services or facilities not explicitly mentioned in the inclusions.",
        ],
      },
      {
        sectionTitle: "Porter Services",
        content: [
          "Personal porter services for carrying individual backpacks, if required.",
        ],
      },
      {
        sectionTitle: "Optional Activities",
        content: [
          "Costs associated with optional activities or excursions not included in the trek itinerary.",
        ],
      },
      {
        sectionTitle: "Government Fees",
        content: [
          "Any additional fees imposed by government authorities during the trek.",
        ],
      },
    ],
    youtubeLink:
      "https://www.youtube.com/embed/TrVP-533qdc?si=Mjkzm_8qPQt1QNZl",
    expeditionDate: [
      {
        id: 1,
        duration: "7 Days",
        startDate: "",
        endDate: "",
        bookedSeat: "",
        availableSeat: "",
      },
      {
        id: 2,
        duration: "7 Days",
        startDate: "",
        endDate: "",
        bookedSeat: "",
        availableSeat: "",
      },
      {
        id: 3,
        duration: "7 Days",
        startDate: "",
        endDate: "",
        bookedSeat: "",
        availableSeat: "",
      },
      {
        id: 4,
        duration: "7 Days",
        startDate: "",
        endDate: "",
        bookedSeat: "",
        availableSeat: "",
      },
      {
        id: 5,
        duration: "7 Days",
        startDate: "",
        endDate: "",
        bookedSeat: "",
        availableSeat: "",
      },
    ],
  },
  {
    individualExpeditionType: "kangyatse2",
    individualExpeditionName: "Kangyatse 2",
    carouselData: expedition6000MHeroSection,
    location: "Markha Valley, Ladakh",
    duration: "11 Days",
    grade: "Intermediate",
    maxAltitude: "6250 m or 20,505 ft",
    bestSeason: "July, August, September and October",
    experienceRequired:
      "An enthusiast should have prior experience of 5000m/6000m climbs or multiple treks.",
    mainExpeditionContent:
      "Kang Yatse II, the Western Summit of Kang Yatse, is situated in Ladakh's Markha Valley. A stepping stone from trekking to semi-technical mountaineering, this Alpine PD-rated peak stands just above 20,000 feet, offering a feasible yet demanding climb. Revel in panoramic views of K2, Broad Peak, and the Gasherbrum Massif to the north, and Nun Kun and Zanskar peaks to the west.The trek winds through Thactungtse and Nimling pastures adorned with wildflowers, featuring Mani stones and Tigu lakes. Summit day entails grappling with moraines, loose rocks, and snow-covered crevasses, demanding roping up for safety. This expedition is a perfect blend of challenge and pleasure, ideal for mountaineers eager to test their skills on a semi-technical peak while savoring Ladakh's stunning scenery.",
    aboutExpeditionExperienced:
      "Climb Kangyatse 2 with our seasoned climbing team with over a decade of experience in the Indian Himalayas. We pride ourselves on upholding the highest safety standards throughout our operations. With a track record of more than 10 years, we've honed our skills and know these mountains like the back of our hands. Our commitment to safety goes beyond words—we have meticulous rescue plans in place, ready to be executed if the need arises. When you embark on an adventure with us, rest assured that you're in the hands of professionals who prioritize your safety above all else. Welcome to a team that's as prepared as it gets.",
    itineraryData: [
      {
        day: "1",
        title: "Arrival in Leh - Welcome to the Roof of the World!",
        details: [
          "As you touch down in Leh, the gateway to the majestic Himalayas, you are greeted by the crisp mountain air at an elevation that leaves the ordinary far below.",
          "Check into your hotel, a haven amidst the towering peaks, and let the altitude slowly seep into your veins.",
          "Take it easy, savoring every breath as you acclimate to the rarified atmosphere.",
        ],
      },
      {
        day: "2",
        title: "Acclimatization Hike - Discovering Leh's Secrets",
        details: [
          "The morning sun beckons as you embark on a leisurely walk to the Shanti Stupa, a serenely perched monument that seems to touch the sky.",
          "Explore ancient monasteries, dive into the vibrant local market, and if the expedition checklist isn't complete, indulge in some last-minute shopping.",
          "Return to your hotel, letting the charm of Leh work its magic.",
        ],
      },
      {
        day: "3",
        title: "Drive from Leh to Skiu",
        details: [
          "Trek from Skiu to Markha (3-5 hours)",
          "Witness the mesmerising landscape along the journey.",
          "Embark on a trek from Skiu to Markha, passing through the Zanskar and Indus confluence",
          "Night stay at MarkhaHomestay",
          "Elevation gain 3750 M",
        ],
      },
      {
        day: "4",
        title: "Trek from Markha to Hunkar (4-5 hours)",
        details: [
          "Encounter diverse terrains, including river crossings and barley fields.",
          "Explore the beautiful village Hunkar.",
          "Night stay at Homestay",
          "Elevation gain 4300 M",
        ],
      },
      {
        day: "5",
        title: "Hunkar to base camp trek (7-8 hours)",
        details: [
          "Marvel at the views of Kang Yatse and it’s surroundings.",
          "Reach at the base camp and experience it’s exposed beauty.",
          "Elevation gain 5100 M",
        ],
      },
      {
        day: "6",
        title:
          "load ferry and acclimatisation walk to advance camp (3-4 hours)",
        details: [
          "Take a 3-4 hour hike for acclimatisation to advance camp.",
          "Return to base camp and rest.",
        ],
      },
      {
        day: "7",
        title: "Rest and gear training",
        details: [
          "Learn about the gear.",
          "Practice essential skills for the upcoming climb.",
        ],
      },
      {
        day: "8",
        title: "Trek to Advance camp",
        details: [
          "Set up the camp at Advance camp and rest.",
          "Elevation gain 5440 M.",
        ],
      },
      {
        day: "9",
        title: "Summit Push (6250 M)",
        details: [
          "Prepare for summit attempt the following night.",
          "Summit and return to base camp.",
        ],
      },
      {
        day: "10",
        title: "Trek back to Hunkar village",
        details: [
          "Trek from base camp to Hunkar.",
          "Night stay at Hunkar Homestay.",
        ],
      },
      {
        day: "11",
        title: "Back to Leh",
        details: ["Trek to Skiu.", "Drive back to Leh."],
      },
      {
        day: "12-13",
        title: "Buffar day",
        details: [],
      },
    ],
    inclusion: [
      {
        sectionTitle: "Accommodation",
        content: [
          "Comfortable lodging in Leh upon arrival and during the expedition.",
          "Tented accommodation during the trek and at various campsites.",
        ],
      },
      {
        sectionTitle: "Meals",
        content: [
          "Nutritious and delicious meals throughout the expedition, designed to meet the energy demands of high-altitude trekking.",
          "Special provisions for dietary preferences and requirements.",
        ],
      },
      {
        sectionTitle: "Experienced Guides",
        content: [
          "Expert local guides well-versed in the terrain and conditions of the region.",
          "Assistance and guidance to ensure a safe and enjoyable trekking experience.",
        ],
      },
      {
        sectionTitle: "Transportation",
        content: [
          "Transport from Leh to the trek starting points and back, ensuring a seamless and comfortable journey.",
          "Well-maintained vehicles and experienced drivers for safe travel.",
        ],
      },
      {
        sectionTitle: "Camping Equipment",
        content: [
          "High-quality camping gear, including tents, sleeping bags, and other essentials for a comfortable night's rest.",
          "Technical gear distribution and fitting for the summit attempt.",
        ],
      },
      {
        sectionTitle: "Permits and Paperwork",
        content: [
          "Assistance in completing necessary paperwork with the Indian Mountaineering Foundation (IMF).",
          "Coordination of permits required for the expedition.",
        ],
      },
      {
        sectionTitle: "Safety Measures",
        content: [
          "Emergency evacuation plans and access to experienced high-altitude rescue teams.",
          "First aid provisions and medical assistance throughout the expedition.",
        ],
      },
      {
        sectionTitle: "Expedition Certificate",
        content: [
          "Commemorative certificates recognizing the successful completion Expedition",
        ],
      },
      {
        sectionTitle: "Communication",
        content: [
          "Communication equipment for emergencies",
          "Regular updates and briefings",
        ],
      },
      {
        sectionTitle: "Environment and Leave No Trace",
        content: [
          "Commitment to environmentally responsible trekking",
          "Practices to minimize environmental impact",
        ],
      },
    ],
    exclusion: [
      {
        sectionTitle: "Travel Insurance",
        content: [
          "Personal travel insurance covering medical expenses, evacuation, and other unforeseen circumstances.",
        ],
      },
      {
        sectionTitle: "Personal Gear",
        content: [
          "Individual trekking equipment and personal clothing.",
          "Any additional gear required for specific preferences or comfort.",
        ],
      },
      {
        sectionTitle: "Meals in Leh",
        content: [
          "Meals in Leh on arrival and departure days.",
          "Expenses for additional meals beyond the provided trek meals.",
        ],
      },
      {
        sectionTitle: "Beverages",
        content: [
          "Expenses for additional beverages or snacks not included in the provided meals.",
        ],
      },
      {
        sectionTitle: "Personal Expenses",
        content: [
          "Any expenses of a personal nature, including souvenirs, phone calls, laundry, and tips.",
        ],
      },
      {
        sectionTitle: "Transport beyond Itinerary",
        content: [
          "Transportation or expenses beyond the specified itinerary or additional travel arrangements.",
        ],
      },
      {
        sectionTitle: "Medical Expenses",
        content: [
          "Costs related to pre-existing medical conditions or personal medication.",
        ],
      },
      {
        sectionTitle: "Changes in Itinerary",
        content: [
          "Any additional costs incurred due to changes in the itinerary caused by factors beyond the control of the trek organizers.",
        ],
      },
      {
        sectionTitle: "Unforeseen Circumstances",
        content: [
          "Expenses arising from unforeseen circumstances such as natural disasters, political unrest, or flight cancellations.",
        ],
      },
      {
        sectionTitle: "Additional Services",
        content: [
          "Any services or facilities not explicitly mentioned in the inclusions.",
        ],
      },
      {
        sectionTitle: "Porter Services",
        content: [
          "Personal porter services for carrying individual backpacks, if required.",
        ],
      },
      {
        sectionTitle: "Optional Activities",
        content: [
          "Costs associated with optional activities or excursions not included in the trek itinerary.",
        ],
      },
      {
        sectionTitle: "Government Fees",
        content: [
          "Any additional fees imposed by government authorities during the trek.",
        ],
      },
    ],
    youtubeLink:
      "https://www.youtube.com/embed/TrVP-533qdc?si=Mjkzm_8qPQt1QNZl",
    expeditionDate: [
      {
        id: 1,
        duration: "7 Days",
        startDate: "",
        endDate: "",
        bookedSeat: "",
        availableSeat: "",
      },
      {
        id: 2,
        duration: "7 Days",
        startDate: "",
        endDate: "",
        bookedSeat: "",
        availableSeat: "",
      },
      {
        id: 3,
        duration: "7 Days",
        startDate: "",
        endDate: "",
        bookedSeat: "",
        availableSeat: "",
      },
      {
        id: 4,
        duration: "7 Days",
        startDate: "",
        endDate: "",
        bookedSeat: "",
        availableSeat: "",
      },
      {
        id: 5,
        duration: "7 Days",
        startDate: "",
        endDate: "",
        bookedSeat: "",
        availableSeat: "",
      },
    ],
  },
  {
    individualExpeditionType: "utKangri",
    individualExpeditionName: "Ut Kangri",
    carouselData: expedition6000MHeroSection,
    location: "Rumtse, Ladakh",
    duration: "8 Days",
    grade: "Beginners",
    maxAltitude: "6070 m or 19915 ft",
    bestSeason: "July, August, September and October",
    experienceRequired:
      "An enthusiast should have prior experience multiple challenging treks",
    mainExpeditionContent:
      "Nestled in the upper reaches of Rumtse village, the UT Ladakh Kangri peak stands as a majestic, snow-clad jewel, recently conquered by the local mountaineers of the Ladakh Mountain Guides Association in 2019. For those venturing into the world of mountaineering and eyeing their inaugural six-thousander conquest, UT Kangri stands with unparalleled allure.Beyond its aesthetic charm, the ascent to UT Kangri's summit is an interesting challenge, demanding a prerequisite understanding of high-altitude trekking. The summit day unfolds as a marathon lasting a formidable 10-11 hours. The ordeal is undeniably strenuous, yet reaching the top of Kangri is a reward beyond compare.The vista from the summit unfolds a canvas of breathtaking beauty, revealing the grandeur of the surrounding Himalayan ranges—the Karakoram, Zanskar, and Stok ranges—as if painted by the hand of nature herself.",
    aboutExpeditionExperienced:
      "Climb UT Kangri with our seasoned climbing team with over a decade of experience in the Indian Himalayas. We pride ourselves on upholding the highest safety standards throughout our operations. With a track record of more than 10 years, we've honed our skills and know these mountains like the back of our hands. Our commitment to safety goes beyond words—we have meticulous rescue plans in place, ready to be executed if the need arises. When you embark on an adventure with us, rest assured that you're in the hands of professionals who prioritize your safety above all else. Welcome to a team that's as prepared as it gets.",
    itineraryData: [
      {
        day: "1",
        title: "Arrival in Leh (3550 M)",
        details: [
          "Arrival at Leh.",
          "The day will be dedicated to acclimatizing to the pristine air of Ladakh. It is crucial to rest and stay hydrated during this period.",
        ],
      },
      {
        day: "2",
        title: "Small Trek Around Leh for Acclimatization",
        details: [
          "The morning sun beckons as you embark on a leisurely walk to the Shanti Stupa, a serenely perched monument that seems to touch the sky.",
          "Explore ancient monasteries, dive into the vibrant local market, and if the expedition checklist isn't complete, indulge in some last-minute shopping.",
          "Return to your hotel, letting the charm of Leh work its magic.",
        ],
      },
      {
        day: "3",
        title: "Leh to U.T Kangri 1st Camp",
        details: [
          "Depart from the guest house at 8 AM for U.T Kangri's first camp, located before Tanglangla Pass.",
          "The journey is a 3-hour drive, followed by a short trek from Rumtse Tea house to the 1st camp.",
        ],
      },
      {
        day: "4",
        title: "Camp One to Base Camp",
        details: [
          "Start the day with a picturesque mountain view. After breakfast, trek to the base camp, a gradual climb lasting 5-6 hours.",
          "The terrains are well-formed due to frequent visitors. Enjoy lunch with magnificent views, followed by a short walk around the camp for acclimatization.",
        ],
      },
      {
        day: "5",
        title: "Rest Day",
        details: [],
      },
      {
        day: "6",
        title: "U.T Kangri Summit",
        details: [
          "Prepare for an early morning start at 1 AM for the U.T Kangri Summit.",
          "Equip yourself with hiking essentials, and after a quick breakfast, begin the trek.",
          "The duration depends on snow conditions and endurance, likely taking 10 to 11 hours.",
          "The summit rewards you with breathtaking views of the Zanskar region. After descent, enjoy refreshments at the base camp.",
        ],
      },
      {
        day: "7",
        title: "Back to Leh via Thiksey",
        details: [
          "After breakfast, trek down to the road head camp (4 to 5 hours), where cars will pick you up and transfer you to the guest house.",
          "Optionally, explore the main market in the evening for gifts and souvenirs.",
        ],
      },
      {
        day: "8",
        title: "Airport Drop",
        details: [
          "After breakfast, you will be dropped off at the airport according to your flight's timing. Have a happy journey!",
        ],
      },
    ],
    inclusion: [
      {
        sectionTitle: "Accommodation",
        content: [
          "Comfortable lodging in Leh upon arrival and during the expedition.",
          "Tented accommodation during the trek and at various campsites.",
        ],
      },
      {
        sectionTitle: "Meals",
        content: [
          "Nutritious and delicious meals throughout the expedition, designed to meet the energy demands of high-altitude trekking.",
          "Special provisions for dietary preferences and requirements.",
        ],
      },
      {
        sectionTitle: "Experienced Guides",
        content: [
          "Expert local guides well-versed in the terrain and conditions of the region.",
          "Assistance and guidance to ensure a safe and enjoyable trekking experience.",
        ],
      },
      {
        sectionTitle: "Transportation",
        content: [
          "Transport from Leh to the trek starting points and back, ensuring a seamless and comfortable journey.",
          "Well-maintained vehicles and experienced drivers for safe travel.",
        ],
      },
      {
        sectionTitle: "Camping Equipment",
        content: [
          "High-quality camping gear, including tents, sleeping bags, and other essentials for a comfortable night's rest.",
          "Technical gear distribution and fitting for the summit attempt.",
        ],
      },
      {
        sectionTitle: "Permits and Paperwork",
        content: [
          "Assistance in completing necessary paperwork with the Indian Mountaineering Foundation (IMF).",
          "Coordination of permits required for the expedition.",
        ],
      },
      {
        sectionTitle: "Safety Measures",
        content: [
          "Emergency evacuation plans and access to experienced high-altitude rescue teams.",
          "First aid provisions and medical assistance throughout the expedition.",
        ],
      },
      {
        sectionTitle: "Expedition Certificate",
        content: [
          "Commemorative certificates recognizing the successful completion Expedition",
        ],
      },
      {
        sectionTitle: "Communication",
        content: [
          "Communication equipment for emergencies",
          "Regular updates and briefings",
        ],
      },
      {
        sectionTitle: "Environment and Leave No Trace",
        content: [
          "Commitment to environmentally responsible trekking",
          "Practices to minimize environmental impact",
        ],
      },
    ],
    exclusion: [
      {
        sectionTitle: "Travel Insurance",
        content: [
          "Personal travel insurance covering medical expenses, evacuation, and other unforeseen circumstances.",
        ],
      },
      {
        sectionTitle: "Personal Gear",
        content: [
          "Individual trekking equipment and personal clothing.",
          "Any additional gear required for specific preferences or comfort.",
        ],
      },
      {
        sectionTitle: "Meals in Leh",
        content: [
          "Meals in Leh on arrival and departure days.",
          "Expenses for additional meals beyond the provided trek meals.",
        ],
      },
      {
        sectionTitle: "Beverages",
        content: [
          "Expenses for additional beverages or snacks not included in the provided meals.",
        ],
      },
      {
        sectionTitle: "Personal Expenses",
        content: [
          "Any expenses of a personal nature, including souvenirs, phone calls, laundry, and tips.",
        ],
      },
      {
        sectionTitle: "Transport beyond Itinerary",
        content: [
          "Transportation or expenses beyond the specified itinerary or additional travel arrangements.",
        ],
      },
      {
        sectionTitle: "Medical Expenses",
        content: [
          "Costs related to pre-existing medical conditions or personal medication.",
        ],
      },
      {
        sectionTitle: "Changes in Itinerary",
        content: [
          "Any additional costs incurred due to changes in the itinerary caused by factors beyond the control of the trek organizers.",
        ],
      },
      {
        sectionTitle: "Unforeseen Circumstances",
        content: [
          "Expenses arising from unforeseen circumstances such as natural disasters, political unrest, or flight cancellations.",
        ],
      },
      {
        sectionTitle: "Additional Services",
        content: [
          "Any services or facilities not explicitly mentioned in the inclusions.",
        ],
      },
      {
        sectionTitle: "Porter Services",
        content: [
          "Personal porter services for carrying individual backpacks, if required.",
        ],
      },
      {
        sectionTitle: "Optional Activities",
        content: [
          "Costs associated with optional activities or excursions not included in the trek itinerary.",
        ],
      },
      {
        sectionTitle: "Government Fees",
        content: [
          "Any additional fees imposed by government authorities during the trek.",
        ],
      },
    ],
    youtubeLink: "",
    expeditionDate: [
      {
        id: 1,
        duration: "7 Days",
        startDate: "",
        endDate: "",
        bookedSeat: "",
        availableSeat: "",
      },
      {
        id: 2,
        duration: "7 Days",
        startDate: "",
        endDate: "",
        bookedSeat: "",
        availableSeat: "",
      },
      {
        id: 3,
        duration: "7 Days",
        startDate: "",
        endDate: "",
        bookedSeat: "",
        availableSeat: "",
      },
      {
        id: 4,
        duration: "7 Days",
        startDate: "",
        endDate: "",
        bookedSeat: "",
        availableSeat: "",
      },
      {
        id: 5,
        duration: "7 Days",
        startDate: "",
        endDate: "",
        bookedSeat: "",
        availableSeat: "",
      },
    ],
  },
  {
    individualExpeditionType: "mentokKangri",
    individualExpeditionName: "Mentok Kangri",
    carouselData: expedition6000MHeroSection,
    location: "Karzok, Ladakh",
    duration: "11 Days",
    grade: "Intermediate",
    maxAltitude: "6250 m or 20505 ft",
    bestSeason: "July, August, September and October",
    experienceRequired:
      "An enthusiast should have prior experience of 5000m/6000m climbs or multiple challenging treks.",
    mainExpeditionContent:
      "The Mentok Kangri massif is situated on the western shores of Tso Moriri in eastern Ladakh, nestled in the Rupsu valley. Its three peaks stand tall at 6250 m, 6150 m, and 6210 m, linked by a gentle ridge. Dominating the western edge of Tso Moriri is Mentok Kangri II, soaring to an impressive height of 6,250 m. Your journey begins with a drive to Karzok, located on the picturesque banks of Tso Moriri, followed by establishing a base near the Rupshu plateau, home to the Changpa nomads. The name 'Mentok' originates from a Tibetan term linked to a wildflower native to this area.This summit provides a relatively straightforward snow/ice climb, rewarding climbers with breathtaking panoramic views of Tso Moriri and neighboring peaks, including the prominent Chamser and Lungser Kangri at 6600 m. The trek to Mentok Kangri guides adventurers through remote valleys, traditional villages, and stunning hilltop monasteries, showcasing some of the most remarkable vistas of pristine landscapes.",
    aboutExpeditionExperienced:
      "Climb Mentok Kangri 2 with our seasoned climbing team with over a decade of experience in the Indian Himalayas. We pride ourselves on upholding the highest safety standards throughout our operations. With a track record of more than 10 years, we've honed our skills and know these mountains like the back of our hands. Our commitment to safety goes beyond words—we have meticulous rescue plans in place, ready to be executed if the need arises. When you embark on an adventure with us, rest assured that you're in the hands of professionals who prioritize your safety above all else. Welcome to a team that's as prepared as it gets.",
    itineraryData: [
      {
        day: "1",
        title: "Arrival at Leh (3500 m)",
        details: [
          "Your journey begins with the enchanting arrival at Leh, a gateway to the heart of Ladakh.",
          "Check into your cozy hotel and immerse yourself in the unique charm of this culturally rich town.",
          "As you explore the local markets, you'll not only acclimatize to the crisp mountain air but also discover the vibrant tapestry of Leh's monasteries.",
        ],
      },
      {
        day: "2",
        title: "Rest and Acclimatization in Leh",
        details: [
          "Take a day to rejuvenate and acclimatize in Leh.",
          "The nearby monasteries hold secrets waiting to be unraveled, adding a touch of mystique to your expedition.",
          "An overnight stay in the hotel relaxes you and gets you ready for the adventures ahead.",
        ],
      },
      {
        day: "3",
        title: "Leh - Korzok (4500 m) [Drive]",
        details: [
          "Embark on a thrilling 10-hour drive from Leh to Korzok, a journey that traverses breathtaking landscapes.",
          "You’ll be mesmerized seeing dark, crystal-clear blue Tso Morori (Tso means Lake). It is huge!",
          "Arrive at a guesthouse/camp nestled in the heart of the mountains, relax and enjoy.",
        ],
      },
      {
        day: "4",
        title: "Rest and Acclimatization at Korzok",
        details: [
          "With Korzok's high altitude, a day of rest is important.",
          "Allow your body to acclimatize while immersing yourself in the mesmerizing beauty of Tso Moriri Lake, a shimmering blue jewel in the rugged terrain.",
          "The guesthouse/camp offers a haven of comfort against the backdrop of towering peaks.",
        ],
      },
      {
        day: "5",
        title: "Karzok to Base camp (5250 M)",
        details: [
          "Embark on a captivating journey to Korzok Phu, a destination that promises solitude and serenity.",
          "The night unfolds against a canvas of starlit skies, creating a magical atmosphere.",
        ],
      },
      {
        day: "6",
        title: "Karzok to Base camp (5250 M)",
        details: [
          "Rise early to witness the breathtaking sunrise illuminating the majestic mountains and the sparkling beauty of Tso Moriri.",
          "The expedition takes a more serious turn as you ascend to the base camp and come closer to all 3 peaks of Mentok range.",
        ],
      },
      {
        day: "7",
        title: "Training and Acclimatization at BC. Trek to High Camp",
        details: [
          "Engage in invigorating hikes, training your body and soul for the impending challenge. The high-altitude air becomes your companion as you prepare for the summit push.",
        ],
      },
      {
        day: "8",
        title: "Summit Attempt and Return to Base Camp",
        details: [
          "Summit push starts at 3:00 am, a thrilling endeavor that rewards you with a panoramic view of Tso Moriri Lake, Parang La, and the majestic Chamser and Lungser Kangri.",
          "Descend to the base camp, the sense of accomplishment mingling with the crisp mountain air as you retire for the night.",
        ],
      },
      {
        day: "9",
        title: "Reserve Day",
        details: [
          "A day reserved for triumph or challenge, depending on the whims of weather. If needed, the summit climb fee on this day adds a dash of suspense to the adventure.",
        ],
      },
      {
        day: "10",
        title: "Base Camp - Korzok",
        details: [
          "Trace your steps back to Karzok, where the journey began. The guesthouse/camp welcomes you for a well-deserved rest.",
        ],
      },
      {
        day: "11",
        title: "Karzok - Leh",
        details: [
          "The final leg of the adventure unfolds as you embark on a 10-hour drive back to Leh.",
        ],
      },
    ],
    inclusion: [
      {
        sectionTitle: "Accommodation",
        content: [
          "Comfortable lodging in Leh upon arrival and during the expedition.",
          "Tented accommodation during the trek and at various campsites.",
        ],
      },
      {
        sectionTitle: "Meals",
        content: [
          "Nutritious and delicious meals throughout the expedition, designed to meet the energy demands of high-altitude trekking.",
          "Special provisions for dietary preferences and requirements.",
        ],
      },
      {
        sectionTitle: "Experienced Guides",
        content: [
          "Expert local guides well-versed in the terrain and conditions of the region.",
          "Assistance and guidance to ensure a safe and enjoyable trekking experience.",
        ],
      },
      {
        sectionTitle: "Transportation",
        content: [
          "Transport from Leh to the trek starting points and back, ensuring a seamless and comfortable journey.",
          "Well-maintained vehicles and experienced drivers for safe travel.",
        ],
      },
      {
        sectionTitle: "Camping Equipment",
        content: [
          "High-quality camping gear, including tents, sleeping bags, and other essentials for a comfortable night's rest.",
          "Technical gear distribution and fitting for the summit attempt.",
        ],
      },
      {
        sectionTitle: "Permits and Paperwork",
        content: [
          "Assistance in completing necessary paperwork with the Indian Mountaineering Foundation (IMF).",
          "Coordination of permits required for the expedition.",
        ],
      },
      {
        sectionTitle: "Safety Measures",
        content: [
          "Emergency evacuation plans and access to experienced high-altitude rescue teams.",
          "First aid provisions and medical assistance throughout the expedition.",
        ],
      },
      {
        sectionTitle: "Expedition Certificate",
        content: [
          "Commemorative certificates recognizing the successful completion Expedition",
        ],
      },
      {
        sectionTitle: "Communication",
        content: [
          "Communication equipment for emergencies",
          "Regular updates and briefings",
        ],
      },
      {
        sectionTitle: "Environment and Leave No Trace",
        content: [
          "Commitment to environmentally responsible trekking",
          "Practices to minimize environmental impact",
        ],
      },
    ],
    exclusion: [
      {
        sectionTitle: "Travel Insurance",
        content: [
          "Personal travel insurance covering medical expenses, evacuation, and other unforeseen circumstances.",
        ],
      },
      {
        sectionTitle: "Personal Gear",
        content: [
          "Individual trekking equipment and personal clothing.",
          "Any additional gear required for specific preferences or comfort.",
        ],
      },
      {
        sectionTitle: "Meals in Manali",
        content: [
          "Meals in Leh on arrival and departure days.",
          "Expenses for additional meals beyond the provided trek meals.",
        ],
      },
      {
        sectionTitle: "Beverages",
        content: [
          "Expenses for additional beverages or snacks not included in the provided meals.",
        ],
      },
      {
        sectionTitle: "Personal Expenses",
        content: [
          "Any expenses of a personal nature, including souvenirs, phone calls, laundry, and tips.",
        ],
      },
      {
        sectionTitle: "Transport beyond Itinerary",
        content: [
          "Transportation or expenses beyond the specified itinerary or additional travel arrangements.",
        ],
      },
      {
        sectionTitle: "Medical Expenses",
        content: [
          "Costs related to pre-existing medical conditions or personal medication.",
        ],
      },
      {
        sectionTitle: "Changes in Itinerary",
        content: [
          "Any additional costs incurred due to changes in the itinerary caused by factors beyond the control of the trek organizers.",
        ],
      },
      {
        sectionTitle: "Unforeseen Circumstances",
        content: [
          "Expenses arising from unforeseen circumstances such as natural disasters, political unrest, or flight cancellations.",
        ],
      },
      {
        sectionTitle: "Additional Services",
        content: [
          "Any services or facilities not explicitly mentioned in the inclusions.",
        ],
      },
      {
        sectionTitle: "Porter Services",
        content: [
          "Personal porter services for carrying individual backpacks, if required.",
        ],
      },
      {
        sectionTitle: "Optional Activities",
        content: [
          "Costs associated with optional activities or excursions not included in the trek itinerary.",
        ],
      },
      {
        sectionTitle: "Government Fees",
        content: [
          "Any additional fees imposed by government authorities during the trek.",
        ],
      },
    ],
    youtubeLink:
      "https://www.youtube.com/embed/k3ArNz91cH0?si=X0ANwJ1PE8hKxXdX",
    expeditionDate: [
      {
        id: 1,
        duration: "7 Days",
        startDate: "",
        endDate: "",
        bookedSeat: "",
        availableSeat: "",
      },
      {
        id: 2,
        duration: "7 Days",
        startDate: "",
        endDate: "",
        bookedSeat: "",
        availableSeat: "",
      },
      {
        id: 3,
        duration: "7 Days",
        startDate: "",
        endDate: "",
        bookedSeat: "",
        availableSeat: "",
      },
      {
        id: 4,
        duration: "7 Days",
        startDate: "",
        endDate: "",
        bookedSeat: "",
        availableSeat: "",
      },
      {
        id: 5,
        duration: "7 Days",
        startDate: "",
        endDate: "",
        bookedSeat: "",
        availableSeat: "",
      },
    ],
  },
  {
    individualExpeditionType: "chauChauKangNilda",
    individualExpeditionName: "Chau Chau Kang Nilda",
    carouselData: expedition6000MHeroSection,
    location: "Spiti, Himachal Pradesh",
    duration: "11+2 Days",
    grade: "Intermediate",
    maxAltitude: "6303 m or 20679 ft",
    bestSeason: "June, July, August, September and October",
    experienceRequired:
      "An enthusiast should have trekked between 4000 m to 5000 m at least once.",
    mainExpeditionContent:
      "Chau Chau Kang Nilda is one of most beautiful 6000-meter peak in Langza, Spiti. The shape and the setting of the peak will remind you of your childhood days when you tried drawing a peak. The gradual ascent rapidly rises after 5600 m and becomes almost a vertical wall leaving some room for negotiating the incline and reaching the top.Langza is a beautiful fossil village that will instill a sense of spirituality and calmness. History suggests that entire region was once submerged deep into The Tethys Ocean and this explains availability of fossils here. This high-altitude desert makes things cold and challenging.Chau Chau Kang Nilda is definitely a worthy challenge set in a very inspiring location. As you rise from camp to camp, getting closer to the summit, the beautiful views of neighboring ranges and high peaks will fire your flames to climb more. As a climber, this peak will polish and add one more achievement to your list of accomplishments. ",
    aboutExpeditionExperienced:
      "Climb Chau Chau Kiang Nilda with our seasoned climbing team with over a decade of experience in the Indian Himalayas. We pride ourselves on upholding the highest safety standards throughout our operations. With a track record of more than 10 years, we've honed our skills and know these mountains like the back of our hands. Our commitment to safety goes beyond words—we have meticulous rescue plans in place, ready to be executed if the need arises. When you embark on an adventure with us, rest assured that you're in the hands of professionals who prioritize your safety above all else. Welcome to a team that's as prepared as it gets.",
    itineraryData: [
      {
        day: "1",
        title: "Arrival in Shimla",
        details: ["Rest and meeting the team.", "Elevation 2276 M."],
      },
      {
        day: "2",
        title: "Drive from Shimla to Kalpa",
        details: [
          "Enjoy the beautiful landscape along the journey of Kinnaur.",
          "Stay at Kalpa.",
          "Elevation 2960 M.",
        ],
      },
      {
        day: "3",
        title: "Drive from Kalpa to Kaza",
        details: [
          "Entering the beautiful Spiti valley enjoy the mesmerising mountains and it’s exposed beauty.",
          "Walk to the local market of Kaza.",
          "Night stay at Kaza.",
          "Elevation 3800 M.",
        ],
      },
      {
        day: "4",
        title: "Drive from Kaza to Komic",
        details: [
          "Rest and acclimatisation walk at the highest village Komic.",
          "Night stay at Komic.",
          "Elevation 4587 M.",
        ],
      },
      {
        day: "5",
        title: "Trek from Komic to Base camp",
        details: ["Set up the camp and rest.", "Elevation gain 5100 M."],
      },
      {
        day: "6",
        title: "Rest and Acclimatisation at base camp",
        details: [],
      },
      {
        day: "7",
        title: "Trek from Base camp to Advance camp",
        details: ["Set up the camp and rest.", "Elevation gain 5800 M."],
      },
      {
        day: "8",
        title: "Rest at Advance Camp",
        details: [
          "Descend from ABC to base camp.",
          "Pack up and start the return journey to Kaza.",
        ],
      },
      {
        day: "9",
        title: "Summit day",
        details: ["Summit push and back to advance camp", "Elevation 6309 M"],
      },
      {
        day: "10",
        title: "Trek from advance camp to Komic",
        details: [],
      },
      {
        day: "11",
        title: "Komic to Manali and Shimla",
        details: [],
      },
      {
        day: "12-13",
        title: "Buffer Days",
        details: [],
      },
    ],
    inclusion: [
      {
        sectionTitle: "Accommodation",
        content: [
          "Comfortable lodging in Manali/Kaza upon arrival and during the expedition.",
          "Tented accommodation during the climb and at various campsites.",
        ],
      },
      {
        sectionTitle: "Meals",
        content: [
          "Nutritious and delicious meals throughout the expedition, designed to meet the energy demands of high-altitude trekking.",
          "Special provisions for dietary preferences and requirements.",
        ],
      },
      {
        sectionTitle: "Experienced Guides",
        content: [
          "Expert local guides well-versed in the terrain and conditions of the region.",
          "Assistance and guidance to ensure a safe and enjoyable climbing experience.",
        ],
      },
      {
        sectionTitle: "Transportation",
        content: [
          "Transport from Manali to the trek starting points and back, ensuring a seamless and comfortable journey.",
          "Well-maintained vehicles and experienced drivers for safe travel.",
        ],
      },
      {
        sectionTitle: "Camping Equipment",
        content: [
          "High-quality camping gear, including tents, sleeping bags, and other essentials for a comfortable night's rest.",
          "Technical gear distribution and fitting for the summit attempt.",
        ],
      },
      {
        sectionTitle: "Permits and Paperwork",
        content: [
          "Assistance in completing necessary paperwork with the Indian Mountaineering Foundation (IMF).",
          "Coordination of permits required for the expedition.",
        ],
      },
      {
        sectionTitle: "Safety Measures",
        content: [
          "Emergency evacuation plans and access to experienced high-altitude rescue teams.",
          "First aid provisions and medical assistance throughout the expedition.",
        ],
      },
      {
        sectionTitle: "Expedition Certificate",
        content: [
          "Commemorative certificates recognizing the successful completion Expedition",
        ],
      },
      {
        sectionTitle: "Communication",
        content: [
          "Communication equipment for emergencies",
          "Regular updates and briefings",
        ],
      },
      {
        sectionTitle: "Environment and Leave No Trace",
        content: [
          "Commitment to environmentally responsible climbing",
          "Practices to minimize environmental impact",
        ],
      },
    ],
    exclusion: [
      {
        sectionTitle: "Travel Insurance",
        content: [
          "Personal travel insurance covering medical expenses, evacuation, and other unforeseen circumstances.",
        ],
      },
      {
        sectionTitle: "Personal Gear",
        content: [
          "Individual trekking equipment and personal clothing.",
          "Any additional gear required for specific preferences or comfort.",
        ],
      },
      {
        sectionTitle: "Meals in Manali & Kaza",
        content: [
          "Meals in Manali & Kaza on arrival and departure days.",
          "Expenses for additional meals beyond the provided trek meals.",
        ],
      },
      {
        sectionTitle: "Beverages",
        content: [
          "Expenses for additional beverages or snacks not included in the provided meals.",
        ],
      },
      {
        sectionTitle: "Personal Expenses",
        content: [
          "Any expenses of a personal nature, including souvenirs, phone calls, laundry, and tips.",
        ],
      },
      {
        sectionTitle: "Transport beyond Itinerary",
        content: [
          "Transportation or expenses beyond the specified itinerary or additional travel arrangements.",
        ],
      },
      {
        sectionTitle: "Medical Expenses",
        content: [
          "Costs related to pre-existing medical conditions or personal medication.",
        ],
      },
      {
        sectionTitle: "Changes in Itinerary",
        content: [
          "Any additional costs incurred due to changes in the itinerary caused by factors beyond the control of the expedition organizers.",
        ],
      },
      {
        sectionTitle: "Unforeseen Circumstances",
        content: [
          "Expenses arising from unforeseen circumstances such as natural disasters, political unrest, or flight cancellations.",
        ],
      },
      {
        sectionTitle: "Additional Services",
        content: [
          "Any services or facilities not explicitly mentioned in the inclusions.",
        ],
      },
      {
        sectionTitle: "Porter Services",
        content: [
          "Personal porter services for carrying individual backpacks, if required.",
        ],
      },
      {
        sectionTitle: "Optional Activities",
        content: [
          "Costs associated with optional activities or excursions not included in the expedition itinerary.",
        ],
      },
      {
        sectionTitle: "Government Fees",
        content: [
          "Any additional fees imposed by government authorities during the expedition.",
        ],
      },
    ],
    youtubeLink: "",
    expeditionDate: [
      {
        id: 1,
        duration: "7 Days",
        startDate: "",
        endDate: "",
        bookedSeat: "",
        availableSeat: "",
      },
      {
        id: 2,
        duration: "7 Days",
        startDate: "",
        endDate: "",
        bookedSeat: "",
        availableSeat: "",
      },
      {
        id: 3,
        duration: "7 Days",
        startDate: "",
        endDate: "",
        bookedSeat: "",
        availableSeat: "",
      },
      {
        id: 4,
        duration: "7 Days",
        startDate: "",
        endDate: "",
        bookedSeat: "",
        availableSeat: "",
      },
      {
        id: 5,
        duration: "7 Days",
        startDate: "",
        endDate: "",
        bookedSeat: "",
        availableSeat: "",
      },
    ],
  },
  {
    individualExpeditionType: "leoPurgyil",
    individualExpeditionName: "Leo Purgyil",
    carouselData: expedition6000MHeroSection,
    location: "Nako, Himachal Pradesh",
    duration: "10+2 Days",
    grade: "Advanced",
    maxAltitude: "6793 M or 22286 ft",
    bestSeason: "May, June, September and October",
    experienceRequired:
      "An enthusiast should have prior experience of 5000m/6000m climbs or multiple challenging treks.",
    mainExpeditionContent:
      "Just beside Reo Purgyil, the highest peak of Himachal Pradesh, you’ll find Leo Purgyil. Just 23 m shorter than Reo Purgyil, Mt. Leo is an interesting peak. It is an excellent peak if you wish to climb Mt. Reo later. The technical level of this peak is not as severe as of Reo. Both the mountains share the same base camp.You witness the distance valleys of Spiti and Kinnaur region on your way up the summit. Mt. Manirang is also visible from the summit. The dry region of Spiti and shortage of oxygen will be felt as you start to gain altitude. With right mindset, physical fitness and a good team you can brave this peak.",
    aboutExpeditionExperienced:
      "Climb Leo Purgyil with our seasoned climbing team with over a decade of experience in the Indian Himalayas. We pride ourselves on upholding the highest safety standards throughout our operations. With a track record of more than 10 years, we've honed our skills and know these mountains like the back of our hands. Our commitment to safety goes beyond words—we have meticulous rescue plans in place, ready to be executed if the need arises. When you embark on an adventure with us, rest assured that you're in the hands of professionals who prioritize your safety above all else. Welcome to a team that's as prepared as it gets.",
    itineraryData: [
      {
        day: 1,
        title: "Shimla or Manali to Nako (8-9 hrs drive)",
        details: [
          "Depart from Shimla or Manali for Nako, a charming village in the Himalayas.",
          "Enjoy a scenic drive through the mountains with stops for meals and rest.",
          "Check into accommodation upon reaching Nako.",
        ],
      },
      {
        day: 2,
        title: "Rest and acclimatize at Nako",
        details: [
          "Take a day to rest and acclimatize to the high altitude (3,662 meters/12,014 feet).",
          "Explore local surroundings, visit Nako Lake, and adjust to the altitude.",
        ],
      },
      {
        day: 3,
        title: "Nako to Transit Camp (6 hrs trek)",
        details: [
          "Embark on a 6-hour trek from Nako to the Transit Camp.",
          "Arrive at the Transit Camp (4700 m), have dinner, and rest in tents.",
        ],
      },
      {
        day: 4,
        title: "Transit Camp to Base Camp",
        details: [
          "Continue trekking from the Transit Camp to the Base Camp (5400 m).",
          "Settle into your tent at the Base Camp, preparing for the upcoming days.",
        ],
      },
      {
        day: 5,
        title: "Acclimatize Hike at 5400m",
        details: [
          "Undertake an acclimatization hike to an altitude of 5,400 meters.",
          "Spend the day adjusting to higher elevation, soaking in breathtaking views.",
        ],
      },
      {
        day: 6,
        title: "From Base Camp 1",
        details: [
          "Move from Base Camp to the designated Camp 1.",
          "Acclimatize to the increasing altitude in preparation for the ascent.",
        ],
      },
      {
        day: 7,
        title: "Camp 1 to Summit Camp",
        details: [
          "Continue the ascent from Camp 1 towards the Summit Camp (6000 m).",
          "Set up camp, rest, and prepare for the summit push.",
        ],
      },
      {
        day: 8,
        title: "Summit Day",
        details: [
          "Summit Mt. Leo Purgyil and relish the accomplishment.",
          "Descend to either Camp 1 or Base Camp based on conditions.",
          "Rest and celebrate the successful summit.",
        ],
      },
      {
        day: 9,
        title: "Return to Nako",
        details: [
          "Descend from the mountain to Nako.",
          "Reach Nako, relax, and reflect on the expedition.",
        ],
      },
      {
        day: 10,
        title: "Ride Back to Shimla from Nako",
        details: [
          "Begin the journey back to Shimla, reminiscing about the incredible adventure.",
          "Allow for flexibility and use reserved days if needed.",
        ],
      },
      {
        day: "Reserve Days",
        title: "Reserved for unforeseen circumstances",
        details: [
          "Keep two days as reserves for unforeseen circumstances or extending stays as needed.",
        ],
      },
    ],
    inclusion: [
      {
        sectionTitle: "Accommodation",
        content: [
          "Comfortable lodging in Shimla/Manali upon arrival and during the expedition.",
          "Tented accommodation during the trek and at various campsites.",
        ],
      },
      {
        sectionTitle: "Meals",
        content: [
          "Nutritious and delicious meals throughout the expedition, designed to meet the energy demands of high-altitude trekking.",
          "Special provisions for dietary preferences and requirements.",
        ],
      },
      {
        sectionTitle: "Experienced Guides",
        content: [
          "Expert local guides well-versed in the terrain and conditions of the region.",
          "Assistance and guidance to ensure a safe and enjoyable trekking experience.",
        ],
      },
      {
        sectionTitle: "Transportation",
        content: [
          "Transport from Manali/Shimla to the trek starting points and back, ensuring a seamless and comfortable journey.",
          "Well-maintained vehicles and experienced drivers for safe travel.",
        ],
      },
      {
        sectionTitle: "Camping Equipment",
        content: [
          "High-quality camping gear, including tents, sleeping bags, and other essentials for a comfortable night's rest.",
          "Technical gear distribution and fitting for the summit attempt.",
        ],
      },
      {
        sectionTitle: "Permits and Paperwork",
        content: [
          "Assistance in completing necessary paperwork with the Indian Mountaineering Foundation (IMF).",
          "Coordination of permits required for the expedition.",
        ],
      },
      {
        sectionTitle: "Safety Measures",
        content: [
          "Emergency evacuation plans and access to experienced high-altitude rescue teams.",
          "First aid provisions and medical assistance throughout the expedition.",
        ],
      },
      {
        sectionTitle: "Expedition Certificate",
        content: [
          "Commemorative certificates recognizing the successful completion Expedition",
        ],
      },
      {
        sectionTitle: "Communication",
        content: [
          "Communication equipment for emergencies",
          "Regular updates and briefings",
        ],
      },
      {
        sectionTitle: "Environment and Leave No Trace",
        content: [
          "Commitment to environmentally responsible trekking",
          "Practices to minimize environmental impact",
        ],
      },
    ],
    exclusion: [
      {
        sectionTitle: "Travel Insurance",
        content: [
          "Personal travel insurance covering medical expenses, evacuation, and other unforeseen circumstances.",
        ],
      },
      {
        sectionTitle: "Personal Gear",
        content: [
          "Individual trekking equipment and personal clothing.",
          "Any additional gear required for specific preferences or comfort.",
        ],
      },
      {
        sectionTitle: "Meals in Manali/Shimla",
        content: [
          "Meals in Manali/Shimla on arrival and departure days.",
          "Expenses for additional meals beyond the provided trek meals.",
        ],
      },
      {
        sectionTitle: "Beverages",
        content: [
          "Expenses for additional beverages or snacks not included in the provided meals.",
        ],
      },
      {
        sectionTitle: "Personal Expenses",
        content: [
          "Any expenses of a personal nature, including souvenirs, phone calls, laundry, and tips.",
        ],
      },
      {
        sectionTitle: "Transport beyond Itinerary",
        content: [
          "Transportation or expenses beyond the specified itinerary or additional travel arrangements.",
        ],
      },
      {
        sectionTitle: "Medical Expenses",
        content: [
          "Costs related to pre-existing medical conditions or personal medication.",
        ],
      },
      {
        sectionTitle: "Changes in Itinerary",
        content: [
          "Any additional costs incurred due to changes in the itinerary caused by factors beyond the control of the trek organizers.",
        ],
      },
      {
        sectionTitle: "Unforeseen Circumstances",
        content: [
          "Expenses arising from unforeseen circumstances such as natural disasters, political unrest, or flight cancellations.",
        ],
      },
      {
        sectionTitle: "Additional Services",
        content: [
          "Any services or facilities not explicitly mentioned in the inclusions.",
        ],
      },
      {
        sectionTitle: "Porter Services",
        content: [
          "Personal porter services for carrying individual backpacks, if required.",
        ],
      },
      {
        sectionTitle: "Optional Activities",
        content: [
          "Costs associated with optional activities or excursions not included in the trek itinerary.",
        ],
      },
      {
        sectionTitle: "Government Fees",
        content: [
          "Any additional fees imposed by government authorities during the trek.",
        ],
      },
    ],
    youtubeLink: "",
    expeditionDate: [
      {
        id: 1,
        duration: "7 Days",
        startDate: "",
        endDate: "",
        bookedSeat: "",
        availableSeat: "",
      },
      {
        id: 2,
        duration: "7 Days",
        startDate: "",
        endDate: "",
        bookedSeat: "",
        availableSeat: "",
      },
      {
        id: 3,
        duration: "7 Days",
        startDate: "",
        endDate: "",
        bookedSeat: "",
        availableSeat: "",
      },
      {
        id: 4,
        duration: "7 Days",
        startDate: "",
        endDate: "",
        bookedSeat: "",
        availableSeat: "",
      },
      {
        id: 5,
        duration: "7 Days",
        startDate: "",
        endDate: "",
        bookedSeat: "",
        availableSeat: "",
      },
    ],
  },
  {
    individualExpeditionType: "manirang",
    individualExpeditionName: "Manirang",
    carouselData: expedition6000MHeroSection,
    location: "Spiti, Himachal Pradesh",
    duration: "12 Days",
    grade: "Advanced",
    maxAltitude: "6593 m or 21625 ft",
    bestSeason: "May, June, September and October",
    experienceRequired:
      "An enthusiast should have prior experience of 5000m/6000m climbs or multiple challenging treks.",
    mainExpeditionContent:
      "One of the precious peaks, a crown jewel, of Himachal Pradesh is Mt. Manirang (6593 m). Lying on the border of Kinnaur and Lahaul & Spiti district, it is accessible from village Mane that is almost 20 kms from Kaza town, the administrative headquarters of Lahaul & Spiti region. Manirang pass, which is close to the peak stands tall at 5550 m and is very remote and one of the least explored passes.Manirang Pass was a part of an ancient trade route that connected the people of Spiti region with Kinnaur. As technology and vehicles start to ease the life of the villagers, this region remains remote and unexplored. One of the most interesting parts of the route is that it rises gradually. You cross some of the most beautiful scenery consisting of man-made wooden bridges, crystal clear streams, wild-life, wide plain field, variety of flowers and colorful shrubs.Mt. Manirang is a challenging peak where last 200 m require technical knowledge or understanding as you have negotiate icy and rocky section. It is rewarding to reach the top and get a sense of true adventure and accomplishment.",
    aboutExpeditionExperienced:
      "Climb Mt. Manerang with our seasoned climbing team with over a decade of experience in the Indian Himalayas. We pride ourselves on upholding the highest safety standards throughout our operations. With a track record of more than 10 years, we've honed our skills and know these mountains like the back of our hands. Our commitment to safety goes beyond words—we have meticulous rescue plans in place, ready to be executed if the need arises. When you embark on an adventure with us, rest assured that you're in the hands of professionals who prioritize your safety above all else. Welcome to a team that's as prepared as it gets.",
    itineraryData: [
      {
        day: 1,
        title: "Shimla/Manali to Kaza (3650 m) (9-10 hrs drive)",
        details: [
          "Depart from Manali for Kaza, enjoying scenic Himalayan landscapes.",
          "Plan breaks for meals and rest during the journey.",
          "Check into accommodation upon arrival in Kaza.",
        ],
      },
      {
        day: 2,
        title: "Kaza to Mane Village(3550 m) (2 hrs drive)",
        details: [
          "Travel from Kaza to Mane Village, a picturesque 2-hour drive.",
          "Marvel at the beautiful peaks and landscapes of Himachal Pradesh.",
          "Arrive at Mane Village and immerse yourself in the local surroundings.",
        ],
      },
      {
        day: 3,
        title: "Mane Village to Base Camp (4800 m) (7-8 hrs trek)",
        details: [
          "Embark on a trek from Mane Village to the Base Camp (7-8 hours).",
          "Set up camp at the Base Camp and rest for the night.",
        ],
      },
      {
        day: 4,
        title: "Rest Day and Acclimatize Walk",
        details: [
          "Take a day to rest at the Base Camp.",
          "Engage in acclimatization walks to adjust to the higher altitude.",
        ],
      },
      {
        day: 5,
        title: "Base Camp to Camp 1 (5200 m) (5-6 hrs trek)",
        details: [
          "Trek from the Base Camp to Camp 1 (5-6 hours).",
          "Settle into Camp 1, rest, and prepare for the upcoming days.",
        ],
      },
      {
        day: 6,
        title: "Camp 1 to Camp 2 (5600 m) (4 hrs trek)",
        details: [
          "Continue the ascent from Camp 1 to Camp 2 (4 hours).",
          "Reach Camp 2, rest, and have dinner in preparation for the summit push.",
        ],
      },
      {
        day: 7,
        title: "Camp 2 to Summit Camp (5900 m) (3 hrs trek)",
        details: [
          "Trek from Camp 2 to the Summit Camp (3 hours).",
          "Prepare for the summit push and rest for the upcoming challenge.",
        ],
      },
      {
        day: 8,
        title: "Summit Day",
        details: [
          "Undertake the summit push, dedicating approximately 8 hours to reach the summit of Mt. Manirang (6593 meters).",
          "Descend back to the Summit Camp after the successful summit.",
        ],
      },
      {
        day: 9,
        title: "Summit Camp to Base Camp",
        details: [
          "Descend from the Summit Camp to the Base Camp, relishing the memories of the summit.",
          "Reach the Base Camp, rest, and reflect on the expedition.",
        ],
      },
      {
        day: 10,
        title: "Base Camp to Mane Village",
        details: [
          "Trek from the Base Camp to Mane Village, appreciating the journey.",
          "Arrive in Mane Village and unwind.",
        ],
      },
      {
        day: 11,
        title: "Mane Village to Kaza",
        details: [
          "Travel from Mane Village to Kaza, taking in the sights and sounds.",
          "Rest in Kaza and celebrate the success of the summit.",
        ],
      },
      {
        day: 12,
        title: "Kaza to Shimla/Manali",
        details: [
          "Begin the journey back to Manali, completing the circuit.",
          "Allow for flexibility with two reserve days for any unforeseen weather conditions or other challenges.",
        ],
      },
    ],
    inclusion: [
      {
        sectionTitle: "Accommodation",
        content: [
          "Comfortable lodging in Shimla/Manali and Kaza upon arrival and during the expedition.",
          "Tented accommodation during the trek and at various campsites.",
        ],
      },
      {
        sectionTitle: "Meals",
        content: [
          "Nutritious and delicious meals throughout the expedition, designed to meet the energy demands of high-altitude trekking.",
          "Special provisions for dietary preferences and requirements.",
        ],
      },
      {
        sectionTitle: "Experienced Guides",
        content: [
          "Expert local guides well-versed in the terrain and conditions of the region.",
          "Assistance and guidance to ensure a safe and enjoyable trekking experience.",
        ],
      },
      {
        sectionTitle: "Transportation",
        content: [
          "Transport from Shimla/Manali to the trek starting points and back, ensuring a seamless and comfortable journey.",
          "Well-maintained vehicles and experienced drivers for safe travel.",
        ],
      },
      {
        sectionTitle: "Camping Equipment",
        content: [
          "High-quality camping gear, including tents, sleeping bags, and other essentials for a comfortable night's rest.",
          "Technical gear distribution and fitting for the summit attempt.",
        ],
      },
      {
        sectionTitle: "Permits and Paperwork",
        content: [
          "Assistance in completing necessary paperwork with the Indian Mountaineering Foundation (IMF).",
          "Coordination of permits required for the expedition.",
        ],
      },
      {
        sectionTitle: "Safety Measures",
        content: [
          "Emergency evacuation plans and access to experienced high-altitude rescue teams.",
          "First aid provisions and medical assistance throughout the expedition.",
        ],
      },
      {
        sectionTitle: "Expedition Certificate",
        content: [
          "Commemorative certificates recognizing the successful completion Expedition",
        ],
      },
      {
        sectionTitle: "Communication",
        content: [
          "Communication equipment for emergencies",
          "Regular updates and briefings",
        ],
      },
      {
        sectionTitle: "Environment and Leave No Trace",
        content: [
          "Commitment to environmentally responsible trekking",
          "Practices to minimize environmental impact",
        ],
      },
    ],
    exclusion: [
      {
        sectionTitle: "Travel Insurance",
        content: [
          "Personal travel insurance covering medical expenses, evacuation, and other unforeseen circumstances.",
        ],
      },
      {
        sectionTitle: "Personal Gear",
        content: [
          "Individual trekking equipment and personal clothing.",
          "Any additional gear required for specific preferences or comfort.",
        ],
      },
      {
        sectionTitle: "Meals in Shimla/Manali",
        content: [
          "Meals in Shimla/Manali on arrival and departure days.",
          "Expenses for additional meals beyond the provided trek meals.",
        ],
      },
      {
        sectionTitle: "Beverages",
        content: [
          "Expenses for additional beverages or snacks not included in the provided meals.",
        ],
      },
      {
        sectionTitle: "Personal Expenses",
        content: [
          "Any expenses of a personal nature, including souvenirs, phone calls, laundry, and tips.",
        ],
      },
      {
        sectionTitle: "Transport beyond Itinerary",
        content: [
          "Transportation or expenses beyond the specified itinerary or additional travel arrangements.",
        ],
      },
      {
        sectionTitle: "Medical Expenses",
        content: [
          "Costs related to pre-existing medical conditions or personal medication.",
        ],
      },
      {
        sectionTitle: "Changes in Itinerary",
        content: [
          "Any additional costs incurred due to changes in the itinerary caused by factors beyond the control of the trek organizers.",
        ],
      },
      {
        sectionTitle: "Unforeseen Circumstances",
        content: [
          "Expenses arising from unforeseen circumstances such as natural disasters, political unrest, or flight cancellations.",
        ],
      },
      {
        sectionTitle: "Additional Services",
        content: [
          "Any services or facilities not explicitly mentioned in the inclusions.",
        ],
      },
      {
        sectionTitle: "Porter Services",
        content: [
          "Personal porter services for carrying individual backpacks, if required.",
        ],
      },
      {
        sectionTitle: "Optional Activities",
        content: [
          "Costs associated with optional activities or excursions not included in the trek itinerary.",
        ],
      },
      {
        sectionTitle: "Government Fees",
        content: [
          "Any additional fees imposed by government authorities during the trek.",
        ],
      },
    ],
    youtubeLink:
      "https://www.youtube.com/embed/yOpHoq1Kayc?si=6dMWw7p_aESEAuYY",
    expeditionDate: [
      {
        id: 1,
        duration: "7 Days",
        startDate: "",
        endDate: "",
        bookedSeat: "",
        availableSeat: "",
      },
      {
        id: 2,
        duration: "7 Days",
        startDate: "",
        endDate: "",
        bookedSeat: "",
        availableSeat: "",
      },
      {
        id: 3,
        duration: "7 Days",
        startDate: "",
        endDate: "",
        bookedSeat: "",
        availableSeat: "",
      },
      {
        id: 4,
        duration: "7 Days",
        startDate: "",
        endDate: "",
        bookedSeat: "",
        availableSeat: "",
      },
      {
        id: 5,
        duration: "7 Days",
        startDate: "",
        endDate: "",
        bookedSeat: "",
        availableSeat: "",
      },
    ],
  },
  {
    individualExpeditionType: "kanamo",
    individualExpeditionName: "Kanamo",
    carouselData: expedition5000MHeroSection,
    location: "Spiti, Himachal Pradesh",
    duration: "8 Days",
    grade: "Beginners",
    maxAltitude: "5974 M or 19553 ft",
    bestSeason: "May, June, July, August, September and October",
    experienceRequired:
      "An enthusiast should have trekked between 4000 m to 5000 m at least once.",
    mainExpeditionContent:
      "Trekking a high-altitude peak has its own raw appeal, especially when it reaches heights usually reserved for hardcore mountaineers. Kanamo is one such peak that you can trek. Unlike the technical climbs of traditional peaks, trekking this giant doesn't demand any fancy mountaineering skills—just a good acclimatization.Don't be fooled, though; tagging Kanamo as a mere trek would be a mistake. This is no cakewalk, you need serious fitness levels. The climb from Kanamo base camp to the summit is a relentless grind over scree and boulder moraine. And at that altitude, putting one foot in front of the other is no walk in the park. On summit day, you're not just hauling yourself up 4,500 ft; you've got to make it back to base camp on the same day. It's a brutal 12-14 hour trek, covering roughly 10 km through unforgiving terrain. This one's for the heavyweights—the toughest, fittest, and most seasoned trekkers out there!",
    aboutExpeditionExperienced:
      "Climb Kanamo Peak with our seasoned climbing team with over a decade of experience in the Indian Himalayas. We pride ourselves on upholding the highest safety standards throughout our operations. With a track record of more than 10 years, we've honed our skills and know these mountains like the back of our hands. Our commitment to safety goes beyond words—we have meticulous rescue plans in place, ready to be executed if the need arises. When you embark on an adventure with us, rest assured that you're in the hands of professionals who prioritize your safety above all else. Welcome to a team that's as prepared as it gets.",
    itineraryData: [
      {
        day: 1,
        title: "Shimla/Manali to Kaza Drive (3650 m) (7-8 hrs)",
        details: [
          "Depart from Manali, embarking on a scenic 7-8 hour drive through the Himalayan landscapes.",
          "Plan breaks for meals and rest along the journey.",
          "Arrive in Kaza and settle into your accommodation for the night.",
        ],
      },
      {
        day: 2,
        title: "Kaza to Kibber Drive (4270 m) (1 hr)",
        details: [
          "Travel from Kaza to Kibber, the second-highest village, in just an hour.",
          "Explore Kibber village and acclimatize to the high altitude.",
          "Take in breathtaking views and prepare for the upcoming trek.",
        ],
      },
      {
        day: 3,
        title: "Explore Kibber Village and Acclimatization",
        details: [
          "Spend the day exploring Kibber village and acclimatizing to the altitude.",
          "Visit local sights and immerse yourself in the unique culture of the village.",
          "Rest and prepare for the trek to the base camp.",
        ],
      },
      {
        day: 4,
        title:
          "Kibber Village to Base Camp (4700 m) (5-6 hrs trek, elevation gain)",
        details: [
          "Embark on a 5-6 hour trek from Kibber village to the base camp, facing elevation gains.",
          "Reach the base camp, situated at an elevated altitude, and rest in preparation for the summit day.",
        ],
      },
      {
        day: 5,
        title: "Base Camp to Summit (Early Morning)",
        details: [
          "Start early for the summit push from the base camp to the summit of Kanamo (5900 meters).",
          "Enjoy stunning sunrise views from the summit.",
          "Descend back to the base camp after the successful summit.",
        ],
      },
      {
        day: 6,
        title: "Base Camp to Kibber Village Trek and Drive to Kaza",
        details: [
          "Trek from the base camp back to Kibber village, capturing the last moments in the high-altitude terrain.",
          "Drive back to Kaza and relax after the trekking adventure.",
        ],
      },
      {
        day: 7,
        title: "Rest in Kaza and Celebrate Summit Party",
        details: [
          "Take a well-deserved rest day in Kaza to recover from the trek.",
          "Celebrate the successful summit with your team or fellow trekkers.",
        ],
      },
      {
        day: 8,
        title: "Kaza to Drive Back to Shimla/Manali",
        details: [
          "Begin your journey back to Manali, reflecting on the memorable experiences of the Kanamo trek.",
          "Allow for flexibility in the schedule to accommodate travel time.",
        ],
      },
    ],
    inclusion: [
      {
        sectionTitle: "Accommodation",
        content: [
          "Comfortable lodging in Shimla/Manali and Kaza upon arrival and during the expedition.",
          "Tented accommodation during the trek and at various campsites.",
        ],
      },
      {
        sectionTitle: "Meals",
        content: [
          "Nutritious and delicious meals throughout the expedition, designed to meet the energy demands of high-altitude trekking.",
          "Special provisions for dietary preferences and requirements.",
        ],
      },
      {
        sectionTitle: "Experienced Guides",
        content: [
          "Expert local guides well-versed in the terrain and conditions of the region.",
          "Assistance and guidance to ensure a safe and enjoyable trekking experience.",
        ],
      },
      {
        sectionTitle: "Transportation",
        content: [
          "Transport from Shimla/Manali to the trek starting points and back, ensuring a seamless and comfortable journey.",
          "Well-maintained vehicles and experienced drivers for safe travel.",
        ],
      },
      {
        sectionTitle: "Camping Equipment",
        content: [
          "High-quality camping gear, including tents, sleeping bags, and other essentials for a comfortable night's rest.",
          "Technical gear distribution and fitting for the summit attempt.",
        ],
      },
      {
        sectionTitle: "Permits and Paperwork",
        content: [
          "Assistance in completing necessary paperwork with the Indian Mountaineering Foundation (IMF).",
          "Coordination of permits required for the expedition.",
        ],
      },
      {
        sectionTitle: "Safety Measures",
        content: [
          "Emergency evacuation plans and access to experienced high-altitude rescue teams.",
          "First aid provisions and medical assistance throughout the expedition.",
        ],
      },
      {
        sectionTitle: "Expedition Certificate",
        content: [
          "Commemorative certificates recognizing the successful completion Expedition",
        ],
      },
      {
        sectionTitle: "Communication",
        content: [
          "Communication equipment for emergencies",
          "Regular updates and briefings",
        ],
      },
      {
        sectionTitle: "Environment and Leave No Trace",
        content: [
          "Commitment to environmentally responsible trekking",
          "Practices to minimize environmental impact",
        ],
      },
    ],
    exclusion: [
      {
        sectionTitle: "Travel Insurance",
        content: [
          "Personal travel insurance covering medical expenses, evacuation, and other unforeseen circumstances.",
        ],
      },
      {
        sectionTitle: "Personal Gear",
        content: [
          "Individual trekking equipment and personal clothing.",
          "Any additional gear required for specific preferences or comfort.",
        ],
      },
      {
        sectionTitle: "Meals in Shimla/Manali",
        content: [
          "Meals in Shimla/Manali on arrival and departure days.",
          "Expenses for additional meals beyond the provided trek meals.",
        ],
      },
      {
        sectionTitle: "Beverages",
        content: [
          "Expenses for additional beverages or snacks not included in the provided meals.",
        ],
      },
      {
        sectionTitle: "Personal Expenses",
        content: [
          "Any expenses of a personal nature, including souvenirs, phone calls, laundry, and tips.",
        ],
      },
      {
        sectionTitle: "Transport beyond Itinerary",
        content: [
          "Transportation or expenses beyond the specified itinerary or additional travel arrangements.",
        ],
      },
      {
        sectionTitle: "Medical Expenses",
        content: [
          "Costs related to pre-existing medical conditions or personal medication.",
        ],
      },
      {
        sectionTitle: "Changes in Itinerary",
        content: [
          "Any additional costs incurred due to changes in the itinerary caused by factors beyond the control of the trek organizers.",
        ],
      },
      {
        sectionTitle: "Unforeseen Circumstances",
        content: [
          "Expenses arising from unforeseen circumstances such as natural disasters, political unrest, or flight cancellations.",
        ],
      },
      {
        sectionTitle: "Additional Services",
        content: [
          "Any services or facilities not explicitly mentioned in the inclusions.",
        ],
      },
      {
        sectionTitle: "Porter Services",
        content: [
          "Personal porter services for carrying individual backpacks, if required.",
        ],
      },
      {
        sectionTitle: "Optional Activities",
        content: [
          "Costs associated with optional activities or excursions not included in the trek itinerary.",
        ],
      },
      {
        sectionTitle: "Government Fees",
        content: [
          "Any additional fees imposed by government authorities during the trek.",
        ],
      },
    ],
    youtubeLink:
      "https://www.youtube.com/embed/tqh2-NjBGzQ?si=psDqBtbSI4MI_EoG",
    expeditionDate: [
      {
        id: 1,
        duration: "7 Days",
        startDate: "",
        endDate: "",
        bookedSeat: "",
        availableSeat: "",
      },
      {
        id: 2,
        duration: "7 Days",
        startDate: "",
        endDate: "",
        bookedSeat: "",
        availableSeat: "",
      },
      {
        id: 3,
        duration: "7 Days",
        startDate: "",
        endDate: "",
        bookedSeat: "",
        availableSeat: "",
      },
      {
        id: 4,
        duration: "7 Days",
        startDate: "",
        endDate: "",
        bookedSeat: "",
        availableSeat: "",
      },
      {
        id: 5,
        duration: "7 Days",
        startDate: "",
        endDate: "",
        bookedSeat: "",
        availableSeat: "",
      },
    ],
  },
  {
    individualExpeditionType: "nun",
    individualExpeditionName: "Nun",
    carouselData: expedition7000MHeroSection,
    location: "Kargil, Ladakh",
    duration: "14+2 Days",
    grade: "Advanced",
    maxAltitude: "7135 m or 23,409 ft",
    bestSeason: "August & September",
    experienceRequired:
      "An enthusiast should have prior experience of 5000m/6000m climbs.",
    mainExpeditionContent:
      "Mount Nun, standing at an elevation of approximately 7,135 meters (23,409 feet) above sea level, is a prominent peak nestled within the stunning Nun-Kun massif in Himalayas. Located in the Suru Valley of the Kargil district in the Indian state of Jammu and Kashmir, Mt. Nun holds the distinction of being the highest mountain in this region. This majestic peak, along with its slightly lower counterpart, Mount Kun, forms the Nun Kun massif. Renowned for its challenging ascent, Mount Nun attracts adventurers and mountaineers seeking to conquer its formidable heights.The surrounding terrain is characterized by its remote and mountainous nature, necessitating careful consideration of weather conditions and logistical challenges for those attempting to explore or climb this iconic peak. The base camp in the Suru Valley serves as a crucial staging point for acclimatization before the ambitious summit attempt.This demanding climb requires specific mountaineering knowledge and skills, making it an expedition exclusively for experienced climbers. Mountaineering certification or equivalent high-altitude experience, with a summit of over 6,500M, is a prerequisite.",
    aboutExpeditionExperienced:
      "Climb Nun with our seasoned climbing team with over a decade of experience in the Indian Himalayas. We pride ourselves on upholding the highest safety standards throughout our operations. With a track record of more than 10 years, we've honed our skills and know these mountains like the back of our hands. Our commitment to safety goes beyond words—we have meticulous rescue plans in place, ready to be executed if the need arises. When you embark on an adventure with us, rest assured that you're in the hands of professionals who prioritize your safety above all else. Welcome to a team that's as prepared as it gets.",
    itineraryData: [
      {
        day: 1,
        title: "Arrive in Leh (3,500 M)",
        details: [
          "Upon arrival in Leh, climbers will check into their accommodation and have the day at leisure. The primary focus on this day is allowing participants to acclimatize to the high-altitude conditions.",
        ],
      },
      {
        day: 2,
        title: "Local Sightseeing and Acclimatization Walk (3,500 M)",
        details: [
          "Exploration of Leh's city market and immersion in the local culture. A leisurely acclimatization walk is scheduled. In the evening, a group debriefing session will be conducted, covering the upcoming days' schedule, expectations, mountain etiquette, and environmental preservation.",
        ],
      },
      {
        day: 3,
        title: "Leh - Kargil (2,676 M) - Drive to Tangol (3,400 M)",
        details: [
          "The journey begins with a drive to Kargil and then onwards to Tangol, reaching an elevation of 3,400 meters.",
        ],
      },
      {
        day: 4,
        title: "Tangol - Base Camp (4,500 M)",
        details: [
          "A trek from Tangol to the base camp, lasting 5-7 hours. Upon arrival, participants will set up their base camp and stay overnight in tents.",
        ],
      },
      {
        day: 5,
        title: "Rest and Acclimatization at Base Camp (4,500 M)",
        details: [
          "A day dedicated to acclimatization with an introduction to climbing gear by the White Expeditions team leader. Theory and practical training on necessary equipment are scheduled.",
        ],
      },
      {
        day: 6,
        title: "Load Ferry to Camp 1 and Return to Base Camp (5,500 M)",
        details: [
          "The day involves loading ferries to Camp 1, situated at 5,500 meters, with a 900-meter vertical gain from the base camp.",
        ],
      },
      {
        day: 7,
        title: "Rest at Base Camp (4,500 M)",
        details: [
          "A day of rest at the base camp for recovery and additional acclimatization.",
        ],
      },
      {
        day: 8,
        title: "Base Camp to Camp 1 (5,500 M)",
        details: ["Climbers ascend to Camp 1 at an elevation of 5,500 meters."],
      },
      {
        day: 9,
        title: "Camp 1 to Camp 2 (6,100 M)",
        details: [
          "Continuation of the ascent to Camp 2, located at 6,100 meters.",
        ],
      },
      {
        day: 10,
        title: "Camp 2 to Summit Camp (6,400 M)",
        details: [
          "Three days dedicated to load ferries, setting up Camps 1, 2, and 3, and acclimatizing for the summit push. The route includes challenging terrains like moraines, snow/ice walls, crevassed glaciers, and steep snow walls.",
        ],
      },
      {
        day: 11,
        title: "Summit Camp to Summit (7,135 M)",
        details: [
          "The summit push involves a challenging climb of 300 meters to the top of the West Ridge, traversing to the summit ridge, and finally reaching the summit. Breathtaking panoramic views of Zanskar, Ladakh, Karakoram, and the Kashmir Valley are expected on clear days. Return to Base Camp.",
        ],
      },
      {
        day: 12,
        title: "Camp 1 to Base Camp (4,500 M)",
        details: [
          "Descend from Camp 1 to the base camp, allowing time for recuperation.",
        ],
      },
      {
        day: 13,
        title: "Base Camp to Tangol",
        details: ["Trek back from the base camp to Tangol."],
      },
      {
        day: 14,
        title: "Tangol to Leh - Departure",
        details: [
          "The journey concludes with a drive from Tangol to Leh, marking the departure of participants from the expedition.",
        ],
      },
    ],
    inclusion: [
      {
        sectionTitle: "Accommodation",
        content: [
          "Comfortable lodging in Leh upon arrival and during the expedition.",
          "Tented accommodation during the trek and at various campsites.",
        ],
      },
      {
        sectionTitle: "Meals",
        content: [
          "Nutritious and delicious meals throughout the expedition, designed to meet the energy demands of high-altitude trekking.",
          "Special provisions for dietary preferences and requirements.",
        ],
      },
      {
        sectionTitle: "Experienced Guides",
        content: [
          "Expert local guides well-versed in the terrain and conditions of the region.",
          "Assistance and guidance to ensure a safe and enjoyable trekking experience.",
        ],
      },
      {
        sectionTitle: "Transportation",
        content: [
          "Transport from Leh to the trek starting points and back, ensuring a seamless and comfortable journey.",
          "Well-maintained vehicles and experienced drivers for safe travel.",
        ],
      },
      {
        sectionTitle: "Camping Equipment",
        content: [
          "High-quality camping gear, including tents, sleeping bags, and other essentials for a comfortable night's rest.",
          "Technical gear distribution and fitting for the summit attempt.",
        ],
      },
      {
        sectionTitle: "Permits and Paperwork",
        content: [
          "Assistance in completing necessary paperwork with the Indian Mountaineering Foundation (IMF).",
          "Coordination of permits required for the expedition.",
        ],
      },
      {
        sectionTitle: "Safety Measures",
        content: [
          "Emergency evacuation plans and access to experienced high-altitude rescue teams.",
          "First aid provisions and medical assistance throughout the expedition.",
        ],
      },
      {
        sectionTitle: "Expedition Certificate",
        content: [
          "Commemorative certificates recognizing the successful completion Expedition",
        ],
      },
      {
        sectionTitle: "Communication",
        content: [
          "Communication equipment for emergencies",
          "Regular updates and briefings",
        ],
      },
      {
        sectionTitle: "Environment and Leave No Trace",
        content: [
          "Commitment to environmentally responsible trekking",
          "Practices to minimize environmental impact",
        ],
      },
    ],
    exclusion: [
      {
        sectionTitle: "Travel Insurance",
        content: [
          "Personal travel insurance covering medical expenses, evacuation, and other unforeseen circumstances.",
        ],
      },
      {
        sectionTitle: "Personal Gear",
        content: [
          "Individual trekking equipment and personal clothing.",
          "Any additional gear required for specific preferences or comfort.",
        ],
      },
      {
        sectionTitle: "Meals in Leh",
        content: [
          "Meals in Leh on arrival and departure days.",
          "Expenses for additional meals beyond the provided trek meals.",
        ],
      },
      {
        sectionTitle: "Beverages",
        content: [
          "Expenses for additional beverages or snacks not included in the provided meals.",
        ],
      },
      {
        sectionTitle: "Personal Expenses",
        content: [
          "Any expenses of a personal nature, including souvenirs, phone calls, laundry, and tips.",
        ],
      },
      {
        sectionTitle: "Transport beyond Itinerary",
        content: [
          "Transportation or expenses beyond the specified itinerary or additional travel arrangements.",
        ],
      },
      {
        sectionTitle: "Medical Expenses",
        content: [
          "Costs related to pre-existing medical conditions or personal medication.",
        ],
      },
      {
        sectionTitle: "Changes in Itinerary",
        content: [
          "Any additional costs incurred due to changes in the itinerary caused by factors beyond the control of the trek organizers.",
        ],
      },
      {
        sectionTitle: "Unforeseen Circumstances",
        content: [
          "Expenses arising from unforeseen circumstances such as natural disasters, political unrest, or flight cancellations.",
        ],
      },
      {
        sectionTitle: "Additional Services",
        content: [
          "Any services or facilities not explicitly mentioned in the inclusions.",
        ],
      },
      {
        sectionTitle: "Porter Services",
        content: [
          "Personal porter services for carrying individual backpacks, if required.",
        ],
      },
      {
        sectionTitle: "Optional Activities",
        content: [
          "Costs associated with optional activities or excursions not included in the trek itinerary.",
        ],
      },
      {
        sectionTitle: "Government Fees",
        content: [
          "Any additional fees imposed by government authorities during the trek.",
        ],
      },
    ],
    youtubeLink:
      "https://www.youtube.com/embed/63bRSbdPsuo?si=27ZU2Vd3g25NQpOH",
    expeditionDate: [
      {
        id: 1,
        duration: "7 Days",
        startDate: "",
        endDate: "",
        bookedSeat: "",
        availableSeat: "",
      },
      {
        id: 2,
        duration: "7 Days",
        startDate: "",
        endDate: "",
        bookedSeat: "",
        availableSeat: "",
      },
      {
        id: 3,
        duration: "7 Days",
        startDate: "",
        endDate: "",
        bookedSeat: "",
        availableSeat: "",
      },
      {
        id: 4,
        duration: "7 Days",
        startDate: "",
        endDate: "",
        bookedSeat: "",
        availableSeat: "",
      },
      {
        id: 5,
        duration: "7 Days",
        startDate: "",
        endDate: "",
        bookedSeat: "",
        availableSeat: "",
      },
    ],
  },
  {
    individualExpeditionType: "kun",
    individualExpeditionName: "Kun",
    carouselData: expedition7000MHeroSection,
    location: "Kargil, Ladakh",
    duration: "15 Days",
    grade: "Advanced",
    maxAltitude: "7077 m or 23,218 ft",
    bestSeason: "August, September",
    experienceRequired:
      "An enthusiast should have prior experience of 5000m/6000m.",
    mainExpeditionContent:
      "Mount Kun is Ladakh's second-highest peak at 7,077 meters. Nestled in the Zanskar Valley, this majestic peak presents a challenge marked by imposing slopes, unpredictable weather, and a captivating hold on climbers. As part of the Nun-Kun massif, it proudly stands beside its taller twin, Mt. Nun, with a 4 km long snow plateau separating the two. Pinnacle Peak (6,930M), the third highest in the massif, adds to the allure of this challenging terrain located in the Suru Valley, Kargil district, approximately 250 km east of Srinagar.Mt. Kun, amidst the vast Himalayan expanse, challenges climbers seeking thrill, serving as a stepping stone or formidable goal for those aspiring to conquer the 8000ers. First conquered in 1913 and witnessing its second summit in 1971, the mountain demands a technical climb through treacherous terrain, battling violent winds and extreme cold.The nature of Mt. Kun, with its crevasse-riddled surfaces, changing faces, elaborate glacial formations, and high-gradient ice walls, sets it apart from other 7,000M peaks. This demanding climb requires specific mountaineering knowledge and skills, making it an expedition exclusively for experienced climbers. Mountaineering certification or equivalent high-altitude experience, with a summit of over 6,500M, is a prerequisite.",
    aboutExpeditionExperienced:
      "Climb Kun with our seasoned climbing team with over a decade of experience in the Indian Himalayas. We pride ourselves on upholding the highest safety standards throughout our operations. With a track record of more than 10 years, we've honed our skills and know these mountains like the back of our hands. Our commitment to safety goes beyond words—we have meticulous rescue plans in place, ready to be executed if the need arises. When you embark on an adventure with us, rest assured that you're in the hands of professionals who prioritize your safety above all else. Welcome to a team that's as prepared as it gets.",
    itineraryData: [
      {
        day: 1,
        title: "Arrive in Leh (3,500m)",
        details: [
          "Arrival at Leh.",
          "Climbers settle into accommodation and adjust to the mountain air at 3,500 meters.",
        ],
      },
      {
        day: 2,
        title: "Local Sightseeing and Acclimatization Walk (3,500m)",
        details: [
          "Rest and acclimatization day.",
          "Explore Leh city market.",
          "Debriefing session with the group.",
        ],
      },
      {
        day: 3,
        title: "Drive Leh to Kargil (2800m) 6-7hrs. To Shafat Nala (4300m)",
        details: [
          "Drive to Kargil via Indus valley.",
          "Sightseeing at Nimu, Alchi Monastery, and Lamayuru Monastery.",
          "Overnight in Kargil at 2800m.",
        ],
      },
      {
        day: 4,
        title: "Shafat Nala (4300m)",
        details: [
          "Drive to Shafat.",
          "Views of Nun-Kun peaks.",
          "Rest at Shafat.",
          "Overnight Camp.",
        ],
      },
      {
        day: 5,
        title: "Trek to Base Camp (4300m)",
        details: [
          "Organize gear for the climb.",
          "Trek through meadows to Chasme.",
          "Cross Shafat glacier to Base Camp.",
          "Overnight Camp.",
        ],
      },
      {
        day: 6,
        title: "Rest Day at Base Camp (4300m)",
        details: [
          "Follow load ferry to Shafat glacier.",
          "Visit Advance Base Camp 1.",
          "Return to Base Camp.",
          "Overnight Camp.",
        ],
      },
      {
        day: "7-12",
        title: "Climbing Period",
        details: ["C1- 5300m", "C2- 6050m", "C3- 6300m (summit camp)"],
      },
      {
        day: 13,
        title: "Summit and back to Base Camp (4300m)",
        details: [
          "Summit attempt and descent to Base Camp.",
          "Overnight Camp.",
        ],
      },
      {
        day: 14,
        title: "Trek Base Camp to Shafat (4300m)",
        details: ["Trek back from Base Camp to Shafat.", "Overnight Camp."],
      },
      {
        day: 15,
        title: "Drive Shafat to Leh",
        details: [
          "Long drive from Shafat to Leh (10-12 hrs).",
          "Overnight in Leh.",
        ],
      },
    ],
    inclusion: [
      {
        sectionTitle: "Accommodation",
        content: [
          "Comfortable lodging in Leh upon arrival and during the expedition.",
          "Tented accommodation during the trek and at various campsites.",
        ],
      },
      {
        sectionTitle: "Meals",
        content: [
          "Nutritious and delicious meals throughout the expedition, designed to meet the energy demands of high-altitude trekking.",
          "Special provisions for dietary preferences and requirements.",
        ],
      },
      {
        sectionTitle: "Experienced Guides",
        content: [
          "Expert local guides well-versed in the terrain and conditions of the region.",
          "Assistance and guidance to ensure a safe and enjoyable trekking experience.",
        ],
      },
      {
        sectionTitle: "Transportation",
        content: [
          "Transport from Leh to the trek starting points and back, ensuring a seamless and comfortable journey.",
          "Well-maintained vehicles and experienced drivers for safe travel.",
        ],
      },
      {
        sectionTitle: "Camping Equipment",
        content: [
          "High-quality camping gear, including tents, sleeping bags, and other essentials for a comfortable night's rest.",
          "Technical gear distribution and fitting for the summit attempt.",
        ],
      },
      {
        sectionTitle: "Permits and Paperwork",
        content: [
          "Assistance in completing necessary paperwork with the Indian Mountaineering Foundation (IMF).",
          "Coordination of permits required for the expedition.",
        ],
      },
      {
        sectionTitle: "Safety Measures",
        content: [
          "Emergency evacuation plans and access to experienced high-altitude rescue teams.",
          "First aid provisions and medical assistance throughout the expedition.",
        ],
      },
      {
        sectionTitle: "Expedition Certificate",
        content: [
          "Commemorative certificates recognizing the successful completion Expedition",
        ],
      },
      {
        sectionTitle: "Communication",
        content: [
          "Communication equipment for emergencies",
          "Regular updates and briefings",
        ],
      },
      {
        sectionTitle: "Environment and Leave No Trace",
        content: [
          "Commitment to environmentally responsible trekking",
          "Practices to minimize environmental impact",
        ],
      },
    ],
    exclusion: [
      {
        sectionTitle: "Travel Insurance",
        content: [
          "Personal travel insurance covering medical expenses, evacuation, and other unforeseen circumstances.",
        ],
      },
      {
        sectionTitle: "Personal Gear",
        content: [
          "Individual trekking equipment and personal clothing.",
          "Any additional gear required for specific preferences or comfort.",
        ],
      },
      {
        sectionTitle: "Meals in Leh",
        content: [
          "Meals in Leh on arrival and departure days.",
          "Expenses for additional meals beyond the provided trek meals.",
        ],
      },
      {
        sectionTitle: "Beverages",
        content: [
          "Expenses for additional beverages or snacks not included in the provided meals.",
        ],
      },
      {
        sectionTitle: "Personal Expenses",
        content: [
          "Any expenses of a personal nature, including souvenirs, phone calls, laundry, and tips.",
        ],
      },
      {
        sectionTitle: "Transport beyond Itinerary",
        content: [
          "Transportation or expenses beyond the specified itinerary or additional travel arrangements.",
        ],
      },
      {
        sectionTitle: "Medical Expenses",
        content: [
          "Costs related to pre-existing medical conditions or personal medication.",
        ],
      },
      {
        sectionTitle: "Changes in Itinerary",
        content: [
          "Any additional costs incurred due to changes in the itinerary caused by factors beyond the control of the trek organizers.",
        ],
      },
      {
        sectionTitle: "Unforeseen Circumstances",
        content: [
          "Expenses arising from unforeseen circumstances such as natural disasters, political unrest, or flight cancellations.",
        ],
      },
      {
        sectionTitle: "Additional Services",
        content: [
          "Any services or facilities not explicitly mentioned in the inclusions.",
        ],
      },
      {
        sectionTitle: "Porter Services",
        content: [
          "Personal porter services for carrying individual backpacks, if required.",
        ],
      },
      {
        sectionTitle: "Optional Activities",
        content: [
          "Costs associated with optional activities or excursions not included in the trek itinerary.",
        ],
      },
      {
        sectionTitle: "Government Fees",
        content: [
          "Any additional fees imposed by government authorities during the trek.",
        ],
      },
    ],
    youtubeLink:
      "https://www.youtube.com/embed/4up7D4axvCs?si=-IjmqMDS48cuV_g-",
    expeditionDate: [
      {
        id: 1,
        duration: "7 Days",
        startDate: "",
        endDate: "",
        bookedSeat: "",
        availableSeat: "",
      },
      {
        id: 2,
        duration: "7 Days",
        startDate: "",
        endDate: "",
        bookedSeat: "",
        availableSeat: "",
      },
      {
        id: 3,
        duration: "7 Days",
        startDate: "",
        endDate: "",
        bookedSeat: "",
        availableSeat: "",
      },
      {
        id: 4,
        duration: "7 Days",
        startDate: "",
        endDate: "",
        bookedSeat: "",
        availableSeat: "",
      },
      {
        id: 5,
        duration: "7 Days",
        startDate: "",
        endDate: "",
        bookedSeat: "",
        availableSeat: "",
      },
    ],
  },
  {
    individualExpeditionType: "kiagarRi",
    individualExpeditionName: "Kiagar Ri",
    carouselData: expedition6000MHeroSection,
    location: "Karzog, Ladakh",
    duration: "7 Days",
    grade: "Beginners",
    maxAltitude: "6100 m or 20013 ft",
    bestSeason: "July, August, and September",
    experienceRequired:
      "An enthusiast should have trekked between 4000 m to 5000 m at least once.",
    mainExpeditionContent:
      "Nestled in the breathtaking landscapes of Ladakh, the Kiagar Ri peak stands as an awe-inspiring mountain complimenting to the majestic beauty of the Himalayas. Rising to an elevation of 6,100 meters, this formidable summit offers adventurers a challenging yet rewarding trekking experience. The journey begins in Leh, where travelers acclimate to the high-altitude environment and explore the cultural gems of the region, including Shanti Stupa and Leh Palace.Exploring a high-altitude summit holds a unique charm, particularly when it soars to elevations typically tackled by seasoned mountaineers. Kiagar Ri stands out as one such peak that offers an enticing trekking experience. Unlike the intricate ascents required for conventional peaks, conquering this colossal mountain doesn't necessitate advanced mountaineering expertise—only a solid acclimatization is key.As the expedition unfolds, participants embark on a scenic drive to Karzok Village, located near the pristine Tso Moriri Lake setting the stage for an unforgettable adventure. The trek then takes an exhilarating turn as enthusiasts traverse from Karzok to the Kiagar Ri Base Camp, situated amidst the rugged Himalayan terrain. The final ascent to the summit promises panoramic views, making the Kiagar Ri peak expedition a fusion of cultural immersion, high-altitude acclimatization, and the thrill of conquering one of Ladakh's iconic peaks.",
    aboutExpeditionExperienced:
      "Climb Kiagar Ri with our seasoned climbing team with over a decade of experience in the Indian Himalayas. We pride ourselves on upholding the highest safety standards throughout our operations. With a track record of more than 10 years, we've honed our skills and know these mountains like the back of our hands. Our commitment to safety goes beyond words—we have meticulous rescue plans in place, ready to be executed if the need arises. When you embark on an adventure with us, rest assured that you're in the hands of professionals who prioritize your safety above all else. Welcome to a team that's as prepared as it gets.",
    itineraryData: [
      {
        day: 1,
        title: "Arrival in Leh",
        details: [
          "Arrive in Leh and rest after your journey.",
          "Check into your hotel and acclimate to the high altitude.",
        ],
      },
      {
        day: 2,
        title: "Explore Local Leh",
        details: [
          "Explore Leh's local attractions, including Shanti Stupa, Leh Palace, and Mall Road.",
          "Visit local food cafes to experience the regional cuisine.",
          "Take some time for shopping to ensure you have all necessary personal gear for the upcoming trek.",
        ],
      },
      {
        day: 3,
        title: "Drive from Leh to Karzok Village (5-6 hrs)",
        details: [
          "Begin your journey from Leh to Karzok Village, located near Tso Moriri Lake. Rest and enjoy a meal in a homestay or camp in Karzok.",
        ],
      },
      {
        day: 4,
        title: "Explore Tso Moriri Lake",
        details: [
          "Spend the day exploring the mesmerizing Tso Moriri Lake.",
          "Acclimatize to the cold desert environment of the Himalayas.",
          "Take in the breathtaking scenery and capture memorable moments.",
        ],
      },
      {
        day: 5,
        title: "Karzok to Base Camp (2 hrs trek)",
        details: [
          "Trek for approximately 2 hours from Karzok to the Base Camp. Set up camp and prepare for the upcoming summit trek.",
        ],
      },
      {
        day: 6,
        title: "Base Camp to Summit (6 hrs trek)",
        details: [
          "Embark on a challenging expedition from the Base Camp to the summit of Mount Kiagar ri (6100 meters), taking around 6 hours. Enjoy the panoramic views from the summit. Return to the Base Camp for a well-deserved rest.",
        ],
      },
      {
        day: 7,
        title: "Base Camp to Leh",
        details: [
          "Trek back from the Base Camp to Karzok Village.",
          "Drive back to Leh, completing the trek.",
        ],
      },
    ],
    inclusion: [
      {
        sectionTitle: "Accommodation",
        content: [
          "Comfortable lodging in Leh upon arrival and during the expedition.",
          "Tented accommodation during the trek and at various campsites.",
        ],
      },
      {
        sectionTitle: "Meals",
        content: [
          "Nutritious and delicious meals throughout the expedition, designed to meet the energy demands of high-altitude trekking.",
          "Special provisions for dietary preferences and requirements.",
        ],
      },
      {
        sectionTitle: "Experienced Guides",
        content: [
          "Expert local guides well-versed in the terrain and conditions of the region.",
          "Assistance and guidance to ensure a safe and enjoyable trekking experience.",
        ],
      },
      {
        sectionTitle: "Transportation",
        content: [
          "Transport from Leh to the trek starting points and back, ensuring a seamless and comfortable journey.",
          "Well-maintained vehicles and experienced drivers for safe travel.",
        ],
      },
      {
        sectionTitle: "Camping Equipment",
        content: [
          "High-quality camping gear, including tents, sleeping bags, and other essentials for a comfortable night's rest.",
          "Technical gear distribution and fitting for the summit attempt.",
        ],
      },
      {
        sectionTitle: "Permits and Paperwork",
        content: [
          "Assistance in completing necessary paperwork with the Indian Mountaineering Foundation (IMF).",
          "Coordination of permits required for the expedition.",
        ],
      },
      {
        sectionTitle: "Safety Measures",
        content: [
          "Emergency evacuation plans and access to experienced high-altitude rescue teams.",
          "First aid provisions and medical assistance throughout the expedition.",
        ],
      },
      {
        sectionTitle: "Expedition Certificate",
        content: [
          "Commemorative certificates recognizing the successful completion Expedition",
        ],
      },
      {
        sectionTitle: "Communication",
        content: [
          "Communication equipment for emergencies",
          "Regular updates and briefings",
        ],
      },
      {
        sectionTitle: "Environment and Leave No Trace",
        content: [
          "Commitment to environmentally responsible trekking",
          "Practices to minimize environmental impact",
        ],
      },
    ],
    exclusion: [
      {
        sectionTitle: "Travel Insurance",
        content: [
          "Personal travel insurance covering medical expenses, evacuation, and other unforeseen circumstances.",
        ],
      },
      {
        sectionTitle: "Personal Gear",
        content: [
          "Individual trekking equipment and personal clothing.",
          "Any additional gear required for specific preferences or comfort.",
        ],
      },
      {
        sectionTitle: "Meals in Leh",
        content: [
          "Meals in Leh on arrival and departure days.",
          "Expenses for additional meals beyond the provided trek meals.",
        ],
      },
      {
        sectionTitle: "Beverages",
        content: [
          "Expenses for additional beverages or snacks not included in the provided meals.",
        ],
      },
      {
        sectionTitle: "Personal Expenses",
        content: [
          "Any expenses of a personal nature, including souvenirs, phone calls, laundry, and tips.",
        ],
      },
      {
        sectionTitle: "Transport beyond Itinerary",
        content: [
          "Transportation or expenses beyond the specified itinerary or additional travel arrangements.",
        ],
      },
      {
        sectionTitle: "Medical Expenses",
        content: [
          "Costs related to pre-existing medical conditions or personal medication.",
        ],
      },
      {
        sectionTitle: "Changes in Itinerary",
        content: [
          "Any additional costs incurred due to changes in the itinerary caused by factors beyond the control of the trek organizers.",
        ],
      },
      {
        sectionTitle: "Unforeseen Circumstances",
        content: [
          "Expenses arising from unforeseen circumstances such as natural disasters, political unrest, or flight cancellations.",
        ],
      },
      {
        sectionTitle: "Additional Services",
        content: [
          "Any services or facilities not explicitly mentioned in the inclusions.",
        ],
      },
      {
        sectionTitle: "Porter Services",
        content: [
          "Personal porter services for carrying individual backpacks, if required.",
        ],
      },
      {
        sectionTitle: "Optional Activities",
        content: [
          "Costs associated with optional activities or excursions not included in the trek itinerary.",
        ],
      },
      {
        sectionTitle: "Government Fees",
        content: [
          "Any additional fees imposed by government authorities during the trek.",
        ],
      },
    ],
    youtubeLink:
      "https://www.youtube.com/embed/gINtMRf2QvI?si=PdGnHxECKwd8zSrG",
    expeditionDate: [
      {
        id: 1,
        duration: "7 Days",
        startDate: "",
        endDate: "",
        bookedSeat: "",
        availableSeat: "",
      },
      {
        id: 2,
        duration: "7 Days",
        startDate: "",
        endDate: "",
        bookedSeat: "",
        availableSeat: "",
      },
      {
        id: 3,
        duration: "7 Days",
        startDate: "",
        endDate: "",
        bookedSeat: "",
        availableSeat: "",
      },
      {
        id: 4,
        duration: "7 Days",
        startDate: "",
        endDate: "",
        bookedSeat: "",
        availableSeat: "",
      },
      {
        id: 5,
        duration: "7 Days",
        startDate: "",
        endDate: "",
        bookedSeat: "",
        availableSeat: "",
      },
    ],
  },
  {
    individualExpeditionType: "spangnakRi",
    individualExpeditionName: "Spangnak Ri",
    carouselData: expedition6000MHeroSection,
    location: " Karzog, Ladakh",
    duration: "8 Days",
    grade: "Beginners",
    maxAltitude: "6393 m or 20,974 ft",
    bestSeason: "July, August, September and October",
    experienceRequired:
      "An enthusiast should have prior experience of multiple challenging treks.",
    mainExpeditionContent:
      "Nestled in the mesmerizing landscapes of Ladakh, Spangnak Ri stands as an awe-inspiring peak, beckoning adventurers with its majestic allure. At an elevation of 6393 meters, this formidable summit promises not only breathtaking panoramic views but also an exhilarating journey through the heart of the Himalayas. The adventure begins in Leh, where travelers acclimate to the high altitude, immersing themselves in the local culture and exploring landmarks such as Shanti Stupa and Leh Palace.The expedition then takes an exciting turn as participants embark on a scenic drive from Leh to Karzok Village, positioned at an altitude of 4595 meters. From there, a trek unfolds, leading through the ethereal Tso Moriri Lake, allowing for acclimatization amidst the cold desert ambiance. The trek progresses to the Base Camp, strategically situated at 5000 meters, setting the stage for the ultimate ascent.The summit bid for Spangnak Ri begins with a challenging trek to the Summit Camp at 5400 meters, where adventurers rest and prepare for the pinnacle endeavor. Finally, the culmination of the journey occurs on Summit Day, as intrepid explorers conquer the peak at 6393 meters, reveling in the triumph of scaling this Himalayan giant. The descent brings a sense of accomplishment, concluding the expedition with a return to Karzok Village and a reflective drive back to Leh, marking the end of an unforgettable odyssey through Ladakh's stunning terrain and the triumphant conquest of Spangnak Ri.",
    aboutExpeditionExperienced:
      "Climb Spangnak Ri with our seasoned climbing team with over a decade of experience in the Indian Himalayas. We pride ourselves on upholding the highest safety standards throughout our operations. With a track record of more than 10 years, we've honed our skills and know these mountains like the back of our hands. Our commitment to safety goes beyond words—we have meticulous rescue plans in place, ready to be executed if the need arises. When you embark on an adventure with us, rest assured that you're in the hands of professionals who prioritize your safety above all else. Welcome to a team that's as prepared as it gets.",
    itineraryData: [
      {
        day: 1,
        title: "Arrival in Leh",
        details: [
          "Arrive in Leh and take the day to rest and acclimate to the high altitude (3500 meters).",
          "Check into your hotel and ensure you are well-rested for the upcoming adventure.",
        ],
      },
      {
        day: 2,
        title: "Explore Local Leh",
        details: [
          "Explore Leh's local attractions, including Shanti Stupa, Leh Palace, Mall Road, and indulge in local food cafes.",
          "Shop for any personal gear you may need for the trek.",
        ],
      },
      {
        day: 3,
        title: "Drive from Leh to Karzok Village (5-6 hrs)",
        details: [
          "Begin the journey from Leh to Karzok Village, situated at an elevation of 4595 meters.",
          "Trek for 5-6 hours to reach Karzok Village, where you can rest and enjoy a meal in homestays or camps.",
        ],
      },
      {
        day: 4,
        title: "Explore Tso Moriri Lake and Acclimatize",
        details: [
          "Spend the day exploring the enchanting Tso Moriri Lake.",
          "Use the time to acclimatize to the cold desert environment of the Himalayas.",
        ],
      },
      {
        day: 5,
        title: "Trek from Karzok to Base Camp (2-3 hrs)",
        details: [
          "Trek for 2-3 hours from Karzok to the Base Camp, situated at an elevation of 5000 meters.",
          "Set up camp and prepare for the journey ahead.",
        ],
      },
      {
        day: 6,
        title: "Base Camp to Summit Camp (5400 m)",
        details: [
          "Trek from the Base Camp to the Summit Camp, located at 5400 meters.",
          "Settle into tents, rest, and have meals to prepare for the summit push.",
        ],
      },
      {
        day: 7,
        title: "Summit Day - Spangnak Ri (6393 m)",
        details: [
          "Begin the early morning ascent to the summit of Mount Spangnak Ri at an elevation of 6393 meters.",
          "Enjoy the breathtaking views from the summit.",
          "Descend back to the Base Camp after the successful summit.",
        ],
      },
      {
        day: 8,
        title: "Base Camp to Karzok and Drive Back to Leh",
        details: [
          "Trek back from the Base Camp to Karzok Village.",
          "Drive back to Leh, completing the trek.",
          "Reflect on the memorable journey and celebrate the successful summit.",
        ],
      },
    ],
    inclusion: [
      {
        sectionTitle: "Accommodation",
        content: [
          "Comfortable lodging in Leh upon arrival and during the expedition.",
          "Tented accommodation during the trek and at various campsites.",
        ],
      },
      {
        sectionTitle: "Meals",
        content: [
          "Nutritious and delicious meals throughout the expedition, designed to meet the energy demands of high-altitude trekking.",
          "Special provisions for dietary preferences and requirements.",
        ],
      },
      {
        sectionTitle: "Experienced Guides",
        content: [
          "Expert local guides well-versed in the terrain and conditions of the region.",
          "Assistance and guidance to ensure a safe and enjoyable trekking experience.",
        ],
      },
      {
        sectionTitle: "Transportation",
        content: [
          "Transport from Leh to the trek starting points and back, ensuring a seamless and comfortable journey.",
          "Well-maintained vehicles and experienced drivers for safe travel.",
        ],
      },
      {
        sectionTitle: "Camping Equipment",
        content: [
          "High-quality camping gear, including tents, sleeping bags, and other essentials for a comfortable night's rest.",
          "Technical gear distribution and fitting for the summit attempt.",
        ],
      },
      {
        sectionTitle: "Permits and Paperwork",
        content: [
          "Assistance in completing necessary paperwork with the Indian Mountaineering Foundation (IMF).",
          "Coordination of permits required for the expedition.",
        ],
      },
      {
        sectionTitle: "Safety Measures",
        content: [
          "Emergency evacuation plans and access to experienced high-altitude rescue teams.",
          "First aid provisions and medical assistance throughout the expedition.",
        ],
      },
      {
        sectionTitle: "Expedition Certificate",
        content: [
          "Commemorative certificates recognizing the successful completion Expedition",
        ],
      },
      {
        sectionTitle: "Communication",
        content: [
          "Communication equipment for emergencies",
          "Regular updates and briefings",
        ],
      },
      {
        sectionTitle: "Environment and Leave No Trace",
        content: [
          "Commitment to environmentally responsible trekking",
          "Practices to minimize environmental impact",
        ],
      },
    ],
    exclusion: [
      {
        sectionTitle: "Travel Insurance",
        content: [
          "Personal travel insurance covering medical expenses, evacuation, and other unforeseen circumstances.",
        ],
      },
      {
        sectionTitle: "Personal Gear",
        content: [
          "Individual trekking equipment and personal clothing.",
          "Any additional gear required for specific preferences or comfort.",
        ],
      },
      {
        sectionTitle: "Meals in Leh",
        content: [
          "Meals in Leh on arrival and departure days.",
          "Expenses for additional meals beyond the provided trek meals.",
        ],
      },
      {
        sectionTitle: "Beverages",
        content: [
          "Expenses for additional beverages or snacks not included in the provided meals.",
        ],
      },
      {
        sectionTitle: "Personal Expenses",
        content: [
          "Any expenses of a personal nature, including souvenirs, phone calls, laundry, and tips.",
        ],
      },
      {
        sectionTitle: "Transport beyond Itinerary",
        content: [
          "Transportation or expenses beyond the specified itinerary or additional travel arrangements.",
        ],
      },
      {
        sectionTitle: "Medical Expenses",
        content: [
          "Costs related to pre-existing medical conditions or personal medication.",
        ],
      },
      {
        sectionTitle: "Changes in Itinerary",
        content: [
          "Any additional costs incurred due to changes in the itinerary caused by factors beyond the control of the trek organizers.",
        ],
      },
      {
        sectionTitle: "Unforeseen Circumstances",
        content: [
          "Expenses arising from unforeseen circumstances such as natural disasters, political unrest, or flight cancellations.",
        ],
      },
      {
        sectionTitle: "Additional Services",
        content: [
          "Any services or facilities not explicitly mentioned in the inclusions.",
        ],
      },
      {
        sectionTitle: "Porter Services",
        content: [
          "Personal porter services for carrying individual backpacks, if required.",
        ],
      },
      {
        sectionTitle: "Optional Activities",
        content: [
          "Costs associated with optional activities or excursions not included in the trek itinerary.",
        ],
      },
      {
        sectionTitle: "Government Fees",
        content: [
          "Any additional fees imposed by government authorities during the trek.",
        ],
      },
    ],
    youtubeLink: "",
    expeditionDate: [
      {
        id: 1,
        duration: "7 Days",
        startDate: "",
        endDate: "",
        bookedSeat: "",
        availableSeat: "",
      },
      {
        id: 2,
        duration: "7 Days",
        startDate: "",
        endDate: "",
        bookedSeat: "",
        availableSeat: "",
      },
      {
        id: 3,
        duration: "7 Days",
        startDate: "",
        endDate: "",
        bookedSeat: "",
        availableSeat: "",
      },
      {
        id: 4,
        duration: "7 Days",
        startDate: "",
        endDate: "",
        bookedSeat: "",
        availableSeat: "",
      },
      {
        id: 5,
        duration: "7 Days",
        startDate: "",
        endDate: "",
        bookedSeat: "",
        availableSeat: "",
      },
    ],
  },
  {
    individualExpeditionType: "p6250",
    individualExpeditionName: "P6250",
    carouselData: expedition5000MHeroSection,
    location: "Manali",
    duration: "7 Days",
    grade: "Beginners",
    maxAltitude: "5289 m or 17, 349 ft",
    bestSeason: "May, June, September and October",
    experienceRequired:
      "An enthusiast should have trekked between 4000 m to 5000 m at least once.",
    mainExpeditionContent:
      "Friendship Peak, a mountain in Manali, Himachal Pradesh stands tall at 5,289 meters. It's like a magnet for folks who love trekking. Though it’s a trekking peak, the duration to reach the top, the gradient, and the efforts required, make it a worthy first climb for those who wish to enter the world of climbing. Picture this: snow all around, stunning views, and glaciers showing off. So, if you're into trekking with awesome scenery and good vibes, Friendship Peak in the Himalayas is your kind of adventure – no fancy words needed. Just a solid mountain and a great time!",
    aboutExpeditionExperienced:
      "Climb Friendship Peak with our seasoned climbing team with over a decade of experience in the Indian Himalayas. We pride ourselves on upholding the highest safety standards throughout our operations. With a track record of more than 10 years, we've honed our skills and know these mountains like the back of our hands. Our commitment to safety goes beyond words—we have meticulous rescue plans in place, ready to be executed if the need arises. When you embark on an adventure with us, rest assured that you're in the hands of professionals who prioritize your safety above all else. Welcome to a team that's as prepared as it gets.",
    itineraryData: [
      {
        day: "1",
        title: "Arrival in Manali",
        details: [
          "Arrive in Manali (elevation: 2,050 meters)",
          "Transfer to the accommodation",
          "Settle in and rest",
          "Evening briefing and gear check by the trek leader",
        ],
      },
      {
        day: "2",
        title: "Drive to Solang Valley and Trek to Bakarthach",
        details: [
          "Breakfast at the accommodation in Manali",
          "Drive to Solang Valley (elevation: 2,560 meters)",
          "Trek to Bakarthach campsite (elevation: 3,330 meters)",
          "Trekking time: Approximately 5-6 hours",
        ],
      },
      {
        day: "3",
        title: "Trek to Lady Leg",
        details: [
          "Short trek for acclimatization",
          "Reach Lady Leg (elevation: 3,800 meters) for rest and preparation",
          "Acclimatization activities and briefing",
          "Trekking time: Approximately 3-4 hours",
        ],
      },
      {
        day: "4",
        title: "Trek to Advanced Base Camp (ABC)",
        details: [
          "Trek to Advanced Base Camp (elevation: 4,300 meters)",
          "Enjoy scenic views",
          "Set up camp at ABC and relax",
          "Trekking time: Approximately 4-5 hours",
        ],
      },
      {
        day: "5",
        title: "Summit Day - Friendship Peak",
        details: [
          "Early morning start for Friendship Peak summit (elevation: 5,289 meters)",
          "Summit and descend to ABC or Lady Leg based on conditions",
          "Trekking time: Approximately 10-12 hours",
        ],
      },
      {
        day: "6",
        title: "Reserve Day / Descend to Manali",
        details: [
          "Keep the day as a reserve for contingencies or gradual descent",
          "If the summit was on Day 5, descend to Manali",
          "Overnight stay in Manali or at a suitable location on the descent",
        ],
      },
      {
        day: "7",
        title: "Departure from Manali",
        details: [
          "Drive back to Manali",
          "Arrive in Manali and conclude the trek",
          "Depart from Manali with memories of the Friendship Peak expedition",
        ],
      },
    ],
    inclusion: [
      {
        sectionTitle: "Accommodation",
        content: [
          "Accommodation in Manali on Day 1 and Day 6 (as applicable)",
          "Camping arrangements during the trek",
        ],
      },
      {
        sectionTitle: "Meals",
        content: [
          "Nutritious and hygienic meals throughout the expedition",
          "Breakfast, lunch, and dinner are provided during the expedition days",
          "Special arrangements for dietary preferences if communicated in advance",
        ],
      },
      {
        sectionTitle: "Transportation",
        content: ["Transportation to and from climbing locations."],
      },
      {
        sectionTitle: "Guides and Support",
        content: [
          "Experienced trek leader and support staff",
          "Local guides familiar with the terrain",
          "Support staff for camping and logistics",
        ],
      },
      {
        sectionTitle: "Permits and Entrance Fees",
        content: [
          "All necessary permits and entry fees",
          "Permissions for climbing in the region",
          "Indian Mountaineering Foundation (IMF) registration",
        ],
      },
      {
        sectionTitle: "Safety and Medical Assistance",
        content: [
          "First aid kit and basic medical assistance",
          "Emergency evacuation plans in place",
          "Experienced guides trained in high-altitude rescue operations",
        ],
      },
      {
        sectionTitle: "Equipment",
        content: [
          "Tents, sleeping bags, and other camping equipment",
          "Safety equipment for the climb",
          "Personal gear check and assistance",
        ],
      },
      {
        sectionTitle: "Climbing Assistance",
        content: [
          "Guidance and support during the summit day",
          "Assistance with technical aspects of the climb",
        ],
      },
      {
        sectionTitle: "Documentation",
        content: [
          "Detailed itinerary and information before the climb",
          "Completion certificates for the participants",
        ],
      },
      {
        sectionTitle: "Communication",
        content: [
          "Communication equipment for emergencies",
          "Regular updates and briefings",
        ],
      },
      {
        sectionTitle: "Environment and Leave No Trace",
        content: [
          "Commitment to environmentally responsible trekking/climbing",
          "Practices to minimize environmental impact",
        ],
      },
    ],
    exclusion: [
      {
        sectionTitle: "Travel Insurance",
        content: [
          "Personal travel insurance covering medical expenses, evacuation, and other unforeseen circumstances.",
        ],
      },
      {
        sectionTitle: "Personal Gear",
        content: [
          "Individual trekking equipment and personal clothing.",
          "Any additional gear required for specific preferences or comfort.",
        ],
      },
      {
        sectionTitle: "Meals in Manali",
        content: [
          "Meals in Manali on arrival and departure days.",
          "Expenses for additional meals beyond the provided trek meals.",
        ],
      },
      {
        sectionTitle: "Beverages",
        content: [
          "Expenses for additional beverages or snacks not included in the provided meals.",
        ],
      },
      {
        sectionTitle: "Personal Expenses",
        content: [
          "Any expenses of a personal nature, including souvenirs, phone calls, laundry, and tips.",
        ],
      },
      {
        sectionTitle: "Transport beyond Itinerary",
        content: [
          "Transportation or expenses beyond the specified itinerary or additional travel arrangements.",
        ],
      },
      {
        sectionTitle: "Medical Expenses",
        content: [
          "Costs related to pre-existing medical conditions or personal medication.",
        ],
      },
      {
        sectionTitle: "Changes in Itinerary",
        content: [
          "Any additional costs incurred due to changes in the itinerary caused by factors beyond the control of the climb organizers.",
        ],
      },
      {
        sectionTitle: "Unforeseen Circumstances",
        content: [
          "Expenses arising from unforeseen circumstances such as natural disasters, political unrest, or flight cancellations.",
        ],
      },
      {
        sectionTitle: "Additional Services",
        content: [
          "Any services or facilities not explicitly mentioned in the inclusions.",
        ],
      },
      {
        sectionTitle: "Porter Services",
        content: [
          "Personal porter services for carrying individual backpacks, if required.",
        ],
      },
      {
        sectionTitle: "Optional Activities",
        content: [
          "Costs associated with optional activities or excursions not included in the itinerary.",
        ],
      },
      {
        sectionTitle: "Government Fees",
        content: [
          "Any additional fees imposed by government authorities during the climb.",
        ],
      },
    ],
    youtubeLink: "",
    expeditionDate: [
      {
        id: 1,
        duration: "7 Days",
        startDate: "",
        endDate: "",
        bookedSeat: "",
        availableSeat: "",
      },
      {
        id: 2,
        duration: "7 Days",
        startDate: "",
        endDate: "",
        bookedSeat: "",
        availableSeat: "",
      },
      {
        id: 3,
        duration: "7 Days",
        startDate: "",
        endDate: "",
        bookedSeat: "",
        availableSeat: "",
      },
      {
        id: 4,
        duration: "7 Days",
        startDate: "",
        endDate: "",
        bookedSeat: "",
        availableSeat: "",
      },
      {
        id: 5,
        duration: "7 Days",
        startDate: "",
        endDate: "",
        bookedSeat: "",
        availableSeat: "",
      },
    ],
  },
  {
    individualExpeditionType: "chamserKangri",
    individualExpeditionName: "Chamser Kangri",
    carouselData: expedition6000MHeroSection,
    location: "Korzog, Ladakh",
    duration: "9 Days",
    grade: "Beginners",
    maxAltitude: "6620 m or 21,765 ft",
    bestSeason: "July, August, September and October",
    experienceRequired:
      "An enthusiast should have trekked between 4000 m to 5000 m at least once.",
    mainExpeditionContent:
      "Chamsher Kangri is a winter wonderland that captivates with its velvety snow, crisp air, and breathtaking vistas—a dreamlike adventure awaiting. Immerse yourself in the tale of this frosty escapade, a memory you'll cherish, an experience draped in nostalgia, and a journey you'll wish would linger.Embark on this exhilarating odyssey, commencing with a gradual trek through the renowned Markha Valley. The path leads to the majestic Chamsher Kangri, standing tall at 21,765 ft., overlooking the divine Tsomoriri Lake in the enchanting Changthang plateau. Markha Valley, a quintessential trek, unfolds the charm of living in Ladakh, one of the world's most exquisite locales.Traverse quaint villages, glimpsing the ethnic tapestry of Ladakh, and cross expansive grazing lands en route to the formidable Kongmaru La. Conquer the lower twin peaks of Chamsher and Lungsher in the Rupshu region, with Chamsher's lofty summit delivering an authentic mountaineering thrill. This journey is not just an ascent; it's a unique window into the nomadic life of Changpa Nomads, wandering the vast landscapes of Changthang.",
    aboutExpeditionExperienced:
      "Climb Chamaner Kangri with our seasoned climbing team with over a decade of experience in the Indian Himalayas. We pride ourselves on upholding the highest safety standards throughout our operations. With a track record of more than 10 years, we've honed our skills and know these mountains like the back of our hands. Our commitment to safety goes beyond words—we have meticulous rescue plans in place, ready to be executed if the need arises. When you embark on an adventure with us, rest assured that you're in the hands of professionals who prioritize your safety above all else. Welcome to a team that's as prepared as it gets.",
    itineraryData: [
      {
        day: 1,
        title: "Arrival in Leh",
        details: [
          "Arrive in Leh and check into your hotel.",
          "Rest and acclimate to the high altitude.",
        ],
      },
      {
        day: 2,
        title: "Explore Local Leh",
        details: [
          "Discover Leh's attractions: Shanti Stupa, Leh Palace, and Mall Road.",
          "Indulge in regional cuisine at local food cafes.",
          "Shop for expedition gear.",
        ],
      },
      {
        day: 3,
        title: "Drive from Leh to Karzok Village (4595 m)",
        details: [
          "Scenic drive to Karzok Village at 4595 meters.",
          "Trek for 5-6 hours to the village.",
          "Rest and enjoy a meal in homestays or camps.",
        ],
      },
      {
        day: 4,
        title: "Explore Tso Moriri Lake and Acclimatize",
        details: [
          "Explore the mesmerizing Tso Moriri Lake.",
          "Acclimatize to the cold desert environment.",
        ],
      },
      {
        day: 5,
        title: "Trek to Base Camp (5200 m)",
        details: [
          "Trek for 6-8 hours to reach the Base Camp at 5200 meters.",
          "Set up camp and rest in preparation for the summit.",
        ],
      },
      {
        day: 6,
        title: "Rest and Acclimatization at Base Camp (5200 m)",
        details: [
          "Take a day to rest and acclimate at the Base Camp.",
          "Engage in light activities and enjoy the surroundings.",
        ],
      },
      {
        day: 7,
        title: "Base Camp to Summit Camp (5700 m)",
        details: [
          "Trek for 4-5 hours to reach the Summit Camp at 5700 meters.",
          "Set up camp and prepare for the summit push.",
        ],
      },
      {
        day: 8,
        title: "Summit Day - Chamsar Kang Ri (6622 m)",
        details: [
          "Early morning trek to the summit, taking 5-6 hours.",
          "Summit Chamsar Kang Ri at 6622 meters.",
          "Descend back to the Base Camp after a successful summit.",
        ],
      },
      {
        day: 9,
        title: "Base Camp to Karzok and Drive Back to Leh",
        details: [
          "Trek from Base Camp to Karzok Village.",
          "Drive back to Leh, concluding the expedition.",
        ],
      },
    ],
    inclusion: [
      {
        sectionTitle: "Accommodation",
        content: [
          "Comfortable lodging in Leh upon arrival and during the expedition.",
          "Tented accommodation during the trek and at various campsites.",
        ],
      },
      {
        sectionTitle: "Meals",
        content: [
          "Nutritious and delicious meals throughout the expedition, designed to meet the energy demands of high-altitude trekking.",
          "Special provisions for dietary preferences and requirements.",
        ],
      },
      {
        sectionTitle: "Experienced Guides",
        content: [
          "Expert local guides well-versed in the terrain and conditions of the region.",
          "Assistance and guidance to ensure a safe and enjoyable trekking experience.",
        ],
      },
      {
        sectionTitle: "Transportation",
        content: [
          "Transport from Leh to the trek starting points and back, ensuring a seamless and comfortable journey.",
          "Well-maintained vehicles and experienced drivers for safe travel.",
        ],
      },
      {
        sectionTitle: "Camping Equipment",
        content: [
          "High-quality camping gear, including tents, sleeping bags, and other essentials for a comfortable night's rest.",
          "Technical gear distribution and fitting for the summit attempt.",
        ],
      },
      {
        sectionTitle: "Permits and Paperwork",
        content: [
          "Assistance in completing necessary paperwork with the Indian Mountaineering Foundation (IMF).",
          "Coordination of permits required for the expedition.",
        ],
      },
      {
        sectionTitle: "Safety Measures",
        content: [
          "Emergency evacuation plans and access to experienced high-altitude rescue teams.",
          "First aid provisions and medical assistance throughout the expedition.",
        ],
      },
      {
        sectionTitle: "Expedition Certificate",
        content: [
          "Commemorative certificates recognizing the successful completion Expedition",
        ],
      },
      {
        sectionTitle: "Communication",
        content: [
          "Communication equipment for emergencies",
          "Regular updates and briefings",
        ],
      },
      {
        sectionTitle: "Environment and Leave No Trace",
        content: [
          "Commitment to environmentally responsible trekking",
          "Practices to minimize environmental impact",
        ],
      },
    ],
    exclusion: [
      {
        sectionTitle: "Travel Insurance",
        content: [
          "Personal travel insurance covering medical expenses, evacuation, and other unforeseen circumstances.",
        ],
      },
      {
        sectionTitle: "Personal Gear",
        content: [
          "Individual trekking equipment and personal clothing.",
          "Any additional gear required for specific preferences or comfort.",
        ],
      },
      {
        sectionTitle: "Meals in Leh",
        content: [
          "Meals in Leh on arrival and departure days.",
          "Expenses for additional meals beyond the provided trek meals.",
        ],
      },
      {
        sectionTitle: "Beverages",
        content: [
          "Expenses for additional beverages or snacks not included in the provided meals.",
        ],
      },
      {
        sectionTitle: "Personal Expenses",
        content: [
          "Any expenses of a personal nature, including souvenirs, phone calls, laundry, and tips.",
        ],
      },
      {
        sectionTitle: "Transport beyond Itinerary",
        content: [
          "Transportation or expenses beyond the specified itinerary or additional travel arrangements.",
        ],
      },
      {
        sectionTitle: "Medical Expenses",
        content: [
          "Costs related to pre-existing medical conditions or personal medication.",
        ],
      },
      {
        sectionTitle: "Changes in Itinerary",
        content: [
          "Any additional costs incurred due to changes in the itinerary caused by factors beyond the control of the trek organizers.",
        ],
      },
      {
        sectionTitle: "Unforeseen Circumstances",
        content: [
          "Expenses arising from unforeseen circumstances such as natural disasters, political unrest, or flight cancellations.",
        ],
      },
      {
        sectionTitle: "Additional Services",
        content: [
          "Any services or facilities not explicitly mentioned in the inclusions.",
        ],
      },
      {
        sectionTitle: "Porter Services",
        content: [
          "Personal porter services for carrying individual backpacks, if required.",
        ],
      },
      {
        sectionTitle: "Optional Activities",
        content: [
          "Costs associated with optional activities or excursions not included in the trek itinerary.",
        ],
      },
      {
        sectionTitle: "Government Fees",
        content: [
          "Any additional fees imposed by government authorities during the trek.",
        ],
      },
    ],
    youtubeLink: "",
    expeditionDate: [
      {
        id: 1,
        duration: "7 Days",
        startDate: "",
        endDate: "",
        bookedSeat: "",
        availableSeat: "",
      },
      {
        id: 2,
        duration: "7 Days",
        startDate: "",
        endDate: "",
        bookedSeat: "",
        availableSeat: "",
      },
      {
        id: 3,
        duration: "7 Days",
        startDate: "",
        endDate: "",
        bookedSeat: "",
        availableSeat: "",
      },
      {
        id: 4,
        duration: "7 Days",
        startDate: "",
        endDate: "",
        bookedSeat: "",
        availableSeat: "",
      },
      {
        id: 5,
        duration: "7 Days",
        startDate: "",
        endDate: "",
        bookedSeat: "",
        availableSeat: "",
      },
    ],
  },
  {
    individualExpeditionType: "lungserKangri",
    individualExpeditionName: "Lungser Kangri",
    carouselData: expedition6000MHeroSection,
    location: "Korzog, Ladakh",
    duration: "9 Days",
    grade: "Beginners",
    maxAltitude: "6666 m or 21870 ft",
    bestSeason: "July, August, September and October",
    experienceRequired:
      "An enthusiast should have trekked between 4000 m to 5000 m at least once.",
    mainExpeditionContent:
      "Mount Lung Sar Kang Ri stands as an awe-inspiring peak, beckoning adventure enthusiasts to embark on a journey of a lifetime. With an elevation of 6666 meters, this formidable mountain offers an exhilarating challenge and a breathtaking panorama of the Himalayan region. The expedition commences in Leh, where travelers acclimate to the high altitude, explore local attractions, and indulge in Ladakhi cuisine.As the journey progresses, participants drive to Karzok Village, situated at 4595 meters, before undertaking a trek to the mesmerizing Tso Moriri Lake for acclimatization. The trek then leads to the Base Camp at 5200 meters, allowing for vital rest before the ascent to the Summit Camp at 5700 meters. The pinnacle of the adventure arrives on Summit Day, with an early morning trek culminating in the triumph of conquering Lung Sar Kang Ri at 6666 meters. This expedition seamlessly weaves cultural exploration, natural wonders, and the thrill of high-altitude trekking, making it an unforgettable odyssey in the heart of Ladakh's pristine beauty.",
    aboutExpeditionExperienced:
      "Climb Lungser Kangri with our seasoned climbing team with over a decade of experience in the Indian Himalayas. We pride ourselves on upholding the highest safety standards throughout our operations. With a track record of more than 10 years, we've honed our skills and know these mountains like the back of our hands. Our commitment to safety goes beyond words—we have meticulous rescue plans in place, ready to be executed if the need arises. When you embark on an adventure with us, rest assured that you're in the hands of professionals who prioritize your safety above all else. Welcome to a team that's as prepared as it gets.",
    itineraryData: [
      {
        day: 1,
        title: "Arrival in Leh",
        details: [
          "Arrive in Leh.",
          "Rest and check into your hotel.",
          "Acclimate to the high altitude.",
        ],
      },
      {
        day: 2,
        title: "Explore Local Leh",
        details: [
          "Explore local attractions: Shanti Stupa, Leh Palace, and Mall Road.",
          "Visit local food cafes for regional cuisine.",
          "Shop for personal gear needed for the expedition.",
        ],
      },
      {
        day: 3,
        title: "Drive from Leh to Karzok Village (4595 m)",
        details: [
          "Scenic drive from Leh to Karzok Village (4595 m).",
          "Trek for 5-6 hours to reach the village.",
          "Rest and enjoy a meal in homestays or camps.",
        ],
      },
      {
        day: 4,
        title: "Explore Tso Moriri Lake and Acclimatize",
        details: [
          "Explore Tso Moriri Lake.",
          "Acclimatize to the cold desert environment.",
        ],
      },
      {
        day: 5,
        title: "Trek to Base Camp (5200 m)",
        details: [
          "Trek for 6-8 hours to reach Base Camp (5200 m).",
          "Set up camp and rest in preparation for the summit.",
        ],
      },
      {
        day: 6,
        title: "Rest and Acclimatization at Base Camp (5200 m)",
        details: [
          "Rest and acclimatize at Base Camp.",
          "Engage in light activities and enjoy the surrounding views.",
        ],
      },
      {
        day: 7,
        title: "Base Camp to Summit Camp (5700 m)",
        details: [
          "Trek for 4-5 hours to reach Summit Camp (5700 m).",
          "Set up camp and prepare for the summit push.",
        ],
      },
      {
        day: 8,
        title: "Summit Day - Lung Sar Kang Ri (6666 m)",
        details: [
          "Start early morning trek to the summit (5-6 hours).",
          "Summit Lung Sar Kang Ri (6666 m).",
          "Descend back to Base Camp after successful summit.",
        ],
      },
      {
        day: 9,
        title: "Base Camp to Karzok and Drive Back to Leh",
        details: [
          "Trek from Base Camp to Karzok Village.",
          "Drive back to Leh, concluding the expedition.",
        ],
      },
    ],
    inclusion: [
      {
        sectionTitle: "Accommodation",
        content: [
          "Comfortable lodging in Leh upon arrival and during the expedition.",
          "Tented accommodation during the trek and at various campsites.",
        ],
      },
      {
        sectionTitle: "Meals",
        content: [
          "Nutritious and delicious meals throughout the expedition, designed to meet the energy demands of high-altitude trekking.",
          "Special provisions for dietary preferences and requirements.",
        ],
      },
      {
        sectionTitle: "Experienced Guides",
        content: [
          "Expert local guides well-versed in the terrain and conditions of the region.",
          "Assistance and guidance to ensure a safe and enjoyable trekking experience.",
        ],
      },
      {
        sectionTitle: "Transportation",
        content: [
          "Transport from Leh to the trek starting points and back, ensuring a seamless and comfortable journey.",
          "Well-maintained vehicles and experienced drivers for safe travel.",
        ],
      },
      {
        sectionTitle: "Camping Equipment",
        content: [
          "High-quality camping gear, including tents, sleeping bags, and other essentials for a comfortable night's rest.",
          "Technical gear distribution and fitting for the summit attempt.",
        ],
      },
      {
        sectionTitle: "Permits and Paperwork",
        content: [
          "Assistance in completing necessary paperwork with the Indian Mountaineering Foundation (IMF).",
          "Coordination of permits required for the expedition.",
        ],
      },
      {
        sectionTitle: "Safety Measures",
        content: [
          "Emergency evacuation plans and access to experienced high-altitude rescue teams.",
          "First aid provisions and medical assistance throughout the expedition.",
        ],
      },
      {
        sectionTitle: "Expedition Certificate",
        content: [
          "Commemorative certificates recognizing the successful completion Expedition",
        ],
      },
      {
        sectionTitle: "Communication",
        content: [
          "Communication equipment for emergencies",
          "Regular updates and briefings",
        ],
      },
      {
        sectionTitle: "Environment and Leave No Trace",
        content: [
          "Commitment to environmentally responsible trekking",
          "Practices to minimize environmental impact",
        ],
      },
    ],
    exclusion: [
      {
        sectionTitle: "Travel Insurance",
        content: [
          "Personal travel insurance covering medical expenses, evacuation, and other unforeseen circumstances.",
        ],
      },
      {
        sectionTitle: "Personal Gear",
        content: [
          "Individual trekking equipment and personal clothing.",
          "Any additional gear required for specific preferences or comfort.",
        ],
      },
      {
        sectionTitle: "Meals in Leh",
        content: [
          "Meals in Leh on arrival and departure days.",
          "Expenses for additional meals beyond the provided trek meals.",
        ],
      },
      {
        sectionTitle: "Beverages",
        content: [
          "Expenses for additional beverages or snacks not included in the provided meals.",
        ],
      },
      {
        sectionTitle: "Personal Expenses",
        content: [
          "Any expenses of a personal nature, including souvenirs, phone calls, laundry, and tips.",
        ],
      },
      {
        sectionTitle: "Transport beyond Itinerary",
        content: [
          "Transportation or expenses beyond the specified itinerary or additional travel arrangements.",
        ],
      },
      {
        sectionTitle: "Medical Expenses",
        content: [
          "Costs related to pre-existing medical conditions or personal medication.",
        ],
      },
      {
        sectionTitle: "Changes in Itinerary",
        content: [
          "Any additional costs incurred due to changes in the itinerary caused by factors beyond the control of the trek organizers.",
        ],
      },
      {
        sectionTitle: "Unforeseen Circumstances",
        content: [
          "Expenses arising from unforeseen circumstances such as natural disasters, political unrest, or flight cancellations.",
        ],
      },
      {
        sectionTitle: "Additional Services",
        content: [
          "Any services or facilities not explicitly mentioned in the inclusions.",
        ],
      },
      {
        sectionTitle: "Porter Services",
        content: [
          "Personal porter services for carrying individual backpacks, if required.",
        ],
      },
      {
        sectionTitle: "Optional Activities",
        content: [
          "Costs associated with optional activities or excursions not included in the trek itinerary.",
        ],
      },
      {
        sectionTitle: "Government Fees",
        content: [
          "Any additional fees imposed by government authorities during the trek.",
        ],
      },
    ],
    youtubeLink: "",
    expeditionDate: [
      {
        id: 1,
        duration: "7 Days",
        startDate: "",
        endDate: "",
        bookedSeat: "",
        availableSeat: "",
      },
      {
        id: 2,
        duration: "7 Days",
        startDate: "",
        endDate: "",
        bookedSeat: "",
        availableSeat: "",
      },
      {
        id: 3,
        duration: "7 Days",
        startDate: "",
        endDate: "",
        bookedSeat: "",
        availableSeat: "",
      },
      {
        id: 4,
        duration: "7 Days",
        startDate: "",
        endDate: "",
        bookedSeat: "",
        availableSeat: "",
      },
      {
        id: 5,
        duration: "7 Days",
        startDate: "",
        endDate: "",
        bookedSeat: "",
        availableSeat: "",
      },
    ],
  },
  {
    individualExpeditionType: "lagpoNorth",
    individualExpeditionName: "Lagpo North",
    carouselData: expedition6000MHeroSection,
    location: "Karzog, Ladakh",
    duration: "10 Days",
    grade: "Intermediate",
    maxAltitude: "6360 m or 20, 866 ft",
    bestSeason: "July, August, September and October",
    experienceRequired:
      "An enthusiast should have trekked between 4000 m to 5000 m at least once.",
    mainExpeditionContent:
      "Get ready for an adrenaline-pumping quest to Lagpo North, soaring at a jaw-dropping 6360 meters in Leh's heart. This 10-day thrill ride kicks off in Leh, where you'll acclimate to dizzying altitudes. The journey weaves through Karzok village, treating your eyes to Tso Moriri Lake's breathtaking vistas.Trek to the 5500-meter-high base camp, your launchpad for the ultimate challenge – a heart-pounding, 6360-meter summit attempt on Lagpo North. Celebrate amidst the Himalayan grandeur, crafting memories that echo with the thrill of scaling nature's towering heights. Get ready for a wild ride into the heart of adventure!",
    aboutExpeditionExperienced:
      "Climb Lagpo Peak with our seasoned climbing team with over a decade of experience in the Indian Himalayas. We pride ourselves on upholding the highest safety standards throughout our operations. With a track record of more than 10 years, we've honed our skills and know these mountains like the back of our hands. Our commitment to safety goes beyond words—we have meticulous rescue plans in place, ready to be executed if the need arises. When you embark on an adventure with us, rest assured that you're in the hands of professionals who prioritize your safety above all else. Welcome to a team that's as prepared as it gets.",
    itineraryData: [
      {
        day: 1,
        title: "Arrival in Leh (3,500 M)",
        details: [
          "Arrive in Leh and check into accommodation.",
          "Rest and acclimatize to the high altitude.",
        ],
      },
      {
        day: 2,
        title: "Local Sightseeing and Acclimatization Walk",
        details: [
          "Explore Leh's local attractions, market, and experience the high-mountain city's culture.",
          "Acclimatization walk to aid in adjusting to the altitude.",
          "Group debriefing session on upcoming days, safety measures, and mountain etiquette.",
        ],
      },
      {
        day: 3,
        title: "Drive from Leh to Karzok Village",
        details: [
          "Journey from Leh to Karzok village, enjoying scenic views.",
          "Rest upon reaching Karzok and prepare for further acclimatization.",
        ],
      },
      {
        day: 4,
        title: "Acclimatize and Explore Tso Moriri Lake (4,500 M)",
        details: [
          "Acclimatization activities in Karzok.",
          "Explore the breathtaking Tso Moriri Lake at an altitude of 4,500 meters.",
        ],
      },
      {
        day: 5,
        title: "Karzok to Base Camp Trek (5,500 M)",
        details: [
          "Begin trekking from Karzok to the base camp.",
          "Trek for 4-5 hours, reaching an altitude of 5,500 meters.",
          "Set up base camp and acclimatize.",
        ],
      },
      {
        day: 6,
        title: "Acclimatization Day at Base Camp",
        details: [
          "Rest and acclimatize at the base camp (5,500 meters).",
          "Short walks to maintain physical activity while adjusting to the altitude.",
        ],
      },
      {
        day: 7,
        title: "Base Camp to Summit Camp Trek (5,800 M)",
        details: [
          "Trek from the base camp to the summit camp.",
          "Trek for 3-4 hours, reaching an altitude of 5,800 meters.",
          "Prepare for the summit push.",
        ],
      },
      {
        day: 8,
        title: "Summit Day - Lagpo North (6360m)",
        details: [
          "Early morning trek from the summit camp to the peak of Lagpo North (6360m).",
          "Approximately 5 hours to summit and return to the base camp.",
          "Celebrate the achievement and rest at the base camp.",
        ],
      },
      {
        day: 9,
        title: "Trek from Base Camp to Karzok",
        details: [
          "Descend from the base camp to Karzok village.",
          "Reflect on the journey and enjoy the scenic landscapes.",
        ],
      },
      {
        day: 10,
        title: "Drive Back to Leh from Karzok",
        details: [
          "Drive back to Leh from Karzok.",
          "Conclude the expedition with fond memories and a sense of accomplishment.",
        ],
      },
    ],
    inclusion: [
      {
        sectionTitle: "Accommodation",
        content: [
          "Comfortable lodging in Leh upon arrival and during the Deo Tibba expedition.",
          "Tented accommodation during the trek and at various campsites.",
        ],
      },
      {
        sectionTitle: "Meals",
        content: [
          "Nutritious and delicious meals throughout the expedition, designed to meet the energy demands of high-altitude trekking.",
          "Special provisions for dietary preferences and requirements.",
        ],
      },
      {
        sectionTitle: "Experienced Guides",
        content: [
          "Expert local guides well-versed in the terrain and conditions of the region.",
          "Assistance and guidance to ensure a safe and enjoyable trekking experience.",
        ],
      },
      {
        sectionTitle: "Transportation",
        content: [
          "Transport from Leh to the trek starting points and back, ensuring a seamless and comfortable journey.",
          "Well-maintained vehicles and experienced drivers for safe travel.",
        ],
      },
      {
        sectionTitle: "Camping Equipment",
        content: [
          "High-quality camping gear, including tents, sleeping bags, and other essentials for a comfortable night's rest.",
          "Technical gear distribution and fitting for the summit attempt.",
        ],
      },
      {
        sectionTitle: "Permits and Paperwork",
        content: [
          "Assistance in completing necessary paperwork with the Indian Mountaineering Foundation (IMF).",
          "Coordination of permits required for the expedition.",
        ],
      },
      {
        sectionTitle: "Safety Measures",
        content: [
          "Emergency evacuation plans and access to experienced high-altitude rescue teams.",
          "First aid provisions and medical assistance throughout the expedition.",
        ],
      },
      {
        sectionTitle: "Expedition Certificate",
        content: [
          "Commemorative certificates recognizing the successful completion of Deo Tibba Expedition",
        ],
      },
      {
        sectionTitle: "Communication",
        content: [
          "Communication equipment for emergencies",
          "Regular updates and briefings",
        ],
      },
      {
        sectionTitle: "Environment and Leave No Trace",
        content: [
          "Commitment to environmentally responsible trekking",
          "Practices to minimize environmental impact",
        ],
      },
    ],
    exclusion: [
      {
        sectionTitle: "Travel Insurance",
        content: [
          "Personal travel insurance covering medical expenses, evacuation, and other unforeseen circumstances.",
        ],
      },
      {
        sectionTitle: "Personal Gear",
        content: [
          "Individual trekking equipment and personal clothing.",
          "Any additional gear required for specific preferences or comfort.",
        ],
      },
      {
        sectionTitle: "Meals in Leh",
        content: [
          "Meals in Leh on arrival and departure days.",
          "Expenses for additional meals beyond the provided trek meals.",
        ],
      },
      {
        sectionTitle: "Beverages",
        content: [
          "Expenses for additional beverages or snacks not included in the provided meals.",
        ],
      },
      {
        sectionTitle: "Personal Expenses",
        content: [
          "Any expenses of a personal nature, including souvenirs, phone calls, laundry, and tips.",
        ],
      },
      {
        sectionTitle: "Transport beyond Itinerary",
        content: [
          "Transportation or expenses beyond the specified itinerary or additional travel arrangements.",
        ],
      },
      {
        sectionTitle: "Medical Expenses",
        content: [
          "Costs related to pre-existing medical conditions or personal medication.",
        ],
      },
      {
        sectionTitle: "Changes in Itinerary",
        content: [
          "Any additional costs incurred due to changes in the itinerary caused by factors beyond the control of the trek organizers.",
        ],
      },
      {
        sectionTitle: "Unforeseen Circumstances",
        content: [
          "Expenses arising from unforeseen circumstances such as natural disasters, political unrest, or flight cancellations.",
        ],
      },
      {
        sectionTitle: "Additional Services",
        content: [
          "Any services or facilities not explicitly mentioned in the inclusions.",
        ],
      },
      {
        sectionTitle: "Porter Services",
        content: [
          "Personal porter services for carrying individual backpacks, if required.",
        ],
      },
      {
        sectionTitle: "Optional Activities",
        content: [
          "Costs associated with optional activities or excursions not included in the trek itinerary.",
        ],
      },
      {
        sectionTitle: "Government Fees",
        content: [
          "Any additional fees imposed by government authorities during the trek.",
        ],
      },
    ],
    youtubeLink: "",
    expeditionDate: [
      {
        id: 1,
        duration: "7 Days",
        startDate: "",
        endDate: "",
        bookedSeat: "",
        availableSeat: "",
      },
      {
        id: 2,
        duration: "7 Days",
        startDate: "",
        endDate: "",
        bookedSeat: "",
        availableSeat: "",
      },
      {
        id: 3,
        duration: "7 Days",
        startDate: "",
        endDate: "",
        bookedSeat: "",
        availableSeat: "",
      },
      {
        id: 4,
        duration: "7 Days",
        startDate: "",
        endDate: "",
        bookedSeat: "",
        availableSeat: "",
      },
      {
        id: 5,
        duration: "7 Days",
        startDate: "",
        endDate: "",
        bookedSeat: "",
        availableSeat: "",
      },
    ],
  },
  {
    individualExpeditionType: "chalung",
    individualExpeditionName: "Chalung",
    carouselData: expedition6000MHeroSection,
    location: "Ladakh",
    duration: "10 Days",
    grade: "Intermediate",
    maxAltitude: "6446 m or 21148 ft",
    bestSeason: "July, August, September and October",
    experienceRequired:
      "An enthusiast should have trekked between 4000 m to 5000 m at least once.",
    mainExpeditionContent:
      "Prepare for an exhilarating odyssey through Ladakh's untamed wilderness, where the imposing Chalung Peak (6,446 meters) stands as a formidable challenge for intrepid explorers. Over 10 pulse-pounding days, traverse the rugged landscapes of Leh and Karzok Village, immersing yourself in the vibrant high-mountain culture.Amidst the thin air and pristine beauty, acclimatize at the enchanting Tso Moriri Lake and establish a base camp at 5,500 meters. Chart an audacious course from Karzok to the summit camp at 5,800 meters, setting the stage for the ultimate conquest of Chalung Peak. This epic journey, pulsating with raw adventure, promises not just panoramic views but an indomitable test of resilience and an untold chapter in the high-altitude chronicles of Ladakh's allure. ",
    aboutExpeditionExperienced:
      "Climb Chalung Peak with our seasoned climbing team with over a decade of experience in the Indian Himalayas. We pride ourselves on upholding the highest safety standards throughout our operations. With a track record of more than 10 years, we've honed our skills and know these mountains like the back of our hands. Our commitment to safety goes beyond words—we have meticulous rescue plans in place, ready to be executed if the need arises. When you embark on an adventure with us, rest assured that you're in the hands of professionals who prioritize your safety above all else. Welcome to a team that's as prepared as it gets.",
    itineraryData: [
      {
        day: 1,
        title: "Leh Arrival (3,500 M)",
        details: [
          "Arrive in Leh and settle into accommodation.",
          "Rest and acclimatize to the high altitude.",
        ],
      },
      {
        day: 2,
        title: "Local Exploration and Acclimatization Walk",
        details: [
          "Explore Leh's local attractions and market.",
          "Acclimatization walk to adjust to the altitude.",
          "Group debriefing session on upcoming days, safety measures, and mountain etiquette.",
        ],
      },
      {
        day: 3,
        title: "Leh to Karzok Village Drive",
        details: [
          "Scenic journey from Leh to Karzok village.",
          "Enjoy views along the way.",
          "Rest in Karzok and prepare for further acclimatization.",
        ],
      },
      {
        day: 4,
        title: "Acclimatization Day at Tso Moriri Lake (4,500 M)",
        details: [
          "Acclimatization activities in Karzok.",
          "Explore Tso Moriri Lake at 4,500 meters.",
        ],
      },
      {
        day: 5,
        title: "Karzok to Base Camp Trek (5,500 M)",
        details: [
          "Trek from Karzok to the base camp.",
          "4-5 hours of trekking, reaching 5,500 meters.",
          "Set up base camp and acclimatize.",
        ],
      },
      {
        day: 6,
        title: "Acclimatization Day at Base Camp",
        details: [
          "Rest and acclimatize at the base camp (5,500 meters).",
          "Short walks to maintain physical activity.",
        ],
      },
      {
        day: 7,
        title: "Base Camp to Summit Camp Trek (5,800 M)",
        details: [
          "Trek from base camp to summit camp.",
          "3-4 hours of trekking, reaching 5,800 meters.",
          "Prepare for the summit push.",
        ],
      },
      {
        day: 8,
        title: "Summit Day - Chalung (6,446 M)",
        details: [
          "Early morning trek to Mount Chalung peak (6,546 meters).",
          "Approximately 5 hours for summit and return to base camp.",
          "Celebrate and rest at the base camp.",
        ],
      },
      {
        day: 9,
        title: "Trek from Base Camp to Karzok",
        details: [
          "Descend from base camp to Karzok village.",
          "Reflect on the journey and enjoy scenic landscapes.",
        ],
      },
      {
        day: 10,
        title: "Karzok to Leh Drive",
        details: [
          "Drive back to Leh from Karzok.",
          "Conclude the expedition with fond memories and a sense of accomplishment.",
        ],
      },
    ],
    inclusion: [
      {
        sectionTitle: "Accommodation",
        content: [
          "Comfortable lodging in Leh upon arrival and during the Deo Tibba expedition.",
          "Tented accommodation during the trek and at various campsites.",
        ],
      },
      {
        sectionTitle: "Meals",
        content: [
          "Nutritious and delicious meals throughout the expedition, designed to meet the energy demands of high-altitude trekking.",
          "Special provisions for dietary preferences and requirements.",
        ],
      },
      {
        sectionTitle: "Experienced Guides",
        content: [
          "Expert local guides well-versed in the terrain and conditions of the region.",
          "Assistance and guidance to ensure a safe and enjoyable trekking experience.",
        ],
      },
      {
        sectionTitle: "Transportation",
        content: [
          "Transport from Leh to the trek starting points and back, ensuring a seamless and comfortable journey.",
          "Well-maintained vehicles and experienced drivers for safe travel.",
        ],
      },
      {
        sectionTitle: "Camping Equipment",
        content: [
          "High-quality camping gear, including tents, sleeping bags, and other essentials for a comfortable night's rest.",
          "Technical gear distribution and fitting for the summit attempt.",
        ],
      },
      {
        sectionTitle: "Permits and Paperwork",
        content: [
          "Assistance in completing necessary paperwork with the Indian Mountaineering Foundation (IMF).",
          "Coordination of permits required for the expedition.",
        ],
      },
      {
        sectionTitle: "Safety Measures",
        content: [
          "Emergency evacuation plans and access to experienced high-altitude rescue teams.",
          "First aid provisions and medical assistance throughout the expedition.",
        ],
      },
      {
        sectionTitle: "Expedition Certificate",
        content: [
          "Commemorative certificates recognizing the successful completion of Deo Tibba Expedition",
        ],
      },
      {
        sectionTitle: "Communication",
        content: [
          "Communication equipment for emergencies",
          "Regular updates and briefings",
        ],
      },
      {
        sectionTitle: "Environment and Leave No Trace",
        content: [
          "Commitment to environmentally responsible trekking",
          "Practices to minimize environmental impact",
        ],
      },
    ],
    exclusion: [
      {
        sectionTitle: "Travel Insurance",
        content: [
          "Personal travel insurance covering medical expenses, evacuation, and other unforeseen circumstances.",
        ],
      },
      {
        sectionTitle: "Personal Gear",
        content: [
          "Individual trekking equipment and personal clothing.",
          "Any additional gear required for specific preferences or comfort.",
        ],
      },
      {
        sectionTitle: "Meals in Leh",
        content: [
          "Meals in Leh on arrival and departure days.",
          "Expenses for additional meals beyond the provided trek meals.",
        ],
      },
      {
        sectionTitle: "Beverages",
        content: [
          "Expenses for additional beverages or snacks not included in the provided meals.",
        ],
      },
      {
        sectionTitle: "Personal Expenses",
        content: [
          "Any expenses of a personal nature, including souvenirs, phone calls, laundry, and tips.",
        ],
      },
      {
        sectionTitle: "Transport beyond Itinerary",
        content: [
          "Transportation or expenses beyond the specified itinerary or additional travel arrangements.",
        ],
      },
      {
        sectionTitle: "Medical Expenses",
        content: [
          "Costs related to pre-existing medical conditions or personal medication.",
        ],
      },
      {
        sectionTitle: "Changes in Itinerary",
        content: [
          "Any additional costs incurred due to changes in the itinerary caused by factors beyond the control of the trek organizers.",
        ],
      },
      {
        sectionTitle: "Unforeseen Circumstances",
        content: [
          "Expenses arising from unforeseen circumstances such as natural disasters, political unrest, or flight cancellations.",
        ],
      },
      {
        sectionTitle: "Additional Services",
        content: [
          "Any services or facilities not explicitly mentioned in the inclusions.",
        ],
      },
      {
        sectionTitle: "Porter Services",
        content: [
          "Personal porter services for carrying individual backpacks, if required.",
        ],
      },
      {
        sectionTitle: "Optional Activities",
        content: [
          "Costs associated with optional activities or excursions not included in the trek itinerary.",
        ],
      },
      {
        sectionTitle: "Government Fees",
        content: [
          "Any additional fees imposed by government authorities during the trek.",
        ],
      },
    ],
    youtubeLink: "",
    expeditionDate: [
      {
        id: 1,
        duration: "7 Days",
        startDate: "",
        endDate: "",
        bookedSeat: "",
        availableSeat: "",
      },
      {
        id: 2,
        duration: "7 Days",
        startDate: "",
        endDate: "",
        bookedSeat: "",
        availableSeat: "",
      },
      {
        id: 3,
        duration: "7 Days",
        startDate: "",
        endDate: "",
        bookedSeat: "",
        availableSeat: "",
      },
      {
        id: 4,
        duration: "7 Days",
        startDate: "",
        endDate: "",
        bookedSeat: "",
        availableSeat: "",
      },
      {
        id: 5,
        duration: "7 Days",
        startDate: "",
        endDate: "",
        bookedSeat: "",
        availableSeat: "",
      },
    ],
  },
  {
    individualExpeditionType: "shukule",
    individualExpeditionName: "Shukule",
    carouselData: expedition6000MHeroSection,
    location: " Karzog, Ladakh",
    duration: "10 Days",
    grade: "Intermediate",
    maxAltitude: "6508 m or 21,351 ft",
    bestSeason: "July, August, September and October",
    experienceRequired:
      "An enthusiast should have trekked between 4000 m to 5000 m at least once.",
    mainExpeditionContent:
      "Nestled in the awe-inspiring landscapes of the Himalayas, Shukule Peak stands majestically at 6508 meters, offering a captivating challenge for seasoned mountaineers. This high-altitude expedition unfolds over ten exhilarating days, commencing in Leh at 3500 meters. The journey includes cultural immersion in Leh, a scenic drive to Karzok Village, and acclimatization activities along the pristine Tso Moriri Lake at 4500 meters.The ascent continues with a trek to the Base Camp at 5500 meters, allowing adventurers to acclimatize well. A dedicated acclimatization day precedes the trek to the Summit Camp at 5800 meters. The pinnacle of the expedition arrives on Summit Day, as climbers embark on an early morning trek to Shukule's apex, experiencing the triumph of reaching 6508 meters. The descent retraces the route through Karzok, culminating in the return to Leh, leaving wonderful memories of a challenging yet rewarding journey to the summit of Shukule Peak.",
    aboutExpeditionExperienced:
      "Climb Shukule Peak with our seasoned climbing team with over a decade of experience in the Indian Himalayas. We pride ourselves on upholding the highest safety standards throughout our operations. With a track record of more than 10 years, we've honed our skills and know these mountains like the back of our hands. Our commitment to safety goes beyond words—we have meticulous rescue plans in place, ready to be executed if the need arises. When you embark on an adventure with us, rest assured that you're in the hands of professionals who prioritize your safety above all else. Welcome to a team that's as prepared as it gets.",
    itineraryData: [
      {
        day: 1,
        title: "Arrive in Leh (3,500 M)",
        details: [
          "Arrive in Leh and settle into accommodation.",
          "Rest and allow the body to acclimatize to the high altitude.",
        ],
      },
      {
        day: 2,
        title: "Local Sightseeing and Acclimatization Walk",
        details: [
          "Explore Leh's local attractions, market, and immerse in the high-mountain city's culture.",
          "Acclimatization walk to help the body adjust to the altitude.",
          "Group debriefing session to discuss the upcoming days, safety measures, and mountain etiquette.",
        ],
      },
      {
        day: 3,
        title: "Drive from Leh to Karzok Village",
        details: [
          "Journey from Leh to Karzok village.",
          "Take in the scenic views along the way.",
          "Rest upon reaching Karzok and prepare for further acclimatization.",
        ],
      },
      {
        day: 4,
        title: "Acclimatize and Explore Tso Moriri Lake (4,500 M)",
        details: [
          "Acclimatization activities in Karzok.",
          "Explore the breathtaking Tso Moriri Lake at an altitude of 4,500 meters.",
        ],
      },
      {
        day: 5,
        title: "Karzok to Base Camp Trek (5,500 M)",
        details: [
          "Begin trekking from Karzok to the base camp.",
          "Trek for 4-5 hours, reaching an altitude of 5,500 meters.",
          "Set up base camp and acclimatize.",
        ],
      },
      {
        day: 6,
        title: "Acclimatization Day at Base Camp",
        details: [
          "Rest and acclimatize at the base camp (5,500 meters).",
          "Short walks to maintain physical activity while allowing the body to adjust.",
        ],
      },
      {
        day: 7,
        title: "Base Camp to Summit Camp Trek (5,800 M)",
        details: [
          "Trek from the base camp to the summit camp.",
          "Trekking for 3-4 hours, reaching an altitude of 5,800 meters.",
          "Prepare for the summit push.",
        ],
      },
      {
        day: 8,
        title: "Summit Day - (Shukule 6508)",
        details: [
          "Early morning trek from summit camp to the peak of Mount Shukule 6508.",
          "Approximately 5 hours to summit and return to the base camp.",
          "Celebrate the achievement and rest at the base camp.",
        ],
      },
      {
        day: 9,
        title: "Trek from Base Camp to Karzok",
        details: [
          "Descend from the base camp to Karzok village.",
          "Reflect on the journey and enjoy the scenic landscapes.",
        ],
      },
      {
        day: 10,
        title: "Drive Back to Leh from Karzok",
        details: [
          "Drive back to Leh from Karzok.",
          "Conclude the expedition with fond memories and a sense of accomplishment.",
        ],
      },
    ],
    inclusion: [
      {
        sectionTitle: "Accommodation",
        content: [
          "Comfortable lodging in Leh upon arrival and during the Deo Tibba expedition.",
          "Tented accommodation during the trek and at various campsites.",
        ],
      },
      {
        sectionTitle: "Meals",
        content: [
          "Nutritious and delicious meals throughout the expedition, designed to meet the energy demands of high-altitude trekking.",
          "Special provisions for dietary preferences and requirements.",
        ],
      },
      {
        sectionTitle: "Experienced Guides",
        content: [
          "Expert local guides well-versed in the terrain and conditions of the region.",
          "Assistance and guidance to ensure a safe and enjoyable trekking experience.",
        ],
      },
      {
        sectionTitle: "Transportation",
        content: [
          "Transport from Leh to the trek starting points and back, ensuring a seamless and comfortable journey.",
          "Well-maintained vehicles and experienced drivers for safe travel.",
        ],
      },
      {
        sectionTitle: "Camping Equipment",
        content: [
          "High-quality camping gear, including tents, sleeping bags, and other essentials for a comfortable night's rest.",
          "Technical gear distribution and fitting for the summit attempt.",
        ],
      },
      {
        sectionTitle: "Permits and Paperwork",
        content: [
          "Assistance in completing necessary paperwork with the Indian Mountaineering Foundation (IMF).",
          "Coordination of permits required for the expedition.",
        ],
      },
      {
        sectionTitle: "Safety Measures",
        content: [
          "Emergency evacuation plans and access to experienced high-altitude rescue teams.",
          "First aid provisions and medical assistance throughout the expedition.",
        ],
      },
      {
        sectionTitle: "Expedition Certificate",
        content: [
          "Commemorative certificates recognizing the successful completion of Deo Tibba Expedition",
        ],
      },
      {
        sectionTitle: "Communication",
        content: [
          "Communication equipment for emergencies",
          "Regular updates and briefings",
        ],
      },
      {
        sectionTitle: "Environment and Leave No Trace",
        content: [
          "Commitment to environmentally responsible trekking",
          "Practices to minimize environmental impact",
        ],
      },
    ],
    exclusion: [
      {
        sectionTitle: "Travel Insurance",
        content: [
          "Personal travel insurance covering medical expenses, evacuation, and other unforeseen circumstances.",
        ],
      },
      {
        sectionTitle: "Personal Gear",
        content: [
          "Individual trekking equipment and personal clothing.",
          "Any additional gear required for specific preferences or comfort.",
        ],
      },
      {
        sectionTitle: "Meals in Leh",
        content: [
          "Meals in Leh on arrival and departure days.",
          "Expenses for additional meals beyond the provided trek meals.",
        ],
      },
      {
        sectionTitle: "Beverages",
        content: [
          "Expenses for additional beverages or snacks not included in the provided meals.",
        ],
      },
      {
        sectionTitle: "Personal Expenses",
        content: [
          "Any expenses of a personal nature, including souvenirs, phone calls, laundry, and tips.",
        ],
      },
      {
        sectionTitle: "Transport beyond Itinerary",
        content: [
          "Transportation or expenses beyond the specified itinerary or additional travel arrangements.",
        ],
      },
      {
        sectionTitle: "Medical Expenses",
        content: [
          "Costs related to pre-existing medical conditions or personal medication.",
        ],
      },
      {
        sectionTitle: "Changes in Itinerary",
        content: [
          "Any additional costs incurred due to changes in the itinerary caused by factors beyond the control of the trek organizers.",
        ],
      },
      {
        sectionTitle: "Unforeseen Circumstances",
        content: [
          "Expenses arising from unforeseen circumstances such as natural disasters, political unrest, or flight cancellations.",
        ],
      },
      {
        sectionTitle: "Additional Services",
        content: [
          "Any services or facilities not explicitly mentioned in the inclusions.",
        ],
      },
      {
        sectionTitle: "Porter Services",
        content: [
          "Personal porter services for carrying individual backpacks, if required.",
        ],
      },
      {
        sectionTitle: "Optional Activities",
        content: [
          "Costs associated with optional activities or excursions not included in the trek itinerary.",
        ],
      },
      {
        sectionTitle: "Government Fees",
        content: [
          "Any additional fees imposed by government authorities during the trek.",
        ],
      },
    ],
    youtubeLink: "",
    expeditionDate: [
      {
        id: 1,
        duration: "7 Days",
        startDate: "",
        endDate: "",
        bookedSeat: "",
        availableSeat: "",
      },
      {
        id: 2,
        duration: "7 Days",
        startDate: "",
        endDate: "",
        bookedSeat: "",
        availableSeat: "",
      },
      {
        id: 3,
        duration: "7 Days",
        startDate: "",
        endDate: "",
        bookedSeat: "",
        availableSeat: "",
      },
      {
        id: 4,
        duration: "7 Days",
        startDate: "",
        endDate: "",
        bookedSeat: "",
        availableSeat: "",
      },
      {
        id: 5,
        duration: "7 Days",
        startDate: "",
        endDate: "",
        bookedSeat: "",
        availableSeat: "",
      },
    ],
  },
  {
    individualExpeditionType: "cb13",
    individualExpeditionName: "CB13",
    carouselData: expedition6000MHeroSection,
    location: "Lahaul & Spiti",
    duration: "10 Days",
    grade: "Intermediate",
    maxAltitude: "6264 m or 20551 ft",
    bestSeason: "July, August, September, and October",
    experienceRequired:
      "An enthusiast should have trekked between 4000 m to 5000 m at least once.",
    mainExpeditionContent:
      "Welcome to the formidable challenge of conquering the Chandrabhaga-13 (CB13) peak, a relentless endeavor that beckons seasoned mountaineers. CB13, standing tall at 6,264 meters, unfolds a saga of treacherous terrain, unpredictable weather, and a demanding ascent that pushes the limits of human endurance. This summit, nestled in the Lahaul region of Himachal Pradesh, is renowned for its dynamic conditions, with climbers navigating through steep slopes, rocky outcrops, and snow-covered expanses. As climbers, you'll delve into the intricacies of this high-altitude expedition, grappling with nature's forces while strategically planning rotations between camps. Beyond the conventional, CB13 presents an authentic challenge, where resilience meets the raw grandeur of the mountains.",
    aboutExpeditionExperienced:
      "Climb CB 13 Peak with our seasoned climbing team with over a decade of experience in the Indian Himalayas. We pride ourselves on upholding the highest safety standards throughout our operations. With a track record of more than 10 years, we've honed our skills and know these mountains like the back of our hands. Our commitment to safety goes beyond words—we have meticulous rescue plans in place, ready to be executed if the need arises. When you embark on an adventure with us, rest assured that you're in the hands of professionals who prioritize your safety above all else. Welcome to a team that's as prepared as it gets.",
    itineraryData: [
      {
        day: 1,
        title: "Arrival in Manali (2,050 M)",
        details: [
          "Arrive in Manali, a high-altitude backpacking center.",
          "Participants settle into accommodation.",
          "Allow time for acclimatization and introduction to mountain rules.",
          "Briefing on route, terrain, weather, and CB13 expectations.",
        ],
      },
      {
        day: 2,
        title: "Manali (2,050M) to Batal (4,060M)",
        details: [
          "Depart between 9 and 10 AM after breakfast.",
          "Leisurely 5-6 hour drive to Batal, the expedition trailhead.",
          "Batal: Basecamp for Chandratal Lake; situated in Lahaul district, Himachal Pradesh.",
        ],
      },
      {
        day: 3,
        title: "Batal (4,060M) to Base Camp (4,440M)",
        details: [
          "Trekking distance: 10 kms; Duration: 7 hours.",
          "Commence trek after early breakfast at 7 AM.",
          "Encounter challenges like crossing Pagal Nalla and multiple streams.",
          "Steep ascent of 300M, followed by a climb through green rocky paths and boulders.",
          "Reach Base Camp (4,440M) after a mostly downhill last kilometer.",
        ],
      },
      {
        day: 4,
        title: "Acclimatization at Base Camp (4,440M)",
        details: [
          "Morning puja for safety and success.",
          "Set up gear, technical training, and basic technique practice.",
          "Full day for rest, acclimatization, and adjustment to base camp conditions.",
        ],
      },
      {
        day: "5 to 7",
        title: "Summit Attempt (Subject to Conditions)",
        details: [
          "Variable schedule due to unpredictable high-altitude conditions.",
          "Rotation rounds between camps for acclimatization.",
          "Advance Base Camp (4,860M) to Summit Camp (5,650M) with load ferry and occupy.",
          "Summit attempt from Summit Camp to Summit of CB13 (6,264M).",
          "Return to Advance Base Camp and further descent if conditions allow.",
          "Extra days for extreme snow conditions.",
        ],
      },
      {
        day: "8/9",
        title: "Descent - Advance Base Camp (4,900M) to Base Camp (4,440M)",
        details: [
          "Trekking distance: 3.5-4 kms; Duration: 2.5-3.5 hours.",
          "Retrace steps back to Base Camp along the ascent route.",
        ],
      },
      {
        day: 9,
        title: "Base Camp (4,440M) to Batal (4,060M)",
        details: [
          "Trekking distance: Same as ascent; Duration: Same as ascent.",
          "Return to Batal; camp overnight for early departure to Manali.",
        ],
      },
      {
        day: 10,
        title: "Batal (4,060M) to Manali (2,050M)",
        details: [
          "Return to Manali, plan next day's journey ahead for any unforeseen delays.",
          "Expect to reach Manali by evening.",
        ],
      },
    ],
    inclusion: [
      {
        sectionTitle: "Accommodation",
        content: [
          "Comfortable lodging in Manali upon arrival and during the Deo Tibba expedition.",
          "Tented accommodation during the trek and at various campsites.",
        ],
      },
      {
        sectionTitle: "Meals",
        content: [
          "Nutritious and delicious meals throughout the expedition, designed to meet the energy demands of high-altitude trekking.",
          "Special provisions for dietary preferences and requirements.",
        ],
      },
      {
        sectionTitle: "Experienced Guides",
        content: [
          "Expert local guides well-versed in the terrain and conditions of the region.",
          "Assistance and guidance to ensure a safe and enjoyable trekking experience.",
        ],
      },
      {
        sectionTitle: "Transportation",
        content: [
          "Transport from Manali to the trek starting points and back, ensuring a seamless and comfortable journey.",
          "Well-maintained vehicles and experienced drivers for safe travel.",
        ],
      },
      {
        sectionTitle: "Camping Equipment",
        content: [
          "High-quality camping gear, including tents, sleeping bags, and other essentials for a comfortable night's rest.",
          "Technical gear distribution and fitting for the summit attempt.",
        ],
      },
      {
        sectionTitle: "Permits and Paperwork",
        content: [
          "Assistance in completing necessary paperwork with the Indian Mountaineering Foundation (IMF).",
          "Coordination of permits required for the expedition.",
        ],
      },
      {
        sectionTitle: "Safety Measures",
        content: [
          "Emergency evacuation plans and access to experienced high-altitude rescue teams.",
          "First aid provisions and medical assistance throughout the expedition.",
        ],
      },
      {
        sectionTitle: "Expedition Certificate",
        content: [
          "Commemorative certificates recognizing the successful completion of Deo Tibba Expedition",
        ],
      },
      {
        sectionTitle: "Communication",
        content: [
          "Communication equipment for emergencies",
          "Regular updates and briefings",
        ],
      },
      {
        sectionTitle: "Environment and Leave No Trace",
        content: [
          "Commitment to environmentally responsible trekking",
          "Practices to minimize environmental impact",
        ],
      },
    ],
    exclusion: [
      {
        sectionTitle: "Travel Insurance",
        content: [
          "Personal travel insurance covering medical expenses, evacuation, and other unforeseen circumstances.",
        ],
      },
      {
        sectionTitle: "Personal Gear",
        content: [
          "Individual trekking equipment and personal clothing.",
          "Any additional gear required for specific preferences or comfort.",
        ],
      },
      {
        sectionTitle: "Meals in Manali",
        content: [
          "Meals in Manali on arrival and departure days.",
          "Expenses for additional meals beyond the provided trek meals.",
        ],
      },
      {
        sectionTitle: "Beverages",
        content: [
          "Expenses for additional beverages or snacks not included in the provided meals.",
        ],
      },
      {
        sectionTitle: "Personal Expenses",
        content: [
          "Any expenses of a personal nature, including souvenirs, phone calls, laundry, and tips.",
        ],
      },
      {
        sectionTitle: "Transport beyond Itinerary",
        content: [
          "Transportation or expenses beyond the specified itinerary or additional travel arrangements.",
        ],
      },
      {
        sectionTitle: "Medical Expenses",
        content: [
          "Costs related to pre-existing medical conditions or personal medication.",
        ],
      },
      {
        sectionTitle: "Changes in Itinerary",
        content: [
          "Any additional costs incurred due to changes in the itinerary caused by factors beyond the control of the trek organizers.",
        ],
      },
      {
        sectionTitle: "Unforeseen Circumstances",
        content: [
          "Expenses arising from unforeseen circumstances such as natural disasters, political unrest, or flight cancellations.",
        ],
      },
      {
        sectionTitle: "Additional Services",
        content: [
          "Any services or facilities not explicitly mentioned in the inclusions.",
        ],
      },
      {
        sectionTitle: "Porter Services",
        content: [
          "Personal porter services for carrying individual backpacks, if required.",
        ],
      },
      {
        sectionTitle: "Optional Activities",
        content: [
          "Costs associated with optional activities or excursions not included in the trek itinerary.",
        ],
      },
      {
        sectionTitle: "Government Fees",
        content: [
          "Any additional fees imposed by government authorities during the trek.",
        ],
      },
    ],
    youtubeLink: "",
    expeditionDate: [
      {
        id: 1,
        duration: "7 Days",
        startDate: "",
        endDate: "",
        bookedSeat: "",
        availableSeat: "",
      },
      {
        id: 2,
        duration: "7 Days",
        startDate: "",
        endDate: "",
        bookedSeat: "",
        availableSeat: "",
      },
      {
        id: 3,
        duration: "7 Days",
        startDate: "",
        endDate: "",
        bookedSeat: "",
        availableSeat: "",
      },
      {
        id: 4,
        duration: "7 Days",
        startDate: "",
        endDate: "",
        bookedSeat: "",
        availableSeat: "",
      },
      {
        id: 5,
        duration: "7 Days",
        startDate: "",
        endDate: "",
        bookedSeat: "",
        availableSeat: "",
      },
    ],
  },
  {
    individualExpeditionType: "menthosa",
    individualExpeditionName: "Menthosa",
    carouselData: expedition6000MHeroSection,
    location: "Lahaul & Spiti",
    duration: "9+3 Days",
    grade: "Intermediate",
    maxAltitude: "6443 m or 21138 ft",
    bestSeason: "July, August, September and October",
    experienceRequired:
      "An enthusiast should have trekked between 4000 m to 5000 m at least once.",
    mainExpeditionContent:
      "Mount Menthosa, standing tall at 6,443 meters, is a formidable peak in the Indian Himalayas, beckoning adventure enthusiasts to experience the raw, untamed beauty of its challenging slopes. Situated in the Pir Panjal range, this towering giant presents a thrilling ordeal for mountaineers seeking an authentic test of skill and endurance. The rugged terrain, with its unpredictable weather and demanding altitudes, adds an element of unpredictability to the ascent, making every step a true adventure. Menthosa's snow-capped peaks and ice-covered faces serve as a canvas for a daring narrative of exploration, where nature's forces and human determination converge in a high-altitude drama. This isn't just a climb; it's an immersive saga of resilience, where the mountain and the mountaineer engage in a dance of courage and tenacity.",
    aboutExpeditionExperienced:
      "Climb Menthosa Peak with our seasoned climbing team with over a decade of experience in the Indian Himalayas. We pride ourselves on upholding the highest safety standards throughout our operations. With a track record of more than 10 years, we've honed our skills and know these mountains like the back of our hands. Our commitment to safety goes beyond words—we have meticulous rescue plans in place, ready to be executed if the need arises. When you embark on an adventure with us, rest assured that you're in the hands of professionals who prioritize your safety above all else. Welcome to a team that's as prepared as it gets.",
    itineraryData: [
      {
        day: 1,
        title: "Arrival in Manali",
        details: [
          "Arrive in Manali and check into a hotel.",
          "Explore nearby sightseeing spots.",
          "Shop for personal gear in the local market.",
        ],
      },
      {
        day: 2,
        title: "Drive from Manali to Shukto",
        details: [
          "Begin the long drive from Manali to Shukto, approximately 3-4 hours.",
          "Enjoy the scenic journey through the beautiful landscapes.",
        ],
      },
      {
        day: 3,
        title: "Drive from Shukto to Urgos Nallah",
        details: [
          "Continue the drive to Urgos Nallah.",
          "Acclimatize and settle down in Urgos Nallah at an altitude of 3,200 meters.",
        ],
      },
      {
        day: 4,
        title: "Trek from Urgos to Base Camp (4,450 M)",
        details: [
          "Trek for 7 hours from Urgos to the base camp at an altitude of 4,450 meters.",
          "Enjoy the picturesque surroundings and set up camp.",
        ],
      },
      {
        day: 5,
        title: "Base Camp Acclimatization Day",
        details: [
          "Acclimatization day at the base camp.",
          "Rest in tents, take walks, and prepare for the upcoming trekking days.",
        ],
      },
      {
        day: 6,
        title: "Base Camp to Camp 1 (5,000 M)",
        details: [
          "Trek from the base camp to Camp 1 at an altitude of 5,000 meters.",
          "4-5 hours of trekking through challenging terrains.",
        ],
      },
      {
        day: 7,
        title: "Camp 1 to Camp 2 (5,700 M)",
        details: [
          "Trek from Camp 1 to Camp 2, gaining an altitude of 5,700 meters.",
          "A challenging 8-hour trek.",
        ],
      },
      {
        day: 8,
        title: "Camp 2 to Summit Camp (6,000 M)",
        details: [
          "Trek from Camp 2 to the summit camp at 6,000 meters.",
          "4-5 hours of trekking, rest, and preparation for the summit day.",
        ],
      },
      {
        day: 9,
        title: "Summit Day (6,443 M)",
        details: [
          "Summit push of 7-8 hours to reach the peak of Mount Menthosa (6,443 meters).",
          "Descend to the summit camp, rest, and celebrate the achievement.",
        ],
      },
      {
        day: 10,
        title: "Descend to Base Camp and Return to Urgos Village",
        details: [
          "Trek from the summit camp to the base camp.",
          "Descend to Urgos village and drive back to Manali.",
        ],
      },
      {
        day: "Buffer Days",
        title: "Buffer Days",
        details: [
          "Keep 3 buffer days in case of weather changes or unforeseen circumstances that may impact the itinerary.",
        ],
      },
    ],
    inclusion: [
      {
        sectionTitle: "Accommodation",
        content: [
          "Comfortable lodging in Manali upon arrival and during the Deo Tibba expedition.",
          "Tented accommodation during the trek and at various campsites.",
        ],
      },
      {
        sectionTitle: "Meals",
        content: [
          "Nutritious and delicious meals throughout the expedition, designed to meet the energy demands of high-altitude trekking.",
          "Special provisions for dietary preferences and requirements.",
        ],
      },
      {
        sectionTitle: "Experienced Guides",
        content: [
          "Expert local guides well-versed in the terrain and conditions of the region.",
          "Assistance and guidance to ensure a safe and enjoyable trekking experience.",
        ],
      },
      {
        sectionTitle: "Transportation",
        content: [
          "Transport from Manali to the trek starting points and back, ensuring a seamless and comfortable journey.",
          "Well-maintained vehicles and experienced drivers for safe travel.",
        ],
      },
      {
        sectionTitle: "Camping Equipment",
        content: [
          "High-quality camping gear, including tents, sleeping bags, and other essentials for a comfortable night's rest.",
          "Technical gear distribution and fitting for the summit attempt.",
        ],
      },
      {
        sectionTitle: "Permits and Paperwork",
        content: [
          "Assistance in completing necessary paperwork with the Indian Mountaineering Foundation (IMF).",
          "Coordination of permits required for the expedition.",
        ],
      },
      {
        sectionTitle: "Safety Measures",
        content: [
          "Emergency evacuation plans and access to experienced high-altitude rescue teams.",
          "First aid provisions and medical assistance throughout the expedition.",
        ],
      },
      {
        sectionTitle: "Expedition Certificate",
        content: [
          "Commemorative certificates recognizing the successful completion of Deo Tibba Expedition",
        ],
      },
      {
        sectionTitle: "Communication",
        content: [
          "Communication equipment for emergencies",
          "Regular updates and briefings",
        ],
      },
      {
        sectionTitle: "Environment and Leave No Trace",
        content: [
          "Commitment to environmentally responsible trekking",
          "Practices to minimize environmental impact",
        ],
      },
    ],
    exclusion: [
      {
        sectionTitle: "Travel Insurance",
        content: [
          "Personal travel insurance covering medical expenses, evacuation, and other unforeseen circumstances.",
        ],
      },
      {
        sectionTitle: "Personal Gear",
        content: [
          "Individual trekking equipment and personal clothing.",
          "Any additional gear required for specific preferences or comfort.",
        ],
      },
      {
        sectionTitle: "Meals in Manali",
        content: [
          "Meals in Manali on arrival and departure days.",
          "Expenses for additional meals beyond the provided trek meals.",
        ],
      },
      {
        sectionTitle: "Beverages",
        content: [
          "Expenses for additional beverages or snacks not included in the provided meals.",
        ],
      },
      {
        sectionTitle: "Personal Expenses",
        content: [
          "Any expenses of a personal nature, including souvenirs, phone calls, laundry, and tips.",
        ],
      },
      {
        sectionTitle: "Transport beyond Itinerary",
        content: [
          "Transportation or expenses beyond the specified itinerary or additional travel arrangements.",
        ],
      },
      {
        sectionTitle: "Medical Expenses",
        content: [
          "Costs related to pre-existing medical conditions or personal medication.",
        ],
      },
      {
        sectionTitle: "Changes in Itinerary",
        content: [
          "Any additional costs incurred due to changes in the itinerary caused by factors beyond the control of the trek organizers.",
        ],
      },
      {
        sectionTitle: "Unforeseen Circumstances",
        content: [
          "Expenses arising from unforeseen circumstances such as natural disasters, political unrest, or flight cancellations.",
        ],
      },
      {
        sectionTitle: "Additional Services",
        content: [
          "Any services or facilities not explicitly mentioned in the inclusions.",
        ],
      },
      {
        sectionTitle: "Porter Services",
        content: [
          "Personal porter services for carrying individual backpacks, if required.",
        ],
      },
      {
        sectionTitle: "Optional Activities",
        content: [
          "Costs associated with optional activities or excursions not included in the trek itinerary.",
        ],
      },
      {
        sectionTitle: "Government Fees",
        content: [
          "Any additional fees imposed by government authorities during the trek.",
        ],
      },
    ],
    youtubeLink: "",
    expeditionDate: [
      {
        id: 1,
        duration: "7 Days",
        startDate: "",
        endDate: "",
        bookedSeat: "",
        availableSeat: "",
      },
      {
        id: 2,
        duration: "7 Days",
        startDate: "",
        endDate: "",
        bookedSeat: "",
        availableSeat: "",
      },
      {
        id: 3,
        duration: "7 Days",
        startDate: "",
        endDate: "",
        bookedSeat: "",
        availableSeat: "",
      },
      {
        id: 4,
        duration: "7 Days",
        startDate: "",
        endDate: "",
        bookedSeat: "",
        availableSeat: "",
      },
      {
        id: 5,
        duration: "7 Days",
        startDate: "",
        endDate: "",
        bookedSeat: "",
        availableSeat: "",
      },
    ],
  },

  // Pure adrenaline

  {
    individualExpeditionType: "papsura",
    individualExpeditionName: "Papsura",
    carouselData: expeditionPureAdrenalineHeroSection,
    location: "Parvati Valley, Himachal Pradesh",
    duration: "10 Days",
    grade: "Advanced",
    maxAltitude: "6450 M or 21160 ft",
    bestSeason: "May, June, September and October",
    experienceRequired: "For experienced climbers only.",
    mainExpeditionContent:
      "Papsura, known as the 'Peak of Evil', is a tough mountain in Himachal Pradesh, India, standing at about 6,450 meters (21,160 feet). Its distinct pyramid shape attracts experienced climbers. The climb involves tackling glaciers, rocks, and steep slopes, and climbers use specialized gear like ice axes and ropes.Reaching Papsura's summit is a challenging adventure, set against stunning landscapes of meadows and snow-covered peaks. The nickname 'Peak of Evil' hints at the difficulties faced by climbers due to unpredictable weather and technical challenges. Climbers need good preparation, including physical fitness and adjusting to high altitudes. Conquering Papsura offers a rewarding view from the top, showcasing the beauty of the Himalayas. Overall, Papsura is a tough but awe-inspiring mountain, inviting adventurers to test their skills in the heart of nature.",
    aboutExpeditionExperienced:
      "Climb Papsura with our seasoned climbing team with over a decade of experience in the Indian Himalayas. We pride ourselves on upholding the highest safety standards throughout our operations. With a track record of more than 10 years, we've honed our skills and know these mountains like the back of our hands. Our commitment to safety goes beyond words—we have meticulous rescue plans in place, ready to be executed if the need arises. When you embark on an adventure with us, rest assured that you're in the hands of professionals who prioritize your safety above all else. Welcome to a team that's as prepared as it gets.",
    itineraryData: [
      {
        day: 1,
        title: "Arrive Manali",
        details: [
          "Altitude: 2,050 m/ 6,750 ft",
          "Check-in at the hotel (CP Plan) (twin/triple sharing)",
          "Briefing after lunch around 12 pm",
          "Take a walk, relax in the café, and explore the sights around Manali if desired.",
        ],
      },
      {
        day: 2,
        title: "Manali - Kasol – Tosh – Kutla (2825M) by Drive",
        details: [
          "Early morning drive to Tosh village (20 km from Kasol)",
          "Reach Tosh, staff sets up camps.",
          "Trek from Tosh to Kutla through pine trees and waterfalls.",
          "Enjoy valley views and snow-covered mountains.",
          "Reach camping site in the afternoon.",
          "Overnight in white expedition trekking tents.",
        ],
      },
      {
        day: 3,
        title: "Kutla - Saran Thatch (3450 M) Trek",
        details: [
          "Morning trek to Saran (12 km from Kutla)",
          "6 hours trek to the next camping site.",
          "Pack lunch provided for the journey.",
          "Scenic route with landscape and flowers.",
          "Evening reach Saran Thatch, overnight in tents.",
        ],
      },
      {
        day: 4,
        title: "Saran Thatch to Samsi Thach (3760 M) Trek",
        details: [
          "No tree lines at 3760 M, offering open views of the Himalayas and valley peaks.",
          "Local guide shares insights into the flora and fauna.",
          "Stay overnight in tents.",
        ],
      },
      {
        day: 5,
        title: "Samsi Thach to Kuta Thach/Base Camp (4230 M) Trek",
        details: [
          "Last day of trekking, reach the base camp of Dharamsura and Papsura.",
          "Cross the Tosh Glacier to reach the Base camp.",
        ],
      },
      {
        day: "6 to 9",
        title: "Summit Mt. Papsura (6440M) – Basecamp",
        details: [
          "Next 4 days for summiting Mt. Papsura and descending back to Base camp.",
          "Climbing leader assists in opening the route and making necessary arrangements.",
          "Stay connected with the base camp and backup team through satellite phone and walkie-talkie.",
          "Panoramic views of Kullu and Spiti Valley from the summit.",
        ],
      },
      {
        day: 10,
        title: "Base Camp to Tosh village",
        details: [
          "Due to the heavy load, it takes time to reach Tosh village.",
          "Return to Manali on the same evening (departure from Kasol possible)",
        ],
      },
    ],
    inclusion: [
      {
        sectionTitle: "Accommodation",
        content: [
          "Comfortable lodging in Manali & Tosh upon arrival and during the expedition.",
          "Tented accommodation during the trek and at various campsites.",
        ],
      },
      {
        sectionTitle: "Meals",
        content: [
          "Nutritious and delicious meals throughout the expedition, designed to meet the energy demands of high-altitude trekking.",
          "Special provisions for dietary preferences and requirements.",
        ],
      },
      {
        sectionTitle: "Experienced Guides",
        content: [
          "Expert local guides well-versed in the terrain and conditions of the region.",
          "Assistance and guidance to ensure a safe and enjoyable trekking experience.",
        ],
      },
      {
        sectionTitle: "Transportation",
        content: [
          "Transport from Manali to the trek starting points and back, ensuring a seamless and comfortable journey.",
          "Well-maintained vehicles and experienced drivers for safe travel.",
        ],
      },
      {
        sectionTitle: "Camping Equipment",
        content: [
          "High-quality camping gear, including tents, sleeping bags, and other essentials for a comfortable night's rest.",
          "Technical gear distribution and fitting for the summit attempt.",
        ],
      },
      {
        sectionTitle: "Permits and Paperwork",
        content: [
          "Assistance in completing necessary paperwork with the Indian Mountaineering Foundation (IMF).",
          "Coordination of permits required for the expedition.",
        ],
      },
      {
        sectionTitle: "Safety Measures",
        content: [
          "Emergency evacuation plans and access to experienced high-altitude rescue teams.",
          "First aid provisions and medical assistance throughout the expedition.",
        ],
      },
      {
        sectionTitle: "Expedition Certificate",
        content: [
          "Commemorative certificates recognizing the successful completion of Expedition",
        ],
      },
      {
        sectionTitle: "Communication",
        content: [
          "Communication equipment for emergencies",
          "Regular updates and briefings",
        ],
      },
      {
        sectionTitle: "Environment and Leave No Trace",
        content: [
          "Commitment to environmentally responsible trekking",
          "Practices to minimize environmental impact",
        ],
      },
    ],
    exclusion: [
      {
        sectionTitle: "Travel Insurance",
        content: [
          "Personal travel insurance covering medical expenses, evacuation, and other unforeseen circumstances.",
        ],
      },
      {
        sectionTitle: "Personal Gear",
        content: [
          "Individual trekking equipment and personal clothing.",
          "Any additional gear required for specific preferences or comfort.",
        ],
      },
      {
        sectionTitle: "Meals in Manali",
        content: [
          "Meals in Manali on arrival and departure days.",
          "Expenses for additional meals beyond the provided trek meals.",
        ],
      },
      {
        sectionTitle: "Beverages",
        content: [
          "Expenses for additional beverages or snacks not included in the provided meals.",
        ],
      },
      {
        sectionTitle: "Personal Expenses",
        content: [
          "Any expenses of a personal nature, including souvenirs, phone calls, laundry, and tips.",
        ],
      },
      {
        sectionTitle: "Transport beyond Itinerary",
        content: [
          "Transportation or expenses beyond the specified itinerary or additional travel arrangements.",
        ],
      },
      {
        sectionTitle: "Medical Expenses",
        content: [
          "Costs related to pre-existing medical conditions or personal medication.",
        ],
      },
      {
        sectionTitle: "Changes in Itinerary",
        content: [
          "Any additional costs incurred due to changes in the itinerary caused by factors beyond the control of the trek organizers.",
        ],
      },
      {
        sectionTitle: "Unforeseen Circumstances",
        content: [
          "Expenses arising from unforeseen circumstances such as natural disasters, political unrest, or flight cancellations.",
        ],
      },
      {
        sectionTitle: "Additional Services",
        content: [
          "Any services or facilities not explicitly mentioned in the inclusions.",
        ],
      },
      {
        sectionTitle: "Porter Services",
        content: [
          "Personal porter services for carrying individual backpacks, if required.",
        ],
      },
      {
        sectionTitle: "Optional Activities",
        content: [
          "Costs associated with optional activities or excursions not included in the trek itinerary.",
        ],
      },
      {
        sectionTitle: "Government Fees",
        content: [
          "Any additional fees imposed by government authorities during the trek.",
        ],
      },
    ],
    youtubeLink: "",
    expeditionDate: [
      {
        id: 1,
        duration: "7 Days",
        startDate: "",
        endDate: "",
        bookedSeat: "",
        availableSeat: "",
      },
      {
        id: 2,
        duration: "7 Days",
        startDate: "",
        endDate: "",
        bookedSeat: "",
        availableSeat: "",
      },
      {
        id: 3,
        duration: "7 Days",
        startDate: "",
        endDate: "",
        bookedSeat: "",
        availableSeat: "",
      },
      {
        id: 4,
        duration: "7 Days",
        startDate: "",
        endDate: "",
        bookedSeat: "",
        availableSeat: "",
      },
      {
        id: 5,
        duration: "7 Days",
        startDate: "",
        endDate: "",
        bookedSeat: "",
        availableSeat: "",
      },
    ],
  },
  {
    individualExpeditionType: "reoPurgyil",
    individualExpeditionName: "Reo Purgyil",
    carouselData: expeditionPureAdrenalineHeroSection,
    location: "Nako, Himachal Pradesh",
    duration: "13+2 Days",
    grade: "Advanced",
    maxAltitude: "6816 m or 22362 ft",
    bestSeason: "May, June, September and October",
    experienceRequired: "For experienced climbers only.",
    mainExpeditionContent:
      "Reo Purgyil is the highest mountain in the Indian state of Himachal Pradesh and the second-highest peak in the Indian Himalayas. It is situated in the Kinnaur district near the Tibetan border. Reo Purgyil has an elevation of 6,816 meters (22,362 feet) above sea level. The mountain is known for its challenging terrain and climbing conditions. It is a popular destination for mountaineers and trekkers, offering stunning views of the surrounding landscapes. As soon as start trekking after the base camp, things start to get super challenging. The entire route is filled with moraines. Negotiating carefully, you manage to reach the advanced camp. From here on, the climb begins. As a climber, you need to have experience with tough and challenging peaks if you wish to ace Reo Purgyil. It will require negotiating sharp inclined slopes, and rocky and icy patches. The last few 100 mtrs are pretty demanding. All in all, you are in for an adrenaline-rich adventure of a lifetime.",
    aboutExpeditionExperienced:
      "Climb Reo Purgyil with our seasoned climbing team with over a decade of experience in the Indian Himalayas. We pride ourselves on upholding the highest safety standards throughout our operations. With a track record of more than 10 years, we've honed our skills and know these mountains like the back of our hands. Our commitment to safety goes beyond words—we have meticulous rescue plans in place, ready to be executed if the need arises. When you embark on an adventure with us, rest assured that you're in the hands of professionals who prioritize your safety above all else. Welcome to a team that's as prepared as it gets.",
    itineraryData: [
      {
        day: 1,
        title: "Journey to Nako from Shimla or Manali",
        details: [
          "Embark on the expedition with a scenic drive from either Shimla or Manali to Nako, a charming town.",
          "Set the stage for the adventure that lies ahead.",
        ],
      },
      {
        day: 2,
        title: "Nako Exploration and Acclimatization",
        details: [
          "Explore the cultural richness of Nako.",
          "Focus on acclimatization to the high-altitude environment, essential preparation for the challenging days to come.",
        ],
      },
      {
        day: 3,
        title: "Transit to 4700m High Camp",
        details: [
          "Continue the ascent by moving from Nako to the strategically positioned transit camp at 4700 meters elevation.",
          "Immerse yourself in the rugged beauty of the landscape.",
        ],
      },
      {
        day: 4,
        title: "Transit Camp to Base Camp",
        details: [
          "Progress further as you ascend from the transit camp to the base camp.",
          "Position yourself strategically for the rigorous journey ahead.",
        ],
      },
      {
        day: 5,
        title: "Acclimatization at Base Camp",
        details: [
          "Allow a day of rest at the base camp.",
          "Provide necessary time for acclimatization to the altitude and ensure optimal physical readiness.",
        ],
      },
      {
        day: 6,
        title: "Base Camp to Camp 1",
        details: [
          "Embark on a challenging trek from the base camp to Camp 1.",
          "Navigate steeper terrain and test your determination.",
        ],
      },
      {
        day: 7,
        title: "Load Ferry to Camp 2",
        details: [
          "Prepare for the next phase by loading essential gear and supplies.",
          "Transition from Camp 1 to Camp 2 with a focus on endurance and strategic planning.",
        ],
      },
      {
        day: 8,
        title: "Camp 1 to Camp 2",
        details: [
          "Continue the ascent as you journey from Camp 1 to Camp 2.",
          "Overcome challenges and experience the changing landscape.",
        ],
      },
      {
        day: 9,
        title: "Camp 2 to Summit Camp",
        details: [
          "Reach new heights as you climb from Camp 2 to the summit camp.",
          "Achieve a momentous milestone at the heart of the expedition.",
        ],
      },
      {
        day: 10,
        title: "Summit and Return to Camp 1",
        details: [
          "Celebrate the summit followed by a descent to Camp 1.",
          "Mark a significant milestone in the expedition.",
        ],
      },
      {
        day: 11,
        title: "Descent to Base Camp",
        details: [
          "Begin the descent from the summit to the base camp.",
          "Reflect on the challenges overcome and the breathtaking Himalayan landscape.",
        ],
      },
      {
        day: 12,
        title: "Base Camp to Nako",
        details: [
          "Bid farewell to the base camp and make your way back to Nako.",
          "Transition from high-altitude realms to more familiar terrain.",
        ],
      },
      {
        day: 13,
        title: "Return from Nako to Shimla or Manali",
        details: [
          "Conclude the journey with a return from Nako to the starting point.",
          "Reflect on the transformative experience of this meticulously planned Himalayan odyssey.",
        ],
      },
      {
        day: "Reserve Days",
        title: "Reserve Days",
        details: [
          "Keep two days reserved for unforeseen circumstances or extend stays as needed.",
        ],
      },
    ],
    inclusion: [
      {
        sectionTitle: "Accommodation",
        content: [
          "Comfortable lodging in Shimla/Manali, Reckong Peo/Kalpa, and Nako upon arrival and during the expedition.",
          "Tented accommodation during the trek and at various campsites.",
        ],
      },
      {
        sectionTitle: "Meals",
        content: [
          "Nutritious and delicious meals throughout the expedition, designed to meet the energy demands of high-altitude trekking.",
          "Special provisions for dietary preferences and requirements.",
        ],
      },
      {
        sectionTitle: "Experienced Guides",
        content: [
          "Expert local guides well-versed in the terrain and conditions of the region.",
          "Assistance and guidance to ensure a safe and enjoyable trekking experience.",
        ],
      },
      {
        sectionTitle: "Transportation",
        content: [
          "Transport from Shimla/Manali to the trek starting points and back, ensuring a seamless and comfortable journey.",
          "Well-maintained vehicles and experienced drivers for safe travel.",
        ],
      },
      {
        sectionTitle: "Camping Equipment",
        content: [
          "High-quality camping gear, including tents, sleeping bags, and other essentials for a comfortable night's rest.",
          "Technical gear distribution and fitting for the summit attempt.",
        ],
      },
      {
        sectionTitle: "Permits and Paperwork",
        content: [
          "Assistance in completing necessary paperwork with the Indian Mountaineering Foundation (IMF).",
          "Coordination of permits required for the expedition.",
        ],
      },
      {
        sectionTitle: "Safety Measures",
        content: [
          "Emergency evacuation plans and access to experienced high-altitude rescue teams.",
          "First aid provisions and medical assistance throughout the expedition.",
        ],
      },
      {
        sectionTitle: "Expedition Certificate",
        content: [
          "Commemorative certificates recognizing the successful completion of Expedition",
        ],
      },
      {
        sectionTitle: "Communication",
        content: [
          "Communication equipment for emergencies",
          "Regular updates and briefings",
        ],
      },
      {
        sectionTitle: "Environment and Leave No Trace",
        content: [
          "Commitment to environmentally responsible trekking",
          "Practices to minimize environmental impact",
        ],
      },
    ],
    exclusion: [
      {
        sectionTitle: "Travel Insurance",
        content: [
          "Personal travel insurance covering medical expenses, evacuation, and other unforeseen circumstances.",
        ],
      },
      {
        sectionTitle: "Personal Gear",
        content: [
          "Individual trekking equipment and personal clothing.",
          "Any additional gear required for specific preferences or comfort.",
        ],
      },
      {
        sectionTitle: "Meals in Shimla/Manali & Reckong Peo/Kalpa",
        content: [
          "Meals in Shimla & Reckong Peo/Kalpa on arrival and departure days.",
          "Expenses for additional meals beyond the provided trek meals.",
        ],
      },
      {
        sectionTitle: "Beverages",
        content: [
          "Expenses for additional beverages or snacks not included in the provided meals.",
        ],
      },
      {
        sectionTitle: "Personal Expenses",
        content: [
          "Any expenses of a personal nature, including souvenirs, phone calls, laundry, and tips.",
        ],
      },
      {
        sectionTitle: "Transport beyond Itinerary",
        content: [
          "Transportation or expenses beyond the specified itinerary or additional travel arrangements.",
        ],
      },
      {
        sectionTitle: "Medical Expenses",
        content: [
          "Costs related to pre-existing medical conditions or personal medication.",
        ],
      },
      {
        sectionTitle: "Changes in Itinerary",
        content: [
          "Any additional costs incurred due to changes in the itinerary caused by factors beyond the control of the trek organizers.",
        ],
      },
      {
        sectionTitle: "Unforeseen Circumstances",
        content: [
          "Expenses arising from unforeseen circumstances such as natural disasters, political unrest, or flight cancellations.",
        ],
      },
      {
        sectionTitle: "Additional Services",
        content: [
          "Any services or facilities not explicitly mentioned in the inclusions.",
        ],
      },
      {
        sectionTitle: "Porter Services",
        content: [
          "Personal porter services for carrying individual backpacks, if required.",
        ],
      },
      {
        sectionTitle: "Optional Activities",
        content: [
          "Costs associated with optional activities or excursions not included in the trek itinerary.",
        ],
      },
      {
        sectionTitle: "Government Fees",
        content: [
          "Any additional fees imposed by government authorities during the trek.",
        ],
      },
    ],
    youtubeLink:
      "https://www.youtube.com/embed/CTIFcnKzy7g?si=N4uYbdXzGu_vm00B",
    expeditionDate: [
      {
        id: 1,
        duration: "7 Days",
        startDate: "",
        endDate: "",
        bookedSeat: "",
        availableSeat: "",
      },
      {
        id: 2,
        duration: "7 Days",
        startDate: "",
        endDate: "",
        bookedSeat: "",
        availableSeat: "",
      },
      {
        id: 3,
        duration: "7 Days",
        startDate: "",
        endDate: "",
        bookedSeat: "",
        availableSeat: "",
      },
      {
        id: 4,
        duration: "7 Days",
        startDate: "",
        endDate: "",
        bookedSeat: "",
        availableSeat: "",
      },
      {
        id: 5,
        duration: "7 Days",
        startDate: "",
        endDate: "",
        bookedSeat: "",
        availableSeat: "",
      },
    ],
  },
  {
    individualExpeditionType: "indrasan",
    individualExpeditionName: "Indrasan",
    carouselData: expeditionPureAdrenalineHeroSection,
    location: "Manali",
    duration: "14+2 Days",
    grade: "Advanced",
    maxAltitude: "6221 m or 20411 ft",
    bestSeason: "June and September",
    experienceRequired: "For experienced climbers only.",
    mainExpeditionContent:
      "Mount Indrasan boasts a rich climbing history, serving as a magnetic force for mountaineers globally. The pinnacle of its historical significance was reached in 1961 when an Indo-Japanese expedition, under the leadership of H. Chhibber, successfully achieved the first ascent of Mount Indrasan. Renowned for its formidable terrain, Mount Indrasan presents a formidable combination of rocky surfaces and icy slopes. The climb is characterized by intricate technical challenges, including steep ascents and demanding traverses that test the mettle of even the most seasoned climbers. Nestled within the Pir Panjal range, Mount Indrasan stands as one of the eminent peaks in the region. Its significance extends beyond its sheer elevation, contributing both culturally and geographically to the breathtaking panorama of the Himalayas. As a symbol of natural grandeur, Mount Indrasan holds a paramount place in the collective consciousness of those who are drawn to the allure of challenging and awe-inspiring mountain ascents.",
    aboutExpeditionExperienced:
      "Climb Indrasan with our seasoned climbing team with over a decade of experience in the Manali. We pride ourselves on upholding the highest safety standards throughout our operations. With a track record of more than 10 years, we've honed our skills and know these mountains like the back of our hands. Our commitment to safety goes beyond words—we have meticulous rescue plans in place, ready to be executed if the need arises. When you embark on an adventure with us, rest assured that you're in the hands of professionals who prioritize your safety above all else. Welcome to a team that's as prepared as it gets.",
    itineraryData: [
      {
        day: 1,
        title: "Arrival in Manali",
        details: [
          "Arrive in Manali and check into the hotel.",
          "Explore nearby sights and attractions.",
          "Check and organize equipment.",
          "Explore the local Manali market and shop for personal gear if needed.",
        ],
      },
      {
        day: 2,
        title: "Leisure Day in Manali",
        details: [
          "Free day to rest and make final preparations for the trek.",
          "Optional activities in and around Manali.",
        ],
      },
      {
        day: 3,
        title: "Manali to Chikha",
        details: [
          "Drive from Manali to Jagathsukh Power Plant (approx. 1 hour).",
          "Trek to Shikha (3 hours).",
          "Set up camp and rest in tents.",
        ],
      },
      {
        day: 4,
        title: "Chikha to Seiri",
        details: [
          "Trek from Chikha to Seiri (4-5 hours).",
          "Acclimatization and rest.",
        ],
      },
      {
        day: 5,
        title: "Seiri to Tainta",
        details: ["Trek from Seiri to Tainta (3-4 hours)."],
      },
      {
        day: 6,
        title: "Acclimatization Day in Tainta",
        details: [
          "Rest day for acclimatization.",
          "Short acclimatization walk and planning for the upcoming days.",
        ],
      },
      {
        day: 7,
        title: "Tainta to Camp 1 (Duhangan Col)",
        details: [
          "Trek from Tainta to Camp 1 (Duhangan Col) (4-5 hours).",
          "Steep climb.",
        ],
      },
      {
        day: 8,
        title: "Camp 1 to Camp 2",
        details: [
          "Trek from Camp 1 to Camp 2 (4-5 hours).",
          "Set up camp and rest.",
        ],
      },
      {
        day: 9,
        title: "Rest and Acclimatization in Camp 2",
        details: [],
      },
      {
        day: 10,
        title: "Camp 2 to Summit Camp",
        details: ["Trek from Camp 2 to Summit Camp (3 hours)."],
      },
      {
        day: 11,
        title: "Summit Day",
        details: ["Summit Mt. Indrasan (6221 m) and return to Summit Camp."],
      },
      {
        day: 12,
        title: "Descent from Summit Camp to Base Camp (Tainta)",
        details: [
          "Trek from Summit Camp to Base Camp (Tainta) via Duhangan Col.",
        ],
      },
      {
        day: 13,
        title: "Base Camp to Chikha",
        details: ["Trek from Base Camp to Chikha."],
      },
      {
        day: 14,
        title: "Chikha to Manali",
        details: ["Drive back to Manali.", "Depart for home."],
      },
      {
        day: "Reserved",
        title: "Reserved for Weather Changes",
        details: [
          "2 Extra Days: Reserved for weather changes or unforeseen circumstances.",
        ],
      },
    ],
    inclusion: [
      {
        sectionTitle: "Accommodation",
        content: [
          "Comfortable lodging in Manali upon arrival and during the expedition.",
          "Tented accommodation during the trek and at various campsites.",
        ],
      },
      {
        sectionTitle: "Meals",
        content: [
          "Nutritious and delicious meals throughout the expedition, designed to meet the energy demands of high-altitude trekking.",
          "Special provisions for dietary preferences and requirements.",
        ],
      },
      {
        sectionTitle: "Experienced Guides",
        content: [
          "Expert local guides well-versed in the terrain and conditions of the region.",
          "Assistance and guidance to ensure a safe and enjoyable trekking experience.",
        ],
      },
      {
        sectionTitle: "Transportation",
        content: [
          "Transport from Manali to the trek starting points and back, ensuring a seamless and comfortable journey.",
          "Well-maintained vehicles and experienced drivers for safe travel.",
        ],
      },
      {
        sectionTitle: "Camping Equipment",
        content: [
          "High-quality camping gear, including tents, sleeping bags, and other essentials for a comfortable night's rest.",
          "Technical gear distribution and fitting for the summit attempt.",
        ],
      },
      {
        sectionTitle: "Permits and Paperwork",
        content: [
          "Assistance in completing necessary paperwork with the Indian Mountaineering Foundation (IMF).",
          "Coordination of permits required for the expedition.",
        ],
      },
      {
        sectionTitle: "Safety Measures",
        content: [
          "Emergency evacuation plans and access to experienced high-altitude rescue teams.",
          "First aid provisions and medical assistance throughout the expedition.",
        ],
      },
      {
        sectionTitle: "Expedition Certificate",
        content: [
          "Commemorative certificates recognizing the successful completion of Expedition",
        ],
      },
      {
        sectionTitle: "Communication",
        content: [
          "Communication equipment for emergencies",
          "Regular updates and briefings",
        ],
      },
      {
        sectionTitle: "Environment and Leave No Trace",
        content: [
          "Commitment to environmentally responsible trekking",
          "Practices to minimize environmental impact",
        ],
      },
    ],
    exclusion: [
      {
        sectionTitle: "Travel Insurance",
        content: [
          "Personal travel insurance covering medical expenses, evacuation, and other unforeseen circumstances.",
        ],
      },
      {
        sectionTitle: "Personal Gear",
        content: [
          "Individual trekking equipment and personal clothing.",
          "Any additional gear required for specific preferences or comfort.",
        ],
      },
      {
        sectionTitle: "Meals in Manali",
        content: [
          "Meals in Manali on arrival and departure days.",
          "Expenses for additional meals beyond the provided trek meals.",
        ],
      },
      {
        sectionTitle: "Beverages",
        content: [
          "Expenses for additional beverages or snacks not included in the provided meals.",
        ],
      },
      {
        sectionTitle: "Personal Expenses",
        content: [
          "Any expenses of a personal nature, including souvenirs, phone calls, laundry, and tips.",
        ],
      },
      {
        sectionTitle: "Transport beyond Itinerary",
        content: [
          "Transportation or expenses beyond the specified itinerary or additional travel arrangements.",
        ],
      },
      {
        sectionTitle: "Medical Expenses",
        content: [
          "Costs related to pre-existing medical conditions or personal medication.",
        ],
      },
      {
        sectionTitle: "Changes in Itinerary",
        content: [
          "Any additional costs incurred due to changes in the itinerary caused by factors beyond the control of the trek organizers.",
        ],
      },
      {
        sectionTitle: "Unforeseen Circumstances",
        content: [
          "Expenses arising from unforeseen circumstances such as natural disasters, political unrest, or flight cancellations.",
        ],
      },
      {
        sectionTitle: "Additional Services",
        content: [
          "Any services or facilities not explicitly mentioned in the inclusions.",
        ],
      },
      {
        sectionTitle: "Porter Services",
        content: [
          "Personal porter services for carrying individual backpacks, if required.",
        ],
      },
      {
        sectionTitle: "Optional Activities",
        content: [
          "Costs associated with optional activities or excursions not included in the trek itinerary.",
        ],
      },
      {
        sectionTitle: "Government Fees",
        content: [
          "Any additional fees imposed by government authorities during the trek",
        ],
      },
    ],
    youtubeLink: "",
    expeditionDate: [
      {
        id: 1,
        duration: "7 Days",
        startDate: "",
        endDate: "",
        bookedSeat: "",
        availableSeat: "",
      },
      {
        id: 2,
        duration: "7 Days",
        startDate: "",
        endDate: "",
        bookedSeat: "",
        availableSeat: "",
      },
      {
        id: 3,
        duration: "7 Days",
        startDate: "",
        endDate: "",
        bookedSeat: "",
        availableSeat: "",
      },
      {
        id: 4,
        duration: "7 Days",
        startDate: "",
        endDate: "",
        bookedSeat: "",
        availableSeat: "",
      },
      {
        id: 5,
        duration: "7 Days",
        startDate: "",
        endDate: "",
        bookedSeat: "",
        availableSeat: "",
      },
    ],
  },
  {
    individualExpeditionType: "mukerBeh",
    individualExpeditionName: "Muker Beh",
    carouselData: expeditionPureAdrenalineHeroSection,
    location: "Manali",
    duration: "14+2 Days",
    grade: "Advanced",
    maxAltitude: "6221 m or 20411 ft",
    bestSeason: "June and September",
    experienceRequired: "For experienced climbers only.",
    mainExpeditionContent:
      "Mount Mukerbeh, an imposing peak in the Himalayan panorama, challenges the tough mountaineer to conquer its formidable 6069-meter peak. This ascent is a pinnacle of technical expertise, where the adept climber navigates through intricate terrains and grapples with the rigidity of high-altitude mountaineering. This expedition unfolds with strategic precision, starting from the base in Manali and progressing through diverse landscapes. From the scenic Beas Kund to the technical ascent of Shitidhar Summit, each day presents a unique test of skill. Expertise is not just encouraged but essential, as climbers tackle the complexities of the route with advanced techniques and physical resilience. For the seasoned mountaineer, Mount Mukerbeh is not merely a summit—it is an immersive encounter with the very essence of high-altitude mastery. Embark on this journey, where each step is a calculated move toward the zenith of expertise and triumph over the challenges that only a mountain of this caliber can present.",
    aboutExpeditionExperienced:
      "Climb Muker Beh with our seasoned climbing team with over a decade of experience in the Manali. We pride ourselves on upholding the highest safety standards throughout our operations. With a track record of more than 10 years, we've honed our skills and know these mountains like the back of our hands. Our commitment to safety goes beyond words—we have meticulous rescue plans in place, ready to be executed if the need arises. When you embark on an adventure with us, rest assured that you're in the hands of professionals who prioritize your safety above all else. Welcome to a team that's as prepared as it gets.",
    itineraryData: [
      {
        day: 1,
        title: "Arrival in Manali",
        details: [
          "Arrive in Manali and check into the hotel.",
          "Explore nearby sights and attractions.",
          "Check and organize equipment.",
          "Explore the local Manali market and shop for personal gear if needed.",
        ],
      },
      {
        day: 2,
        title: "Manali to Bakarthatch via Dhundi",
        details: [
          "Drive from Manali to Dhundi.",
          "Trek from Dhundi to Bakarthatch (2 hours).",
          "Set up camp at Bakarthatch.",
        ],
      },
      {
        day: 3,
        title: "Bakarthatch to Beas Kund",
        details: [
          "Trek from Bakarthatch to Beas Kund (3-4 hours).",
          "Enjoy the breathtaking view of Beas Kund and Mt. Hanuman Tibba.",
        ],
      },
      {
        day: 4,
        title: "Beas Kund to Shitidhar Summit Camp",
        details: ["Trek from Beas Kund to Shitidhar Summit Camp (3-4 hours)."],
      },
      {
        day: 5,
        title: "Summit Shitidhar and Camp 1",
        details: [
          "Summit Shitidhar with a technical climb (5-6 hours).",
          "Set up Camp 1 near the summit of Shitidhar.",
        ],
      },
      {
        day: 6,
        title: "Camp 1 to Camp 2",
        details: ["Trek from Camp 1 to Camp 2 (5-6 hours).", "Rest in Camp 2."],
      },
      {
        day: 7,
        title: "Camp 2 to Summit Camp",
        details: [
          "Trek from Camp 2 to Summit Camp (5-6 hours).",
          "Set up camp and plan for the summit.",
        ],
      },
      {
        day: 8,
        title: "Summit Day",
        details: [
          "Summit Mount Mukerbeh (6069 m) with 8-9 hours of technical climbing.",
          "Return to Summit Camp and rest.",
        ],
      },
      {
        day: 9,
        title: "Summit Camp to Camp 1 (Shitidhar Summit)",
        details: ["Descend from Summit Camp to Camp 1."],
      },
      {
        day: 10,
        title: "Camp 1 to Bakarthatch",
        details: ["Trek from Camp 1 to Bakarthatch."],
      },
      {
        day: 11,
        title: "Bakarthatch to Dhundi and Drive to Manali",
        details: ["Trek from Bakarthatch to Dhundi.", "Drive back to Manali."],
      },
      {
        day: "Buffer (Day 12 and Day 13)",
        title: "Reserved for unforeseen circumstances or changes in weather.",
        details: [
          "Reserved for unforeseen circumstances or changes in weather.",
        ],
      },
    ],
    inclusion: [
      {
        sectionTitle: "Accommodation",
        content: [
          "Comfortable lodging in Manali upon arrival and during the expedition.",
          "Tented accommodation during the trek and at various campsites.",
        ],
      },
      {
        sectionTitle: "Meals",
        content: [
          "Nutritious and delicious meals throughout the expedition, designed to meet the energy demands of high-altitude trekking.",
          "Special provisions for dietary preferences and requirements.",
        ],
      },
      {
        sectionTitle: "Experienced Guides",
        content: [
          "Expert local guides well-versed in the terrain and conditions of the region.",
          "Assistance and guidance to ensure a safe and enjoyable trekking experience.",
        ],
      },
      {
        sectionTitle: "Transportation",
        content: [
          "Transport from Manali to the trek starting points and back, ensuring a seamless and comfortable journey.",
          "Well-maintained vehicles and experienced drivers for safe travel.",
        ],
      },
      {
        sectionTitle: "Camping Equipment",
        content: [
          "High-quality camping gear, including tents, sleeping bags, and other essentials for a comfortable night's rest.",
          "Technical gear distribution and fitting for the summit attempt.",
        ],
      },
      {
        sectionTitle: "Permits and Paperwork",
        content: [
          "Assistance in completing necessary paperwork with the Indian Mountaineering Foundation (IMF).",
          "Coordination of permits required for the expedition.",
        ],
      },
      {
        sectionTitle: "Safety Measures",
        content: [
          "Emergency evacuation plans and access to experienced high-altitude rescue teams.",
          "First aid provisions and medical assistance throughout the expedition.",
        ],
      },
      {
        sectionTitle: "Expedition Certificate",
        content: [
          "Commemorative certificates recognizing the successful completion Expedition",
        ],
      },
      {
        sectionTitle: "Communication",
        content: [
          "Communication equipment for emergencies",
          "Regular updates and briefings",
        ],
      },
      {
        sectionTitle: "Environment and Leave No Trace",
        content: [
          "Commitment to environmentally responsible trekking",
          "Practices to minimize environmental impact",
        ],
      },
    ],
    exclusion: [
      {
        sectionTitle: "Travel Insurance",
        content: [
          "Personal travel insurance covering medical expenses, evacuation, and other unforeseen circumstances.",
        ],
      },
      {
        sectionTitle: "Personal Gear",
        content: [
          "Individual trekking equipment and personal clothing.",
          "Any additional gear required for specific preferences or comfort.",
        ],
      },
      {
        sectionTitle: "Meals in Manali",
        content: [
          "Meals in Manali on arrival and departure days.",
          "Expenses for additional meals beyond the provided trek meals.",
        ],
      },
      {
        sectionTitle: "Beverages",
        content: [
          "Expenses for additional beverages or snacks not included in the provided meals.",
        ],
      },
      {
        sectionTitle: "Personal Expenses",
        content: [
          "Any expenses of a personal nature, including souvenirs, phone calls, laundry, and tips.",
        ],
      },
      {
        sectionTitle: "Transport beyond Itinerary",
        content: [
          "Transportation or expenses beyond the specified itinerary or additional travel arrangements.",
        ],
      },
      {
        sectionTitle: "Medical Expenses",
        content: [
          "Costs related to pre-existing medical conditions or personal medication.",
        ],
      },
      {
        sectionTitle: "Changes in Itinerary",
        content: [
          "Any additional costs incurred due to changes in the itinerary caused by factors beyond the control of the trek organizers.",
        ],
      },
      {
        sectionTitle: "Unforeseen Circumstances",
        content: [
          "Expenses arising from unforeseen circumstances such as natural disasters, political unrest, or flight cancellations.",
        ],
      },
      {
        sectionTitle: "Additional Services",
        content: [
          "Any services or facilities not explicitly mentioned in the inclusions.",
        ],
      },
      {
        sectionTitle: "Porter Services",
        content: [
          "Personal porter services for carrying individual backpacks, if required.",
        ],
      },
      {
        sectionTitle: "Optional Activities",
        content: [
          "Costs associated with optional activities or excursions not included in the trek itinerary.",
        ],
      },
      {
        sectionTitle: "Government Fees",
        content: [
          "Any additional fees imposed by government authorities during the trek.",
        ],
      },
    ],
    youtubeLink: "",
    expeditionDate: [
      {
        id: 1,
        duration: "7 Days",
        startDate: "",
        endDate: "",
        bookedSeat: "",
        availableSeat: "",
      },
      {
        id: 2,
        duration: "7 Days",
        startDate: "",
        endDate: "",
        bookedSeat: "",
        availableSeat: "",
      },
      {
        id: 3,
        duration: "7 Days",
        startDate: "",
        endDate: "",
        bookedSeat: "",
        availableSeat: "",
      },
      {
        id: 4,
        duration: "7 Days",
        startDate: "",
        endDate: "",
        bookedSeat: "",
        availableSeat: "",
      },
      {
        id: 5,
        duration: "7 Days",
        startDate: "",
        endDate: "",
        bookedSeat: "",
        availableSeat: "",
      },
    ],
  },

  // Trekking

  {
    individualExpeditionType: "pinParvatiPassTrek",
    individualExpeditionName: "Pin Parvati Pass Trek",
    carouselData: expeditionTrekkingHeroSection,
    location: "Parvati Valley to Spiti Valley",
    duration: "13 Days",
    grade: "Advanced",
    maxAltitude: "5319 M or 17450 ft",
    bestSeason: "June, July, August and September",
    experienceRequired: "A participant should’ve trekked before.",
    mainExpeditionContent:
      "Pin Parvati is a mind-blowing and soul-testing trek through the rugged terrains of Himachal Pradesh, where nature truly overpowers the thoughts with which you started the trek and exposes the beauty and strength that lie within. Starting from the quaint village of Barsheni, the trail meanders through the Parvati Valley, revealing a mosaic of landscapes – from lush meadows to dense forests. The real challenge arises as trekkers ascend to the formidable Pin Parvati Pass, standing tall at 17,450 feet, where the air is thin, and panoramic views of snow-clad peaks unfold like a breathtaking tapestry.",
    aboutExpeditionExperienced:
      "Trek Pin-Parvati Pass with us, a team with over a decade of experience in the Indian Himalayas. We pride ourselves on upholding the highest safety standards throughout our operations. With a track record of more than 10 years, we've honed our skills and know these mountains like the back of our hands. Our commitment to safety goes beyond words—we have meticulous rescue plans in place, ready to be executed if the need arises. When you embark on an adventure with us, rest assured that you're in the hands of professionals who prioritize your safety above all else. Welcome to a team that's as prepared as it gets.",
    itineraryData: [
      {
        day: 1,
        title: "Arrival in Manali (6,726 ft)",
        details: [
          "Arrive in Manali, check-in, and rest.",
          "Attend trek briefing for acclimatization.",
          "Explore Manali's attractions.",
        ],
      },
      {
        day: 2,
        title: "Manali to Barsheni (7,677 ft) - Drive to Kheerganga (9,350 ft)",
        details: [
          "Drive to Barsheni from Manali.",
          "Trek to Kheerganga, camp overnight.",
        ],
      },
      {
        day: 3,
        title: "Kheerganga to Tunda Bhuj (10,076 ft)",
        details: [
          "Trek from Kheerganga to Tunda Bhuj through forests.",
          "Camp overnight at Tunda Bhuj.",
        ],
      },
      {
        day: 4,
        title: "Tunda Bhuj to Thakur Kuan (11,050 ft)",
        details: [
          "Trek to Thakur Kuan, surrounded by meadows.",
          "Camp overnight at Thakur Kuan.",
        ],
      },
      {
        day: 5,
        title: "Thakur Kuan to Odi Thach (11,712 ft)",
        details: [
          "Continue trek to Odi Thach, an alpine meadow.",
          "Camp overnight at Odi Thach.",
        ],
      },
      {
        day: 6,
        title: "Odi Thach to Mantalai Lake (13,451 ft)",
        details: [
          "Trek to Mantalai Lake, acclimatization day.",
          "Camp near the lake.",
        ],
      },
      {
        day: 7,
        title: "Mantalai Lake to Base Camp (14,500 ft)",
        details: [
          "Trek to Pin Parvati Pass Base Camp.",
          "Camp overnight for preparation.",
        ],
      },
      {
        day: 8,
        title:
          "Base Camp to Pin Parvati Pass (17,457 ft) to Pin Valley Camp (14,600 ft)",
        details: [
          "Early ascent to Pin Parvati Pass.",
          "Descend to Pin Valley Camp, camp overnight.",
        ],
      },
      {
        day: 9,
        title: "Pin Valley Camp to Mudh (12,800 ft)",
        details: [
          "Trek to Mudh, the last village in Pin Valley.",
          "Camp overnight at Mudh.",
        ],
      },
      {
        day: 10,
        title: "Mudh to Kaza (12,467 ft) - Drive",
        details: [
          "Drive to Kaza from Mudh.",
          "Explore Kaza and its monasteries.",
          "Overnight stay in Kaza.",
        ],
      },
      {
        day: 11,
        title: "Kaza to Manali - Drive",
        details: [
          "Drive back to Manali from Kaza. If you wish to leave from Kaza, you can do so.",
          "Check into accommodation in Manali.",
          "Celebrate the completion of the Pin Parvati trek.",
        ],
      },
    ],
    inclusion: [
      {
        sectionTitle: "Accommodation",
        content: [
          "Comfortable lodging in Manali and Kaza upon arrival and during the trek.",
          "Tented accommodation during the trek and at various campsites.",
        ],
      },
      {
        sectionTitle: "Meals",
        content: [
          "Nutritious and delicious meals throughout the expedition, designed to meet the energy demands of high-altitude trekking.",
          "Special provisions for dietary preferences and requirements.",
        ],
      },
      {
        sectionTitle: "Experienced Guides",
        content: [
          "Expert local guides well-versed in the terrain and conditions of the region.",
          "Assistance and guidance to ensure a safe and enjoyable trekking experience.",
        ],
      },
      {
        sectionTitle: "Transportation",
        content: [
          "Transport from Manali to the trek starting points and back, ensuring a seamless and comfortable journey.",
          "Well-maintained vehicles and experienced drivers for safe travel.",
        ],
      },
      {
        sectionTitle: "Camping Equipment",
        content: [
          "High-quality camping gear, including tents, sleeping bags, and other essentials for a comfortable night's rest.",
          "Technical gear distribution and fitting for the summit attempt.",
        ],
      },
      {
        sectionTitle: "Permits and Paperwork",
        content: ["Coordination of permits required for the trek."],
      },
      {
        sectionTitle: "Safety Measures",
        content: [
          "Emergency evacuation plans and access to experienced high-altitude rescue teams.",
          "First aid provisions and medical assistance throughout the expedition.",
        ],
      },
      {
        sectionTitle: "Expedition Certificate",
        content: [
          "Commemorative certificates recognizing the successful completion of the trek",
        ],
      },
      {
        sectionTitle: "Communication",
        content: [
          "Communication equipment for emergencies",
          "Regular updates and briefings",
        ],
      },
      {
        sectionTitle: "Environment and Leave No Trace",
        content: [
          "Commitment to environmentally responsible trekking",
          "Practices to minimize environmental impact",
        ],
      },
    ],
    exclusion: [
      {
        sectionTitle: "Travel Insurance",
        content: [
          "Personal travel insurance covering medical expenses, evacuation, and other unforeseen circumstances.",
        ],
      },
      {
        sectionTitle: "Personal Gear",
        content: [
          "Individual trekking equipment and personal clothing.",
          "Any additional gear required for specific preferences or comfort.",
        ],
      },
      {
        sectionTitle: "Meals in Manali and Kaza",
        content: [
          "Meals in Manali and Kaza on arrival and departure days. You may order or explore the local cuisine.",
          "Expenses for additional meals beyond the provided trek meals.",
        ],
      },
      {
        sectionTitle: "Beverages",
        content: [
          "Expenses for additional beverages or snacks not included in the provided meals.",
        ],
      },
      {
        sectionTitle: "Personal Expenses",
        content: [
          "Any expenses of a personal nature, including souvenirs, phone calls, laundry, and tips.",
        ],
      },
      {
        sectionTitle: "Transport beyond Itinerary",
        content: [
          "Transportation or expenses beyond the specified itinerary or additional travel arrangements.",
        ],
      },
      {
        sectionTitle: "Medical Expenses",
        content: [
          "Costs related to pre-existing medical conditions or personal medication.",
        ],
      },
      {
        sectionTitle: "Changes in Itinerary",
        content: [
          "Any additional costs incurred due to changes in the itinerary caused by factors beyond the control of the trek organizers.",
        ],
      },
      {
        sectionTitle: "Unforeseen Circumstances",
        content: [
          "Expenses arising from unforeseen circumstances such as natural disasters, political unrest, or flight cancellations.",
        ],
      },
      {
        sectionTitle: "Additional Services",
        content: [
          "Any services or facilities not explicitly mentioned in the inclusions.",
        ],
      },
      {
        sectionTitle: "Porter Services",
        content: [
          "Personal porter services for carrying individual backpacks, if required.",
        ],
      },
      {
        sectionTitle: "Optional Activities",
        content: [
          "Costs associated with optional activities or excursions not included in the trek itinerary.",
        ],
      },
      {
        sectionTitle: "Government Fees",
        content: [
          "Any additional fees imposed by government authorities during the trek",
        ],
      },
    ],
    youtubeLink: "",
    expeditionDate: [
      {
        id: 1,
        duration: "7 Days",
        startDate: "",
        endDate: "",
        bookedSeat: "",
        availableSeat: "",
      },
      {
        id: 2,
        duration: "7 Days",
        startDate: "",
        endDate: "",
        bookedSeat: "",
        availableSeat: "",
      },
      {
        id: 3,
        duration: "7 Days",
        startDate: "",
        endDate: "",
        bookedSeat: "",
        availableSeat: "",
      },
      {
        id: 4,
        duration: "7 Days",
        startDate: "",
        endDate: "",
        bookedSeat: "",
        availableSeat: "",
      },
      {
        id: 5,
        duration: "7 Days",
        startDate: "",
        endDate: "",
        bookedSeat: "",
        availableSeat: "",
      },
    ],
  },
  {
    individualExpeditionType: "parangLaTrek",
    individualExpeditionName: "Parang La Trek",
    carouselData: expeditionTrekkingHeroSection,
    location: "Spiti Valley To Tso Morori, Ladakh",
    duration: "14 Days",
    grade: "Advanced",
    maxAltitude: "5319 M or 17450 ft",
    bestSeason: "June, July, August and September",
    experienceRequired: "A participant should’ve trekked before.",
    mainExpeditionContent:
      "The Parang La Pass Trek is one of a kind. You start from a culturally rich Spiti and land across numerous mountains to reach the panoramic wonders of Ladakh. It is a challenging and awe-inspiring expedition that traverses the remote and rugged landscapes connecting the seekers of thril and adventure with stunning views and sublime feelings. This high-altitude adventure takes you through iconic monasteries, picturesque villages, and pristine high-altitude lakes.The journey reaches its peak while crossing the mighty Parang La Pass at 5,600m, offering breathtaking panoramic views of the surrounding peaks. This trek is a peek into the diverse terrains of the Himalayas, showcasing vast meadows, desert-like expanses, and serene lakes. It demands physical fitness, acclimatization, and a sense of adventure, promising an unforgettable experience for those seeking the thrill of exploration in the trans-Himalayan region. Ah, there’s a big blue lake as well!",
    aboutExpeditionExperienced:
      "Trek Parang La with us, a team with over a decade of experience in the Indian Himalayas. We pride ourselves on upholding the highest safety standards throughout our operations. With a track record of more than 10 years, we've honed our skills and know these mountains like the back of our hands. Our commitment to safety goes beyond words—we have meticulous rescue plans in place, ready to be executed if the need arises. When you embark on an adventure with us, rest assured that you're in the hands of professionals who prioritize your safety above all else. Welcome to a team that's as prepared as it gets.",
    itineraryData: [
      {
        day: 1,
        title: "Arrival in Manali",
        details: [
          "Arrive in Manali and relax.",
          "Explore the local cafes and markets and just take it easy.",
        ],
      },
      {
        day: 2,
        title: "Manali to Kaza",
        details: [
          "Drive from Manali to Kaza (202 km).",
          "Marvel at the majestic landscapes of the Himalayas.",
        ],
      },
      {
        day: 3,
        title: "Rest & Acclimatization Day at Chicham",
        details: [
          "Take a break at Chicham (18 km from Kaza).",
          "Immerse yourself in the serene surroundings for acclimatization.",
        ],
      },
      {
        day: 4,
        title: "Rest & Acclimatization Day at Chicham",
        details: [
          "Continue acclimatization at Chicham.",
          "Explore the beauty and culture of the region.",
        ],
      },
      {
        day: 5,
        title: "Chicham to Thaltak",
        details: [
          "Trek from Chicham to Thaltak (8 km).",
          "Experience stunning vistas of snow-clad peaks and alpine meadows.",
        ],
      },
      {
        day: 6,
        title: "Thaltak to Borochin",
        details: [
          "Trek from Thaltak to Borochin (12 km).",
          "Enjoy panoramic views of the rugged landscape.",
        ],
      },
      {
        day: 7,
        title: "Borochin to Kharsa Yongma via Parang La",
        details: [
          "Conquer Parang La pass (5580 m).",
          "Trek from Borochin to Kharsa Yongma (14 km).",
        ],
      },
      {
        day: 8,
        title: "Kharsa Yongma to Kapapuche",
        details: [
          "Descend to Kapapuche (20 km).",
          "Witness diverse landscapes and unique flora and fauna.",
        ],
      },
      {
        day: 9,
        title: "Kapapuche to Kitpodansa",
        details: [
          "Continue descending to Kitpodansa (20 km).",
          "Experience the transition in terrain and altitude.",
        ],
      },
      {
        day: 10,
        title: "Kitpodansa to Chumik Shilde",
        details: [
          "Traverse to Chumik Shilde (18 km).",
          "Witness stunning alpine landscapes and pristine lakes.",
        ],
      },
      {
        day: 11,
        title: "Chumik Shilde to Kiangdom",
        details: [
          "Journey to Kiangdom (14 km).",
          "Experience the rugged beauty of the mountains.",
        ],
      },
      {
        day: 12,
        title: "Kiangdom to Karzok",
        details: [
          "Trek to Karzok (21.5 km).",
          "Enjoy panoramic views of Tso Morori lake.",
        ],
      },
      {
        day: 13,
        title: "Karzok to Leh",
        details: [
          "Drive from Karzok to Leh (240 km).",
          "Reflect on the memorable journey through the Himalayas.",
        ],
      },
      {
        day: 14,
        title: "Departure",
        details: [
          "Conclude the journey and depart.",
          "Carry unforgettable memories of the adventure.",
        ],
      },
    ],
    inclusion: [
      {
        sectionTitle: "Accommodation",
        content: [
          "Comfortable lodging in Manali/Kaza and Leh upon arrival and during the trek.",
          "Tented accommodation during the trek and at various campsites.",
        ],
      },
      {
        sectionTitle: "Meals",
        content: [
          "Nutritious and delicious meals throughout the expedition, designed to meet the energy demands of high-altitude trekking.",
          "Special provisions for dietary preferences and requirements.",
        ],
      },
      {
        sectionTitle: "Experienced Guides",
        content: [
          "Expert local guides well-versed in the terrain and conditions of the region.",
          "Assistance and guidance to ensure a safe and enjoyable trekking experience.",
        ],
      },
      {
        sectionTitle: "Transportation",
        content: [
          "Transport from Manali/Kaza to the trek starting points and back, ensuring a seamless and comfortable journey.",
          "Well-maintained vehicles and experienced drivers for safe travel.",
        ],
      },
      {
        sectionTitle: "Camping Equipment",
        content: [
          "High-quality camping gear, including tents, sleeping bags, and other essentials for a comfortable night's rest.",
          "Technical gear distribution and fitting for the summit attempt.",
        ],
      },
      {
        sectionTitle: "Permits and Paperwork",
        content: ["Coordination of permits required for the trek."],
      },
      {
        sectionTitle: "Safety Measures",
        content: [
          "Emergency evacuation plans and access to experienced high-altitude rescue teams.",
          "First aid provisions and medical assistance throughout the expedition.",
        ],
      },
      {
        sectionTitle: "Expedition Certificate",
        content: [
          "Commemorative certificates recognizing the successful completion of the trek",
        ],
      },
      {
        sectionTitle: "Communication",
        content: [
          "Communication equipment for emergencies",
          "Regular updates and briefings",
        ],
      },
      {
        sectionTitle: "Environment and Leave No Trace",
        content: [
          "Commitment to environmentally responsible trekking",
          "Practices to minimize environmental impact",
        ],
      },
    ],
    exclusion: [
      {
        sectionTitle: "Travel Insurance",
        content: [
          "Personal travel insurance covering medical expenses, evacuation, and other unforeseen circumstances.",
        ],
      },
      {
        sectionTitle: "Personal Gear",
        content: [
          "Individual trekking equipment and personal clothing.",
          "Any additional gear required for specific preferences or comfort.",
        ],
      },
      {
        sectionTitle: "Meals in Manali/Kaza and Leh",
        content: [
          "Meals in Manali/Kaza and Leh on arrival and departure days.",
          "Expenses for additional meals beyond the provided trek meals.",
        ],
      },
      {
        sectionTitle: "Beverages",
        content: [
          "Expenses for additional beverages or snacks not included in the provided meals.",
        ],
      },
      {
        sectionTitle: "Personal Expenses",
        content: [
          "Any expenses of a personal nature, including souvenirs, phone calls, laundry, and tips.",
        ],
      },
      {
        sectionTitle: "Transport beyond Itinerary",
        content: [
          "Transportation or expenses beyond the specified itinerary or additional travel arrangements.",
        ],
      },
      {
        sectionTitle: "Medical Expenses",
        content: [
          "Costs related to pre-existing medical conditions or personal medication.",
        ],
      },
      {
        sectionTitle: "Changes in Itinerary",
        content: [
          "Any additional costs incurred due to changes in the itinerary caused by factors beyond the control of the trek organizers.",
        ],
      },
      {
        sectionTitle: "Unforeseen Circumstances",
        content: [
          "Expenses arising from unforeseen circumstances such as natural disasters, political unrest, or flight cancellations.",
        ],
      },
      {
        sectionTitle: "Additional Services",
        content: [
          "Any services or facilities not explicitly mentioned in the inclusions.",
        ],
      },
      {
        sectionTitle: "Porter Services",
        content: [
          "Personal porter services for carrying individual backpacks, if required.",
        ],
      },
      {
        sectionTitle: "Optional Activities",
        content: [
          "Costs associated with optional activities or excursions not included in the trek itinerary.",
        ],
      },
      {
        sectionTitle: "Government Fees",
        content: [
          "Any additional fees imposed by government authorities during the trek.",
        ],
      },
    ],
    youtubeLink: "",
    expeditionDate: [
      {
        id: 1,
        duration: "7 Days",
        startDate: "",
        endDate: "",
        bookedSeat: "",
        availableSeat: "",
      },
      {
        id: 2,
        duration: "7 Days",
        startDate: "",
        endDate: "",
        bookedSeat: "",
        availableSeat: "",
      },
      {
        id: 3,
        duration: "7 Days",
        startDate: "",
        endDate: "",
        bookedSeat: "",
        availableSeat: "",
      },
      {
        id: 4,
        duration: "7 Days",
        startDate: "",
        endDate: "",
        bookedSeat: "",
        availableSeat: "",
      },
      {
        id: 5,
        duration: "7 Days",
        startDate: "",
        endDate: "",
        bookedSeat: "",
        availableSeat: "",
      },
    ],
  },
  {
    individualExpeditionType: "audenColTrek",
    individualExpeditionName: "Auden's Col Trek",
    carouselData: expeditionTrekkingHeroSection,
    location: "Gangotri, Uttarakhand",
    duration: "16 Days",
    grade: "Advanced",
    maxAltitude: "5490 m or 18,011 ft",
    bestSeason: "May, June, September and October",
    experienceRequired: "A participant should’ve trekked before.",
    mainExpeditionContent:
      "Auden's Col, situated in the Garhwal region of the Indian Himalayas, is one of the most challenging treks. Named after the renowned British geologist and explorer John Bicknell Auden, this formidable mountain pass is at an elevation of 5,490 meters (18,011 feet), making it one of the highest and most challenging passes in the region. Auden's Col serves as a crucial link between the Gangotri and Kedarnath valleys, providing a dramatic traverse through a landscape characterized by huge glaciers, high peaks, and dense wilderness. Loved by expert-level adventurers, this route demands expertise, physical endurance, and an understanding of high-altitude environments. The journey to Auden's Col is of high-altitude trekking, and panoramic views of the Himalayas splendor that is capable of challenging even the most seasoned mountaineers with its rugged terrain and formidable altitudes.",
    aboutExpeditionExperienced:
      "Trek Auden’s Col with us, a team with over a decade of experience in the Indian Himalayas. We pride ourselves on upholding the highest safety standards throughout our operations. With a track record of more than 10 years, we've honed our skills and know these mountains like the back of our hands. Our commitment to safety goes beyond words—we have meticulous rescue plans in place, ready to be executed if the need arises. When you embark on an adventure with us, rest assured that you're in the hands of professionals who prioritize your safety above all else. Welcome to a team that's as prepared as it gets.",
    itineraryData: [
      {
        day: 1,
        title: "Reach Dehradun and Arrival in Gangotri (3,415M)",
        details: [
          "Arrive in Gangotri and check into the hotel.",
          "Attend a briefing session with the trek leader.",
          "Evening at leisure to explore the beautiful surroundings.",
        ],
      },
      {
        day: 2,
        title: "Rest and Acclimatization in Gangotri",
        details: [
          "Acclimatization day in Gangotri.",
          "Explore the Gangotri town and visit the Gangotri Temple.",
          "Briefing on the upcoming trek and safety guidelines.",
        ],
      },
      {
        day: 3,
        title: "Gangotri (3,415M) to Nala Camp (3,760M)",
        details: [
          "Start the trek towards Nala Camp.",
          "Trek through lush green landscapes and gradually gain altitude.",
          "Camp overnight at Nala Camp.",
        ],
      },
      {
        day: 4,
        title: "Nala Camp (3,760M) to Rudugaira Camp (4,350M)",
        details: [
          "Continue the ascent towards Rudugaira Camp.",
          "Witness stunning views of the surrounding peaks.",
          "Camp overnight at Rudugaira Camp.",
        ],
      },
      {
        day: 5,
        title: "Rest and Acclimatization at Rudugaira Base Camp (4,350M)",
        details: [
          "Acclimatization day at Rudugaira Base Camp.",
          "Short hikes in the vicinity to adapt to the higher altitude.",
        ],
      },
      {
        day: 6,
        title:
          "Rudugaira Base Camp (4,350M) to Auden's Col Base Camp (4,720M) via Gangotri Base Camp (4,500M)",
        details: [
          "Trek to Auden's Col Base Camp via Gangotri Base Camp.",
          "Reach the base camp and prepare for the challenging days ahead.",
        ],
      },
      {
        day: 7,
        title:
          "Auden's Col Base Camp (4,720M) to Khatling Glacier Camp (5,000M) via Auden's Col (5,490M)",
        details: [
          "Cross Auden's Col, the highest point of the trek.",
          "Descend to Khatling Glacier Camp for an overnight stay.",
        ],
      },
      {
        day: 8,
        title: "Khatling Glacier Camp (5,000M) to Zero Point (3,870M)",
        details: [
          "Trek down to Zero Point through varied terrains.",
          "Camp overnight at Zero Point.",
        ],
      },
      {
        day: 9,
        title: "Rest Day at Zero Point (3,870M)",
        details: [
          "Rest and recovery day at Zero Point.",
          "Optional short hikes or activities.",
        ],
      },
      {
        day: 10,
        title: "Zero Point (3,870M) to Chowki (3,580M)",
        details: [
          "Descend further towards Chowki.",
          "Experience the changing landscapes along the way.",
        ],
      },
      {
        day: 11,
        title: "Chowki (3,580M) to Masar Tal (4,135M)",
        details: [
          "Trek to Masar Tal, a stunning high-altitude lake.",
          "Camp overnight near the lake.",
        ],
      },
      {
        day: 12,
        title:
          "Masar Tal (4,135M) to Vasuki Tal (4,135M) via Mayali Pass (5,000M)",
        details: [
          "Cross Mayali Pass and reach Vasuki Tal.",
          "Enjoy breathtaking views of the Himalayan peaks.",
        ],
      },
      {
        day: 13,
        title: "Vasuki Tal (4,135M) to Kedarnath (3,590M)",
        details: [
          "Trek to Kedarnath, a significant pilgrimage site.",
          "Visit the Kedarnath Temple.",
        ],
      },
      {
        day: 14,
        title: "Kedarnath (3,590M) to Gaurikund (1,985M)",
        details: ["Trek down to Gaurikund.", "Check into a hotel and relax."],
      },
      {
        day: 15,
        title: "Gaurikund (1,985M) to Dehradun (430M)",
        details: [
          "Drive from Gaurikund to Dehradun.",
          "Check into a hotel in Dehradun.",
        ],
      },
      {
        day: 16,
        title: "Reserve Day for Bad Weather",
        details: [
          "A reserve day in case of unfavorable weather conditions.",
          "Explore Dehradun or relax at the hotel.",
        ],
      },
    ],
    inclusion: [
      {
        sectionTitle: "Accommodation",
        content: [
          "Comfortable lodging in Gangotri, Kedarnath and Gaurikund upon arrival and during the trek.",
          "Tented accommodation during the trek and at various campsites.",
        ],
      },
      {
        sectionTitle: "Meals",
        content: [
          "Nutritious and delicious meals from Day 2 lunch to Day 13, designed to meet the energy demands of high-altitude trekking.",
          "Special provisions for dietary preferences and requirements.",
        ],
      },
      {
        sectionTitle: "Experienced Guides",
        content: [
          "Expert local guides well-versed in the terrain and conditions of the region.",
          "Assistance and guidance to ensure a safe and enjoyable trekking experience.",
        ],
      },
      {
        sectionTitle: "Transportation",
        content: [
          "Transport from Gangotri to the trek starting points and back, ensuring a seamless and comfortable journey.",
          "Well-maintained vehicles and experienced drivers for safe travel.",
        ],
      },
      {
        sectionTitle: "Camping Equipment",
        content: [
          "High-quality camping gear, including tents, sleeping bags, and other essentials for a comfortable night's rest.",
          "Technical gear distribution and fitting for the summit attempt.",
        ],
      },
      {
        sectionTitle: "Permits and Paperwork",
        content: ["Coordination of permits required for the trek."],
      },
      {
        sectionTitle: "Safety Measures",
        content: [
          "Emergency evacuation plans and access to experienced high-altitude rescue teams.",
          "First aid provisions and medical assistance throughout the expedition.",
        ],
      },
      {
        sectionTitle: "Expedition Certificate",
        content: [
          "Commemorative certificates recognizing the successful completion of the trek",
        ],
      },
      {
        sectionTitle: "Communication",
        content: [
          "Communication equipment for emergencies",
          "Regular updates and briefings",
        ],
      },
      {
        sectionTitle: "Environment and Leave No Trace",
        content: [
          "Commitment to environmentally responsible trekking",
          "Practices to minimize environmental impact",
        ],
      },
    ],
    exclusion: [
      {
        sectionTitle: "Travel Insurance",
        content: [
          "Personal travel insurance covering medical expenses, evacuation, and other unforeseen circumstances.",
        ],
      },
      {
        sectionTitle: "Personal Gear",
        content: [
          "Individual trekking equipment and personal clothing.",
          "Any additional gear required for specific preferences or comfort.",
        ],
      },
      {
        sectionTitle: "Meals in Gangotri, Dehradun and Gaurikund",
        content: [
          "Meals in Gangotri, Dehradun and Gaurikund on arrival and departure days.",
          "Expenses for additional meals beyond the provided trek meals.",
        ],
      },
      {
        sectionTitle: "Beverages",
        content: [
          "Expenses for additional beverages or snacks not included in the provided meals.",
        ],
      },
      {
        sectionTitle: "Personal Expenses",
        content: [
          "Any expenses of a personal nature, including souvenirs, phone calls, laundry, and tips.",
        ],
      },
      {
        sectionTitle: "Transport beyond Itinerary",
        content: [
          "Transportation or expenses beyond the specified itinerary or additional travel arrangements.",
        ],
      },
      {
        sectionTitle: "Medical Expenses",
        content: [
          "Costs related to pre-existing medical conditions or personal medication.",
        ],
      },
      {
        sectionTitle: "Changes in Itinerary",
        content: [
          "Any additional costs incurred due to changes in the itinerary caused by factors beyond the control of the trek organizers.",
        ],
      },
      {
        sectionTitle: "Unforeseen Circumstances",
        content: [
          "Expenses arising from unforeseen circumstances such as natural disasters, political unrest, or flight cancellations.",
        ],
      },
      {
        sectionTitle: "Additional Services",
        content: [
          "Any services or facilities not explicitly mentioned in the inclusions.",
        ],
      },
      {
        sectionTitle: "Porter Services",
        content: [
          "Personal porter services for carrying individual backpacks, if required.",
        ],
      },
      {
        sectionTitle: "Optional Activities",
        content: [
          "Costs associated with optional activities or excursions not included in the trek itinerary.",
        ],
      },
      {
        sectionTitle: "Government Fees",
        content: [
          "Any additional fees imposed by government authorities during the trek.",
        ],
      },
    ],
    youtubeLink: "",
    expeditionDate: [
      {
        id: 1,
        duration: "7 Days",
        startDate: "",
        endDate: "",
        bookedSeat: "",
        availableSeat: "",
      },
      {
        id: 2,
        duration: "7 Days",
        startDate: "",
        endDate: "",
        bookedSeat: "",
        availableSeat: "",
      },
      {
        id: 3,
        duration: "7 Days",
        startDate: "",
        endDate: "",
        bookedSeat: "",
        availableSeat: "",
      },
      {
        id: 4,
        duration: "7 Days",
        startDate: "",
        endDate: "",
        bookedSeat: "",
        availableSeat: "",
      },
      {
        id: 5,
        duration: "7 Days",
        startDate: "",
        endDate: "",
        bookedSeat: "",
        availableSeat: "",
      },
    ],
  },
  {
    individualExpeditionType: "kalindiKhalTrek",
    individualExpeditionName: "Kalindi Khal Trek",
    carouselData: expeditionTrekkingHeroSection,
    location: "Gangotri, Uttarakhand",
    duration: "15 Days",
    grade: "Advanced",
    maxAltitude: "5940 m or 19,488 ft",
    bestSeason: "September and October",
    experienceRequired: "A participant should’ve trekked before.",
    mainExpeditionContent:
      "Kalindi Khal Trek is a challenging expedition, traversing the daunting Kalindi Khal Pass amidst the breathtaking Garhwal Himalayas. The pass connects Gangotri and Badrinath. This challenging route entails navigating through vast glaciers, rocky slopes, and tough snowfields, often requiring the use of technical equipment such as ropes, ice axes, and crampons. As you journey through the Kalindi Khal, you'll traverse halfway around Garhwal, venturing from the Bhagirathi Valley to the Alaknanda Valley.This trek promises awe-inspiring scenery of the highest and most majestic peaks in the Indian Himalayan range. Marvel at Shivling standing tall at 6,543 m, the impressive Meru at 6,630 m, the commanding Kedar Dome at 6,808 m, the Bhagirathi group at 6,856 m, the regal Nilkantha at 6,506 m, the majestic Chandra Parvat at 6,728 m, the formidable Avalanche Peak at 6,413 m, the towering Kamet at 7,756 m, the iconic Mana at 7,272 m, and the imposing Abi Gamin at 7,355 m.",
    aboutExpeditionExperienced:
      "Trek Kalindi Khal with us, a team with over a decade of experience in the Indian Himalayas. We pride ourselves on upholding the highest safety standards throughout our operations. With a track record of more than 10 years, we've honed our skills and know these mountains like the back of our hands. Our commitment to safety goes beyond words—we have meticulous rescue plans in place, ready to be executed if the need arises. When you embark on an adventure with us, rest assured that you're in the hands of professionals who prioritize your safety above all else. Welcome to a team that's as prepared as it gets.",
    itineraryData: [
      {
        day: 1,
        title: "Arrive in Gangotri",
        details: [
          "Arrive in Gangotri, a sacred town on the banks of the Ganges.",
          "Check into your accommodation and attend a briefing session.",
        ],
      },
      {
        day: 2,
        title: "Rest & Acclimatization in Gangotri",
        details: [
          "Acclimatization day in Gangotri.",
          "Explore the town, visit Gangotri Temple, and prepare for the trek.",
        ],
      },
      {
        day: 3,
        title: "Gangotri to Bhojwasa (12,467 ft) - 14 km",
        details: [
          "Begin the trek to Bhojwasa, the base for the journey ahead.",
          "Trek through scenic landscapes with the Bhagirathi River accompanying you.",
        ],
      },
      {
        day: 4,
        title: "Bhojwasa to Tapovan (14,632 ft) via Gomukh - 13 km",
        details: [
          "Trek to Tapovan via Gomukh, the source of the Ganges.",
          "Enjoy panoramic views of Shivling, Meru, and Bhagirathi group of peaks.",
        ],
      },
      {
        day: 5,
        title: "Tapovan to Nandanvan (14,271 ft) via Meru Glacier - 8 km",
        details: [
          "Trek to Nandanvan, passing through the captivating Meru Glacier.",
          "Marvel at the rugged beauty of the surroundings.",
        ],
      },
      {
        day: 6,
        title: "Nandanvan to Vasuki Tal (16,010 ft) - 6 km",
        details: [
          "Trek to Vasuki Tal, a stunning high-altitude lake.",
          "Enjoy the serene surroundings and acclimatize to the increasing altitude.",
        ],
      },
      {
        day: 7,
        title: "Rest & Acclimatization at Vasuki Tal",
        details: [
          "Rest day at Vasuki Tal for acclimatization.",
          "Short hikes around the lake to adapt to the altitude.",
        ],
      },
      {
        day: 8,
        title: "Vasuki Tal to Khara Patthar (17,060 ft) - 6 km",
        details: [
          "Ascend to Khara Patthar, a high-altitude plateau.",
          "Witness breathtaking views of the surrounding peaks.",
        ],
      },
      {
        day: 9,
        title: "Khara Patthar to Sweta Glacier (17,880 ft) - 8 km",
        details: [
          "Trek to Sweta Glacier, crossing challenging terrains.",
          "Set up camp amidst the snow-capped landscape.",
        ],
      },
      {
        day: 10,
        title: "Sweta Glacier to Kalindi Base Camp (18,569 ft) - 6 km",
        details: [
          "Reach the Kalindi Base Camp, your point of departure for the challenging Kalindi Khal pass.",
        ],
      },
      {
        day: 11,
        title:
          "Kalindi Base Camp to Rajaparav (16,076 ft) via Kalindi Khal (19,488 ft) - 13 km",
        details: [
          "Cross the formidable Kalindi Khal pass and descend to Rajaparav.",
          "A challenging day with spectacular mountain views.",
        ],
      },
      {
        day: 12,
        title: "Rajaparav to Arwa Tal (14,763 ft) - 13 km",
        details: [
          "Descend to Arwa Tal, a pristine high-altitude lake.",
          "Camp overnight amidst the serene surroundings.",
        ],
      },
      {
        day: 13,
        title:
          "Arwa Tal to Badrinath (9,842 ft) via Ghastoli (12,467 ft) - 12 km",
        details: [
          "Trek to Ghastoli and then drive to Badrinath.",
          "Visit the Badrinath Temple, a major pilgrimage site.",
        ],
      },
      {
        day: 14,
        title: "Badrinath to Dehradun - 295 km drive",
        details: [
          "Drive back to Dehradun, reflecting on the incredible journey.",
        ],
      },
      {
        day: 15,
        title: "Reserve Day for Climate Change",
        details: [
          "A reserve day to account for any unforeseen weather challenges.",
        ],
      },
    ],
    inclusion: [
      {
        sectionTitle: "Accommodation",
        content: [
          "Comfortable lodging in Gangotri, Badrinath, and Dehradun upon arrival and during the trek.",
          "Tented accommodation during the trek and at various campsites.",
        ],
      },
      {
        sectionTitle: "Meals",
        content: [
          "Nutritious and delicious meals from Day 3 breakfast to Day 13 lunch, designed to meet the energy demands of high-altitude trekking.",
          "Special provisions for dietary preferences and requirements.",
        ],
      },
      {
        sectionTitle: "Experienced Guides",
        content: [
          "Expert local guides well-versed in the terrain and conditions of the region.",
          "Assistance and guidance to ensure a safe and enjoyable trekking experience.",
        ],
      },
      {
        sectionTitle: "Transportation",
        content: [
          "Transport from Gangotri to the trek starting points and back, ensuring a seamless and comfortable journey.",
          "Well-maintained vehicles and experienced drivers for safe travel.",
        ],
      },
      {
        sectionTitle: "Camping Equipment",
        content: [
          "High-quality camping gear, including tents, sleeping bags, and other essentials for a comfortable night's rest.",
          "Technical gear distribution and fitting for the summit attempt.",
        ],
      },
      {
        sectionTitle: "Permits and Paperwork",
        content: ["Coordination of permits required for the trek."],
      },
      {
        sectionTitle: "Safety Measures",
        content: [
          "Emergency evacuation plans and access to experienced high-altitude rescue teams.",
          "First aid provisions and medical assistance throughout the expedition.",
        ],
      },
      {
        sectionTitle: "Expedition Certificate",
        content: [
          "Commemorative certificates recognizing the successful completion of the trek",
        ],
      },
      {
        sectionTitle: "Communication",
        content: [
          "Communication equipment for emergencies",
          "Regular updates and briefings",
        ],
      },
      {
        sectionTitle: "Environment and Leave No Trace",
        content: [
          "Commitment to environmentally responsible trekking",
          "Practices to minimize environmental impact",
        ],
      },
    ],
    exclusion: [
      {
        sectionTitle: "Travel Insurance",
        content: [
          "Personal travel insurance covering medical expenses, evacuation, and other unforeseen circumstances.",
        ],
      },
      {
        sectionTitle: "Personal Gear",
        content: [
          "Individual trekking equipment and personal clothing.",
          "Any additional gear required for specific preferences or comfort.",
        ],
      },
      {
        sectionTitle: "Meals in Gangotri, Dehradun and Badrinath",
        content: [
          "Meals in Gangotri, Dehradun and Badrinath on arrival and departure days.",
          "Expenses for additional meals beyond the provided trek meals.",
        ],
      },
      {
        sectionTitle: "Beverages",
        content: [
          "Expenses for additional beverages or snacks not included in the provided meals.",
        ],
      },
      {
        sectionTitle: "Personal Expenses",
        content: [
          "Any expenses of a personal nature, including souvenirs, phone calls, laundry, and tips.",
        ],
      },
      {
        sectionTitle: "Transport beyond Itinerary",
        content: [
          "Transportation or expenses beyond the specified itinerary or additional travel arrangements.",
        ],
      },
      {
        sectionTitle: "Medical Expenses",
        content: [
          "Costs related to pre-existing medical conditions or personal medication.",
        ],
      },
      {
        sectionTitle: "Changes in Itinerary",
        content: [
          "Any additional costs incurred due to changes in the itinerary caused by factors beyond the control of the trek organizers.",
        ],
      },
      {
        sectionTitle: "Unforeseen Circumstances",
        content: [
          "Expenses arising from unforeseen circumstances such as natural disasters, political unrest, or flight cancellations.",
        ],
      },
      {
        sectionTitle: "Additional Services",
        content: [
          "Any services or facilities not explicitly mentioned in the inclusions.",
        ],
      },
      {
        sectionTitle: "Porter Services",
        content: [
          "Personal porter services for carrying individual backpacks, if required.",
        ],
      },
      {
        sectionTitle: "Optional Activities",
        content: [
          "Costs associated with optional activities or excursions not included in the trek itinerary.",
        ],
      },
      {
        sectionTitle: "Government Fees",
        content: [
          "Any additional fees imposed by government authorities during the trek.",
        ],
      },
    ],
    youtubeLink: "",
    expeditionDate: [
      {
        id: 1,
        duration: "7 Days",
        startDate: "",
        endDate: "",
        bookedSeat: "",
        availableSeat: "",
      },
      {
        id: 2,
        duration: "7 Days",
        startDate: "",
        endDate: "",
        bookedSeat: "",
        availableSeat: "",
      },
      {
        id: 3,
        duration: "7 Days",
        startDate: "",
        endDate: "",
        bookedSeat: "",
        availableSeat: "",
      },
      {
        id: 4,
        duration: "7 Days",
        startDate: "",
        endDate: "",
        bookedSeat: "",
        availableSeat: "",
      },
      {
        id: 5,
        duration: "7 Days",
        startDate: "",
        endDate: "",
        bookedSeat: "",
        availableSeat: "",
      },
    ],
  },
  {
    individualExpeditionType: "kangLaTrek",
    individualExpeditionName: "Kang La Trek",
    carouselData: expeditionTrekkingHeroSection,
    location: "Manali to Zanskar",
    duration: "10 Days",
    grade: "Advanced",
    maxAltitude: "4600 M or 15100 ft",
    bestSeason: "September, October and November",
    experienceRequired: "A participant should’ve trekked before.",
    mainExpeditionContent:
      "Kang La Pass trek, starting in Lahaul's Miyar Valley, is celebrated for its unspoiled alpine pastures and colourful wildflowers. This demanding expedition reaches the highest level at Kang La Pass, an elevation of 5465 meters, revealing the alpine beauty of Ladakh's Zanskar Valley—a rugged terrain dominated by rock. Serving as the border between Himachal and Jammu & Kashmir, Kang La boasts the central Himalaya's lengthiest glacial approach, spanning 24 km. Traverse through velvety green meadows, glacial lakes, snowfields, and moraines, leading to the secluded village of Padum in Zanskar. An undiscovered jewel in the Indian Himalayas, Kang La demands expertise from seasoned trekkers with robust physical fitness. This extensive journey, from Manali to Leh, guarantees an extraordinary tapestry of evolving landscapes, cultures, and languages—an all-encompassing adventure.",
    aboutExpeditionExperienced:
      "Trek Kang La with us, a team with over a decade of experience in the Indian Himalayas. We pride ourselves on upholding the highest safety standards throughout our operations. With a track record of more than 10 years, we've honed our skills and know these mountains like the back of our hands. Our commitment to safety goes beyond words—we have meticulous rescue plans in place, ready to be executed if the need arises. When you embark on an adventure with us, rest assured that you're in the hands of professionals who prioritize your safety above all else. Welcome to a team that's as prepared as it gets.",
    itineraryData: [
      {
        day: 1,
        title: "Manali to Changut (10,500 ft) via Udaipur - 135 km Drive",
        details: [
          "Depart from Manali and drive to Changut via Udaipur.",
          "Enjoy the scenic journey through the Lahaul Valley.",
          "Reach Changut and set up the first camp.",
        ],
      },
      {
        day: 2,
        title: "Changut to Yolithang (12,300 ft) via Shukto - 10 km",
        details: [
          "Trek from Changut to Yolithang via Shukto.",
          "Experience the varied landscapes and pristine surroundings.",
          "Camp overnight at Yolithang.",
        ],
      },
      {
        day: 3,
        title: "Yolithang to Zardung (12,800 ft) - 10 km",
        details: [
          "Continue the trek to Zardung, surrounded by snow-capped peaks.",
          "Enjoy the serene beauty of the region.",
          "Camp overnight at Zardung.",
        ],
      },
      {
        day: 4,
        title: "Zardung to Kesar Yon Chhaap (13,100 ft) - 10 km",
        details: [
          "Trek towards Kesar Yon Chhaap, a high-altitude campsite.",
          "Enjoy breathtaking views of the surrounding mountains.",
          "Camp overnight at Kesar Yon Chhaap.",
        ],
      },
      {
        day: 5,
        title: "Rest and Acclimatization at Kesar Yon Chaap",
        details: [
          "Rest day for acclimatization and exploration.",
          "Short hikes around Kesar Yon Chaap to adapt to the altitude.",
        ],
      },
      {
        day: 6,
        title: "Kesar Yon Chaap to Miyar Glacier Camp (14,600 ft) - 12 km",
        details: [
          "Trek to Miyar Glacier Camp, passing through alpine meadows.",
          "Witness the stunning Miyar Glacier.",
          "Camp overnight at Miyar Glacier.",
        ],
      },
      {
        day: 7,
        title: "Miyar Glacier Camp to Kang La Base Camp (15,750 ft) - 9 km",
        details: [
          "Continue the trek to Kang La Base Camp.",
          "Marvel at the majestic Kang La Pass in the distance.",
          "Camp overnight at the base of Kang La.",
        ],
      },
      {
        day: 8,
        title:
          "Kang La Base Camp to Temasa Tongpo (14,500 ft) via Kang La (17,900 ft) - 17 km",
        details: [
          "Early morning ascent to Kang La Pass, the highest point of the trek.",
          "Descend to Temasa Tongpo through rocky terrains.",
          "Camp overnight at Temasa Tongpo.",
        ],
      },
      {
        day: 9,
        title:
          "Temasa Tongpo to Padam (11,800 ft) - 15 km Trek, 12 km Drive (Rest in Padam)",
        details: [
          "Trek to Padam, the endpoint of the trek.",
          "Drive to Padam for rest and relaxation.",
          "Overnight stay in Padum.",
        ],
      },
      {
        day: 10,
        title: "Drive from Padum to Leh",
        details: [
          "Depart from Padum and drive to Leh.",
          "Reach Leh and conclude the adventurous Kang La trek.",
        ],
      },
    ],
    inclusion: [
      {
        sectionTitle: "Accommodation",
        content: [
          "Comfortable lodging in Manali, Padum, and Leh upon arrival and during the trek.",
          "Tented accommodation during the trek and at various campsites.",
        ],
      },
      {
        sectionTitle: "Meals",
        content: [
          "Nutritious and delicious meals throughout the expedition, designed to meet the energy demands of high-altitude trekking.",
          "Special provisions for dietary preferences and requirements.",
        ],
      },
      {
        sectionTitle: "Experienced Guides",
        content: [
          "Expert local guides well-versed in the terrain and conditions of the region.",
          "Assistance and guidance to ensure a safe and enjoyable trekking experience.",
        ],
      },
      {
        sectionTitle: "Transportation",
        content: [
          "Transport from Manali to the trek starting points and back, ensuring a seamless and comfortable journey.",
          "Well-maintained vehicles and experienced drivers for safe travel.",
        ],
      },
      {
        sectionTitle: "Camping Equipment",
        content: [
          "High-quality camping gear, including tents, sleeping bags, and other essentials for a comfortable night's rest.",
          "Technical gear distribution and fitting for the summit attempt.",
        ],
      },
      {
        sectionTitle: "Permits and Paperwork",
        content: ["Coordination of permits required for the trek."],
      },
      {
        sectionTitle: "Safety Measures",
        content: [
          "Emergency evacuation plans and access to experienced high-altitude rescue teams.",
          "First aid provisions and medical assistance throughout the expedition.",
        ],
      },
      {
        sectionTitle: "Expedition Certificate",
        content: [
          "Commemorative certificates recognizing the successful completion of the trek",
        ],
      },
      {
        sectionTitle: "Communication",
        content: [
          "Communication equipment for emergencies",
          "Regular updates and briefings",
        ],
      },
      {
        sectionTitle: "Environment and Leave No Trace",
        content: [
          "Commitment to environmentally responsible trekking",
          "Practices to minimize environmental impact",
        ],
      },
    ],
    exclusion: [
      {
        sectionTitle: "Travel Insurance",
        content: [
          "Personal travel insurance covering medical expenses, evacuation, and other unforeseen circumstances.",
        ],
      },
      {
        sectionTitle: "Personal Gear",
        content: [
          "Individual trekking equipment and personal clothing.",
          "Any additional gear required for specific preferences or comfort.",
        ],
      },
      {
        sectionTitle: "Meals in Manali and Leh",
        content: [
          "Meals in Manali and Leh on arrival and departure days.",
          "Expenses for additional meals beyond the provided trek meals.",
        ],
      },
      {
        sectionTitle: "Beverages",
        content: [
          "Expenses for additional beverages or snacks not included in the provided meals.",
        ],
      },
      {
        sectionTitle: "Personal Expenses",
        content: [
          "Any expenses of a personal nature, including souvenirs, phone calls, laundry, and tips.",
        ],
      },
      {
        sectionTitle: "Transport beyond Itinerary",
        content: [
          "Transportation or expenses beyond the specified itinerary or additional travel arrangements.",
        ],
      },
      {
        sectionTitle: "Medical Expenses",
        content: [
          "Costs related to pre-existing medical conditions or personal medication.",
        ],
      },
      {
        sectionTitle: "Changes in Itinerary",
        content: [
          "Any additional costs incurred due to changes in the itinerary caused by factors beyond the control of the trek organizers.",
        ],
      },
      {
        sectionTitle: "Unforeseen Circumstances",
        content: [
          "Expenses arising from unforeseen circumstances such as natural disasters, political unrest, or flight cancellations.",
        ],
      },
      {
        sectionTitle: "Additional Services",
        content: [
          "Any services or facilities not explicitly mentioned in the inclusions.",
        ],
      },
      {
        sectionTitle: "Porter Services",
        content: [
          "Personal porter services for carrying individual backpacks, if required.",
        ],
      },
      {
        sectionTitle: "Optional Activities",
        content: [
          "Costs associated with optional activities or excursions not included in the trek itinerary.",
        ],
      },
      {
        sectionTitle: "Government Fees",
        content: [
          "Any additional fees imposed by government authorities during the trek.",
        ],
      },
    ],
    youtubeLink: "",
    expeditionDate: [
      {
        id: 1,
        duration: "7 Days",
        startDate: "",
        endDate: "",
        bookedSeat: "",
        availableSeat: "",
      },
      {
        id: 2,
        duration: "7 Days",
        startDate: "",
        endDate: "",
        bookedSeat: "",
        availableSeat: "",
      },
      {
        id: 3,
        duration: "7 Days",
        startDate: "",
        endDate: "",
        bookedSeat: "",
        availableSeat: "",
      },
      {
        id: 4,
        duration: "7 Days",
        startDate: "",
        endDate: "",
        bookedSeat: "",
        availableSeat: "",
      },
      {
        id: 5,
        duration: "7 Days",
        startDate: "",
        endDate: "",
        bookedSeat: "",
        availableSeat: "",
      },
    ],
  },
  {
    individualExpeditionType: "goechaLaTrek",
    individualExpeditionName: "Goecha La Trek",
    carouselData: expeditionTrekkingHeroSection,
    location: "Siliguri, West Bengal",
    duration: "10 Days",
    grade: "Intermediate",
    maxAltitude: "5465 M or 17929 ft",
    bestSeason: "June, July, August and September",
    experienceRequired: "A participant should’ve trekked before.",
    mainExpeditionContent:
      "The Goechala Trek in Sikkim India, calls the adventure enthusiasts to experience the beauty and challenges. This iconic trek is located against the backdrop of the mighty Kanchenjunga, the world's third-highest peak. The route offers a visual treat of snow-capped summits, rhododendron-rich slopes, and glacial lakes. Spanning approximately 90 kilometres, the journey winds through the heart of the Khangchendzonga National Park, a UNESCO World Heritage Site renowned for its diverse flora and fauna. Trekkers navigate through dense forests, traverse high-altitude meadows and reaching the Goechala Pass at 4,940 meters, where the panoramic views of the peaks awaits. The trek's allure lies not only in its challenging terrain but also in the profound connection it fosters with nature. With the rhythmic crunch of footsteps on mountain trails, the Goechala Trek promises an unforgettable adventure, blending physical endurance with the spiritual rejuvenation that comes from immersing oneself in the Himalayan wilderness.",
    aboutExpeditionExperienced:
      "Trek Goechla with us, a team with over a decade of experience in the Indian Himalayas. We pride ourselves on upholding the highest safety standards throughout our operations. With a track record of more than 10 years, we've honed our skills and know these mountains-like the back of our hands. Our commitment to safety goes beyond words—we have meticulous rescue plans in place, ready to be executed if the need arises. When you embark on an adventure with us, rest assured that you're in the hands of professionals who prioritize your safety above all else. Welcome to a team that's as prepared as it gets.",
    itineraryData: [
      {
        day: 1,
        title: "Yuksom (5,643 ft) to Sachen (7,200 ft)",
        details: [
          "Begin the trek from Yuksom, the trailhead for Goechala.",
          "Trek through dense forests and reach Sachen.",
          "Camp overnight at Sachen.",
        ],
      },
      {
        day: 2,
        title: "Sachen (7,200 ft) to Tshoka (9,650 ft)",
        details: [
          "Continue trekking through rhododendron forests.",
          "Reach Tshoka, a beautiful settlement with stunning mountain views.",
          "Camp overnight at Tshoka.",
        ],
      },
      {
        day: 3,
        title: "Tshoka (9,650 ft) to Dzongri (12,980 ft)",
        details: [
          "Trek to Dzongri, offering breathtaking panoramic views.",
          "Pass by Phedang, a scenic high-altitude meadow.",
          "Camp overnight at Dzongri.",
        ],
      },
      {
        day: 4,
        title: "Acclimatization Day at Dzongri (12,980 ft)",
        details: [
          "Rest day for acclimatization.",
          "Optional short hikes to nearby viewpoints.",
          "Camp overnight at Dzongri.",
        ],
      },
      {
        day: 5,
        title: "Dzongri (12,980 ft) to Lamuney (13,700 ft)",
        details: [
          "Trek to Lamuney, passing through meadows and rocky terrains.",
          "Enjoy views of Pandim, Tenzingkhang, and Jopuno.",
          "Camp overnight at Lamuney.",
        ],
      },
      {
        day: 6,
        title:
          "Lamuney (13,700 ft) to Goechala (16,000 ft) and Back to Kokchurang (12,000 ft)",
        details: [
          "Early morning trek to Goechala, the highest point of the trek.",
          "Witness stunning views of the Kanchenjunga massif.",
          "Descend to Kokchurang for an overnight stay.",
        ],
      },
      {
        day: 7,
        title: "Kokchurang (12,000 ft) to Tshoka",
        details: [
          "Trek back to Tshoka, retracing your steps.",
          "Enjoy the scenic beauty of the trail.",
          "Camp overnight at Tshoka.",
        ],
      },
      {
        day: 8,
        title: "Tshoka to Yuksom",
        details: [
          "Descend to Yuksom, concluding the trek.",
          "Celebrate the successful completion of Goechala trek.",
          "Overnight stay in Yuksom.",
        ],
      },
      {
        day: 9,
        title: "Departure",
        details: ["Explore Yuksom or depart for your onward journey."],
      },
    ],
    inclusion: [
      {
        sectionTitle: "Accommodation",
        content: [
          "Comfortable lodging in Yuksom upon arrival and during the trek.",
          "Tented accommodation during the trek and at various campsites.",
        ],
      },
      {
        sectionTitle: "Meals",
        content: [
          "Nutritious and delicious meals throughout the expedition, designed to meet the energy demands of high-altitude trekking.",
          "Special provisions for dietary preferences and requirements.",
        ],
      },
      {
        sectionTitle: "Experienced Guides",
        content: [
          "Expert local guides well-versed in the terrain and conditions of the region.",
          "Assistance and guidance to ensure a safe and enjoyable trekking experience.",
        ],
      },
      {
        sectionTitle: "Transportation",
        content: [
          "Transport from Yuksom to the trek starting points and back, ensuring a seamless and comfortable journey.",
          "Well-maintained vehicles and experienced drivers for safe travel.",
        ],
      },
      {
        sectionTitle: "Camping Equipment",
        content: [
          "High-quality camping gear, including tents, sleeping bags, and other essentials for a comfortable night's rest.",
          "Technical gear distribution and fitting for the summit attempt.",
        ],
      },
      {
        sectionTitle: "Permits and Paperwork",
        content: ["Coordination of permits required for the trek."],
      },
      {
        sectionTitle: "Safety Measures",
        content: [
          "Emergency evacuation plans and access to experienced high-altitude rescue teams.",
          "First aid provisions and medical assistance throughout the expedition.",
        ],
      },
      {
        sectionTitle: "Expedition Certificate",
        content: [
          "Commemorative certificates recognizing the successful completion of the trek",
        ],
      },
      {
        sectionTitle: "Communication",
        content: [
          "Communication equipment for emergencies",
          "Regular updates and briefings",
        ],
      },
      {
        sectionTitle: "Environment and Leave No Trace",
        content: [
          "Commitment to environmentally responsible trekking",
          "Practices to minimize environmental impact",
        ],
      },
    ],
    exclusion: [
      {
        sectionTitle: "Travel Insurance",
        content: [
          "Personal travel insurance covering medical expenses, evacuation, and other unforeseen circumstances.",
        ],
      },
      {
        sectionTitle: "Personal Gear",
        content: [
          "Individual trekking equipment and personal clothing.",
          "Any additional gear required for specific preferences or comfort.",
        ],
      },
      {
        sectionTitle: "Meals in Yuksom",
        content: [
          "Meals in Yuksom on arrival and departure days.",
          "Expenses for additional meals beyond the provided trek meals.",
        ],
      },
      {
        sectionTitle: "Beverages",
        content: [
          "Expenses for additional beverages or snacks not included in the provided meals.",
        ],
      },
      {
        sectionTitle: "Personal Expenses",
        content: [
          "Any expenses of a personal nature, including souvenirs, phone calls, laundry, and tips.",
        ],
      },
      {
        sectionTitle: "Transport beyond Itinerary",
        content: [
          "Transportation or expenses beyond the specified itinerary or additional travel arrangements.",
        ],
      },
      {
        sectionTitle: "Medical Expenses",
        content: [
          "Costs related to pre-existing medical conditions or personal medication.",
        ],
      },
      {
        sectionTitle: "Changes in Itinerary",
        content: [
          "Any additional costs incurred due to changes in the itinerary caused by factors beyond the control of the trek organizers.",
        ],
      },
      {
        sectionTitle: "Unforeseen Circumstances",
        content: [
          "Expenses arising from unforeseen circumstances such as natural disasters, political unrest, or flight cancellations.",
        ],
      },
      {
        sectionTitle: "Additional Services",
        content: [
          "Any services or facilities not explicitly mentioned in the inclusions.",
        ],
      },
      {
        sectionTitle: "Porter Services",
        content: [
          "Personal porter services for carrying individual backpacks, if required.",
        ],
      },
      {
        sectionTitle: "Optional Activities",
        content: [
          "Costs associated with optional activities or excursions not included in the trek itinerary.",
        ],
      },
      {
        sectionTitle: "Government Fees",
        content: [
          "Any additional fees imposed by government authorities during the trek.",
        ],
      },
    ],
    youtubeLink: "",
    expeditionDate: [
      {
        id: 1,
        duration: "7 Days",
        startDate: "",
        endDate: "",
        bookedSeat: "",
        availableSeat: "",
      },
      {
        id: 2,
        duration: "7 Days",
        startDate: "",
        endDate: "",
        bookedSeat: "",
        availableSeat: "",
      },
      {
        id: 3,
        duration: "7 Days",
        startDate: "",
        endDate: "",
        bookedSeat: "",
        availableSeat: "",
      },
      {
        id: 4,
        duration: "7 Days",
        startDate: "",
        endDate: "",
        bookedSeat: "",
        availableSeat: "",
      },
      {
        id: 5,
        duration: "7 Days",
        startDate: "",
        endDate: "",
        bookedSeat: "",
        availableSeat: "",
      },
    ],
  },
  {
    individualExpeditionType: "baraBangalTrek",
    individualExpeditionName: "Bara Bangal Trek",
    carouselData: expeditionTrekkingHeroSection,
    location: "Manali",
    duration: "8 Days",
    grade: "Intermediate",
    maxAltitude: "4850 M or 15912 ft",
    bestSeason: "June, July, September and October",
    experienceRequired: "A participant should’ve trekked before.",
    mainExpeditionContent:
      "Embark on a journey through the untamed beauty of the Himalayas with the Bara Bangal trek. This meticulously crafted eight-day itinerary promises an unforgettable adventure, winding through diverse landscapes and reaching heights that unveil panoramic vistas. Beginning in Manali, the gateway to the mountains, each day unfolds a new chapter of exploration and endurance.The trek kicks off with a drive to the trailhead at Dhundi, followed by a trek to Lama Dugh, where a scenic meadow becomes the first campsite under the starlit sky. As the journey progresses, participants traverse dense forests, ascending to the enchanting Riyali Thach and the challenging Kalihani Base Camp. The pinnacle of the trek is reached at Kalihani Pass (15,550 ft), offering a breathtaking panorama.Descending through alpine meadows, the trek weaves through remote villages like Bara Bhangal. The expedition concludes with a trek back to Manali, ensuring a seamless blend of adventure and cultural immersion. Throughout, participants are reminded to adapt to changing conditions, prioritize acclimatization, and savour the guidance of experienced leaders for a safe and rewarding journey.",
    aboutExpeditionExperienced:
      "Trek Bara Bangal with us, a team with over a decade of experience in the Indian Himalayas. We pride ourselves on upholding the highest safety standards throughout our operations. With a track record of more than 10 years, we've honed our skills and know these mountains like the back of our hands. Our commitment to safety goes beyond words—we have meticulous rescue plans in place, ready to be executed if the need arises. When you embark on an adventure with us, rest assured that you're in the hands of professionals who prioritize your safety above all else. Welcome to a team that's as prepared as it gets.",
    itineraryData: [
      {
        day: 1,
        title: "Manali to Lama Dugh (9,842 ft)",
        details: [
          "Drive from Manali to the roadhead at Dhundi.",
          "Begin trekking to Lama Dugh, a picturesque meadow.",
          "Camp overnight at Lama Dugh.",
        ],
      },
      {
        day: 2,
        title: "Lama Dugh to Riyali Thach (11,150 ft)",
        details: [
          "Trek through birch and oak forests to Riyali Thach.",
          "Enjoy the scenic landscapes and camp overnight.",
        ],
      },
      {
        day: 3,
        title: "Riyali Thach to Kalihani Base Camp (12,700 ft)",
        details: [
          "Continue the ascent to Kalihani Base Camp.",
          "Experience the changing terrain and set up camp.",
        ],
      },
      {
        day: 4,
        title:
          "Kalihani Base Camp to Devi Ki Marhi (14,550 ft) via Kalihani Pass (15,994 ft)",
        details: [
          "Early morning trek to Kalihani Pass, the highest point.",
          "Descend to Devi Ki Marhi and camp overnight.",
        ],
      },
      {
        day: 5,
        title: "Devi Ki Marhi to Dal Marhi (13,780 ft)",
        details: [
          "Trek to Dal Marhi through alpine meadows.",
          "Enjoy the views of surrounding peaks.",
          "Camp overnight at Dal Marhi.",
        ],
      },
      {
        day: 6,
        title: "Dal Marhi to Bara Bhangal (8,775 ft)",
        details: [
          "Descend to Bara Bhangal, a remote village.",
          "Explore the unique culture of the village.",
          "Camp overnight in Bara Bhangal.",
        ],
      },
      {
        day: 7,
        title: "Bara Bhangal to Marhi (11,150 ft)",
        details: [
          "Trek to Marhi through lush green meadows.",
          "Enjoy the serene surroundings and camp overnight.",
        ],
      },
      {
        day: 8,
        title: "Marhi to Manali",
        details: [
          "Trek to the roadhead at Dhundi.",
          "Drive back to Manali, marking the end of the trek.",
        ],
      },
    ],
    inclusion: [
      {
        sectionTitle: "Accommodation",
        content: [
          "Comfortable lodging in Manali upon arrival and during the trek.",
          "Tented accommodation during the trek and at various campsites.",
        ],
      },
      {
        sectionTitle: "Meals",
        content: [
          "Nutritious and delicious meals throughout the expedition, designed to meet the energy demands of high-altitude trekking.",
          "Special provisions for dietary preferences and requirements.",
        ],
      },
      {
        sectionTitle: "Experienced Guides",
        content: [
          "Expert local guides well-versed in the terrain and conditions of the region.",
          "Assistance and guidance to ensure a safe and enjoyable trekking experience.",
        ],
      },
      {
        sectionTitle: "Transportation",
        content: [
          "Transport from Manali to the trek starting points and back, ensuring a seamless and comfortable journey.",
          "Well-maintained vehicles and experienced drivers for safe travel.",
        ],
      },
      {
        sectionTitle: "Camping Equipment",
        content: [
          "High-quality camping gear, including tents, sleeping bags, and other essentials for a comfortable night's rest.",
          "Technical gear distribution and fitting for the summit attempt.",
        ],
      },
      {
        sectionTitle: "Permits and Paperwork",
        content: ["Coordination of permits required for the trek."],
      },
      {
        sectionTitle: "Safety Measures",
        content: [
          "Emergency evacuation plans and access to experienced high-altitude rescue teams.",
          "First aid provisions and medical assistance throughout the expedition.",
        ],
      },
      {
        sectionTitle: "Expedition Certificate",
        content: [
          "Commemorative certificates recognizing the successful completion of the trek",
        ],
      },
      {
        sectionTitle: "Communication",
        content: [
          "Communication equipment for emergencies",
          "Regular updates and briefings",
        ],
      },
      {
        sectionTitle: "Environment and Leave No Trace",
        content: [
          "Commitment to environmentally responsible trekking",
          "Practices to minimize environmental impact",
        ],
      },
    ],
    exclusion: [
      {
        sectionTitle: "Travel Insurance",
        content: [
          "Personal travel insurance covering medical expenses, evacuation, and other unforeseen circumstances.",
        ],
      },
      {
        sectionTitle: "Personal Gear",
        content: [
          "Individual trekking equipment and personal clothing.",
          "Any additional gear required for specific preferences or comfort.",
        ],
      },
      {
        sectionTitle: "Meals in Manali",
        content: [
          "Meals in Manali on arrival and departure days.",
          "Expenses for additional meals beyond the provided trek meals.",
        ],
      },
      {
        sectionTitle: "Beverages",
        content: [
          "Expenses for additional beverages or snacks not included in the provided meals.",
        ],
      },
      {
        sectionTitle: "Personal Expenses",
        content: [
          "Any expenses of a personal nature, including souvenirs, phone calls, laundry, and tips.",
        ],
      },
      {
        sectionTitle: "Transport beyond Itinerary",
        content: [
          "Transportation or expenses beyond the specified itinerary or additional travel arrangements.",
        ],
      },
      {
        sectionTitle: "Medical Expenses",
        content: [
          "Costs related to pre-existing medical conditions or personal medication.",
        ],
      },
      {
        sectionTitle: "Changes in Itinerary",
        content: [
          "Any additional costs incurred due to changes in the itinerary caused by factors beyond the control of the trek organizers.",
        ],
      },
      {
        sectionTitle: "Unforeseen Circumstances",
        content: [
          "Expenses arising from unforeseen circumstances such as natural disasters, political unrest, or flight cancellations.",
        ],
      },
      {
        sectionTitle: "Additional Services",
        content: [
          "Any services or facilities not explicitly mentioned in the inclusions.",
        ],
      },
      {
        sectionTitle: "Porter Services",
        content: [
          "Personal porter services for carrying individual backpacks, if required.",
        ],
      },
      {
        sectionTitle: "Optional Activities",
        content: [
          "Costs associated with optional activities or excursions not included in the trek itinerary.",
        ],
      },
      {
        sectionTitle: "Government Fees",
        content: [
          "Any additional fees imposed by government authorities during the trek.",
        ],
      },
    ],
    youtubeLink: "",
    expeditionDate: [
      {
        id: 1,
        duration: "7 Days",
        startDate: "",
        endDate: "",
        bookedSeat: "",
        availableSeat: "",
      },
      {
        id: 2,
        duration: "7 Days",
        startDate: "",
        endDate: "",
        bookedSeat: "",
        availableSeat: "",
      },
      {
        id: 3,
        duration: "7 Days",
        startDate: "",
        endDate: "",
        bookedSeat: "",
        availableSeat: "",
      },
      {
        id: 4,
        duration: "7 Days",
        startDate: "",
        endDate: "",
        bookedSeat: "",
        availableSeat: "",
      },
      {
        id: 5,
        duration: "7 Days",
        startDate: "",
        endDate: "",
        bookedSeat: "",
        availableSeat: "",
      },
    ],
  },
  {
    individualExpeditionType: "lehLamayuruTrek",
    individualExpeditionName: "Leh Lamayuru Trek",
    carouselData: expeditionTrekkingHeroSection,
    location: "Leh",
    duration: "6 Days",
    grade: "Beginner",
    maxAltitude: "3850 M or 12631 ft",
    bestSeason: "May, June, July, August, September and October",
    experienceRequired: "A participant should’ve trekked before.",
    mainExpeditionContent:
      "Embark on an exhilarating journey through the mesmerizing landscapes of Ladakh with our Ladakh Trekking Adventure. This six-day expedition offers a perfect blend of cultural exploration and physical challenge, taking you from the bustling town of Leh to the remote, picturesque villages nestled in the heart of the Himalayas. Witness the grandeur of Lamayuru Monastery, traverse scenic barley fields, and marvel at the ancient Alchi Monastery.As you trek through diverse terrains, including meadows and mountain passes, each day unfolds with breathtaking views of the Stok and Karakoram ranges. Immerse yourself in the rich culture of Ladakh, from traditional villages to renowned monasteries. With carefully planned acclimatization and expert guidance, this trek promises not only an adrenaline-filled adventure but also an intimate connection with the awe-inspiring beauty and spirituality of Ladakh.",
    aboutExpeditionExperienced:
      "Trek Leh-Lamayuru with us, a team with over a decade of experience in the Indian Himalayas. We pride ourselves on upholding the highest safety standards throughout our operations. With a track record of more than 10 years, we've honed our skills and know these mountains like the back of our hands. Our commitment to safety goes beyond words—we have meticulous rescue plans in place, ready to be executed if the need arises. When you embark on an adventure with us, rest assured that you're in the hands of professionals who prioritize your safety above all else. Welcome to a team that's as prepared as it gets.",
    itineraryData: [
      {
        day: 1,
        title: "Arrival in Leh",
        details: [
          "Arrive in Leh and acclimatize to the high altitude.",
          "Drive to Lamayuru, enjoying picturesque Ladakh landscapes.",
          "Visit Lamayuru Monastery for its stunning architecture and spiritual significance.",
          "Overnight stay at Lamayuru for further acclimatization.",
        ],
      },
      {
        day: 2,
        title: "Lamayuru to Wanla",
        details: [
          "Begin the trek from Lamayuru to Wanla.",
          "Trek through scenic barley fields, and small villages, and visit Alchi Monastery.",
          "Camp overnight at Wanla.",
        ],
      },
      {
        day: 3,
        title: "Wanla to Hinju",
        details: [
          "Trek towards Hinju, crossing beautiful meadows and small streams.",
          "Enjoy panoramic views of the Stok and Karakoram ranges.",
          "Camp overnight at Hinju.",
        ],
      },
      {
        day: 4,
        title: "Hinju to Sumdo",
        details: [
          "Continue trekking, crossing the Kanski La pass.",
          "Explore Kanski La valley, and interact with local shepherds.",
          "Camp overnight at Sumdo.",
        ],
      },
      {
        day: 5,
        title: "Sumdo to Chilling",
        details: [
          "Trek towards Chilling, passing through charming villages and landscapes.",
          "Explore Chilling Village, known for copper craftsmanship.",
          "Visit Chilling Monastery.",
          "Camp or stay in a local guesthouse.",
        ],
      },
      {
        day: 6,
        title: "Chilling to Leh (Drive)",
        details: [
          "Drive back to Leh from Chilling.",
          "Enjoy scenic beauty, and stop for photo opportunities en route.",
          "Arrive in Leh, explore local markets, and relax.",
          "Depart from Leh or extend your stay to explore more of Ladakh.",
        ],
      },
    ],
    inclusion: [
      {
        sectionTitle: "Accommodation",
        content: [
          "Comfortable lodging in Leh and Chilling upon arrival and during the trek.",
          "Tented accommodation during the trek and at various campsites.",
        ],
      },
      {
        sectionTitle: "Meals",
        content: [
          "Nutritious and delicious meals throughout the expedition, designed to meet the energy demands of high-altitude trekking.",
          "Special provisions for dietary preferences and requirements.",
        ],
      },
      {
        sectionTitle: "Experienced Guides",
        content: [
          "Expert local guides well-versed in the terrain and conditions of the region.",
          "Assistance and guidance to ensure a safe and enjoyable trekking experience.",
        ],
      },
      {
        sectionTitle: "Transportation",
        content: [
          "Transport from Leh to the trek starting points and back, ensuring a seamless and comfortable journey.",
          "Well-maintained vehicles and experienced drivers for safe travel.",
        ],
      },
      {
        sectionTitle: "Camping Equipment",
        content: [
          "High-quality camping gear, including tents, sleeping bags, and other essentials for a comfortable night's rest.",
          "Technical gear distribution and fitting for the summit attempt.",
        ],
      },
      {
        sectionTitle: "Permits and Paperwork",
        content: ["Coordination of permits required for the trek."],
      },
      {
        sectionTitle: "Safety Measures",
        content: [
          "Emergency evacuation plans and access to experienced high-altitude rescue teams.",
          "First aid provisions and medical assistance throughout the expedition.",
        ],
      },
      {
        sectionTitle: "Expedition Certificate",
        content: [
          "Commemorative certificates recognizing the successful completion of the trek",
        ],
      },
      {
        sectionTitle: "Communication",
        content: [
          "Communication equipment for emergencies",
          "Regular updates and briefings",
        ],
      },
      {
        sectionTitle: "Environment and Leave No Trace",
        content: [
          "Commitment to environmentally responsible trekking",
          "Practices to minimize environmental impact",
        ],
      },
    ],
    exclusion: [
      {
        sectionTitle: "Travel Insurance",
        content: [
          "Personal travel insurance covering medical expenses, evacuation, and other unforeseen circumstances.",
        ],
      },
      {
        sectionTitle: "Personal Gear",
        content: [
          "Individual trekking equipment and personal clothing.",
          "Any additional gear required for specific preferences or comfort.",
        ],
      },
      {
        sectionTitle: "Meals in Leh",
        content: [
          "Meals in Leh on arrival and departure days.",
          "Expenses for additional meals beyond the provided trek meals.",
        ],
      },
      {
        sectionTitle: "Beverages",
        content: [
          "Expenses for additional beverages or snacks not included in the provided meals.",
        ],
      },
      {
        sectionTitle: "Personal Expenses",
        content: [
          "Any expenses of a personal nature, including souvenirs, phone calls, laundry, and tips.",
        ],
      },
      {
        sectionTitle: "Transport beyond Itinerary",
        content: [
          "Transportation or expenses beyond the specified itinerary or additional travel arrangements.",
        ],
      },
      {
        sectionTitle: "Medical Expenses",
        content: [
          "Costs related to pre-existing medical conditions or personal medication.",
        ],
      },
      {
        sectionTitle: "Changes in Itinerary",
        content: [
          "Any additional costs incurred due to changes in the itinerary caused by factors beyond the control of the trek organizers.",
        ],
      },
      {
        sectionTitle: "Unforeseen Circumstances",
        content: [
          "Expenses arising from unforeseen circumstances such as natural disasters, political unrest, or flight cancellations.",
        ],
      },
      {
        sectionTitle: "Additional Services",
        content: [
          "Any services or facilities not explicitly mentioned in the inclusions.",
        ],
      },
      {
        sectionTitle: "Porter Services",
        content: [
          "Personal porter services for carrying individual backpacks, if required.",
        ],
      },
      {
        sectionTitle: "Optional Activities",
        content: [
          "Costs associated with optional activities or excursions not included in the trek itinerary.",
        ],
      },
      {
        sectionTitle: "Government Fees",
        content: [
          "Any additional fees imposed by government authorities during the trek.",
        ],
      },
    ],
    youtubeLink: "",
    expeditionDate: [
      {
        id: 1,
        duration: "7 Days",
        startDate: "",
        endDate: "",
        bookedSeat: "",
        availableSeat: "",
      },
      {
        id: 2,
        duration: "7 Days",
        startDate: "",
        endDate: "",
        bookedSeat: "",
        availableSeat: "",
      },
      {
        id: 3,
        duration: "7 Days",
        startDate: "",
        endDate: "",
        bookedSeat: "",
        availableSeat: "",
      },
      {
        id: 4,
        duration: "7 Days",
        startDate: "",
        endDate: "",
        bookedSeat: "",
        availableSeat: "",
      },
      {
        id: 5,
        duration: "7 Days",
        startDate: "",
        endDate: "",
        bookedSeat: "",
        availableSeat: "",
      },
    ],
  },
  {
    individualExpeditionType: "markhaValleyTrek",
    individualExpeditionName: "Markha Valley Trek",
    carouselData: expeditionTrekkingHeroSection,
    location: "Leh",
    duration: "7 Days",
    grade: "Intermediate",
    maxAltitude: "4750 M or 15583 ft",
    bestSeason: "May, June, July, August, September and October",
    experienceRequired: "A participant should’ve trekked before.",
    mainExpeditionContent:
      "Welcome to an exhilarating journey through the enchanting landscapes of Ladakh! This meticulously crafted itinerary promises an unforgettable adventure, weaving through the majestic Himalayan terrain and providing a glimpse into the rich cultural tapestry of the region. Over the course of seven days, you will traverse from the ancient city of Leh, situated at an altitude of 3,500 meters, to the remote and awe-inspiring reaches of Nimaling at 4,750 meters.This trek is not merely a physical odyssey but a holistic experience, immersing you in the vibrant hues of Hemis National Park, the tranquility of quaint villages like Rumbak and Markha, and the spiritual aura of monasteries like Hemis and Markha. Each day unfolds a new chapter, taking you through diverse terrains, challenging mountain passes, and serene meadows, offering a perfect blend of adventure, cultural exploration, and natural beauty.Embark on this expedition that goes beyond the ordinary, leading you to discover the heart and soul of Ladakh, where every step echoes with the tales of ancient monasteries, nomadic life, and the resplendent grandeur of the Kang Yatse peaks. Join us as we traverse the trails less traveled, creating memories that will linger in your heart long after the journey concludes. Get ready to be captivated by the magic of Ladakh!",
    aboutExpeditionExperienced:
      "Trek Markha Valley with us, a team with over a decade of experience in the Indian Himalayas. We pride ourselves on upholding the highest safety standards throughout our operations. With a track record of more than 10 years, we've honed our skills and know these mountains like the back of our hands. Our commitment to safety goes beyond words—we have meticulous rescue plans in place, ready to be executed if the need arises. When you embark on an adventure with us, rest assured that you're in the hands of professionals who prioritize your safety above all else. Welcome to a team that's as prepared as it gets.",
    itineraryData: [
      {
        day: 1,
        title: "Leh (3,500m) to Zingchen (3,400m) and Trek to Yurutse (3,550m)",
        details: [
          "Start the day with a 4-5 hours drive from Leh to Zingchen.",
          "Begin trekking towards Yurutse, passing through the Hemis National Park known for its diverse flora and fauna.",
          "Traverse the picturesque Rumbak village.",
          "Camp overnight at Yurutse.",
        ],
      },
      {
        day: 2,
        title: "Yurutse (3,550m) to Skiu (3,350m)",
        details: [
          "Embark on a 6-7 hours trek to Skiu, crossing the Ganda La pass.",
          "Enjoy breathtaking views of the Markha Valley.",
          "Pass through beautiful landscapes and the charming Hemis Shukpachan village.",
          "Camp overnight at Skiu.",
        ],
      },
      {
        day: 3,
        title: "Skiu (3,350m) to Markha (3,800m)",
        details: [
          "Trek for 6-7 hours towards Markha, crossing numerous streams and the Markha River.",
          "Explore the Markha village and the historic Markha Monastery.",
          "Camp overnight at Markha.",
        ],
      },
      {
        day: 4,
        title: "Markha (3,800m) to Thachungtse (4,050m)",
        details: [
          "Trek for 5-6 hours towards Thachungtse, passing through lush meadows.",
          "Enjoy views of Kang Yatse, a prominent peak in the region.",
          "Camp overnight at Thachungtse.",
        ],
      },
      {
        day: 5,
        title: "Thachungtse (4,050m) to Nimaling (4,750m)",
        details: [
          "Trek for 3-4 hours to Nimaling, a high-altitude grazing area with stunning views.",
          "Marvel at the grandeur of the Kang Yatse peaks.",
          "Camp overnight at Nimaling.",
        ],
      },
      {
        day: 6,
        title: "Nimaling (4,750m) to Shang Sumdo (3,750m)",
        details: [
          "Trek for 7-8 hours towards Shang Sumdo, descending through beautiful landscapes.",
          "Enjoy the last day of trekking with varied terrains.",
          "Camp overnight at Shang Sumdo.",
        ],
      },
      {
        day: 7,
        title: "Shang Sumdo (3,750m) to Hemis (3,600m) and Drive to Leh",
        details: [
          "Conclude the trek with a 2-3 hours trek to Hemis.",
          "Visit the Hemis Monastery, one of the largest and wealthiest monasteries in Ladakh.",
          "Drive back to Leh for an overnight stay.",
        ],
      },
    ],
    inclusion: [
      {
        sectionTitle: "Accommodation",
        content: [
          "Comfortable lodging in Leh upon arrival and during the trek.",
          "Tented accommodation during the trek and at various campsites.",
        ],
      },
      {
        sectionTitle: "Meals",
        content: [
          "Nutritious and delicious meals throughout the expedition, designed to meet the energy demands of high-altitude trekking.",
          "Special provisions for dietary preferences and requirements.",
        ],
      },
      {
        sectionTitle: "Experienced Guides",
        content: [
          "Expert local guides well-versed in the terrain and conditions of the region.",
          "Assistance and guidance to ensure a safe and enjoyable trekking experience.",
        ],
      },
      {
        sectionTitle: "Transportation",
        content: [
          "Transport from Leh to the trek starting points and back, ensuring a seamless and comfortable journey.",
          "Well-maintained vehicles and experienced drivers for safe travel.",
        ],
      },
      {
        sectionTitle: "Camping Equipment",
        content: [
          "High-quality camping gear, including tents, sleeping bags, and other essentials for a comfortable night's rest.",
          "Technical gear distribution and fitting for the summit attempt.",
        ],
      },
      {
        sectionTitle: "Permits and Paperwork",
        content: ["Coordination of permits required for the trek."],
      },
      {
        sectionTitle: "Safety Measures",
        content: [
          "Emergency evacuation plans and access to experienced high-altitude rescue teams.",
          "First aid provisions and medical assistance throughout the expedition.",
        ],
      },
      {
        sectionTitle: "Expedition Certificate",
        content: [
          "Commemorative certificates recognizing the successful completion of the trek",
        ],
      },
      {
        sectionTitle: "Communication",
        content: [
          "Communication equipment for emergencies",
          "Regular updates and briefings",
        ],
      },
      {
        sectionTitle: "Environment and Leave No Trace",
        content: [
          "Commitment to environmentally responsible trekking",
          "Practices to minimize environmental impact",
        ],
      },
    ],
    exclusion: [
      {
        sectionTitle: "Travel Insurance",
        content: [
          "Personal travel insurance covering medical expenses, evacuation, and other unforeseen circumstances.",
        ],
      },
      {
        sectionTitle: "Personal Gear",
        content: [
          "Individual trekking equipment and personal clothing.",
          "Any additional gear required for specific preferences or comfort.",
        ],
      },
      {
        sectionTitle: "Meals in Leh",
        content: [
          "Meals in Leh on arrival and departure days.",
          "Expenses for additional meals beyond the provided trek meals.",
        ],
      },
      {
        sectionTitle: "Beverages",
        content: [
          "Expenses for additional beverages or snacks not included in the provided meals.",
        ],
      },
      {
        sectionTitle: "Personal Expenses",
        content: [
          "Any expenses of a personal nature, including souvenirs, phone calls, laundry, and tips.",
        ],
      },
      {
        sectionTitle: "Transport beyond Itinerary",
        content: [
          "Transportation or expenses beyond the specified itinerary or additional travel arrangements.",
        ],
      },
      {
        sectionTitle: "Medical Expenses",
        content: [
          "Costs related to pre-existing medical conditions or personal medication.",
        ],
      },
      {
        sectionTitle: "Changes in Itinerary",
        content: [
          "Any additional costs incurred due to changes in the itinerary caused by factors beyond the control of the trek organizers.",
        ],
      },
      {
        sectionTitle: "Unforeseen Circumstances",
        content: [
          "Expenses arising from unforeseen circumstances such as natural disasters, political unrest, or flight cancellations.",
        ],
      },
      {
        sectionTitle: "Additional Services",
        content: [
          "Any services or facilities not explicitly mentioned in the inclusions.",
        ],
      },
      {
        sectionTitle: "Porter Services",
        content: [
          "Personal porter services for carrying individual backpacks, if required.",
        ],
      },
      {
        sectionTitle: "Optional Activities",
        content: [
          "Costs associated with optional activities or excursions not included in the trek itinerary.",
        ],
      },
      {
        sectionTitle: "Government Fees",
        content: [
          "Any additional fees imposed by government authorities during the trek.",
        ],
      },
    ],
    youtubeLink: "",
    expeditionDate: [
      {
        id: 1,
        duration: "7 Days",
        startDate: "",
        endDate: "",
        bookedSeat: "",
        availableSeat: "",
      },
      {
        id: 2,
        duration: "7 Days",
        startDate: "",
        endDate: "",
        bookedSeat: "",
        availableSeat: "",
      },
      {
        id: 3,
        duration: "7 Days",
        startDate: "",
        endDate: "",
        bookedSeat: "",
        availableSeat: "",
      },
      {
        id: 4,
        duration: "7 Days",
        startDate: "",
        endDate: "",
        bookedSeat: "",
        availableSeat: "",
      },
      {
        id: 5,
        duration: "7 Days",
        startDate: "",
        endDate: "",
        bookedSeat: "",
        availableSeat: "",
      },
    ],
  },
  {
    individualExpeditionType: "spitukToMathoTrek",
    individualExpeditionName: "Spituk To Matho Trek",
    carouselData: expeditionTrekkingHeroSection,
    location: "Ladakh",
    duration: "5 Days",
    grade: "Beginner",
    maxAltitude: "4750 M or 15583 ft",
    bestSeason: "May, June, July, August, September and October",
    experienceRequired: "A participant should’ve trekked before.",
    mainExpeditionContent:
      "Embark on an exhilarating Ladakh Trekking Expedition, a five-day journey through the pristine landscapes of the Himalayas, revealing the untamed beauty of the region. Begin your adventure with a scenic drive from Leh, the heart of Ladakh, to Zingchen, where the first steps of the trek lead you to the enchanting Yurutse. As you traverse the rugged terrains, experience the ever-changing scenery along the Indus River, leading you through gorges and alpine forests.Encounter diverse flora and fauna in Hemis National Park, keeping an eager eye out for elusive snow leopards, Tibetan wolves, and Bharal. Discover the rich cultural tapestry of Ladakh by exploring historic monasteries, such as Markha, and immersing yourself in the local way of life. Camp under the star-lit skies in picturesque locations like Skiu and Thachungtse, with breathtaking views of towering mountains. Conclude your trek with a final stretch to Matho and return to Leh, leaving you with indelible memories of Ladakh's majestic landscapes and vibrant cultural heritage.",
    aboutExpeditionExperienced:
      "Trek from Spituk to Matho with us, a team with over a decade of experience in the Indian Himalayas. We pride ourselves on upholding the highest safety standards throughout our operations. With a track record of more than 10 years, we've honed our skills and know these mountains like the back of our hands. Our commitment to safety goes beyond words—we have meticulous rescue plans in place, ready to be executed if the need arises. When you embark on an adventure with us, rest assured that you're in the hands of professionals who prioritize your safety above all else. Welcome to a team that's as prepared as it gets.",
    itineraryData: [
      {
        day: 1,
        title:
          "Leh (3,500m) to Zingchen (3-4 hours drive, 3,600m) and Trek to Yurutse (3,900m)",
        details: [
          "Morning: Scenic drive from Leh to Spituk (3-4 hours)",
          "Afternoon: Begin trek to Yurutse, passing through picturesque landscapes along the Indus River",
          "Evening: Enter the gorge leading to Zingchen, set up camp",
          "Night: Overnight stay at Yurutse surrounded by natural beauty",
        ],
      },
      {
        day: 2,
        title: "Yurutse (3,900m) to Skiu (3,980m)",
        details: [
          "Morning: Trek towards Skiu (5-6 hours)",
          "Afternoon: Cross Ganda La pass, explore Hemis National Park",
          "Evening: Camp at Skiu, a charming village with a scenic backdrop",
          "Night: Overnight stay in Skiu, surrounded by diverse flora and fauna",
        ],
      },
      {
        day: 3,
        title: "Skiu (3,980m) to Markha (3,800m)",
        details: [
          "Morning: Continue trek to Markha (6-7 hours)",
          "Afternoon: Explore Markha Monastery, immerse in local culture",
          "Evening: Camp at Markha, enjoy the serene atmosphere of the valley",
          "Night: Overnight stay in Markha",
        ],
      },
      {
        day: 4,
        title: "Markha (3,800m) to Thachungtse (4,050m)",
        details: [
          "Morning: Trek towards Thachungtse (5-6 hours) through alpine trees and dry forests",
          "Afternoon: Enjoy the tranquility and changing landscapes",
          "Evening: Camp at Thachungtse, with views of majestic mountains",
          "Night: Overnight stay in Thachungtse",
        ],
      },
      {
        day: 5,
        title: "Thachungtse (4,050m) to Matho (4,300m) and Drive to Leh",
        details: [
          "Morning: Final trek stretch to Matho (5-6 hours)",
          "Afternoon: Drive back to Leh, reflecting on the beautiful landscapes and experiences",
          "Evening: Arrive in Leh, explore local markets or relax",
          "Night: Spend the evening at leisure in Leh",
        ],
      },
    ],
    inclusion: [
      {
        sectionTitle: "Accommodation",
        content: [
          "Comfortable lodging in Leh upon arrival and during the trek.",
          "Tented accommodation during the trek and at various campsites.",
        ],
      },
      {
        sectionTitle: "Meals",
        content: [
          "Nutritious and delicious meals throughout the expedition, designed to meet the energy demands of high-altitude trekking.",
          "Special provisions for dietary preferences and requirements.",
        ],
      },
      {
        sectionTitle: "Experienced Guides",
        content: [
          "Expert local guides well-versed in the terrain and conditions of the region.",
          "Assistance and guidance to ensure a safe and enjoyable trekking experience.",
        ],
      },
      {
        sectionTitle: "Transportation",
        content: [
          "Transport from Leh to the trek starting points and back, ensuring a seamless and comfortable journey.",
          "Well-maintained vehicles and experienced drivers for safe travel.",
        ],
      },
      {
        sectionTitle: "Camping Equipment",
        content: [
          "High-quality camping gear, including tents, sleeping bags, and other essentials for a comfortable night's rest.",
          "Technical gear distribution and fitting for the summit attempt.",
        ],
      },
      {
        sectionTitle: "Permits and Paperwork",
        content: ["Coordination of permits required for the trek."],
      },
      {
        sectionTitle: "Safety Measures",
        content: [
          "Emergency evacuation plans and access to experienced high-altitude rescue teams.",
          "First aid provisions and medical assistance throughout the expedition.",
        ],
      },
      {
        sectionTitle: "Expedition Certificate",
        content: [
          "Commemorative certificates recognizing the successful completion of the trek",
        ],
      },
      {
        sectionTitle: "Communication",
        content: [
          "Communication equipment for emergencies",
          "Regular updates and briefings",
        ],
      },
      {
        sectionTitle: "Environment and Leave No Trace",
        content: [
          "Commitment to environmentally responsible trekking",
          "Practices to minimize environmental impact",
        ],
      },
    ],
    exclusion: [
      {
        sectionTitle: "Travel Insurance",
        content: [
          "Personal travel insurance covering medical expenses, evacuation, and other unforeseen circumstances.",
        ],
      },
      {
        sectionTitle: "Personal Gear",
        content: [
          "Individual trekking equipment and personal clothing.",
          "Any additional gear required for specific preferences or comfort.",
        ],
      },
      {
        sectionTitle: "Meals in Leh",
        content: [
          "Meals in Leh on arrival and departure days.",
          "Expenses for additional meals beyond the provided trek meals.",
        ],
      },
      {
        sectionTitle: "Beverages",
        content: [
          "Expenses for additional beverages or snacks not included in the provided meals.",
        ],
      },
      {
        sectionTitle: "Personal Expenses",
        content: [
          "Any expenses of a personal nature, including souvenirs, phone calls, laundry, and tips.",
        ],
      },
      {
        sectionTitle: "Transport beyond Itinerary",
        content: [
          "Transportation or expenses beyond the specified itinerary or additional travel arrangements.",
        ],
      },
      {
        sectionTitle: "Medical Expenses",
        content: [
          "Costs related to pre-existing medical conditions or personal medication.",
        ],
      },
      {
        sectionTitle: "Changes in Itinerary",
        content: [
          "Any additional costs incurred due to changes in the itinerary caused by factors beyond the control of the trek organizers.",
        ],
      },
      {
        sectionTitle: "Unforeseen Circumstances",
        content: [
          "Expenses arising from unforeseen circumstances such as natural disasters, political unrest, or flight cancellations.",
        ],
      },
      {
        sectionTitle: "Additional Services",
        content: [
          "Any services or facilities not explicitly mentioned in the inclusions.",
        ],
      },
      {
        sectionTitle: "Porter Services",
        content: [
          "Personal porter services for carrying individual backpacks, if required.",
        ],
      },
      {
        sectionTitle: "Optional Activities",
        content: [
          "Costs associated with optional activities or excursions not included in the trek itinerary.",
        ],
      },
      {
        sectionTitle: "Government Fees",
        content: [
          "Any additional fees imposed by government authorities during the trek.",
        ],
      },
    ],
    youtubeLink: "",
    expeditionDate: [
      {
        id: 1,
        duration: "7 Days",
        startDate: "",
        endDate: "",
        bookedSeat: "",
        availableSeat: "",
      },
      {
        id: 2,
        duration: "7 Days",
        startDate: "",
        endDate: "",
        bookedSeat: "",
        availableSeat: "",
      },
      {
        id: 3,
        duration: "7 Days",
        startDate: "",
        endDate: "",
        bookedSeat: "",
        availableSeat: "",
      },
      {
        id: 4,
        duration: "7 Days",
        startDate: "",
        endDate: "",
        bookedSeat: "",
        availableSeat: "",
      },
      {
        id: 5,
        duration: "7 Days",
        startDate: "",
        endDate: "",
        bookedSeat: "",
        availableSeat: "",
      },
    ],
  },
  {
    individualExpeditionType: "padumToDarchaTrek",
    individualExpeditionName: "Padum To Darcha Trek",
    carouselData: expeditionTrekkingHeroSection,
    location: "Ladakh",
    duration: "13 Days",
    grade: "Advanced",
    maxAltitude: "5100 M or 16700 ft",
    bestSeason: "May, June, July, August, September and October",
    experienceRequired: "A participant should’ve trekked before.",
    mainExpeditionContent:
      "Embark on an unforgettable journey through the remote and rugged landscapes of Zanskar with our 10-day trekking expedition. This adventure takes you through picturesque villages, ancient monasteries, and challenging mountain passes, offering a rare glimpse into the rich culture and breathtaking natural beauty of the Himalayan region.Starting from the historic capital of Zanskar, Padum, each day brings new landscapes and cultural encounters as you traverse from Karsha to Pishu, Hanumil, Snertse, Lingshed, Singge La Base, Photoksar, Zanskar Sumdo, Chuminakpo, and finally concluding in Darcha in the Lahaul region. Immerse yourself in the tranquillity of high-altitude campsites, cross mountain passes, and connect with the warm hospitality of the local communities. This expedition promises not only a physical challenge but also a soul-stirring experience in one of the most awe-inspiring corners of the world.",
    aboutExpeditionExperienced:
      "Trek from Padum to Darcha with us, a team with over a decade of experience in the Indian Himalayas. We pride ourselves on upholding the highest safety standards throughout our operations. With a track record of more than 10 years, we've honed our skills and know these mountains like the back of our hands. Our commitment to safety goes beyond words—we have meticulous rescue plans in place, ready to be executed if the need arises. When you embark on an adventure with us, rest assured that you're in the hands of professionals who prioritize your safety above all else. Welcome to a team that's as prepared as it gets.",
    itineraryData: [
      {
        day: 1,
        title: "Arrive in Leh",
        details: [],
      },
      {
        day: 2,
        title: "Reach Padum",
        details: [],
      },
      {
        day: 3,
        title: "Padum to Karsha (4-5 hours trek)",
        details: [
          "Start the trek from Padum, the capital of Zanskar.",
          "Hike to Karsha Monastery, one of the largest monastic complexes in the region.",
          "Camp overnight near Karsha, enjoying the scenic surroundings.",
        ],
      },
      {
        day: 4,
        title: "Karsha to Pishu (5-6 hours trek)",
        details: [
          "Trek to Pishu, passing through picturesque landscapes and small villages.",
          "Explore Pishu village and interact with the locals.",
          "Camp overnight at Pishu.",
        ],
      },
      {
        day: 5,
        title: "Pishu to Hanumil (6-7 hours trek)",
        details: [
          "Continue the trek towards Hanumil, navigating through rocky terrain.",
          "Cross the Zanskar River on a bridge.",
          "Camp overnight at Hanumil, surrounded by mountains.",
        ],
      },
      {
        day: 6,
        title: "Hanumil to Snertse (7-8 hours trek)",
        details: [
          "Trek towards Snertse, crossing the Purfi La pass.",
          "Enjoy breathtaking views of the surrounding peaks.",
          "Camp overnight at Snertse.",
        ],
      },
      {
        day: 7,
        title: "Snertse to Lingshed (6-7 hours trek)",
        details: [
          "Hike to Lingshed, a remote village with a beautiful monastery.",
          "Explore Lingshed Monastery and immerse in the local culture.",
          "Camp overnight at Lingshed.",
        ],
      },
      {
        day: 8,
        title: "Lingshed to Singge La Base (6-7 hours trek)",
        details: [
          "Trek to the base of Singge La pass, ascending gradually.",
          "Camp overnight at Singge La Base, preparing for the pass crossing.",
        ],
      },
      {
        day: 9,
        title: "Singge La Base to Photoksar (8-9 hours trek)",
        details: [
          "Cross Singge La pass (5,090m) and descend towards Photoksar.",
          "Admire the stunning landscapes and mountain views.",
          "Camp overnight at Photoksar.",
        ],
      },
      {
        day: 10,
        title: "Photoksar to Zanskar Sumdo (6-7 hours trek)",
        details: [
          "Trek to Zanskar Sumdo, passing through high-altitude landscapes.",
          "Camp overnight at Zanskar Sumdo, relishing the tranquility.",
        ],
      },
      {
        day: 11,
        title: "Zanskar Sumdo to Chuminakpo (6-7 hours trek)",
        details: [
          "Continue the trek to Chuminakpo, crossing a suspension bridge.",
          "Enjoy the scenic beauty and peaceful surroundings.",
          "Camp overnight at Chuminakpo.",
        ],
      },
      {
        day: 12,
        title: "Chuminakpo to Darcha (7-8 hours trek)",
        details: [
          "Conclude the trek by hiking to Darcha in the Lahaul region.",
          "Drive to Keylong or continue to Manali, depending on your travel plans.",
        ],
      },
      {
        day: 13,
        title: "Departure",
        details: [],
      },
    ],
    inclusion: [
      {
        sectionTitle: "Accommodation",
        content: [
          "Comfortable lodging in Leh and Padum upon arrival and during the trek.",
          "Tented accommodation during the trek and at various campsites.",
        ],
      },
      {
        sectionTitle: "Meals",
        content: [
          "Nutritious and delicious meals throughout the expedition, designed to meet the energy demands of high-altitude trekking.",
          "Special provisions for dietary preferences and requirements.",
        ],
      },
      {
        sectionTitle: "Experienced Guides",
        content: [
          "Expert local guides well-versed in the terrain and conditions of the region.",
          "Assistance and guidance to ensure a safe and enjoyable trekking experience.",
        ],
      },
      {
        sectionTitle: "Transportation",
        content: [
          "Transport from Leh to the trek starting points and back, ensuring a seamless and comfortable journey.",
          "Well-maintained vehicles and experienced drivers for safe travel.",
        ],
      },
      {
        sectionTitle: "Camping Equipment",
        content: [
          "High-quality camping gear, including tents, sleeping bags, and other essentials for a comfortable night's rest.",
          "Technical gear distribution and fitting for the summit attempt.",
        ],
      },
      {
        sectionTitle: "Permits and Paperwork",
        content: ["Coordination of permits required for the trek."],
      },
      {
        sectionTitle: "Safety Measures",
        content: [
          "Emergency evacuation plans and access to experienced high-altitude rescue teams.",
          "First aid provisions and medical assistance throughout the expedition.",
        ],
      },
      {
        sectionTitle: "Expedition Certificate",
        content: [
          "Commemorative certificates recognizing the successful completion of the trek",
        ],
      },
      {
        sectionTitle: "Communication",
        content: [
          "Communication equipment for emergencies",
          "Regular updates and briefings",
        ],
      },
      {
        sectionTitle: "Environment and Leave No Trace",
        content: [
          "Commitment to environmentally responsible trekking",
          "Practices to minimize environmental impact",
        ],
      },
    ],
    exclusion: [
      {
        sectionTitle: "Travel Insurance",
        content: [
          "Personal travel insurance covering medical expenses, evacuation, and other unforeseen circumstances.",
        ],
      },
      {
        sectionTitle: "Personal Gear",
        content: [
          "Individual trekking equipment and personal clothing.",
          "Any additional gear required for specific preferences or comfort.",
        ],
      },
      {
        sectionTitle: "Meals in Leh",
        content: [
          "Meals in Leh on arrival and departure days.",
          "Expenses for additional meals beyond the provided trek meals.",
        ],
      },
      {
        sectionTitle: "Beverages",
        content: [
          "Expenses for additional beverages or snacks not included in the provided meals.",
        ],
      },
      {
        sectionTitle: "Personal Expenses",
        content: [
          "Any expenses of a personal nature, including souvenirs, phone calls, laundry, and tips.",
        ],
      },
      {
        sectionTitle: "Transport beyond Itinerary",
        content: [
          "Transportation or expenses beyond the specified itinerary or additional travel arrangements.",
        ],
      },
      {
        sectionTitle: "Medical Expenses",
        content: [
          "Costs related to pre-existing medical conditions or personal medication.",
        ],
      },
      {
        sectionTitle: "Changes in Itinerary",
        content: [
          "Any additional costs incurred due to changes in the itinerary caused by factors beyond the control of the trek organizers.",
        ],
      },
      {
        sectionTitle: "Unforeseen Circumstances",
        content: [
          "Expenses arising from unforeseen circumstances such as natural disasters, political unrest, or flight cancellations.",
        ],
      },
      {
        sectionTitle: "Additional Services",
        content: [
          "Any services or facilities not explicitly mentioned in the inclusions.",
        ],
      },
      {
        sectionTitle: "Porter Services",
        content: [
          "Personal porter services for carrying individual backpacks, if required.",
        ],
      },
      {
        sectionTitle: "Optional Activities",
        content: [
          "Costs associated with optional activities or excursions not included in the trek itinerary.",
        ],
      },
      {
        sectionTitle: "Government Fees",
        content: [
          "Any additional fees imposed by government authorities during the trek.",
        ],
      },
    ],
    youtubeLink: "",
    expeditionDate: [
      {
        id: 1,
        duration: "7 Days",
        startDate: "",
        endDate: "",
        bookedSeat: "",
        availableSeat: "",
      },
      {
        id: 2,
        duration: "7 Days",
        startDate: "",
        endDate: "",
        bookedSeat: "",
        availableSeat: "",
      },
      {
        id: 3,
        duration: "7 Days",
        startDate: "",
        endDate: "",
        bookedSeat: "",
        availableSeat: "",
      },
      {
        id: 4,
        duration: "7 Days",
        startDate: "",
        endDate: "",
        bookedSeat: "",
        availableSeat: "",
      },
      {
        id: 5,
        duration: "7 Days",
        startDate: "",
        endDate: "",
        bookedSeat: "",
        availableSeat: "",
      },
    ],
  },
  {
    individualExpeditionType: "lehToTsoMororiTrek",
    individualExpeditionName: "Leh To Tso Morori Trek",
    carouselData: expeditionTrekkingHeroSection,
    location: "Ladakh",
    duration: "10 Days",
    grade: "Advanced",
    maxAltitude: "3100 M or 11560 ft",
    bestSeason: "May, June, July, August, September and October",
    experienceRequired: "A participant should’ve trekked before.",
    mainExpeditionContent:
      "Embark on an exhilarating 10-day trek through the mesmerizing landscapes of Ladakh, India, with altitudes ranging from 3,500 to 4,900 meters. Begin in Leh, acclimatizing to the high altitude, exploring local attractions, and gearing up for the adventure. Traverse from Rumtse to Kyamar, witnessing scenic beauty and camping at 4,050 meters. Challenge yourself on day four with a trek to Tisaling, crossing the Mandalchan La pass at 4,900 meters, and continue to Pangunagu on day five. Day six leads to Nuruchan, crossing the Nuruchan La pass at 4,800 meters, and day seven brings you to Gyama. Trek to the pristine Tso Moriri Lake on day eight, camping at 4,595 meters, followed by a rest day to absorb the tranquil surroundings. Conclude the journey with a short hike to Karzok and a drive back to Leh, reflecting on the awe-inspiring beauty and cultural richness experienced along the way.",
    aboutExpeditionExperienced:
      "Trek from Leh to Tso Morori with us, a team with over a decade of experience in the Indian Himalayas. We pride ourselves on upholding the highest safety standards throughout our operations. With a track record of more than 10 years, we've honed our skills and know these mountains like the back of our hands. Our commitment to safety goes beyond words—we have meticulous rescue plans in place, ready to be executed if the need arises. When you embark on an adventure with us, rest assured that you're in the hands of professionals who prioritize your safety above all else. Welcome to a team that's as prepared as it gets.",
    itineraryData: [
      {
        day: 1,
        title: "Arrival in Leh - Acclimatization",
        details: [
          "Arrive in Leh and spend the day acclimatizing to the high altitude.",
          "Take it easy, rest, and hydrate to minimize the effects of altitude sickness.",
          "Explore Leh town in the evening and visit local attractions like Shanti Stupa or Leh Palace.",
          "Overnight stay in Leh.",
        ],
      },
      {
        day: 2,
        title: "Leh - Acclimatization and Gear Shopping",
        details: [
          "Continue acclimatizing in Leh.",
          "Visit the Leh market to shop for any personal trekking gear or supplies you may need.",
          "Attend a briefing session with your trekking guide, where you'll receive information about the trek ahead.",
          "Rest and hydrate for the upcoming journey.",
          "Overnight stay in Leh.",
        ],
      },
      {
        day: 3,
        title: "Leh to Rumtse and Trek to Kyamar",
        details: [
          "Drive from Leh to Rumtse (about 2-3 hours' drive).",
          "Start trekking from Rumtse to Kyamar, passing through scenic landscapes.",
          "Begin your trek with gradual ascents and enjoy the picturesque surroundings.",
          "Camp overnight at Kyamar.",
        ],
      },
      {
        day: 4,
        title: "Kyamar to Tisaling",
        details: [
          "Trek from Kyamar to Tisaling, crossing the Mandalchan La pass.",
          "Enjoy breathtaking views of the surrounding mountains.",
          "Camp overnight at Tisaling.",
        ],
      },
      {
        day: 5,
        title: "Tisaling to Pangunagu",
        details: [
          "Continue the trek to Pangunagu, crossing the Shibuk La pass.",
          "Descend into a beautiful valley and camp near Pangunagu.",
          "Embrace the serenity of the region.",
          "Camp overnight at Pangunagu.",
        ],
      },
      {
        day: 6,
        title: "Pangunagu to Nuruchan",
        details: [
          "Trek to Nuruchan, crossing the Nuruchan La pass.",
          "Witness stunning landscapes and high mountain peaks.",
          "Camp overnight at Nuruchan.",
        ],
      },
      {
        day: 7,
        title: "Nuruchan to Gyama",
        details: [
          "Hike to Gyama, passing through scenic landscapes and river streams.",
          "Enjoy the beauty of the region.",
          "Camp overnight at Gyama.",
        ],
      },
      {
        day: 8,
        title: "Gyama to Korzok (Tso Moriri Lake)",
        details: [
          "Trek towards Tso Moriri Lake and the village of Korzok.",
          "Enjoy breathtaking views of the lake and the surrounding mountains.",
          "Explore the Korzok Monastery and camp near Tso Moriri Lake.",
        ],
      },
      {
        day: 9,
        title: "Rest Day at Tso Moriri",
        details: [
          "Spend a day at Tso Moriri to rest and enjoy the peaceful ambiance.",
          "Explore the area around the lake and capture the beauty.",
          "Camp overnight at Tso Moriri.",
        ],
      },
      {
        day: 10,
        title: "Tso Moriri to Karzok and Drive to Leh",
        details: [
          "Conclude the trek with a short hike to Karzok.",
          "Drive back to Leh, reflecting on the beautiful landscapes and experiences.",
          "Arrive in Leh and spend the evening exploring the local markets or relaxing.",
        ],
      },
    ],
    inclusion: [
      {
        sectionTitle: "Accommodation",
        content: [
          "Comfortable lodging in Leh and Padum upon arrival and during the trek.",
          "Tented accommodation during the trek and at various campsites.",
        ],
      },
      {
        sectionTitle: "Meals",
        content: [
          "Nutritious and delicious meals throughout the expedition, designed to meet the energy demands of high-altitude trekking.",
          "Special provisions for dietary preferences and requirements.",
        ],
      },
      {
        sectionTitle: "Experienced Guides",
        content: [
          "Expert local guides well-versed in the terrain and conditions of the region.",
          "Assistance and guidance to ensure a safe and enjoyable trekking experience.",
        ],
      },
      {
        sectionTitle: "Transportation",
        content: [
          "Transport from Leh to the trek starting points and back, ensuring a seamless and comfortable journey.",
          "Well-maintained vehicles and experienced drivers for safe travel.",
        ],
      },
      {
        sectionTitle: "Camping Equipment",
        content: [
          "High-quality camping gear, including tents, sleeping bags, and other essentials for a comfortable night's rest.",
          "Technical gear distribution and fitting for the summit attempt.",
        ],
      },
      {
        sectionTitle: "Permits and Paperwork",
        content: ["Coordination of permits required for the trek."],
      },
      {
        sectionTitle: "Safety Measures",
        content: [
          "Emergency evacuation plans and access to experienced high-altitude rescue teams.",
          "First aid provisions and medical assistance throughout the expedition.",
        ],
      },
      {
        sectionTitle: "Expedition Certificate",
        content: [
          "Commemorative certificates recognizing the successful completion of the trek",
        ],
      },
      {
        sectionTitle: "Communication",
        content: [
          "Communication equipment for emergencies",
          "Regular updates and briefings",
        ],
      },
      {
        sectionTitle: "Environment and Leave No Trace",
        content: [
          "Commitment to environmentally responsible trekking",
          "Practices to minimize environmental impact",
        ],
      },
    ],
    exclusion: [
      {
        sectionTitle: "Travel Insurance",
        content: [
          "Personal travel insurance covering medical expenses, evacuation, and other unforeseen circumstances.",
        ],
      },
      {
        sectionTitle: "Personal Gear",
        content: [
          "Individual trekking equipment and personal clothing.",
          "Any additional gear required for specific preferences or comfort.",
        ],
      },
      {
        sectionTitle: "Meals in Leh",
        content: [
          "Meals in Leh on arrival and departure days.",
          "Expenses for additional meals beyond the provided trek meals.",
        ],
      },
      {
        sectionTitle: "Beverages",
        content: [
          "Expenses for additional beverages or snacks not included in the provided meals.",
        ],
      },
      {
        sectionTitle: "Personal Expenses",
        content: [
          "Any expenses of a personal nature, including souvenirs, phone calls, laundry, and tips.",
        ],
      },
      {
        sectionTitle: "Transport beyond Itinerary",
        content: [
          "Transportation or expenses beyond the specified itinerary or additional travel arrangements.",
        ],
      },
      {
        sectionTitle: "Medical Expenses",
        content: [
          "Costs related to pre-existing medical conditions or personal medication.",
        ],
      },
      {
        sectionTitle: "Changes in Itinerary",
        content: [
          "Any additional costs incurred due to changes in the itinerary caused by factors beyond the control of the trek organizers.",
        ],
      },
      {
        sectionTitle: "Unforeseen Circumstances",
        content: [
          "Expenses arising from unforeseen circumstances such as natural disasters, political unrest, or flight cancellations.",
        ],
      },
      {
        sectionTitle: "Additional Services",
        content: [
          "Any services or facilities not explicitly mentioned in the inclusions.",
        ],
      },
      {
        sectionTitle: "Porter Services",
        content: [
          "Personal porter services for carrying individual backpacks, if required.",
        ],
      },
      {
        sectionTitle: "Optional Activities",
        content: [
          "Costs associated with optional activities or excursions not included in the trek itinerary.",
        ],
      },
      {
        sectionTitle: "Government Fees",
        content: [
          "Any additional fees imposed by government authorities during the trek.",
        ],
      },
    ],
    youtubeLink: "",
    expeditionDate: [
      {
        id: 1,
        duration: "7 Days",
        startDate: "",
        endDate: "",
        bookedSeat: "",
        availableSeat: "",
      },
      {
        id: 2,
        duration: "7 Days",
        startDate: "",
        endDate: "",
        bookedSeat: "",
        availableSeat: "",
      },
      {
        id: 3,
        duration: "7 Days",
        startDate: "",
        endDate: "",
        bookedSeat: "",
        availableSeat: "",
      },
      {
        id: 4,
        duration: "7 Days",
        startDate: "",
        endDate: "",
        bookedSeat: "",
        availableSeat: "",
      },
      {
        id: 5,
        duration: "7 Days",
        startDate: "",
        endDate: "",
        bookedSeat: "",
        availableSeat: "",
      },
    ],
  },
  {
    individualExpeditionType: "nubraValleyTrek",
    individualExpeditionName: "Nubra Valley Trek",
    carouselData: expeditionTrekkingHeroSection,
    location: "Ladakh",
    duration: "6 Days",
    grade: "Intermediate",
    maxAltitude: "3048 M or 10430 ft",
    bestSeason: "May, June, July, August, September and October",
    experienceRequired: "A participant should’ve trekked before.",
    mainExpeditionContent:
      "Embark on an exhilarating journey through the rugged landscapes of Ladakh with our carefully curated trekking itinerary. Starting with an acclimatization day in Leh, you'll gradually adapt to the breathtaking high altitudes that characterize this Himalayan region. Immerse yourself in the rich cultural tapestry of Leh town, exploring iconic sites like Shanti Stupa and Leh Palace, while receiving crucial briefings from experienced trekking guides.The trek unfolds with a scenic drive to Phyang, setting the stage for an awe-inspiring adventure. Traverse from Phyang to Lasermo La Base, camping amidst the pristine wilderness. As you ascend, relish panoramic views of the Phyang Monastery and surrounding vistas. Journey through the dramatic landscapes of Nubra Valley, crossing the Lasermo La pass to reach Hundar Dok, where you'll camp beneath the star-lit sky. Trek onwards to Hunder, discovering the charm of the village and its famed dunes, and concluding with a memorable visit to Diskit Monastery.",
    aboutExpeditionExperienced:
      "Trek from Nubra Valley from Phyang to Hunder with us, a team with over a decade of experience in the Indian Himalayas. We pride ourselves on upholding the highest safety standards throughout our operations. With a track record of more than 10 years, we've honed our skills and know these mountains like the back of our hands. Our commitment to safety goes beyond words—we have meticulous rescue plans in place, ready to be executed if the need arises. When you embark on an adventure with us, rest assured that you're in the hands of professionals who prioritize your safety above all else. Welcome to a team that's as prepared as it gets.",
    itineraryData: [
      {
        day: 1,
        title: "Arrival in Leh - Acclimatization",
        details: [
          "Arrive in Leh.",
          "Acclimatize to high altitude.",
          "Rest and hydrate to minimize altitude sickness.",
          "Evening exploration of Leh town (Shanti Stupa, Leh Palace).",
          "Briefing session with trekking guide.",
          "Overnight stay in Leh.",
        ],
      },
      {
        day: 2,
        title: "Leh - Acclimatization and Gear Shopping",
        details: [
          "Continue acclimatizing in Leh.",
          "Visit Leh market for trekking gear and supplies.",
          "Briefing session with trekking guide.",
          "Rest and hydrate.",
          "Overnight stay in Leh.",
        ],
      },
      {
        day: 3,
        title: "Leh to Phyang and Trek to Lasermo La Base",
        details: [
          "Drive to Phyang (30 minutes).",
          "Trek from Phyang to Lasermo La Base (ascending).",
          "Enjoy views of Phyang Monastery and surroundings.",
          "Camp overnight at Lasermo La Base.",
        ],
      },
      {
        day: 4,
        title: "Lasermo La Base to Hundar Dok",
        details: [
          "Trek from Lasermo La Base to Hundar Dok.",
          "Cross Lasermo La pass.",
          "Descend into Nubra Valley with stunning views.",
          "Camp overnight at Hundar Dok.",
        ],
      },
      {
        day: 5,
        title: "Hundar Dok to Hunder",
        details: [
          "Trek to Hunder through picturesque landscapes.",
          "Explore Hunder village and visit sand dunes.",
          "Witness Bactrian camels in Nubra's cold desert.",
          "Camp overnight at Hunder.",
        ],
      },
      {
        day: 6,
        title: "Hunder to Diskit and Drive to Leh",
        details: [
          "Short hike to Diskit.",
          "Visit Diskit Monastery perched on a hilltop.",
          "Drive back to Leh, reflecting on landscapes and experiences.",
          "Arrive in Leh, explore local markets or relax in the evening.",
        ],
      },
    ],
    inclusion: [
      {
        sectionTitle: "Accommodation",
        content: [
          "Comfortable lodging in Leh upon arrival and during the trek.",
          "Tented accommodation during the trek and at various campsites.",
        ],
      },
      {
        sectionTitle: "Meals",
        content: [
          "Nutritious and delicious meals throughout the expedition, designed to meet the energy demands of high-altitude trekking.",
          "Special provisions for dietary preferences and requirements.",
        ],
      },
      {
        sectionTitle: "Experienced Guides",
        content: [
          "Expert local guides well-versed in the terrain and conditions of the region.",
          "Assistance and guidance to ensure a safe and enjoyable trekking experience.",
        ],
      },
      {
        sectionTitle: "Transportation",
        content: [
          "Transport from Leh to the trek starting points and back, ensuring a seamless and comfortable journey.",
          "Well-maintained vehicles and experienced drivers for safe travel.",
        ],
      },
      {
        sectionTitle: "Camping Equipment",
        content: [
          "High-quality camping gear, including tents, sleeping bags, and other essentials for a comfortable night's rest.",
          "Technical gear distribution and fitting for the summit attempt.",
        ],
      },
      {
        sectionTitle: "Permits and Paperwork",
        content: ["Coordination of permits required for the trek."],
      },
      {
        sectionTitle: "Safety Measures",
        content: [
          "Emergency evacuation plans and access to experienced high-altitude rescue teams.",
          "First aid provisions and medical assistance throughout the expedition.",
        ],
      },
      {
        sectionTitle: "Expedition Certificate",
        content: [
          "Commemorative certificates recognizing the successful completion of the trek",
        ],
      },
      {
        sectionTitle: "Communication",
        content: [
          "Communication equipment for emergencies",
          "Regular updates and briefings",
        ],
      },
      {
        sectionTitle: "Environment and Leave No Trace",
        content: [
          "Commitment to environmentally responsible trekking",
          "Practices to minimize environmental impact",
        ],
      },
    ],
    exclusion: [
      {
        sectionTitle: "Travel Insurance",
        content: [
          "Personal travel insurance covering medical expenses, evacuation, and other unforeseen circumstances.",
        ],
      },
      {
        sectionTitle: "Personal Gear",
        content: [
          "Individual trekking equipment and personal clothing.",
          "Any additional gear required for specific preferences or comfort.",
        ],
      },
      {
        sectionTitle: "Meals in Leh",
        content: [
          "Meals in Leh on arrival and departure days.",
          "Expenses for additional meals beyond the provided trek meals.",
        ],
      },
      {
        sectionTitle: "Beverages",
        content: [
          "Expenses for additional beverages or snacks not included in the provided meals.",
        ],
      },
      {
        sectionTitle: "Personal Expenses",
        content: [
          "Any expenses of a personal nature, including souvenirs, phone calls, laundry, and tips.",
        ],
      },
      {
        sectionTitle: "Transport beyond Itinerary",
        content: [
          "Transportation or expenses beyond the specified itinerary or additional travel arrangements.",
        ],
      },
      {
        sectionTitle: "Medical Expenses",
        content: [
          "Costs related to pre-existing medical conditions or personal medication.",
        ],
      },
      {
        sectionTitle: "Changes in Itinerary",
        content: [
          "Any additional costs incurred due to changes in the itinerary caused by factors beyond the control of the trek organizers.",
        ],
      },
      {
        sectionTitle: "Unforeseen Circumstances",
        content: [
          "Expenses arising from unforeseen circumstances such as natural disasters, political unrest, or flight cancellations.",
        ],
      },
      {
        sectionTitle: "Additional Services",
        content: [
          "Any services or facilities not explicitly mentioned in the inclusions.",
        ],
      },
      {
        sectionTitle: "Porter Services",
        content: [
          "Personal porter services for carrying individual backpacks, if required.",
        ],
      },
      {
        sectionTitle: "Optional Activities",
        content: [
          "Costs associated with optional activities or excursions not included in the trek itinerary.",
        ],
      },
      {
        sectionTitle: "Government Fees",
        content: [
          "Any additional fees imposed by government authorities during the trek.",
        ],
      },
    ],
    youtubeLink: "",
    expeditionDate: [
      {
        id: 1,
        duration: "7 Days",
        startDate: "",
        endDate: "",
        bookedSeat: "",
        availableSeat: "",
      },
      {
        id: 2,
        duration: "7 Days",
        startDate: "",
        endDate: "",
        bookedSeat: "",
        availableSeat: "",
      },
      {
        id: 3,
        duration: "7 Days",
        startDate: "",
        endDate: "",
        bookedSeat: "",
        availableSeat: "",
      },
      {
        id: 4,
        duration: "7 Days",
        startDate: "",
        endDate: "",
        bookedSeat: "",
        availableSeat: "",
      },
      {
        id: 5,
        duration: "7 Days",
        startDate: "",
        endDate: "",
        bookedSeat: "",
        availableSeat: "",
      },
    ],
  },
  {
    individualExpeditionType: "Kangyatse_2x6000M",
    individualExpeditionName: "Mt. Kangyatse 1 & 2",
    carouselData: expedition6000MHeroSection,
    location: "Markha Valley, Ladakh",
    duration: "14+1 Days",
    grade: "Intermediate",
    maxAltitude: "6401 M & 6250 M",
    bestSeason: "July, August, September and October",
    experienceRequired:
      "An enthusiast should have prior experience of 5000m/6000m climbs or multiple treks",
    mainExpeditionContent:
      "Kangyatse is a prominent mountain located in the Indian union territory of Ladakh. It is part of the Himalayas and specifically situated in the Markha Valley region. Kangyatse is a popular peak among mountaineers and trekkers due to its challenging ascent and stunning surrounding landscapes.Kangyatse has two main summits: Kangyatse I (6,401 meters or 21,000 feet) and Kangyatse II (6,250 meters or 20,505 feet). Climbers often aim to reach the higher Kangyatse I. The ascent involves technical climbing, and the trek to the base camp provides breathtaking views of the Himalayan range.Ladakh's harsh yet captivating terrain, with its high-altitude desert and snow-capped peaks, adds to the allure of Mount Kangyatse for adventure enthusiasts seeking a challenging climb and an immersive experience in the stunning landscapes of the region.",
    aboutExpeditionExperienced:
      "Climb Kangyatse 1 & 2 with our seasoned climbing team with over a decade of experience in the Indian Himalayas. We pride ourselves on upholding the highest safety standards throughout our operations. With a track record of more than 10 years, we've honed our skills and know these mountains like the back of our hands. Our commitment to safety goes beyond words—we have meticulous rescue plans in place, ready to be executed if the need arises. When you embark on an adventure with us, rest assured that you're in the hands of professionals who prioritize your safety above all else. Welcome to a team that's as prepared as it gets.",
    itineraryData: [
      {
        day: "1",
        title: "Leh (3524 M)",
        details: [
          "Arrival in Leh.",
          "Arrive in Leh and acclimate to the high altitude.",
          "Check-in at the hotel and take it easy to adjust to the surroundings.  ",
        ],
      },
      {
        day: "2",
        title: "Monastery Marvels and Stupa Serenity",
        details: [
          "Morning walk to Shanti Stupa.",
          "Visit Monastery, market and finish the shopping if you need anything for the expedition.",
          "Relax and unwind at the hotel.",
        ],
      },
      {
        day: "3",
        title:
          "Leh to Skiu drive and Trek To Markha (Drive 3-4 hours) (Trek 3-4 hours ) 3700M",
        details: [
          "Witness the mesmerising landscape along the journey.",
          "Leh to Skiu ( 3500M ) drive (2-3 hours ) and trek to Markha Upon reaching Skiu, you'll start your trek to Markha.",
          "It's essential to acclimatize to the altitude, so take breaks as needed and stay hydrated.",
        ],
      },
      {
        day: "4",
        title: "Markha to Hunkar (3968 M)",
        details: [
          "This day will involve trekking from Markha to Hunkar. Ensure you have adequate supplies, including water, snacks, and appropriate gear for the trek.",
          "Take breaks as needed, and enjoy the scenery along the way.",
        ],
      },
      {
        day: "5",
        title: "Hunkar to Base camp (5100 M)",
        details: [
          "You're moving from the Hunkar location to the base camp, which is situated at an altitude of 5100 meters.",
          "This may involve a trek or climb depending on the terrain and accessibility.",
        ],
      },
      {
        day: "6",
        title: "Rest and acclimatize walk in crampon point and back.",
        details: [
          "The acclimatization walk to Crampon Point and back helps your body adapt to the altitude and terrain, often involving wearing crampons for traction on icy or snowy surfaces.",
        ],
      },
      {
        day: "7",
        title: "Base camp rest and preparation for summit (6250 M)",
        details: [
          "Another rest day at the base camp to ensure your body is well-rested and prepared for the upcoming summit push.",
        ],
      },
      {
        day: "8",
        title: "Summit and back to base camp",
        details: [
          "Once you reach the summit, you'll celebrate your achievement before beginning the descent back to base camp.",
        ],
      },
      {
        day: "9",
        title: "Rest at base camp",
        details: [
          "Another rest day at the base camp to recover from the summit push.",
          "Rest is essential for allowing your body to recover from the physical exertion and stress of high-altitude climbing.",
        ],
      },
      {
        day: "10",
        title: "Base camp to summit camp (5700 M)",
        details: [
          "This is likely a preparatory step for either for another summit attempt.",
        ],
      },
      {
        day: "11",
        title: "From summit to back to base Camp",
        details: [
          "After reaching the summit, we'll begin our descent back to the base camp.",
          "The descent might take several hours depending on the terrain and weather conditions.",
          "We'll ensure a safe and steady return journey, taking in the breathtaking views along the way.",
        ],
      },
      {
        day: "12",
        title: "Rest at base camp",
        details: [
          "We'll take it easy, enjoy the tranquility of the surroundings, and perhaps engage in some light activities like stretching or short walks around the camp.",
        ],
      },
      {
        day: "13",
        title: "Base camp to Hunkar",
        details: [
          "This leg of the trek will offer diverse landscapes and stunning natural beauty.",
          "Our destination, Hunkar, promises spectacular views and a memorable camping experience under the starlit sky.",
        ],
      },
      {
        day: "14",
        title: "Hunkar to Markha and Leh drop",
        details: [
          "This segment of the journey will lead us through picturesque valleys and charming villages, providing insights into the local way of life.",
          "Upon reaching Markha, we'll wrap up our trek and prepare for the Leh drop-off.",
        ],
      },
      {
        day: "15",
        title: "Reserve Day: Contingency Planning",
        details: [
          "We've allocated an additional day as a contingency measure to address any unforeseen challenges that may arise during the expedition.",
        ],
      },
    ],
    inclusion: [
      {
        sectionTitle: "Accommodation",
        content: [
          "Comfortable lodging in Leh upon arrival and during the expedition.",
          "Tented accommodation during the trek and at various campsites.",
        ],
      },
      {
        sectionTitle: "Meals",
        content: [
          "Nutritious and delicious meals throughout the expedition, designed to meet the energy demands of high-altitude trekking.",
          "Special provisions for dietary preferences and requirements.",
        ],
      },
      {
        sectionTitle: "Experienced Guides",
        content: [
          "Expert local guides well-versed in the terrain and conditions of the region.",
          "Assistance and guidance to ensure a safe and enjoyable trekking experience.",
        ],
      },
      {
        sectionTitle: "Transportation",
        content: [
          "Transport from Leh to the trek starting points and back, ensuring a seamless and comfortable journey.",
          "Well-maintained vehicles and experienced drivers for safe travel.",
        ],
      },
      {
        sectionTitle: "Camping Equipment",
        content: [
          "High-quality camping gear, including tents, sleeping bags, and other essentials for a comfortable night's rest.",
          "Technical gear distribution and fitting for the summit attempt.",
        ],
      },
      {
        sectionTitle: "Permits and Paperwork",
        content: [
          "Assistance in completing necessary paperwork with the Indian Mountaineering Foundation (IMF).",
          "Coordination of permits required for the expedition.",
        ],
      },
      {
        sectionTitle: "Safety Measures",
        content: [
          "Emergency evacuation plans and access to experienced high-altitude rescue teams.",
          "First aid provisions and medical assistance throughout the expedition.",
        ],
      },
      {
        sectionTitle: "Expedition Certificate",
        content: [
          "Commemorative certificates recognizing the successful completion Expedition",
        ],
      },
      {
        sectionTitle: "Communication",
        content: [
          "Communication equipment for emergencies",
          "Regular updates and briefings",
        ],
      },
      {
        sectionTitle: "Environment and Leave No Trace",
        content: [
          "Commitment to environmentally responsible trekking",
          "Practices to minimize environmental impact",
        ],
      },
    ],
    exclusion: [
      {
        sectionTitle: "Travel Insurance",
        content: [
          "Personal travel insurance covering medical expenses, evacuation, and other unforeseen circumstances.",
        ],
      },
      {
        sectionTitle: "Personal Gear",
        content: [
          "Individual trekking equipment and personal clothing.",
          "Any additional gear required for specific preferences or comfort.",
        ],
      },
      {
        sectionTitle: "Meals in Leh",
        content: [
          "Meals in Leh on arrival and departure days.",
          "Expenses for additional meals beyond the provided trek meals.",
        ],
      },
      {
        sectionTitle: "Beverages",
        content: [
          "Expenses for additional beverages or snacks not included in the provided meals.",
        ],
      },
      {
        sectionTitle: "Personal Expenses",
        content: [
          "Any expenses of a personal nature, including souvenirs, phone calls, laundry, and tips.",
        ],
      },
      {
        sectionTitle: "Transport beyond Itinerary",
        content: [
          "Transportation or expenses beyond the specified itinerary or additional travel arrangements.",
        ],
      },
      {
        sectionTitle: "Medical Expenses",
        content: [
          "Costs related to pre-existing medical conditions or personal medication.",
        ],
      },
      {
        sectionTitle: "Changes in Itinerary",
        content: [
          "Any additional costs incurred due to changes in the itinerary caused by factors beyond the control of the trek organizers.",
        ],
      },
      {
        sectionTitle: "Unforeseen Circumstances",
        content: [
          "Expenses arising from unforeseen circumstances such as natural disasters, political unrest, or flight cancellations.",
        ],
      },
      {
        sectionTitle: "Additional Services",
        content: [
          "Any services or facilities not explicitly mentioned in the inclusions.",
        ],
      },
      {
        sectionTitle: "Porter Services",
        content: [
          "Personal porter services for carrying individual backpacks, if required.",
        ],
      },
      {
        sectionTitle: "Optional Activities",
        content: [
          "Costs associated with optional activities or excursions not included in the trek itinerary.",
        ],
      },
      {
        sectionTitle: "Government Fees",
        content: [
          "Any additional fees imposed by government authorities during the trek.",
        ],
      },
    ],
    youtubeLink: "",
    expeditionDate: [
      {
        id: 1,
        duration: "7 Days",
        startDate: "",
        endDate: "",
        bookedSeat: "",
        availableSeat: "",
      },
      {
        id: 2,
        duration: "7 Days",
        startDate: "",
        endDate: "",
        bookedSeat: "",
        availableSeat: "",
      },
      {
        id: 3,
        duration: "7 Days",
        startDate: "",
        endDate: "",
        bookedSeat: "",
        availableSeat: "",
      },
      {
        id: 4,
        duration: "7 Days",
        startDate: "",
        endDate: "",
        bookedSeat: "",
        availableSeat: "",
      },
      {
        id: 5,
        duration: "7 Days",
        startDate: "",
        endDate: "",
        bookedSeat: "",
        availableSeat: "",
      },
    ],
  },
  {
    individualExpeditionType: "MentokKangri_3x6000M",
    individualExpeditionName: "Mt. Mentok Kangri + Spangnak Ri + Kiagar Ri",
    carouselData: expedition6000MHeroSection,
    location: "Karzok, Ladakh",
    duration: "12 Days",
    grade: "Intermediate",
    maxAltitude: "6250 m or 20505 ft",
    bestSeason: "July, August, September and October",
    experienceRequired:
      "An enthusiast should have prior experience of 5000m/6000m climbs or multiple challenging treks.",
    mainExpeditionContent:
      "The Mentok Kangri massif is situated on the western shores of Tso Moriri in eastern Ladakh, nestled in the Rupsu valley. Its three peaks stand tall at 6250 m, 6150 m, and 6210 m, linked by a gentle ridge. Dominating the western edge of Tso Moriri is Mentok Kangri II, soaring to an impressive height of 6,250 m. Your journey begins with a drive to Karzok, located on the picturesque banks of Tso Moriri, followed by establishing a base near the Rupshu plateau, home to the Changpa nomads. The name 'Mentok' originates from a Tibetan term linked to a wildflower native to this area.This summit provides a relatively straightforward snow/ice climb, rewarding climbers with breathtaking panoramic views of Tso Moriri and neighboring peaks, including the prominent Chamser and Lungser Kangri at 6600 m. The trek to Mentok Kangri guides adventurers through remote valleys, traditional villages, and stunning hilltop monasteries, showcasing some of the most remarkable vistas of pristine landscapes.",
    aboutExpeditionExperienced:
      "Climb Mentok Kangri 2 with our seasoned climbing team with over a decade of experience in the Indian Himalayas. We pride ourselves on upholding the highest safety standards throughout our operations. With a track record of more than 10 years, we've honed our skills and know these mountains like the back of our hands. Our commitment to safety goes beyond words—we have meticulous rescue plans in place, ready to be executed if the need arises. When you embark on an adventure with us, rest assured that you're in the hands of professionals who prioritize your safety above all else. Welcome to a team that's as prepared as it gets.",
    itineraryData: [
      {
        day: "1-2",
        title: "Leh (3524 M)",
        details: [
          "Arrival in Leh.",
          "Arrive in Leh and acclimate to the high altitude.",
          "Check-in at the hotel and take it easy to adjust to the surroundings.",
        ],
      },
      {
        day: "3",
        title: "Leh to so kar (4500 M) drive (4-5 hours)",
        details: [
          "Depart from Leh and embark on a picturesque journey towards Tso Kar, known for its stunning saltwater lake and surrounding wilderness.",
          "Along the way, marvel at the rugged beauty of the Ladakh region as you traverse through dramatic landscapes.",
        ],
      },
      {
        day: "4",
        title: "Tso kar to KIAGAR RI base camp (5500 M)",
        details: [
          "Continue your adventure as you make your way from Tso Kar to the Kiagar Ri Base Camp.",
          "Set against a backdrop of towering peaks, the base camp provides a serene setting for the upcoming ascent.",
        ],
      },
      {
        day: "5",
        title: "Summit KIAGAR RI and return to SPANGNAK RI base camp (5800 M)",
        details: [
          "Rise early to tackle the summit of Kiagar Ri, a thrilling climb that rewards you with panoramic views of the surrounding Himalayan peaks.",
          "After conquering the summit, descend to the Spangnak Ri Base Camp, where you can rest and recharge for the next challenge.",
        ],
      },
      {
        day: "6",
        title: "Summit SPANGNAK RI and back to KORZOK",
        details: [
          "Today, take on the ascent of Spangnak Ri, another awe-inspiring peak that offers unparalleled vistas of the Ladakh landscape.",
          "After reaching the summit, descend to Korzok, a tranquil village nestled along the shores of Tso Moriri Lake.",
        ],
      },
      {
        day: "7",
        title: "Rest day at KORZOK base camp",
        details: [
          "Enjoy a well-deserved rest day in Korzok, where you can immerse yourself in the local culture, explore the village, or simply relax and take in the breathtaking scenery.",
        ],
      },
      {
        day: "8",
        title: "KORZOK to MENTOK KANGAR RI base camp (5500 M)",
        details: [
          "Set off from Korzok and journey to the Mentok Kangri Base Camp, where you'll prepare for the upcoming summit bid.",
          "Take in the serene surroundings and acclimatize to the altitude.",
        ],
      },
      {
        day: "9",
        title: "Rest and acclimatise to base camp.",
        details: [
          "Spend the day resting and acclimatizing at the base camp, ensuring that you're fully prepared for the challenging ascent ahead.",
        ],
      },
      {
        day: "10",
        title: "Summit MENTOK KANGAR RI and return to base camp",
        details: [
          "Today is the day you've been preparing for - summit day! Embark on the exhilarating climb to the peak of Mt.",
          "Mentok Kangri, where you'll be rewarded with breathtaking views from the summit.",
          "After celebrating your achievement, descend safely back to the base camp.",
        ],
      },
      {
        day: "11",
        title: "Base camp to KORZOK",
        details: [
          "Bid farewell to the base camp as you make your way back to Korzok, reflecting on the unforgettable experiences and memories you've made in the mountains.",
        ],
      },
      {
        day: "12",
        title: "KORZOK to leh",
        details: [
          "Conclude your adventure with a scenic drive back to Leh, where you can reminisce about your incredible journey through the rugged terrain of Ladakh.",
          "Depart with a sense of accomplishment and a newfound appreciation for the majestic Himalayas.",
        ],
      },
    ],
    inclusion: [
      {
        sectionTitle: "Accommodation",
        content: [
          "Comfortable lodging in Leh upon arrival and during the expedition.",
          "Tented accommodation during the trek and at various campsites.",
        ],
      },
      {
        sectionTitle: "Meals",
        content: [
          "Nutritious and delicious meals throughout the expedition, designed to meet the energy demands of high-altitude trekking.",
          "Special provisions for dietary preferences and requirements.",
        ],
      },
      {
        sectionTitle: "Experienced Guides",
        content: [
          "Expert local guides well-versed in the terrain and conditions of the region.",
          "Assistance and guidance to ensure a safe and enjoyable trekking experience.",
        ],
      },
      {
        sectionTitle: "Transportation",
        content: [
          "Transport from Leh to the trek starting points and back, ensuring a seamless and comfortable journey.",
          "Well-maintained vehicles and experienced drivers for safe travel.",
        ],
      },
      {
        sectionTitle: "Camping Equipment",
        content: [
          "High-quality camping gear, including tents, sleeping bags, and other essentials for a comfortable night's rest.",
          "Technical gear distribution and fitting for the summit attempt.",
        ],
      },
      {
        sectionTitle: "Permits and Paperwork",
        content: [
          "Assistance in completing necessary paperwork with the Indian Mountaineering Foundation (IMF).",
          "Coordination of permits required for the expedition.",
        ],
      },
      {
        sectionTitle: "Safety Measures",
        content: [
          "Emergency evacuation plans and access to experienced high-altitude rescue teams.",
          "First aid provisions and medical assistance throughout the expedition.",
        ],
      },
      {
        sectionTitle: "Expedition Certificate",
        content: [
          "Commemorative certificates recognizing the successful completion Expedition",
        ],
      },
      {
        sectionTitle: "Communication",
        content: [
          "Communication equipment for emergencies",
          "Regular updates and briefings",
        ],
      },
      {
        sectionTitle: "Environment and Leave No Trace",
        content: [
          "Commitment to environmentally responsible trekking",
          "Practices to minimize environmental impact",
        ],
      },
    ],
    exclusion: [
      {
        sectionTitle: "Travel Insurance",
        content: [
          "Personal travel insurance covering medical expenses, evacuation, and other unforeseen circumstances.",
        ],
      },
      {
        sectionTitle: "Personal Gear",
        content: [
          "Individual trekking equipment and personal clothing.",
          "Any additional gear required for specific preferences or comfort.",
        ],
      },
      {
        sectionTitle: "Meals in Manali",
        content: [
          "Meals in Leh on arrival and departure days.",
          "Expenses for additional meals beyond the provided trek meals.",
        ],
      },
      {
        sectionTitle: "Beverages",
        content: [
          "Expenses for additional beverages or snacks not included in the provided meals.",
        ],
      },
      {
        sectionTitle: "Personal Expenses",
        content: [
          "Any expenses of a personal nature, including souvenirs, phone calls, laundry, and tips.",
        ],
      },
      {
        sectionTitle: "Transport beyond Itinerary",
        content: [
          "Transportation or expenses beyond the specified itinerary or additional travel arrangements.",
        ],
      },
      {
        sectionTitle: "Medical Expenses",
        content: [
          "Costs related to pre-existing medical conditions or personal medication.",
        ],
      },
      {
        sectionTitle: "Changes in Itinerary",
        content: [
          "Any additional costs incurred due to changes in the itinerary caused by factors beyond the control of the trek organizers.",
        ],
      },
      {
        sectionTitle: "Unforeseen Circumstances",
        content: [
          "Expenses arising from unforeseen circumstances such as natural disasters, political unrest, or flight cancellations.",
        ],
      },
      {
        sectionTitle: "Additional Services",
        content: [
          "Any services or facilities not explicitly mentioned in the inclusions.",
        ],
      },
      {
        sectionTitle: "Porter Services",
        content: [
          "Personal porter services for carrying individual backpacks, if required.",
        ],
      },
      {
        sectionTitle: "Optional Activities",
        content: [
          "Costs associated with optional activities or excursions not included in the trek itinerary.",
        ],
      },
      {
        sectionTitle: "Government Fees",
        content: [
          "Any additional fees imposed by government authorities during the trek.",
        ],
      },
    ],
    youtubeLink:
      "https://www.youtube.com/embed/k3ArNz91cH0?si=X0ANwJ1PE8hKxXdX",
    expeditionDate: [
      {
        id: 1,
        duration: "7 Days",
        startDate: "",
        endDate: "",
        bookedSeat: "",
        availableSeat: "",
      },
      {
        id: 2,
        duration: "7 Days",
        startDate: "",
        endDate: "",
        bookedSeat: "",
        availableSeat: "",
      },
      {
        id: 3,
        duration: "7 Days",
        startDate: "",
        endDate: "",
        bookedSeat: "",
        availableSeat: "",
      },
      {
        id: 4,
        duration: "7 Days",
        startDate: "",
        endDate: "",
        bookedSeat: "",
        availableSeat: "",
      },
      {
        id: 5,
        duration: "7 Days",
        startDate: "",
        endDate: "",
        bookedSeat: "",
        availableSeat: "",
      },
    ],
  },
  {
    individualExpeditionType: "kiagarRi_3x6000M",
    individualExpeditionName: "Mt. Kiagar Ri + Mt. P6250 + Mt. 6270",
    carouselData: expedition6000MHeroSection,
    location: "Karzog, Ladakh",
    duration: "7 Days",
    grade: "Beginners",
    maxAltitude: "6100 m or 20013 ft",
    bestSeason: "July, August, and September",
    experienceRequired:
      "An enthusiast should have trekked between 4000 m to 5000 m at least once.",
    mainExpeditionContent:
      "Nestled in the breathtaking landscapes of Ladakh, the Kiagar Ri peak stands as an awe-inspiring mountain complimenting to the majestic beauty of the Himalayas. Rising to an elevation of 6,100 meters, this formidable summit offers adventurers a challenging yet rewarding trekking experience. The journey begins in Leh, where travelers acclimate to the high-altitude environment and explore the cultural gems of the region, including Shanti Stupa and Leh Palace.Exploring a high-altitude summit holds a unique charm, particularly when it soars to elevations typically tackled by seasoned mountaineers. Kiagar Ri stands out as one such peak that offers an enticing trekking experience. Unlike the intricate ascents required for conventional peaks, conquering this colossal mountain doesn't necessitate advanced mountaineering expertise—only a solid acclimatization is key.As the expedition unfolds, participants embark on a scenic drive to Karzok Village, located near the pristine Tso Moriri Lake setting the stage for an unforgettable adventure. The trek then takes an exhilarating turn as enthusiasts traverse from Karzok to the Kiagar Ri Base Camp, situated amidst the rugged Himalayan terrain. The final ascent to the summit promises panoramic views, making the Kiagar Ri peak expedition a fusion of cultural immersion, high-altitude acclimatization, and the thrill of conquering one of Ladakh's iconic peaks.",
    aboutExpeditionExperienced:
      "Climb Kiagar Ri with our seasoned climbing team with over a decade of experience in the Indian Himalayas. We pride ourselves on upholding the highest safety standards throughout our operations. With a track record of more than 10 years, we've honed our skills and know these mountains like the back of our hands. Our commitment to safety goes beyond words—we have meticulous rescue plans in place, ready to be executed if the need arises. When you embark on an adventure with us, rest assured that you're in the hands of professionals who prioritize your safety above all else. Welcome to a team that's as prepared as it gets.",
    itineraryData: [
      {
        day: 1,
        title: "Arrival in Leh",
        details: [
          "Arrive in Leh. ( Altitude - 3500 M).",
          "Check into your hotel, where you can relax and begin acclimate to the high altitude.",
          "Take a stroll to get acquainted with the charming town of Leh.",
        ],
      },
      {
        day: 2,
        title: "Leh to Sumdo ( Altitude - 4200 M)",
        details: [
          "Start your journey in Leh, the capital of Ladakh.",
          "Head southeast on the Leh-Manali Highway (NH3) toward the destination of Manali.",
          "Pass through villages and towns such as Upshi, Chumathang, and Tso Kar to reach Sumdo.",
          "Relax for the night.",
        ],
      },
      {
        day: 3,
        title: "Sumdo to Tso Kar & Puga Hot Springs",
        details: [
          "Tso Kar is high-altitude saltwater lake ocated in the Rupshu Plateau in the southern part of the ladakh, india.",
          "It is situated at an altitude of about 4,530 M (14,862 Ft.) above the sea level.",
          "The lake is known for its scenic beauty and is surrounded by barren mountains.",
          "Puga valley is in the Changthang region of ladakh, approximately 25 kilometers southeast of famous Tso Moriri Lake.",
          "THe hot springs are situated at an altitude of around 4,400 meters above the sea level.",
        ],
      },
      {
        day: 4,
        title: "Summit kiager Ri and stay at Tso Moriri",
        details: [
          "Reach for the heights as you conquer the summit at 6,100M",
          "Return to the base camp and drive back to your comfortable homestay in Tso Morori.",
        ],
      },
      {
        day: 5,
        title: "Rest at Tso Moriri",
        details: [
          "Recover from the climb and relax. Eat good food and walk on the frozen lake.",
        ],
      },
      {
        day: 6,
        title: "Summit P6250, 6270M & Back to Sumdo",
        details: [
          "Start for the summit in the early hours.",
          "After the summit, return to the base camp and reach Sumdo.",
        ],
      },
      {
        day: 7,
        title: "Return to Leh",
        details: [
          "Conclude your high-altitude journey with a scenic drive back to Leh.",
          "Reflect on your awe-inspiring mountain experiences as you return to the town.",
        ],
      },
    ],
    inclusion: [
      {
        sectionTitle: "Accommodation",
        content: [
          "Comfortable lodging in Leh upon arrival and during the expedition.",
          "Tented accommodation during the trek and at various campsites.",
        ],
      },
      {
        sectionTitle: "Meals",
        content: [
          "Nutritious and delicious meals throughout the expedition, designed to meet the energy demands of high-altitude trekking.",
          "Special provisions for dietary preferences and requirements.",
        ],
      },
      {
        sectionTitle: "Experienced Guides",
        content: [
          "Expert local guides well-versed in the terrain and conditions of the region.",
          "Assistance and guidance to ensure a safe and enjoyable trekking experience.",
        ],
      },
      {
        sectionTitle: "Transportation",
        content: [
          "Transport from Leh to the trek starting points and back, ensuring a seamless and comfortable journey.",
          "Well-maintained vehicles and experienced drivers for safe travel.",
        ],
      },
      {
        sectionTitle: "Camping Equipment",
        content: [
          "High-quality camping gear, including tents, sleeping bags, and other essentials for a comfortable night's rest.",
          "Technical gear distribution and fitting for the summit attempt.",
        ],
      },
      {
        sectionTitle: "Permits and Paperwork",
        content: [
          "Assistance in completing necessary paperwork with the Indian Mountaineering Foundation (IMF).",
          "Coordination of permits required for the expedition.",
        ],
      },
      {
        sectionTitle: "Safety Measures",
        content: [
          "Emergency evacuation plans and access to experienced high-altitude rescue teams.",
          "First aid provisions and medical assistance throughout the expedition.",
        ],
      },
      {
        sectionTitle: "Expedition Certificate",
        content: [
          "Commemorative certificates recognizing the successful completion Expedition",
        ],
      },
      {
        sectionTitle: "Communication",
        content: [
          "Communication equipment for emergencies",
          "Regular updates and briefings",
        ],
      },
      {
        sectionTitle: "Environment and Leave No Trace",
        content: [
          "Commitment to environmentally responsible trekking",
          "Practices to minimize environmental impact",
        ],
      },
    ],
    exclusion: [
      {
        sectionTitle: "Travel Insurance",
        content: [
          "Personal travel insurance covering medical expenses, evacuation, and other unforeseen circumstances.",
        ],
      },
      {
        sectionTitle: "Personal Gear",
        content: [
          "Individual trekking equipment and personal clothing.",
          "Any additional gear required for specific preferences or comfort.",
        ],
      },
      {
        sectionTitle: "Meals in Leh",
        content: [
          "Meals in Leh on arrival and departure days.",
          "Expenses for additional meals beyond the provided trek meals.",
        ],
      },
      {
        sectionTitle: "Beverages",
        content: [
          "Expenses for additional beverages or snacks not included in the provided meals.",
        ],
      },
      {
        sectionTitle: "Personal Expenses",
        content: [
          "Any expenses of a personal nature, including souvenirs, phone calls, laundry, and tips.",
        ],
      },
      {
        sectionTitle: "Transport beyond Itinerary",
        content: [
          "Transportation or expenses beyond the specified itinerary or additional travel arrangements.",
        ],
      },
      {
        sectionTitle: "Medical Expenses",
        content: [
          "Costs related to pre-existing medical conditions or personal medication.",
        ],
      },
      {
        sectionTitle: "Changes in Itinerary",
        content: [
          "Any additional costs incurred due to changes in the itinerary caused by factors beyond the control of the trek organizers.",
        ],
      },
      {
        sectionTitle: "Unforeseen Circumstances",
        content: [
          "Expenses arising from unforeseen circumstances such as natural disasters, political unrest, or flight cancellations.",
        ],
      },
      {
        sectionTitle: "Additional Services",
        content: [
          "Any services or facilities not explicitly mentioned in the inclusions.",
        ],
      },
      {
        sectionTitle: "Porter Services",
        content: [
          "Personal porter services for carrying individual backpacks, if required.",
        ],
      },
      {
        sectionTitle: "Optional Activities",
        content: [
          "Costs associated with optional activities or excursions not included in the trek itinerary.",
        ],
      },
      {
        sectionTitle: "Government Fees",
        content: [
          "Any additional fees imposed by government authorities during the trek.",
        ],
      },
    ],
    youtubeLink:
      "https://www.youtube.com/embed/gINtMRf2QvI?si=PdGnHxECKwd8zSrG",
    expeditionDate: [
      {
        id: 1,
        duration: "7 Days",
        startDate: "",
        endDate: "",
        bookedSeat: "",
        availableSeat: "",
      },
      {
        id: 2,
        duration: "7 Days",
        startDate: "",
        endDate: "",
        bookedSeat: "",
        availableSeat: "",
      },
      {
        id: 3,
        duration: "7 Days",
        startDate: "",
        endDate: "",
        bookedSeat: "",
        availableSeat: "",
      },
      {
        id: 4,
        duration: "7 Days",
        startDate: "",
        endDate: "",
        bookedSeat: "",
        availableSeat: "",
      },
      {
        id: 5,
        duration: "7 Days",
        startDate: "",
        endDate: "",
        bookedSeat: "",
        availableSeat: "",
      },
    ],
  },
];
