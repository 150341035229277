import friendshipPeak from "../../assets/expeditions/5000 M/Friendship_Peak.webp";
import hanumanTibba from "../../assets/expeditions/5000 M/Hanuman_Tibba.webp";
import shitidhar from "../../assets/expeditions/5000 M/Shitidhar.webp";
import ladakhi from "../../assets/expeditions/5000 M/Ladakhi.webp";
import kanamo from "../../assets/expeditions/5000 M/Kanamo.webp";
import p6250 from "../../assets/expeditions/5000 M/P6250.webp";

import yunamPeak from "../../assets/expeditions/6000 M/Yunum_Peak.webp";
import deoTibba from "../../assets/expeditions/6000 M/Deo_Tibba.webp";
import ladyOfKeylong from "../../assets/expeditions/6000 M/Lady_Of_Keylong.webp";
import kangyatse1 from "../../assets/expeditions/6000 M/Kangyatse_1.webp";
import kangyatse2 from "../../assets/expeditions/6000 M/Kangyatse_2.webp";
import utKangri from "../../assets/expeditions/6000 M/Ut_Kangri.webp";
import mentokKangri from "../../assets/expeditions/6000 M/Mentok_Kangri.webp";
import chauChauKangNilda from "../../assets/expeditions/6000 M/Chau_Chau_Kang_Nilda.webp";
import leoPurgyil from "../../assets/expeditions/6000 M/Leo_Purgyil.webp";
import manirang from "../../assets/expeditions/6000 M/Manirang.webp";
import kiagarRi from "../../assets/expeditions/6000 M/Kiagar_Ri.webp";
import spangnakRi from "../../assets/expeditions/6000 M/Spangnak_Ri.webp";
import chamserKangri from "../../assets/expeditions/6000 M/Chamaer_Kangri.webp";
import lungserKangri from "../../assets/expeditions/6000 M/Lungser_Kangri.webp";
import chalung from "../../assets/expeditions/6000 M/Chalung.webp";
import shukule from "../../assets/expeditions/6000 M/Shukule.webp";
import lagpoNorth from "../../assets/expeditions/6000 M/Lagpo.webp";
import cb13 from "../../assets/expeditions/6000 M/Cb_13.webp";
import menthosa from "../../assets/expeditions/6000 M/Menthosa.webp";

import kun from "../../assets/expeditions/7000 M/Kun.webp";
import nun from "../../assets/expeditions/7000 M/Nun.webp";

import papsura from "../../assets/expeditions/Pure Adrenaline/Papsura.webp";
import reoPurgyil from "../../assets/expeditions/Pure Adrenaline/Reo_Purgyil.webp";
import indrasan from "../../assets/expeditions/Pure Adrenaline/Indrasan.webp";
import mukerBeh from "../../assets/expeditions/Pure Adrenaline/Maker_Beh.webp";

import pinParvatiPassTrek from "../../assets/trekkings/Pin_parvati_pass_trek.webp";
import parangLaTrek from "../../assets/trekkings/Parang_la_trek.webp";
import audenColTrek from "../../assets/trekkings/Auden_col_trek.webp";
import kalindiKhalTrek from "../../assets/trekkings/Kalindi_khal_trek.webp";
import kangLaTrek from "../../assets/trekkings/Kang_la_trek.webp";
import goechaLaTrek from "../../assets/trekkings/Goecha_la_trek.webp";
import baraBangalTrek from "../../assets/trekkings/Bara_bangal_trek.webp";
import lehLamayuruTrek from "../../assets/trekkings/Leh_lamayuru_trek.webp";
import markhaValleyTrek from "../../assets/trekkings/Markha_valley_trek.webp";
import spitukToMathoTrek from "../../assets/trekkings/Spituk_to_mathi_trek.webp";
import padumToDarchaTrek from "../../assets/trekkings/Padum_to_darcha_trek.webp";
import lehToTsoMororiTrek from "../../assets/trekkings/Leh_to_Tso_morori_trek.webp";
import nubraValleyTrek from "../../assets/trekkings/Nubra_valley_trek.webp";

import spitiValleyOverlanding from "../../assets/adventure-tours/Spiti_valley_overlanding.webp";
import ladaakhOverlanding from "../../assets/adventure-tours/Ladakh_overlanding.webp";
import zanskarOverlanding from "../../assets/adventure-tours/Zankar_overlanding.webp";
import sachPasKishtwarOverlanding from "../../assets/adventure-tours/Sach_pas_listwar_overlanding.webp";

export const expedition5000M = [
  {
    id: 0,
    img: friendshipPeak,
    title: "Friendship Peak",
    link: "/expedition/friendshipPeak",
    groupBy: "5289 M",
    days: "7 days",
    dates: ["03 Jan, 2023", "10 Feb, 2024", "25 Mar, 2024", "05 Apr, 2024"],
  },
  {
    id: 1,
    img: hanumanTibba,
    title: "Hanuman Tibba",
    link: "/expedition/hanumanTibba",
    groupBy: "5892 M",
    days: "13 days",
    dates: ["15 Jan, 2024", "22 Feb, 2024", "07 Mar, 2023", "12 Apr, 2024"],
  },
  {
    id: 2,
    img: shitidhar,
    title: "Shitidhar",
    link: "/expedition/shitidhar",
    groupBy: "5250 M",
    days: "9 days",
    dates: ["08 Jan, 2024", "19 Feb, 2024", "29 Mar, 2024", "10 Apr, 2024"],
  },
  {
    id: 3,
    img: ladakhi,
    title: "Ladakhi",
    link: "/expedition/ladakhi",
    groupBy: "5577 M",
    days: "8 days",
    dates: ["01 Jan, 2024", "14 Feb, 2024", "23 Mar, 2023", "30 Apr, 2024"],
  },
  {
    id: 4,
    img: kanamo,
    title: "Kanamo",
    link: "/expedition/kanamo",
    groupBy: "5974 M",
    days: "8 days",
    dates: ["27 Jan, 2024", "03 Feb, 2024", "18 Mar, 2024", "01 Apr, 2024"],
  },
];

export const expedition6000M = [
  {
    id: 0,
    img: yunamPeak,
    title: "Yunam Peak",
    link: "/expedition/yunamPeak",
    groupBy: "6150 M",
    days: "9 days",
    dates: ["03 Jan, 2023", "10 Feb, 2024", "25 Mar, 2024", "05 Apr, 2024"],
  },
  {
    id: 1,
    img: deoTibba,
    title: "Deo Tibba",
    link: "/expedition/deoTibba",
    groupBy: "6001 M",
    days: "15 days",
    dates: ["15 Jan, 2024", "22 Feb, 2024", "07 Mar, 2023", "12 Apr, 2024"],
  },
  {
    id: 2,
    img: ladyOfKeylong,
    title: "Lady of Keylong",
    link: "/expedition/ladyOfKeylong",
    groupBy: "6061 M",
    days: "11 days",
    dates: ["08 Jan, 2024", "19 Feb, 2024", "29 Mar, 2024", "10 Apr, 2024"],
  },
  {
    id: 3,
    img: kangyatse1,
    title: "Kangyatse 1",
    link: "/expedition/kangyatse1",
    groupBy: "6401 M",
    days: "13 days",
    dates: ["01 Jan, 2024", "14 Feb, 2024", "23 Mar, 2023", "30 Apr, 2024"],
  },
  {
    id: 4,
    img: kangyatse2,
    title: "Kangyatse 2",
    link: "/expedition/kangyatse2",
    groupBy: "6250 M",
    days: "11 days",
    dates: ["27 Jan, 2024", "03 Feb, 2024", "18 Mar, 2024", "01 Apr, 2024"],
  },
  {
    id: 5,
    img: utKangri,
    title: "UT Kangri",
    link: "/expedition/utKangri",
    groupBy: "6070 M",
    days: "8 days",
    dates: ["09 Jan, 2024", "20 Feb, 2024", "28 Mar, 2024", "11 Apr, 2024"],
  },
  {
    id: 6,
    img: mentokKangri,
    title: "Mentok Kangri",
    link: "/expedition/mentokKangri",
    groupBy: "6250 M",
    days: "11 days",
    dates: ["15 Jan, 2023", "14 Feb, 2024", "17 Mar, 2024", "30 Apr, 2024"],
  },
  {
    id: 7,
    img: chauChauKangNilda,
    title: "Chau Chau Kang Nilda",
    link: "/expedition/chauChauKangNilda",
    groupBy: "6303 M",
    days: "9 days",
    dates: ["30 Jan, 2024", "1 Feb, 2024", "07 Mar, 2023", "26 Apr, 2024"],
  },
  {
    id: 8,
    img: leoPurgyil,
    title: "Leo Purgyil",
    link: "/expedition/leoPurgyil",
    groupBy: "6793 M",
    days: "10 days",
    dates: ["08 Jan, 2024", "13 Feb, 2024", "29 Mar, 2024", "10 Apr, 2024"],
  },
  {
    id: 9,
    img: manirang,
    title: "Manirang",
    link: "/expedition/manirang",
    groupBy: "6593 M",
    days: "12 days",
    dates: ["01 Jan, 2024", "14 Feb, 2024", "23 Mar, 2023", "30 Apr, 2024"],
  },
  {
    id: 10,
    img: kiagarRi,
    title: "Kiagar Ri",
    link: "/expedition/kiagarRi",
    groupBy: "6100 M",
    days: "7 days",
    dates: ["27 Jan, 2024", "03 Feb, 2024", "18 Mar, 2024", "01 Apr, 2024"],
  },
  {
    id: 11,
    img: spangnakRi,
    title: "Spangnak Ri",
    link: "/expedition/spangnakRi",
    groupBy: "6393 M",
    days: "8 days",
    dates: ["09 Jan, 2024", "20 Feb, 2024", "28 Mar, 2024", "11 Apr, 2024"],
  },
  {
    id: 12,
    img: chamserKangri,
    title: "Chamser Kangri",
    link: "/expedition/chamserKangri",
    groupBy: "6620 M",
    days: "9 days",
    dates: ["09 Jan, 2024", "20 Feb, 2024", "28 Mar, 2024", "11 Apr, 2024"],
  },
  {
    id: 13,
    img: lungserKangri,
    title: "Lungser Kangri",
    link: "/expedition/lungserKangri",
    groupBy: "6666 M",
    days: "9 days",
    dates: ["01 Jan, 2024", "14 Feb, 2024", "23 Mar, 2023", "30 Apr, 2024"],
  },
  {
    id: 14,
    img: chalung,
    title: "Chalung",
    link: "/expedition/chalung",
    groupBy: "6446 M",
    days: "10 days",
    dates: ["08 Jan, 2024", "19 Feb, 2024", "29 Mar, 2024", "10 Apr, 2024"],
  },
  {
    id: 15,
    img: shukule,
    title: "Shukule",
    link: "/expedition/shukule",
    groupBy: "6508 M",
    days: "10 days",
    dates: ["27 Jan, 2024", "03 Feb, 2024", "18 Mar, 2024", "01 Apr, 2024"],
  },
  {
    id: 16,
    img: lagpoNorth,
    title: "Lagpo North",
    link: "/expedition/lagpoNorth",
    groupBy: "6360 M",
    days: "10 days",
    dates: ["09 Jan, 2024", "20 Feb, 2024", "28 Mar, 2024", "11 Apr, 2024"],
  },
  {
    id: 17,
    img: cb13,
    title: "CB 13",
    link: "/expedition/cb13",
    groupBy: "6264 M",
    days: "10 days",
    dates: ["01 Jan, 2024", "14 Feb, 2024", "23 Mar, 2023", "30 Apr, 2024"],
  },
  {
    id: 18,
    img: menthosa,
    title: "Menthosa",
    link: "/expedition/menthosa",
    groupBy: "6443 M",
    days: "9 days",
    dates: ["27 Jan, 2024", "03 Feb, 2024", "18 Mar, 2024", "01 Apr, 2024"],
  },
  {
    id: 19,
    img: p6250,
    title: "P6250",
    link: "/expedition/p6250",
    groupBy: "6000 M",
    days: "7 days",
    dates: ["09 Jan, 2024", "20 Feb, 2024", "28 Mar, 2024", "11 Apr, 2024"],
  },
  {
    id: 20,
    img: kangyatse1,
    title: "Kangyatse 2x6000 M",
    link: "/expedition/Kangyatse_2x6000M",
    groupBy: "6000 M",
    days: "7 days",
    dates: ["09 Jan, 2024", "20 Feb, 2024", "28 Mar, 2024", "11 Apr, 2024"],
  },
  {
    id: 21,
    img: mentokKangri,
    title: "Mentok 3x6000 M",
    link: "/expedition/MentokKangri_3x6000M",
    groupBy: "6000 M",
    days: "7 days",
    dates: ["09 Jan, 2024", "20 Feb, 2024", "28 Mar, 2024", "11 Apr, 2024"],
  },
  {
    id: 22,
    img: kiagarRi,
    title: "Kiagar Ri 3x6000 M",
    link: "/expedition/kiagarRi_3x6000M",
    groupBy: "6000 M",
    days: "7 days",
    dates: ["09 Jan, 2024", "20 Feb, 2024", "28 Mar, 2024", "11 Apr, 2024"],
  },
];

export const expedition7000M = [
  {
    id: 0,
    img: nun,
    title: "Nun",
    link: "/expedition/nun",
    groupBy: "7135 M",
    days: "14 days",
    dates: ["18 Mar, 2024", "01 Apr, 2024", "27 Jan, 2024", "03 Feb, 2024"],
  },
  {
    id: 1,
    img: kun,
    title: "Kun",
    link: "/expedition/kun",
    groupBy: "7077 M",
    days: "15 days",
    dates: ["03 Feb, 2024", "27 Jan, 2024", "18 Mar, 2024", "01 Apr, 2024"],
  },
];

export const pureAdrenaline = [
  {
    id: 0,
    img: papsura,
    title: "Papsura",
    link: "/expedition/papsura",
    groupBy: "6450 M",
    days: "10 days",
    dates: ["01 Apr, 2024", "03 Feb, 2024", "27 Jan, 2024", "18 Mar, 2024"],
  },
  {
    id: 1,
    img: reoPurgyil,
    title: "Reo Purgyil",
    link: "/expedition/reoPurgyil",
    groupBy: "6816 M",
    days: "13 days",
    dates: ["18 Mar, 2024", "03 Feb, 2024", "27 Jan, 2024", "01 Apr, 2024"],
  },
  {
    id: 2,
    img: indrasan,
    title: "Indrasan",
    link: "/expedition/indrasan",
    groupBy: "6221 M",
    days: "14 days",
    dates: ["27 Jan, 2024", "01 Apr, 2024", "18 Mar, 2024", "03 Feb, 2024"],
  },
  {
    id: 3,
    img: mukerBeh,
    title: "Muker Beh",
    link: "/expedition/mukerBeh",
    groupBy: "6070 M",
    days: "14 days",
    dates: ["03 Feb, 2024", "27 Jan, 2024", "01 Apr, 2024", "18 Mar, 2024"],
  },
];

export const trekking = [
  {
    id: 0,
    img: pinParvatiPassTrek,
    title: "Pin-Parvati Pass Trek",
    link: "/expedition/pinParvatiPassTrek",
    groupBy: "5319 M",
    days: "13 days",
    dates: ["01 Apr, 2024", "03 Feb, 2024", "27 Jan, 2024", "18 Mar, 2024"],
  },
  {
    id: 1,
    img: parangLaTrek,
    title: "Parang La Trek",
    link: "/expedition/parangLaTrek",
    groupBy: "5319 M",
    days: "14 days",
    dates: ["18 Mar, 2024", "03 Feb, 2024", "27 Jan, 2024", "01 Apr, 2024"],
  },
  {
    id: 2,
    img: audenColTrek,
    title: "Auden’s Col Trek",
    link: "/expedition/audenColTrek",
    groupBy: "5490 M",
    days: "16 days",
    dates: ["27 Jan, 2024", "01 Apr, 2024", "18 Mar, 2024", "03 Feb, 2024"],
  },
  {
    id: 3,
    img: kalindiKhalTrek,
    title: "Kalindi Khal Trek",
    link: "/expedition/kalindiKhalTrek",
    groupBy: "5940 M",
    days: "15 days",
    dates: ["03 Feb, 2024", "27 Jan, 2024", "01 Apr, 2024", "18 Mar, 2024"],
  },
  {
    id: 4,
    img: kangLaTrek,
    title: "Kang La Trek",
    link: "/expedition/kangLaTrek",
    groupBy: "5465 M",
    days: "10 days",
    dates: ["01 Apr, 2024", "27 Jan, 2024", "18 Mar, 2024", "03 Feb, 2024"],
  },
  {
    id: 5,
    img: goechaLaTrek,
    title: "Goecha La Trek",
    link: "/expedition/goechaLaTrek",
    groupBy: "4600 M",
    days: "10 days",
    dates: ["18 Mar, 2024", "01 Apr, 2024", "03 Feb, 2024", "27 Jan, 2024"],
  },
  {
    id: 6,
    img: baraBangalTrek,
    title: "Bara Bangal Trek",
    link: "/expedition/baraBangalTrek",
    groupBy: "4850 M",
    days: "8 days",
    dates: ["27 Jan, 2024", "18 Mar, 2024", "03 Feb, 2024", "01 Apr, 2024"],
  },
  {
    id: 7,
    img: lehLamayuruTrek,
    title: "Leh-Lamayuru Trek",
    link: "/expedition/lehLamayuruTrek",
    groupBy: "3850 M",
    days: "6 days",
    dates: ["03 Feb, 2024", "18 Mar, 2024", "01 Apr, 2024", "27 Jan, 2024"],
  },
  {
    id: 8,
    img: markhaValleyTrek,
    title: "Markha Valley Trek",
    link: "/expedition/markhaValleyTrek",
    groupBy: "4750 M",
    days: "6 days",
    dates: ["01 Apr, 2024", "18 Mar, 2024", "27 Jan, 2024", "03 Feb, 2024"],
  },
  {
    id: 9,
    img: spitukToMathoTrek,
    title: "Spituk to Matho Trek",
    link: "/expedition/spitukToMathoTrek",
    groupBy: "4230 M",
    days: "5 days",
    dates: ["27 Jan, 2024", "03 Feb, 2024", "01 Apr, 2024", "18 Mar, 2024"],
  },
  {
    id: 10,
    img: padumToDarchaTrek,
    title: "Padum to Darcha Trek",
    link: "/expedition/padumToDarchaTrek",
    groupBy: "3360 M",
    days: "13 days",
    dates: ["03 Feb, 2024", "01 Apr, 2024", "27 Jan, 2024", "18 Mar, 2024"],
  },
  {
    id: 11,
    img: lehToTsoMororiTrek,
    title: "Leh to Tso Morori Trek",
    link: "/expedition/lehToTsoMororiTrek",
    groupBy: "4850 M",
    days: "10 days",
    dates: ["18 Mar, 2024", "27 Jan, 2024", "03 Feb, 2024", "01 Apr, 2024"],
  },
  {
    id: 12,
    img: nubraValleyTrek,
    title: "Nubra Valley Trek",
    link: "/expedition/nubraValleyTrek",
    groupBy: "3048 M",
    days: "6 days",
    dates: ["18 Mar, 2024", "27 Jan, 2024", "03 Feb, 2024", "01 Apr, 2024"],
  },
];

export const adventureTour = [
  {
    id: 0,
    img: spitiValleyOverlanding,
    title: "Spiti Valley Overlanding",
    link: "/adventureTours/spitiValleyOverlanding",
    groupBy: "4700 M",
    days: "8 days",
    dates: ["01 Apr, 2024", "03 Feb, 2024", "27 Jan, 2024", "18 Mar, 2024"],
  },
  {
    id: 1,
    img: ladaakhOverlanding,
    title: "Ladakh Overlanding",
    link: "/adventureTours/ladakhOverlanding",
    groupBy: "5883 M",
    days: "10 days",
    dates: ["18 Mar, 2024", "03 Feb, 2024", "27 Jan, 2024", "01 Apr, 2024"],
  },
  {
    id: 2,
    img: zanskarOverlanding,
    title: "Zanskar Overlanding",
    link: "/adventureTours/zanskarOverlanding",
    groupBy: "5000 M",
    days: "10 days",
    dates: ["27 Jan, 2024", "01 Apr, 2024", "18 Mar, 2024", "03 Feb, 2024"],
  },
  {
    id: 3,
    img: sachPasKishtwarOverlanding,
    title: "Sach Pas Kishtwar Overlanding",
    link: "/adventureTours/sachPasKishtwarOverlanding",
    groupBy: "5000 M",
    days: "10 days",
    dates: ["03 Feb, 2024", "27 Jan, 2024", "01 Apr, 2024", "18 Mar, 2024"],
  },
];

export const upcomingTours = [
  {
    id: 0,
    img: kiagarRi,
    title: "Kiagar Ri 3x6000M",
    link: "/expedition/kiagarRi_3x6000M",
    groupBy: "6100 M",
    days: "7 days",
    dates: ["20 June, 2024"],
  },
  {
    id: 1,
    img: chauChauKangNilda,
    title: "Chau Chau Kang Nilda",
    link: "/expedition/chauChauKangNilda",
    groupBy: "6303 M",
    days: "13 days",
    dates: ["15 May, 2024"],
  },
  {
    id: 2,
    img: kangyatse1,
    title: "Kangyatse 2x6000 M",
    link: "/expedition/Kangyatse_2x6000M",
    groupBy: "6401 M",
    days: "15 days",
    dates: ["06 June, 2024"],
  },
  {
    id: 3,
    img: utKangri,
    title: "Ut Kangri",
    link: "/expedition/utKangri",
    groupBy: "6070 M",
    days: "8 days",
    dates: ["19 July, 2024"],
  },
  {
    id: 4,
    img: sachPasKishtwarOverlanding,
    title: "Sach Pass - Kishtwar",
    link: "/adventureTour/sachPasKishtwarOverlanding",
    groupBy: "5974 M",
    days: "8 days",
    dates: ["01 July, 2024"],
  },
  {
    id: 5,
    img: cb13,
    title: "CB 13",
    link: "/expedition/cb13",
    groupBy: "6264 M",
    days: "10 days",
    dates: ["1 August, 2024"],
  },
  {
    id: 6,
    img: friendshipPeak,
    title: "Friendship Peak",
    link: "/expedition/friendshipPeak",
    groupBy: "5289 M",
    days: "7 days",
    dates: ["18 May, 2024"],
  },
  {
    id: 7,
    img: yunamPeak,
    title: "Yunam Peak",
    link: "/expedition/yunamPeak",
    groupBy: "5289 M",
    days: "9 days",
    dates: ["28 May, 2024"],
  },
  {
    id: 8,
    img: kiagarRi,
    title: "Kiagar Ri 3x6000M",
    link: "/expedition/kiagarRi_3x6000M",
    groupBy: "6100 M",
    days: "7 days",
    dates: ["10 July, 2024"],
  },
  {
    id: 9,
    img: yunamPeak,
    title: "Yunam Peak",
    link: "/expedition/yunamPeak",
    groupBy: "5289 M",
    days: "9 days",
    dates: ["10 August, 2024"],
  },
  {
    id: 10,
    img: kiagarRi,
    title: "Kiagar Ri 3x6000M",
    link: "/expedition/kiagarRi_3x6000M",
    groupBy: "6100 M",
    days: "7 days",
    dates: ["20 August, 2024"],
  },
];
