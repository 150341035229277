import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

// Components
import Home from "./pages/Home";
import ExpeditionTemplate from "./pages/Expeditions/ExpeditionTemplate";
import IndividualExpedition from "./pages/IndividualExpedition/IndividualExpedition";
import WhoWeAre from "./pages/WhoWeAre";
import WhyWithUs from "./pages/WhyWithUs";
import OurTeam from "./pages/OurTeam";
import Certificates from "./pages/Certificates";
import IndividualCertificate from "./pages/IndividualCertificate/IndividualCertificate";
import AchievementsRecords from "./pages/AchievementsRecords";
import IndividualAchievementsRecords from "./pages/IndividualAchievementsRecords/IndividualAchievementsRecords";
import GetInTouch from "./pages/GetInTouch";
import IndividualWildlife from "./pages/IndividualWilldlifePhptpography.js/IndividualWildlife";
import IndividualAdventureTours from "./pages/IndividualAdventureTours/IndividualAdventureTours";
import IndividualTeamMember from "./pages/IndividualTeamMember/IndividualTeamMember";
import PlanYourTrip from "./pages/PlanYourTrip";
import TermsConditions from "./pages/Terms&conditions";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import CancellationPolicy from "./pages/CancellationPolicy";
import { UserAuthContextProvider } from "./utils/UserAuthContext";
import SachPass from "./pages/SachPass";
import WinterSpiti from "./pages/WinterSpiti";

function App() {
  return (
    <Router>
      <UserAuthContextProvider>
        <Routes>
          <Route path="/" exact element={<Home />} />
          <Route
            path="/:expeditionType"
            exact
            element={<ExpeditionTemplate />}
          />
          <Route
            path="/expedition/:individualExpeditionType"
            exact
            element={<IndividualExpedition />}
          />
          <Route
            path="/wildlifePhotography/:individualWildlifePhotographyType"
            exact
            element={<IndividualWildlife />}
          />
          <Route
            path="/adventureTours/:individualadventureTourType"
            exact
            element={<IndividualAdventureTours />}
          />
          <Route
            path="/adventureTour/sachPasKishtwarOverlanding"
            exact
            element={<SachPass />}
          />
          <Route
            path="/adventureTour/winterSpitiOverlanding"
            exact
            element={<WinterSpiti />}
          />
          <Route
            path="/whiteExpeditionLeader/:individualLeader"
            exact
            element={<IndividualTeamMember />}
          />
          <Route path="/whoWeAre" exact element={<WhoWeAre />} />
          <Route path="/whyWithUs" exact element={<WhyWithUs />} />
          <Route path="/getInTouch" exact element={<GetInTouch />} />
          <Route path="/planYourTrip" exact element={<PlanYourTrip />} />
          <Route path="/ourTeam" exact element={<OurTeam />} />
          <Route path="/certificates" exact element={<Certificates />} />
          <Route
            path="/termsAndConditions"
            exact
            element={<TermsConditions />}
          />
          <Route path="/privacyPolicy" exact element={<PrivacyPolicy />} />
          <Route
            path="/cancellationPolicy"
            exact
            element={<CancellationPolicy />}
          />
          <Route
            path="/certificates/:individualCertificate"
            exact
            element={<IndividualCertificate />}
          />
          <Route
            path="/AchievementsRecords"
            exact
            element={<AchievementsRecords />}
          />
          <Route
            path="/AchievementsRecords/:individualAchievementsRecords"
            exact
            element={<IndividualAchievementsRecords />}
          />
        </Routes>
      </UserAuthContextProvider>
    </Router>
  );
}

export default App;
