import { leaderHeroSection } from "../../data/Home/dataStorage";

import rickyMountaineer1 from "../../assets/expedition-leader/Ricky/1.webp";
import rickyMountaineer2 from "../../assets/expedition-leader/Ricky/2.webp";
import rickyMountaineer3 from "../../assets/expedition-leader/Ricky/3.webp";
import nikitaThakur1 from "../../assets/expedition-leader/Nikita/1.webp";
import nikitaThakur2 from "../../assets/expedition-leader/Nikita/2.webp";
import nikitaThakur3 from "../../assets/expedition-leader/Nikita/3.webp";
import shubhamBisht1 from "../../assets/expedition-leader/Shubham/1.webp";
import shubhamBisht2 from "../../assets/expedition-leader/Shubham/2.webp";
import shubhamBisht3 from "../../assets/expedition-leader/Shubham/3.webp";

export const individualExpedition = [
  {
    individualExpeditionType: "rickyMountaineer",
    leaderName: "Ricky Mountaineer",
    carouselData: leaderHeroSection,
    mainExpeditionContent:
      "I'm Rahul Thakur known as Ricky Mountaineer, and my story revolves around the incredible Himalayas. I kicked off in the charming town of Shimla, but it was in the serene beauty of Manali where I found my true passion during a decade-long stay.It all started with trekking, and soon I fell in love with climbing. I got serious about it, taking courses like Basic Mountaineering, Advanced Mountaineering, and Search & Rescue. The mountains became my teachers, turning me from an enthusiast into a full-time mountaineer, teaching me life's essential lessons.",
    aboutExpeditionExperienced:
      "For me, the mountains are not just a tough place; they're a wellspring of positivity and self-discovery. Every time life threw a curveball, the mountains offered solace, joy, and crucial life lessons. Empowered by these experiences, I ventured into solo climbs, exploring various peaks and reveling in nature's grandeur. Driven by the desire to share the beauty and challenges of mountain climbing, I founded 'White Expedition,' my own climbing company. Through this, I aim to give people the chance to experience the joy, beauty, and personal growth that comes with climbing mountains. My story is a simple testament to the amazing impact nature, especially mountains, can have on a person.",
    youtubeVideoLink:
      "https://www.youtube.com/embed/KVWiQLM_Akc?si=DVPSlONZfakMEpqv",
    facebookLink: "",
    twitterLink: "",
    instagramLink: "https://www.instagram.com/ricky_mountaineer/",
    youtubeLink: "",
    image1: rickyMountaineer1,
    image2: rickyMountaineer2,
    image3: rickyMountaineer3,
  },
  {
    individualExpeditionType: "nikitaThakur",
    leaderName: "Nikita Thakur",
    carouselData: leaderHeroSection,
    mainExpeditionContent:
      "Nikita, a Shimla, Himachal Pradesh native, is a distinguished mountain guide and expedition leader. Certified as an expedition leader, she has triumphed in over 15 expeditions in the Indian Himalayas, each surpassing the formidable 6000-meter mark. Nikita holds commendable certifications in Basic Mountaineering, Advanced Mountaineering, and Search and Rescue, underscoring her comprehensive skill set in the realm of high-altitude adventures.",
    aboutExpeditionExperienced: "",
    youtubeVideoLink: "",
    facebookLink: "",
    twitterLink: "",
    instagramLink:
      "https://www.instagram.com/_thakur_niki?igsh=MWM0eDJiNXJta2VrMg%3D%3D",
    youtubeLink: "",
    image1: nikitaThakur1,
    image2: nikitaThakur2,
    image3: nikitaThakur3,
  },
  {
    individualExpeditionType: "shubhamBisht",
    leaderName: "Shubham Bisht",
    carouselData: leaderHeroSection,
    mainExpeditionContent:
      "Luckily being born and raised in Himachal  I've been traveling in and around my place for as long as I can remember. What started from bunking classes and hiking to the nearest waterfalls soon converted into trekking and eventually expeditions.For me, mountains are not just a destination but rather a way of life; each climb is a chance to connect with the earth and make a positive impact. I aspire to weave stories amidst the rugged terrains, breathe in the crisp mountain air, and create memories that linger like the echoes of the peaks.Adventure awaits, and I'm here to lead the way.Led expeditions and trekking ventures to Mt. Shetidhar, Mt. Yunam, Mt. Friendship, Mt. Manerang, Thamsar Pass, Indrahar Pass, Beas Kund, Yulla Kanda, and Bhrigu Lake.",
    aboutExpeditionExperienced: "",
    youtubeVideoLink: "",
    facebookLink: "",
    twitterLink: "",
    instagramLink:
      "https://www.instagram.com/hippiewithin?igsh=dWVmM3JxMzVramY5",
    youtubeLink: "",
    image1: shubhamBisht1,
    image2: shubhamBisht2,
    image3: shubhamBisht3,
  },
];
