import React, { useEffect, useState } from "react";

// Components for page structure
import Footer from "../components/Footer";
import HeroSection from "../components/HeroSection";
import InstaFeeds from "../components/InstaFeeds";
import NavigationBar from "../components/NavigationBar";
import Whatsapp from "../components/Whatsapp";

// Data for HeroSection
import { TermsConditionsHeroSection } from "../data/Home/dataStorage";

// Asset for footer background
import expedition from "../assets/home/hero/expedition.webp";

// Animation library (if actively used)
import AOS from "aos";
import "aos/dist/aos.css";
import { Helmet } from "react-helmet";
import UpcomingTours from "../components/UpcomingTours";
import UpcomingToursIcon from "../components/UpcomingToursIcon";

const TermsConditions = () => {
  useEffect(() => {
    // Initialize AOS library for animations
    AOS.init();
  }, []);
  const [isVisible, setIsVisible] = useState(false);

  return (
    <>
      <Helmet>
        <title>White Expedition - Terms & Conditions</title>
        <meta
          name="description"
          content="Understand our agreements! Our Terms & Conditions outline the legal framework for using our services. Get informed before you proceed."
        />
      </Helmet>

      {/* Hero Section with Carousel Data from WhoWeAreHeroSection */}
      <HeroSection carouselData={TermsConditionsHeroSection} />

      {/* Navigation Bar */}
      <section className="center">
        <NavigationBar />
      </section>

      {/* Terms & Conditions Section  */}
      <section className="center">
        <div className="container">
          <div className="grid grid-cols-12 gap-2">
            <div className="col-start-1 col-span-12">
              <h1
                className="paragraph text-start text-primary font-bold margin"
                data-aos="fade-up"
                data-aos-duration="800"
              >
                Welcome to White Expedition!
              </h1>
            </div>

            <div className="col-start-1 col-span-12">
              <h1
                className="paragraph text-start margin"
                data-aos="fade-up"
                data-aos-duration="800"
              >
                We're thrilled to have you explore the world of adventure with
                us. These Terms and Conditions ("Terms") outline the rules and
                regulations for using this website ("Website") operated by White
                Expedition ("we," "us," or "our"). By accessing or using any
                part of the Website, you are agreeing to be bound by these
                Terms. Additionally, you agree to comply with all applicable
                laws and regulations governing the use of our website.
              </h1>
            </div>

            <div className="col-start-1 col-span-12">
              <h1
                className="paragraph text-start text-primary font-bold margin"
                data-aos="fade-up"
                data-aos-duration="800"
              >
                Use of the Website
              </h1>
            </div>

            <div className="col-start-2 col-span-12">
              <h1
                className="paragraph text-start text-primary font-bold margin"
                data-aos="fade-up"
                data-aos-duration="800"
              >
                Permitted Use:
              </h1>
            </div>

            <div className="col-start-2 col-span-12">
              <h1
                className="paragraph text-start margin"
                data-aos="fade-up"
                data-aos-duration="800"
              >
                This website allows you to explore the world of adventure with
                White Expedition! Feel free to browse our content, learn about
                exciting expeditions, and utilize search functions to find the
                perfect trip for you. If applicable, you can also directly use
                the website to inquire about bookings or reserve your spot on an
                adventure.
              </h1>
            </div>

            <div className="col-start-2 col-span-12">
              <h1
                className="paragraph text-start text-primary font-bold margin"
                data-aos="fade-up"
                data-aos-duration="800"
              >
                Prohibited Use:
              </h1>
            </div>

            <div className="col-start-2 col-span-12">
              <h1
                className="paragraph text-start margin"
                data-aos="fade-up"
                data-aos-duration="800"
              >
                White Expedition prioritizes a safe and secure online
                environment for all users. Activities that violate this trust
                are strictly prohibited. These include unauthorized access
                attempts, copyright infringement (copying or modifying our
                content without permission), spamming, disruptive behavior
                (offensive content or disrupting website functionality), and any
                illegal activity.
              </h1>
            </div>

            <div className="col-start-2 col-span-12">
              <h1
                className="paragraph text-start text-primary font-bold margin"
                data-aos="fade-up"
                data-aos-duration="800"
              >
                Account Security:
              </h1>
            </div>

            <div className="col-start-2 col-span-12">
              <h1
                className="paragraph text-start margin"
                data-aos="fade-up"
                data-aos-duration="800"
              >
                For your protection, White Expedition encourages responsible
                account management. If you create an account for bookings or
                managing preferences, ensure you keep your password
                confidential. You are accountable for all activity under your
                account, so be vigilant and report any suspected security
                breaches to our team immediately.
              </h1>
            </div>

            <div className="col-start-1 col-span-12">
              <h1
                className="paragraph text-start text-primary font-bold margin"
                data-aos="fade-up"
                data-aos-duration="800"
              >
                Intellectual Property
              </h1>
            </div>

            <div className="col-start-1 col-span-12">
              <h1
                className="paragraph text-start margin"
                data-aos="fade-up"
                data-aos-duration="800"
              >
                White Expedition takes pride in the creative content and design
                of our website. Everything you see here, from informative text
                to captivating images, is protected by intellectual property
                rights. While we grant you permission to access and explore the
                website, this doesn't transfer ownership.If you have the
                opportunity to submit content like reviews or photos, you retain
                ownership, but by submitting it, you grant White Expedition the
                right to display or remove that content as we see fit.
              </h1>
            </div>

            <div className="col-start-1 col-span-12">
              <h1
                className="paragraph text-start text-primary font-bold margin"
                data-aos="fade-up"
                data-aos-duration="800"
              >
                Disclaimers
              </h1>
            </div>

            <div className="col-start-1 col-span-12">
              <h1
                className="paragraph text-start margin"
                data-aos="fade-up"
                data-aos-duration="800"
              >
                We strive to provide accurate and valuable information on White
                Expedition's website. However, it's important to understand that
                the website and its materials are offered "as is" without any
                guarantees, expressed or implied. This means we can't promise
                the website will always be perfect or meet your specific needs.
                We disclaim any warranties of merchantability, fitness for a
                particular purpose, or non-infringement.
              </h1>
            </div>

            <div className="col-start-1 col-span-12">
              <h1
                className="paragraph text-start text-primary font-bold margin"
                data-aos="fade-up"
                data-aos-duration="800"
              >
                Other Important Terms
              </h1>
            </div>

            <div className="col-start-2 col-span-12">
              <h1
                className="paragraph text-start text-primary font-bold margin"
                data-aos="fade-up"
                data-aos-duration="800"
              >
                Termination:
              </h1>
            </div>

            <div className="col-start-2 col-span-12">
              <h1
                className="paragraph text-start margin"
                data-aos="fade-up"
                data-aos-duration="800"
              >
                We reserve the right to terminate your access to the website if
                you violate these Terms and Conditions or for any other reason
                we deem necessary.
              </h1>
            </div>

            <div className="col-start-2 col-span-12">
              <h1
                className="paragraph text-start text-primary font-bold margin"
                data-aos="fade-up"
                data-aos-duration="800"
              >
                Links:
              </h1>
            </div>

            <div className="col-start-2 col-span-12">
              <h1
                className="paragraph text-start margin"
                data-aos="fade-up"
                data-aos-duration="800"
              >
                Our website may contain links to external third-party websites.
                We are not responsible for the content or practices of those
                sites.
              </h1>
            </div>

            <div className="col-start-2 col-span-12">
              <h1
                className="paragraph text-start text-primary font-bold margin"
                data-aos="fade-up"
                data-aos-duration="800"
              >
                Modifications:
              </h1>
            </div>

            <div className="col-start-2 col-span-12">
              <h1
                className="paragraph text-start margin"
                data-aos="fade-up"
                data-aos-duration="800"
              >
                We may update these Terms and Conditions at any time without
                prior notice.
              </h1>
            </div>

            <div className="col-start-2 col-span-12">
              <h1
                className="paragraph text-start text-primary font-bold margin"
                data-aos="fade-up"
                data-aos-duration="800"
              >
                Governing Law:
              </h1>
            </div>

            <div className="col-start-2 col-span-12">
              <h1
                className="paragraph text-start margin"
                data-aos="fade-up"
                data-aos-duration="800"
              >
                These Terms and Conditions are governed by the laws of India and
                you agree to submit to the exclusive jurisdiction of the courts
                in that location for any disputes arising from these Terms.
              </h1>
            </div>

            <div className="col-start-2 col-span-12">
              <h1
                className="paragraph text-start text-primary font-bold margin"
                data-aos="fade-up"
                data-aos-duration="800"
              >
                Entire Agreement:
              </h1>
            </div>

            <div className="col-start-2 col-span-12">
              <h1
                className="paragraph text-start margin"
                data-aos="fade-up"
                data-aos-duration="800"
              >
                These Terms and Conditions constitute the entire agreement
                between you and White Expedition regarding your use of the
                website.
              </h1>
            </div>
          </div>
        </div>
      </section>

      {/* Instagram Section  */}
      <section className="center bg-black">
        {/* Container for Instagram feed section */}
        <div className="container">
          <div className="grid grid-cols-12 gap-2">
            {/* Section Header (remove commented-out animations) */}
            <h1 className="header col-start-1 col-span-12 text-center">
              Our Instagram
            </h1>

            {/* Instagram Username Link */}
            <a
              href="https://www.instagram.com/white.expedition/"
              target="_blank"
              rel="noopener noreferrer"
              className="col-start-1 col-span-12 text-center"
            >
              <h1 className="tagline text-primary">@white.expedition</h1>
            </a>

            {/* Instagram Feed (replace with your actual Instagram feed component) */}
            <div className="col-start-1 col-span-12 rounded-md overflow-hidden margin">
              <InstaFeeds />
              {/* Comment: Replace InstaFeeds with your actual Instagram feed component */}
            </div>
          </div>
        </div>
      </section>

      {/* Footer section with background image */}
      <section
        className="relative bg-center bg-cover"
        style={{ backgroundImage: `url(${expedition})` }}
      >
        {/* Dark overlay on top of background image */}
        <div className="absolute inset-0 bg-primary opacity-80 z-10"></div>

        {/* Container for footer content */}
        <div className="container relative z-20">
          <div className="grid grid-cols-12 gap-2">
            {/* Footer component (replace with your actual footer component) */}
            <div className="col-start-1 col-span-12">
              <Footer />
              {/* Comment: Replace Footer with your actual footer component */}
            </div>
          </div>
        </div>
      </section>

      {/* WhatsApp integration (replace with your implementation) */}
      <Whatsapp />
      {/* Comment: Replace Whatsapp with your actual WhatsApp integration */}

      {isVisible && <UpcomingTours close={() => setIsVisible(false)} />}

      <UpcomingToursIcon
        open={() => {
          setIsVisible(true);
        }}
      />
    </>
  );
};

export default TermsConditions;
