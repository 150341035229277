import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import HeroSection from "../../components/HeroSection";
import NavigationBar from "../../components/NavigationBar";

import { FaLongArrowAltLeft } from "react-icons/fa";
import { FaLongArrowAltRight } from "react-icons/fa";
import { FaAnglesRight } from "react-icons/fa6";
import InstaFeeds from "../../components/InstaFeeds";
import Footer from "../../components/Footer";

import "../../index.css";
import AOS from "aos";
import "aos/dist/aos.css";

import expedition from "../../assets/home/hero/expedition.webp";

import { expeditionCategory } from "./dataStorage";
import Whatsapp from "../../components/Whatsapp";
import { Helmet } from "react-helmet";
import UpcomingTours from "../../components/UpcomingTours";
import UpcomingToursIcon from "../../components/UpcomingToursIcon";

const ExpeditionTemplate = () => {
  const { expeditionType } = useParams();

  const FilterExpedition = expeditionCategory.find(
    (exp) => exp.expeditionType === expeditionType
  );

  const sortedExpedition5000M = JSON.parse(
    JSON.stringify(FilterExpedition.expeditionArray)
  );

  // Sort each date array within objects
  sortedExpedition5000M.forEach((expedition) => {
    // Filter out past dates
    expedition.dates = expedition.dates.filter((date) => {
      const currentDate = new Date();
      const expeditionDate = new Date(date);
      return expeditionDate > currentDate; // Include only future dates
    });

    // Sort the remaining dates
    expedition.dates.sort((a, b) => {
      const dateA = new Date(a); // Convert date A to Date object
      const dateB = new Date(b); // Convert date B to Date object
      return dateA - dateB; // Compare the dates
    });
  });

  // Sort the objects based on the first date of each array
  sortedExpedition5000M.sort((a, b) => {
    const dateA = new Date(a.dates[0]); // Convert first date of object A to Date object
    const dateB = new Date(b.dates[0]); // Convert first date of object B to Date object
    return dateA - dateB; // Compare the dates
  });

  const [upcomingExpedition5000MCount, setUpcomingExpedition5000MCount] =
    useState(0);

  const oddExpedition = FilterExpedition.expeditionArray.filter(
    (item) => item.id % 2 === 1
  );
  const evenExpedition = FilterExpedition.expeditionArray.filter(
    (item) => item.id % 2 === 0
  );
  const firstArray = FilterExpedition.expeditionArray.filter(
    (_, index) => (index + 1) % 3 === 1
  );
  const secondArray = FilterExpedition.expeditionArray.filter(
    (_, index) => (index + 1) % 3 === 2
  );
  const thirdArray = FilterExpedition.expeditionArray.filter(
    (_, index) => (index + 1) % 3 === 0
  );

  useEffect(() => {
    AOS.init();
  }, []);

  const [isVisible, setIsVisible] = useState(false);

  return (
    <>
      <Helmet>
        <title>
          White Expedition - {}
          {FilterExpedition.aboutExpeditionType}
        </title>
        <meta
          name="description"
          content={`Conquer ${FilterExpedition.aboutExpeditionType} with White Expedition! Embark on an unforgettable guided climb to the majestic ${FilterExpedition.aboutExpeditionType} in Himachal Pradesh.`}
        />
      </Helmet>

      {/* Hero Section  */}
      <HeroSection carouselData={FilterExpedition.carouselData} />

      {/* Navigationbar Section  */}
      <section className="center">
        <NavigationBar />
      </section>

      {/* Expedition about Section  */}
      <section className="center">
        <div className="container">
          <div className="grid grid-cols-12 gap-2">
            <h1
              className="paragraph col-start-1 col-span-12 text-center margin"
              data-aos="fade-up"
              data-aos-duration="800"
            >
              {FilterExpedition.aboutExpeditionType}
            </h1>
          </div>
        </div>
      </section>

      {/* Upcoming Expedition Section  */}
      <section className="center">
        <div className="container bg-black">
          <div className="grid grid-cols-12 gap-2">
            <h1
              className="header col-start-1 col-span-12 text-center"
              data-aos="fade-up"
              data-aos-duration="800"
            >
              Upcoming Expeditions
            </h1>

            {/* Lg device onwards  */}
            <div className="invisible hidden lg:grid lg:visible col-start-1 col-span-12 grid-cols-12 gap-2 margin">
              {sortedExpedition5000M[3 * upcomingExpedition5000MCount + 0] !==
                undefined && (
                <div
                  className="relative col-start-1 col-span-4 rounded-lg aspect-square overflow-hidden"
                  data-aos="fade-up"
                  data-aos-duration="800"
                  data-aos-offset="400"
                >
                  {/* Image div  */}
                  <div className="bg-primary w-full h-4/5 rounded-lg overflow-hidden relative">
                    <img
                      src={
                        sortedExpedition5000M[
                          3 * upcomingExpedition5000MCount + 0
                        ].img
                      }
                      alt="Expedition Group"
                      className="w-full h-full object-cover"
                    />
                    <div className="absolute w-full h-full bg-black bg-opacity-50 top-0 left-0 hover:bg-opacity-70 ease-in-out duration-150 flex items-end">
                      <div className="w-full h-20 bg-transparent flex flex-col justify-center px-4">
                        <h1 className="paragraph text-primary ">
                          {
                            sortedExpedition5000M[
                              3 * upcomingExpedition5000MCount + 0
                            ].groupBy
                          }
                        </h1>
                        <h1 className="extraSmall text-white ">
                          {
                            sortedExpedition5000M[
                              3 * upcomingExpedition5000MCount + 0
                            ].days
                          }
                        </h1>
                      </div>
                    </div>
                  </div>

                  {/* Expedition Details  */}
                  <div className="w-full py-1 h-1/5 rounded-b-lg flex flex-col justify-center ">
                    <h1 className="tagline px-2">
                      {
                        sortedExpedition5000M[
                          3 * upcomingExpedition5000MCount + 0
                        ].title
                      }
                    </h1>
                    <div className="w-full px-2 flex justify-between items-center">
                      <a
                        href={
                          sortedExpedition5000M[
                            3 * upcomingExpedition5000MCount + 0
                          ].link
                        }
                      >
                        <div className="flex space-x-1 items-center cursor-pointer hover:space-x-2 ease-in-out duration-200 transition-transform">
                          <h1 className="extraSmall text-primary">
                            View Details
                          </h1>

                          <FaAnglesRight className="text-primary" size={20} />
                        </div>
                      </a>
                      <a href="/getInTouch">
                        <button className="extraSmall px-2 py-1 bg-primary text-black rounded-md">
                          Book Now
                        </button>
                      </a>
                    </div>
                  </div>

                  {/* <div className="absolute bg-black w-full h-12 top-0 left-0 flex justify-center items-center">
                    <h1 className="subHeader text-white">Booking Open</h1>
                  </div> */}
                </div>
              )}

              {sortedExpedition5000M[3 * upcomingExpedition5000MCount + 1] !==
                undefined && (
                <div
                  className="relative col-start-5 col-span-4 rounded-lg aspect-square overflow-hidden"
                  data-aos="fade-up"
                  data-aos-duration="800"
                  data-aos-delay="300"
                  data-aos-offset="400"
                >
                  {/* Image div  */}
                  <div className="bg-primary w-full h-4/5 rounded-lg overflow-hidden relative">
                    <img
                      src={
                        sortedExpedition5000M[
                          3 * upcomingExpedition5000MCount + 1
                        ].img
                      }
                      alt="Expedition Group"
                      className="w-full h-full object-cover"
                    />
                    <div className="absolute w-full h-full bg-black bg-opacity-50 top-0 left-0 hover:bg-opacity-70 ease-in-out duration-150 flex items-end">
                      <div className="w-full h-20 bg-transparent flex flex-col justify-center px-4">
                        <h1 className="paragraph text-primary ">
                          {
                            sortedExpedition5000M[
                              3 * upcomingExpedition5000MCount + 1
                            ].groupBy
                          }
                        </h1>
                        <h1 className="extraSmall text-white ">
                          {
                            sortedExpedition5000M[
                              3 * upcomingExpedition5000MCount + 0
                            ].days
                          }
                        </h1>
                      </div>
                    </div>
                  </div>

                  {/* Expedition Details  */}
                  <div className="w-full py-1 h-1/5 rounded-b-lg flex flex-col justify-center ">
                    <h1 className="tagline px-2">
                      {
                        sortedExpedition5000M[
                          3 * upcomingExpedition5000MCount + 1
                        ].title
                      }
                    </h1>
                    <div className="w-full px-2 flex justify-between items-center">
                      <a
                        href={
                          sortedExpedition5000M[
                            3 * upcomingExpedition5000MCount + 1
                          ].link
                        }
                      >
                        <div className="flex space-x-1 items-center cursor-pointer hover:space-x-2 ease-in-out duration-200 transition-transform">
                          <h1 className="extraSmall text-primary">
                            View Details
                          </h1>
                          <FaAnglesRight className="text-primary" size={20} />
                        </div>
                      </a>
                      <a href="/getInTouch">
                        <button className="extraSmall px-2 py-1 bg-primary text-black rounded-md">
                          Book Now
                        </button>
                      </a>
                    </div>
                  </div>

                  {/* <div className="absolute bg-black w-full h-12 top-0 left-0 flex justify-center items-center">
                    <h1 className="subHeader text-white">Booking Open</h1>
                  </div> */}
                </div>
              )}

              {sortedExpedition5000M[3 * upcomingExpedition5000MCount + 2] !==
                undefined && (
                <div
                  className="relative col-start-9 col-span-4 rounded-lg aspect-square overflow-hidden"
                  data-aos="fade-up"
                  data-aos-duration="800"
                  data-aos-delay="600"
                  data-aos-offset="400"
                >
                  {/* Image div  */}
                  <div className="bg-primary w-full h-4/5 rounded-lg overflow-hidden relative">
                    <img
                      src={
                        sortedExpedition5000M[
                          3 * upcomingExpedition5000MCount + 2
                        ].img
                      }
                      alt="Expedition Group"
                      className="w-full h-full object-cover"
                    />
                    <div className="absolute w-full h-full bg-black bg-opacity-50 top-0 left-0 hover:bg-opacity-70 ease-in-out duration-150 flex items-end ">
                      <div className="w-full h-20 bg-transparent flex flex-col justify-center px-4">
                        <h1 className="paragraph text-primary ">
                          {
                            sortedExpedition5000M[
                              3 * upcomingExpedition5000MCount + 2
                            ].groupBy
                          }
                        </h1>
                        <h1 className="extraSmall text-white ">
                          {
                            sortedExpedition5000M[
                              3 * upcomingExpedition5000MCount + 0
                            ].days
                          }
                        </h1>
                      </div>
                    </div>
                  </div>

                  {/* Expedition Details  */}
                  <div className="w-full py-1 h-1/5 rounded-b-lg flex flex-col justify-center ">
                    <h1 className="tagline px-2">
                      {
                        sortedExpedition5000M[
                          3 * upcomingExpedition5000MCount + 2
                        ].title
                      }
                    </h1>
                    <div className="w-full px-2 flex justify-between items-center">
                      <a
                        href={
                          sortedExpedition5000M[
                            3 * upcomingExpedition5000MCount + 2
                          ].link
                        }
                      >
                        <div className="flex space-x-1 items-center cursor-pointer hover:space-x-2 ease-in-out duration-200 transition-transform">
                          <h1 className="extraSmall text-primary">
                            View Details
                          </h1>
                          <FaAnglesRight className="text-primary" size={20} />
                        </div>
                      </a>
                      <a href="/getInTouch">
                        <button className="extraSmall px-2 py-1 bg-primary text-black rounded-md">
                          Book Now
                        </button>
                      </a>
                    </div>
                  </div>

                  {/* <div className="absolute bg-black w-full h-12 top-0 left-0 flex justify-center items-center">
                    <h1 className="subHeader text-white">Booking Open</h1>
                  </div> */}
                </div>
              )}
            </div>

            {/* sm to md device */}
            <div className="hidden invisible sm:grid sm:visible lg:hidden lg:invisible col-start-1 col-span-12 grid-cols-12 gap-2 margin">
              {sortedExpedition5000M[2 * upcomingExpedition5000MCount + 0] !==
                undefined && (
                <div
                  className="relative col-start-1 col-span-6 rounded-lg aspect-square overflow-hidden"
                  data-aos="fade-up"
                  data-aos-duration="800"
                  data-aos-offset="400"
                >
                  {/* Image div  */}
                  <div className="bg-primary w-full h-4/5 rounded-lg overflow-hidden relative">
                    <img
                      src={
                        sortedExpedition5000M[
                          2 * upcomingExpedition5000MCount + 0
                        ].img
                      }
                      alt="Expedition Group"
                      className="w-full h-full object-cover"
                    />
                    <div className="absolute w-full h-full bg-black bg-opacity-50 top-0 left-0 hover:bg-opacity-70 ease-in-out duration-150 flex items-end">
                      <div className="w-full h-16 bg-transparent flex flex-col justify-center px-2">
                        <h1 className="paragraph text-primary ">
                          {
                            sortedExpedition5000M[
                              2 * upcomingExpedition5000MCount + 0
                            ].groupBy
                          }
                        </h1>
                        <h1 className="extraSmall text-white ">
                          {
                            sortedExpedition5000M[
                              2 * upcomingExpedition5000MCount + 0
                            ].days
                          }
                        </h1>
                      </div>
                    </div>
                  </div>

                  {/* Expedition Details  */}
                  <div className="w-full py-1 h-1/5 rounded-b-lg flex flex-col justify-center ">
                    <h1 className="tagline px-2">
                      {
                        sortedExpedition5000M[
                          2 * upcomingExpedition5000MCount + 0
                        ].title
                      }
                    </h1>
                    <div className="w-full px-2 flex justify-between items-center">
                      <a
                        href={
                          sortedExpedition5000M[
                            2 * upcomingExpedition5000MCount + 0
                          ].link
                        }
                      >
                        <div className="flex space-x-1 items-center cursor-pointer hover:space-x-2 ease-in-out duration-200 transition-transform">
                          <h1 className="paragraph text-primary">
                            View Details
                          </h1>
                          <FaAnglesRight className="text-primary" size={20} />
                        </div>
                      </a>
                      <a href="/getInTouch">
                        <button className="paragraph px-2 py-0.5 bg-primary text-black rounded-md">
                          Book Now
                        </button>
                      </a>
                    </div>
                  </div>

                  {/* <div className="absolute bg-black w-full h-10 top-0 left-0 flex justify-center items-center">
                    <h1 className="subHeader text-white">Booking Open</h1>
                  </div> */}
                </div>
              )}

              {sortedExpedition5000M[2 * upcomingExpedition5000MCount + 1] !==
                undefined && (
                <div
                  className="relative overflow-hidden col-start-7 col-span-6 rounded-lg aspect-square"
                  data-aos="fade-up"
                  data-aos-duration="800"
                  data-aos-delay="300"
                  data-aos-offset="400"
                >
                  {/* Image div  */}
                  <div className="bg-primary w-full h-4/5 rounded-lg overflow-hidden relative">
                    <img
                      src={
                        sortedExpedition5000M[
                          2 * upcomingExpedition5000MCount + 1
                        ].img
                      }
                      alt="Expedition Group"
                      className="w-full h-full object-cover"
                    />
                    <div className="absolute w-full h-full bg-black bg-opacity-50 top-0 left-0 hover:bg-opacity-70 ease-in-out duration-150 flex items-end ">
                      <div className="w-full h-16 bg-transparent flex flex-col justify-center px-2">
                        <h1 className="paragraph text-primary ">
                          {
                            sortedExpedition5000M[
                              2 * upcomingExpedition5000MCount + 1
                            ].groupBy
                          }
                        </h1>
                        <h1 className="extraSmall text-white ">
                          {
                            sortedExpedition5000M[
                              2 * upcomingExpedition5000MCount + 0
                            ].days
                          }
                        </h1>
                      </div>
                    </div>
                  </div>

                  {/* Expedition Details  */}
                  <div className="w-full py-1 h-1/5 rounded-b-lg flex flex-col justify-center ">
                    <h1 className="tagline px-2">
                      {
                        sortedExpedition5000M[
                          2 * upcomingExpedition5000MCount + 1
                        ].title
                      }
                    </h1>
                    <div className="w-full px-2 flex justify-between items-center">
                      <a
                        href={
                          sortedExpedition5000M[
                            2 * upcomingExpedition5000MCount + 1
                          ].link
                        }
                      >
                        <div className="flex space-x-1 items-center cursor-pointer hover:space-x-2 ease-in-out duration-200 transition-transform">
                          <h1 className="paragraph text-primary">
                            View Details
                          </h1>
                          <FaAnglesRight className="text-primary" size={20} />
                        </div>
                      </a>
                      <a href="/getInTouch">
                        <button className="paragraph px-2 py-0.5 bg-primary text-black rounded-md">
                          Book Now
                        </button>
                      </a>
                    </div>
                  </div>

                  {/* <div className="absolute bg-black w-full h-10 top-0 left-0 flex justify-center items-center">
                    <h1 className="subHeader text-white">Booking Open</h1>
                  </div> */}
                </div>
              )}
            </div>

            {/* default device */}
            <div className="grid visible sm:hidden sm:invisible col-start-1 col-span-12 grid-cols-12 gap-2 margin">
              {sortedExpedition5000M[upcomingExpedition5000MCount] !==
                undefined && (
                <div
                  className="relative col-start-1 col-span-12 rounded-lg aspect-square overflow-hidden"
                  data-aos="fade-up"
                  data-aos-duration="800"
                  data-aos-offset="400"
                >
                  {/* Image div  */}
                  <div className="bg-primary w-full h-4/5 rounded-lg overflow-hidden relative">
                    <img
                      src={
                        sortedExpedition5000M[upcomingExpedition5000MCount].img
                      }
                      alt="Expedition Group"
                      className="w-full h-full object-cover"
                    />
                    <div className="absolute w-full h-full bg-black bg-opacity-50 top-0 left-0 hover:bg-opacity-70 ease-in-out duration-150 flex items-end">
                      <div className="w-full h-14 bg-transparent flex flex-col justify-center px-2">
                        <h1 className="tagline text-primary ">
                          {
                            sortedExpedition5000M[upcomingExpedition5000MCount]
                              .groupBy
                          }
                        </h1>
                        <h1 className="paragraph text-white ">
                          {
                            sortedExpedition5000M[upcomingExpedition5000MCount]
                              .days
                          }
                        </h1>
                      </div>
                    </div>
                  </div>

                  {/* Expedition Details  */}
                  <div className="w-full py-1 h-1/5 rounded-b-lg flex flex-col justify-center ">
                    <h1 className="tagline px-2">
                      {
                        sortedExpedition5000M[upcomingExpedition5000MCount]
                          .title
                      }
                    </h1>
                    <div className="w-full px-2 flex justify-between items-center">
                      <a
                        href={
                          sortedExpedition5000M[upcomingExpedition5000MCount]
                            .link
                        }
                      >
                        <div className="flex space-x-1 items-center cursor-pointer hover:space-x-2 ease-in-out duration-200 transition-transform">
                          <h1 className="paragraph text-primary">
                            View Details
                          </h1>
                          <FaAnglesRight className="text-primary" size={15} />
                        </div>
                      </a>
                      <a href="/getInTouch">
                        <button className="paragraph px-2 py-0.5 bg-primary text-black rounded-md">
                          Book Now
                        </button>
                      </a>
                    </div>
                  </div>

                  {/* <div className="absolute bg-black w-full h-8 top-0 left-0 flex justify-center items-center">
                    <h1 className="subHeader text-white">Booking Open</h1>
                  </div> */}
                </div>
              )}
            </div>

            <div
              className="col-start-6 col-span-2 margin flex space-x-2 justify-center items-center w-full h-auto"
              data-aos="fade-up"
              data-aos-duration="800"
            >
              <div className="sm:invisible sm:hidden">
                <FaLongArrowAltLeft
                  className={`${
                    upcomingExpedition5000MCount > 0
                      ? "text-primary cursor-pointer"
                      : "text-gray-400"
                  }`}
                  size={25}
                  onClick={() => {
                    if (upcomingExpedition5000MCount > 0) {
                      setUpcomingExpedition5000MCount((prevCount) =>
                        prevCount > 0 ? prevCount - 1 : 0
                      );
                    }
                  }}
                />
              </div>
              <div className="sm:invisible sm:hidden">
                <FaLongArrowAltRight
                  className={`${
                    upcomingExpedition5000MCount <
                    Math.ceil(sortedExpedition5000M.length / 1) - 1
                      ? "text-primary cursor-pointer"
                      : "text-gray-400"
                  }`}
                  size={25}
                  onClick={() => {
                    if (
                      upcomingExpedition5000MCount <
                      Math.ceil(sortedExpedition5000M.length / 1) - 1
                    ) {
                      setUpcomingExpedition5000MCount(
                        (prevCount) => prevCount + 1
                      );
                    }
                  }}
                />
              </div>
              <div className="invisible hidden sm:visible sm:block lg:invisible lg:hidden">
                <FaLongArrowAltLeft
                  className={`${
                    upcomingExpedition5000MCount > 0
                      ? "text-primary cursor-pointer"
                      : "text-gray-400"
                  }`}
                  size={25}
                  onClick={() => {
                    if (upcomingExpedition5000MCount > 0) {
                      setUpcomingExpedition5000MCount((prevCount) =>
                        prevCount > 0 ? prevCount - 1 : 0
                      );
                    }
                  }}
                />
              </div>
              <div className="invisible hidden sm:visible sm:block lg:invisible lg:hidden">
                <FaLongArrowAltRight
                  className={`${
                    upcomingExpedition5000MCount <
                    Math.ceil(sortedExpedition5000M.length / 2) - 1
                      ? "text-primary cursor-pointer"
                      : "text-gray-400"
                  }`}
                  size={25}
                  onClick={() => {
                    if (
                      upcomingExpedition5000MCount <
                      Math.ceil(sortedExpedition5000M.length / 2) - 1
                    ) {
                      setUpcomingExpedition5000MCount(
                        (prevCount) => prevCount + 1
                      );
                    }
                  }}
                />
              </div>
              <div className="invisible hidden lg:visible lg:block">
                <FaLongArrowAltLeft
                  className={`${
                    upcomingExpedition5000MCount > 0
                      ? "text-primary cursor-pointer"
                      : "text-gray-400"
                  }`}
                  size={25}
                  onClick={() => {
                    if (upcomingExpedition5000MCount > 0) {
                      setUpcomingExpedition5000MCount((prevCount) =>
                        prevCount > 0 ? prevCount - 1 : 0
                      );
                    }
                  }}
                />
              </div>
              <div className="invisible hidden lg:visible lg:block">
                <FaLongArrowAltRight
                  className={`${
                    upcomingExpedition5000MCount <
                    Math.ceil(sortedExpedition5000M.length / 3) - 1
                      ? "text-primary cursor-pointer"
                      : "text-gray-400"
                  }`}
                  size={25}
                  onClick={() => {
                    if (
                      upcomingExpedition5000MCount <
                      Math.ceil(sortedExpedition5000M.length / 3) - 1
                    ) {
                      setUpcomingExpedition5000MCount(
                        (prevCount) => prevCount + 1
                      );
                    }
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* All Expeditions Section  */}
      <section className="center">
        <div className="container">
          <div className="grid grid-cols-12 gap-2">
            <h1
              className="header col-start-1 col-span-12 text-center"
              data-aos="fade-up"
              data-aos-duration="800"
            >
              {FilterExpedition.expeditionPackageName}
            </h1>

            {/* lg device onwards  */}
            <div className=" col-start-1 col-span-12 grid-cols-12 gap-2 invisible hidden lg:visible lg:grid">
              <div
                className="col-start-1 col-span-4  rounded-lg aspect-square"
                data-aos="fade-up"
                data-aos-duration="800"
                data-aos-offset="400"
              >
                {firstArray.map((expedition, index) => (
                  <div
                    key={index}
                    className="relative margin col-start-1 col-span-4 rounded-lg aspect-square overflow-hidden"
                  >
                    {/* Image div */}
                    <div className="bg-primary w-full h-4/5 rounded-lg overflow-hidden relative">
                      <img
                        src={expedition.img}
                        alt="Expeditions"
                        className="w-full h-full object-cover"
                      />
                      <div className="absolute w-full h-full bg-black bg-opacity-50 top-0 left-0 hover:bg-opacity-70 ease-in-out duration-150 flex items-end">
                        <div className="w-full h-20 bg-transparent flex flex-col justify-center px-4">
                          <h1 className="paragraph text-primary">
                            {expedition.groupBy}
                          </h1>
                          <h1 className="extraSmall text-white">
                            {expedition.days}
                          </h1>
                        </div>
                      </div>
                    </div>

                    {/* Expedition Details */}
                    <div className="w-full py-1 h-1/5 rounded-b-lg flex flex-col justify-center ">
                      <h1 className="tagline px-2">{expedition.title}</h1>
                      <div className="w-full px-2 flex justify-between items-center">
                        <a href={expedition.link}>
                          <div className="flex space-x-1 items-center cursor-pointer hover:space-x-2 ease-in-out duration-200 transition-transform">
                            <h1 className="extraSmall text-primary">
                              View Details
                            </h1>
                            <FaAnglesRight className="text-primary" size={20} />
                          </div>
                        </a>
                        <a href="/getInTouch">
                          <button className="extraSmall px-2 py-1 bg-primary text-black rounded-md">
                            Book Now
                          </button>
                        </a>
                      </div>
                    </div>

                    {/* Uncomment this section if needed */}
                    {/* <div className="absolute bg-black w-full h-12 top-0 left-0 flex justify-center items-center">
          <h1 className="subHeader text-white">Booking Open</h1>
      </div> */}
                  </div>
                ))}
              </div>

              <div
                className="col-start-5 col-span-4  rounded-lg aspect-square"
                data-aos="fade-up"
                data-aos-duration="800"
                data-aos-offset="400"
                data-aos-delay="300"
              >
                {secondArray.map((expedition, index) => (
                  <div
                    key={index}
                    className="relative margin col-start-1 col-span-4 rounded-lg aspect-square overflow-hidden"
                  >
                    {/* Image div */}
                    <div className="bg-primary w-full h-4/5 rounded-lg overflow-hidden relative">
                      <img
                        src={expedition.img}
                        alt="Expeditions"
                        className="w-full h-full object-cover"
                      />
                      <div className="absolute w-full h-full bg-black bg-opacity-50 top-0 left-0 hover:bg-opacity-70 ease-in-out duration-150 flex items-end">
                        <div className="w-full h-20 bg-transparent flex flex-col justify-center px-4">
                          <h1 className="paragraph text-primary">
                            {expedition.groupBy}
                          </h1>
                          <h1 className="extraSmall text-white">
                            {expedition.days}
                          </h1>
                        </div>
                      </div>
                    </div>

                    {/* Expedition Details */}
                    <div className="w-full py-1 h-1/5 rounded-b-lg flex flex-col justify-center ">
                      <h1 className="tagline px-2">{expedition.title}</h1>
                      <div className="w-full px-2 flex justify-between items-center">
                        <a href={expedition.link}>
                          <div className="flex space-x-1 items-center cursor-pointer hover:space-x-2 ease-in-out duration-200 transition-transform">
                            <h1 className="extraSmall text-primary">
                              View Details
                            </h1>
                            <FaAnglesRight className="text-primary" size={20} />
                          </div>
                        </a>
                        <a href="/getInTouch">
                          <button className="extraSmall px-2 py-1 bg-primary text-black rounded-md">
                            Book Now
                          </button>
                        </a>
                      </div>
                    </div>

                    {/* Uncomment this section if needed */}
                    {/* <div className="absolute bg-black w-full h-12 top-0 left-0 flex justify-center items-center">
          <h1 className="subHeader text-white">Booking Open</h1>
      </div> */}
                  </div>
                ))}
              </div>

              <div
                className="col-start-9 col-span-4  rounded-lg aspect-square"
                data-aos="fade-up"
                data-aos-duration="800"
                data-aos-offset="400"
                data-aos-delay="600"
              >
                {thirdArray.map((expedition, index) => (
                  <div
                    key={index}
                    className="relative margin col-start-1 col-span-4 rounded-lg aspect-square overflow-hidden"
                  >
                    {/* Image div */}
                    <div className="bg-primary w-full h-4/5 rounded-lg overflow-hidden relative">
                      <img
                        src={expedition.img}
                        alt="Expeditions"
                        className="w-full h-full object-cover"
                      />
                      <div className="absolute w-full h-full bg-black bg-opacity-50 top-0 left-0 hover:bg-opacity-70 ease-in-out duration-150 flex items-end">
                        <div className="w-full h-20 bg-transparent flex flex-col justify-center px-4">
                          <h1 className="paragraph text-primary">
                            {expedition.groupBy}
                          </h1>
                          <h1 className="extraSmall text-white">
                            {expedition.days}
                          </h1>
                        </div>
                      </div>
                    </div>

                    {/* Expedition Details */}
                    <div className="w-full py-1 h-1/5 rounded-b-lg flex flex-col justify-center ">
                      <h1 className="tagline px-2">{expedition.title}</h1>
                      <div className="w-full px-2 flex justify-between items-center">
                        <a href={expedition.link}>
                          <div className="flex space-x-1 items-center cursor-pointer hover:space-x-2 ease-in-out duration-200 transition-transform">
                            <h1 className="extraSmall text-primary">
                              View Details
                            </h1>
                            <FaAnglesRight className="text-primary" size={20} />
                          </div>
                        </a>
                        <a href="/getInTouch">
                          <button className="extraSmall px-2 py-1 bg-primary text-black rounded-md">
                            Book Now
                          </button>
                        </a>
                      </div>
                    </div>

                    {/* Uncomment this section if needed */}
                    {/* <div className="absolute bg-black w-full h-12 top-0 left-0 flex justify-center items-center">
          <h1 className="subHeader text-white">Booking Open</h1>
      </div> */}
                  </div>
                ))}
              </div>
            </div>

            {/* sm to md device */}
            <div className=" col-start-1 col-span-12 grid-cols-12 gap-2 invisible hidden sm:visible sm:grid lg:invisible lg:hidden">
              <div
                className="col-start-1 col-span-6  rounded-lg aspect-square"
                data-aos="fade-up"
                data-aos-duration="800"
                data-aos-offset="400"
              >
                {oddExpedition.map((expedition, index) => (
                  <div
                    key={index}
                    className="relative margin col-start-1 col-span-4 rounded-lg aspect-square overflow-hidden"
                  >
                    {/* Image div */}
                    <div className="bg-primary w-full h-4/5 rounded-lg overflow-hidden relative">
                      <img
                        src={expedition.img}
                        alt="Expeditions"
                        className="w-full h-full object-cover"
                      />
                      <div className="absolute w-full h-full bg-black bg-opacity-50 top-0 left-0 hover:bg-opacity-70 ease-in-out duration-150 flex items-end">
                        <div className="w-full h-20 bg-transparent flex flex-col justify-center px-4">
                          <h1 className="tagline text-primary">
                            {expedition.groupBy}
                          </h1>
                          <h1 className="paragraph text-white">
                            {expedition.days}
                          </h1>
                        </div>
                      </div>
                    </div>

                    {/* Expedition Details */}
                    <div className="w-full py-1 h-1/5 rounded-b-lg flex flex-col justify-center ">
                      <h1 className="tagline px-2">{expedition.title}</h1>
                      <div className="w-full px-2 flex justify-between items-center">
                        <a href={expedition.link}>
                          <div className="flex space-x-1 items-center cursor-pointer hover:space-x-2 ease-in-out duration-200 transition-transform">
                            <h1 className="paragraph text-primary">
                              View Details
                            </h1>
                            <FaAnglesRight className="text-primary" size={20} />
                          </div>
                        </a>
                        <a href="/getInTouch">
                          <button className="paragraph px-2 py-1 bg-primary text-black rounded-md">
                            Book Now
                          </button>
                        </a>
                      </div>
                    </div>

                    {/* Uncomment this section if needed */}
                    {/* <div className="absolute bg-black w-full h-12 top-0 left-0 flex justify-center items-center">
          <h1 className="subHeader text-white">Booking Open</h1>
      </div> */}
                  </div>
                ))}
              </div>

              <div
                className="col-start-7 col-span-6  rounded-lg aspect-square"
                data-aos="fade-up"
                data-aos-duration="800"
                data-aos-offset="400"
                data-aos-delay="300"
              >
                {evenExpedition.map((expedition, index) => (
                  <div
                    key={index}
                    className="relative margin col-start-1 col-span-4 rounded-lg aspect-square overflow-hidden"
                  >
                    {/* Image div */}
                    <div className="bg-primary w-full h-4/5 rounded-lg overflow-hidden relative">
                      <img
                        src={expedition.img}
                        alt="Expeditions"
                        className="w-full h-full object-cover"
                      />
                      <div className="absolute w-full h-full bg-black bg-opacity-50 top-0 left-0 hover:bg-opacity-70 ease-in-out duration-150 flex items-end">
                        <div className="w-full h-20 bg-transparent flex flex-col justify-center px-4">
                          <h1 className="tagline text-primary">
                            {expedition.groupBy}
                          </h1>
                          <h1 className="paragraph text-white">
                            {expedition.days}
                          </h1>
                        </div>
                      </div>
                    </div>

                    {/* Expedition Details */}
                    <div className="w-full py-1 h-1/5 rounded-b-lg flex flex-col justify-center ">
                      <h1 className="tagline px-2">{expedition.title}</h1>
                      <div className="w-full px-2 flex justify-between items-center">
                        <a href={expedition.link}>
                          <div className="flex space-x-1 items-center cursor-pointer hover:space-x-2 ease-in-out duration-200 transition-transform">
                            <h1 className="paragraph text-primary">
                              View Details
                            </h1>
                            <FaAnglesRight className="text-primary" size={20} />
                          </div>
                        </a>
                        <a href="/getInTouch">
                          <button className="paragraph px-2 py-1 bg-primary text-black rounded-md">
                            Book Now
                          </button>
                        </a>
                      </div>
                    </div>

                    {/* Uncomment this section if needed */}
                    {/* <div className="absolute bg-black w-full h-12 top-0 left-0 flex justify-center items-center">
                        <h1 className="subHeader text-white">Booking Open</h1>
                    </div> */}
                  </div>
                ))}
              </div>
            </div>

            {/* Default device  */}
            <div className=" col-start-1 col-span-12 grid-cols-12 gap-2 visible grid sm:invisible sm:hidden">
              <div
                className="col-start-1 col-span-12  rounded-lg aspect-square"
                data-aos="fade-up"
                data-aos-duration="800"
                data-aos-offset="400"
              >
                {FilterExpedition.expeditionArray.map((expedition, index) => (
                  <div
                    key={index}
                    className="relative margin col-start-1 col-span-4 rounded-lg aspect-square overflow-hidden"
                  >
                    {/* Image div */}
                    <div className="bg-primary w-full h-4/5 rounded-lg overflow-hidden relative">
                      <img
                        src={expedition.img}
                        alt="Expeditions"
                        className="w-full h-full object-cover"
                      />
                      <div className="absolute w-full h-full bg-black bg-opacity-50 top-0 left-0 hover:bg-opacity-70 ease-in-out duration-150 flex items-end">
                        <div className="w-full h-20 bg-transparent flex flex-col justify-center px-4">
                          <h1 className="tagline text-primary">
                            {expedition.groupBy}
                          </h1>
                          <h1 className="paragraph text-white">
                            {expedition.days}
                          </h1>
                        </div>
                      </div>
                    </div>

                    {/* Expedition Details */}
                    <div className="w-full py-1 h-1/5 rounded-b-lg flex flex-col justify-center ">
                      <h1 className="tagline px-2">{expedition.title}</h1>
                      <div className="w-full px-2 flex justify-between items-center">
                        <a href={expedition.link}>
                          <div className="flex space-x-1 items-center cursor-pointer hover:space-x-2 ease-in-out duration-200 transition-transform">
                            <h1 className="paragraph text-primary">
                              View Details
                            </h1>
                            <FaAnglesRight className="text-primary" size={20} />
                          </div>
                        </a>
                        <a href="/getInTouch">
                          <button className="paragraph px-2 py-1 bg-primary text-black rounded-md">
                            Book Now
                          </button>
                        </a>
                      </div>
                    </div>

                    {/* Uncomment this section if needed */}
                    {/* <div className="absolute bg-black w-full h-12 top-0 left-0 flex justify-center items-center">
          <h1 className="subHeader text-white">Booking Open</h1>
      </div> */}
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Instagram Section  */}
      <section className="center">
        <div className="container bg-black">
          <div className="grid grid-cols-12 gap-2 ">
            <h1
              className="header col-start-1 col-span-12 text-center"
              data-aos="fade-up"
              data-aos-duration="800"
            >
              Our Instagram
            </h1>
            <a
              href="https://www.instagram.com/white.expedition/"
              target="_blank"
              rel="noopener noreferrer"
              className="col-start-1 col-span-12 text-center"
            >
              <h1 className="tagline  text-primary">@white.expedition</h1>
            </a>
            <div className="col-start-1 col-span-12 rounded-md overflow-hidden margin">
              <InstaFeeds />
            </div>
          </div>
        </div>
      </section>

      {/* Footer section  */}
      <section
        className="relative bg-center bg-cover"
        style={{ backgroundImage: `url(${expedition})` }}
      >
        <div className="absolute inset-0 bg-primary opacity-80 z-10"></div>
        <div className="container relative z-20">
          <div className="grid grid-cols-12 gap-2">
            <div className="col-start-1 col-span-12">
              <Footer />
            </div>
          </div>
        </div>
      </section>

      <Whatsapp />

      {isVisible && <UpcomingTours close={() => setIsVisible(false)} />}

      <UpcomingToursIcon
        open={() => {
          setIsVisible(true);
        }}
      />
    </>
  );
};

export default ExpeditionTemplate;
