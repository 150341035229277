import certificate1 from "../../assets/certificates/1.webp";
import certificate2 from "../../assets/certificates/2.webp";
import certificate3 from "../../assets/certificates/3.webp";
import certificate4 from "../../assets/certificates/4.webp";
import certificate5 from "../../assets/certificates/5.webp";
import certificate6 from "../../assets/certificates/6.webp";
import certificate7 from "../../assets/certificates/7.webp";
import certificate8 from "../../assets/certificates/8.webp";
import certificate9 from "../../assets/certificates/9.webp";
import certificate10 from "../../assets/certificates/10.webp";

export const IndividualCerti = [
  {
    index: 1,
    img: certificate1,
  },
  {
    index: 2,
    img: certificate2,
  },
  {
    index: 3,
    img: certificate3,
  },
  {
    index: 4,
    img: certificate4,
  },
  {
    index: 5,
    img: certificate5,
  },
  {
    index: 6,
    img: certificate6,
  },
  {
    index: 7,
    img: certificate7,
  },
  {
    index: 8,
    img: certificate8,
  },
  {
    index: 9,
    img: certificate9,
  },
  {
    index: 10,
    img: certificate10,
  },
];
