import React, { useEffect, useState } from "react";
import HeroSection from "../components/HeroSection";
import { CertificatesHeroSection } from "../data/Home/dataStorage";
import NavigationBar from "../components/NavigationBar";

import InstaFeeds from "../components/InstaFeeds";

import certificate1 from "../assets/certificates/1.webp";
import certificate2 from "../assets/certificates/2.webp";
import certificate3 from "../assets/certificates/3.webp";
import certificate4 from "../assets/certificates/4.webp";
import certificate5 from "../assets/certificates/5.webp";
import certificate6 from "../assets/certificates/6.webp";
import certificate7 from "../assets/certificates/7.webp";
import certificate8 from "../assets/certificates/8.webp";
import certificate9 from "../assets/certificates/9.webp";
import certificate10 from "../assets/certificates/10.webp";

import expedition from "../assets/home/hero/expedition.webp";

import AOS from "aos";
import "aos/dist/aos.css";
import Footer from "../components/Footer";
import { useNavigate } from "react-router-dom";
import Whatsapp from "../components/Whatsapp";
import { Helmet } from "react-helmet";
import UpcomingTours from "../components/UpcomingTours";
import UpcomingToursIcon from "../components/UpcomingToursIcon";

const Certificates = () => {
  useEffect(() => {
    AOS.init();
  }, []);

  const [isVisible, setIsVisible] = useState(false);

  const navigate = useNavigate();
  return (
    <>
      <Helmet>
        <title>White Expedition - Certificates</title>
        <meta
          name="description"
          content="Climb with confidence! White Expedition's 'Certificates' page showcases our team's qualifications and certifications for safe and professional climbs."
        />
      </Helmet>

      {/* Hero Section  */}
      <HeroSection carouselData={CertificatesHeroSection} />

      {/* Navigation bar Section  */}
      <section className="center">
        <NavigationBar />
      </section>

      {/* Certification Section  */}
      <section className="center bg-black">
        <div className="container">
          <div className="grid grid-cols-12 gap-2">
            {/* Certificate 1  */}
            <div
              className="relative col-start-1 col-span-12 h-[400px] sm:col-start-3 sm:col-span-8 sm:h-[450px] md:col-start-1 md:col-span-6 md:h-[450px] lg:h-[500px] xl:col-start-1 xl:col-span-4 xl:h-[530px] 2xl:h-[600px] overflow-hidden bg-white rounded-md margin group hover:cursor-pointer"
              data-aos="fade-up"
              data-aos-duration="800"
              onClick={() => {
                navigate("/certificates/1");
              }}
            >
              <img
                src={certificate1}
                alt="certificate 1"
                className="w-full h-full object-cover"
              />
            </div>

            {/* Certificate 2  */}
            <div
              className="relative col-start-1 col-span-12 h-[400px] sm:col-start-3 sm:col-span-8 sm:h-[450px] md:col-start-7 md:col-span-6 md:h-[450px] lg:h-[500px] xl:col-start-5 xl:col-span-4 xl:h-[530px] 2xl:h-[600px] overflow-hidden bg-white rounded-md margin group hover:cursor-pointer"
              data-aos="fade-up"
              data-aos-duration="800"
              onClick={() => {
                navigate("/certificates/2");
              }}
            >
              <img
                src={certificate2}
                alt="certificate 2"
                className="w-full h-full object-cover"
              />
            </div>

            {/* Certificate 3  */}
            <div
              className="relative col-start-1 col-span-12 h-[400px] sm:col-start-3 sm:col-span-8 sm:h-[450px] md:col-start-4 md:col-span-6 md:h-[450px] lg:h-[500px] xl:col-start-9 xl:col-span-4 xl:h-[530px] 2xl:h-[600px] overflow-hidden bg-white rounded-md margin group hover:cursor-pointer"
              data-aos="fade-up"
              data-aos-duration="800"
              onClick={() => {
                navigate("/certificates/3");
              }}
            >
              <img
                src={certificate3}
                alt="certificate 3"
                className="w-full h-full object-cover"
              />
            </div>

            {/* Certificate 4  */}
            <div
              className="relative col-start-1 col-span-12 h-[400px] sm:col-start-3 sm:col-span-8 sm:h-[450px] md:col-start-1 md:col-span-6 md:h-[450px] lg:h-[500px] xl:col-start-1 xl:col-span-4 xl:h-[530px] 2xl:h-[600px] overflow-hidden bg-white rounded-md margin group hover:cursor-pointer"
              data-aos="fade-up"
              data-aos-duration="800"
              onClick={() => {
                navigate("/certificates/4");
              }}
            >
              <img
                src={certificate4}
                alt="certificate 4"
                className="w-full h-full object-cover"
              />
            </div>

            {/* Certificate 5  */}
            <div
              className="relative col-start-1 col-span-12 h-[400px] sm:col-start-3 sm:col-span-8 sm:h-[450px] md:col-start-7 md:col-span-6 md:h-[450px] lg:h-[500px] xl:col-start-5 xl:col-span-4 xl:h-[530px] 2xl:h-[600px] overflow-hidden bg-white rounded-md margin group hover:cursor-pointer"
              data-aos="fade-up"
              data-aos-duration="800"
              onClick={() => {
                navigate("/certificates/5");
              }}
            >
              <img
                src={certificate5}
                alt="certificate 5"
                className="w-full h-full object-cover"
              />
            </div>

            {/* Member 6  */}
            <div
              className="relative col-start-1 col-span-12 h-[400px] sm:col-start-3 sm:col-span-8 sm:h-[450px] md:col-start-4 md:col-span-6 md:h-[450px] lg:h-[500px] xl:col-start-9 xl:col-span-4 xl:h-[530px] 2xl:h-[600px] overflow-hidden bg-white rounded-md margin group hover:cursor-pointer"
              data-aos="fade-up"
              data-aos-duration="800"
              onClick={() => {
                navigate("/certificates/6");
              }}
            >
              <img
                src={certificate6}
                alt="certificate 6"
                className="w-full h-full object-cover"
              />
            </div>

            {/* Member 7  */}
            <div
              className="relative col-start-1 col-span-12 h-[400px] sm:col-start-3 sm:col-span-8 sm:h-[450px] md:col-start-1 md:col-span-6 md:h-[450px] lg:h-[500px] xl:col-start-1 xl:col-span-4 xl:h-[530px] 2xl:h-[600px] overflow-hidden bg-white rounded-md margin group hover:cursor-pointer"
              data-aos="fade-up"
              data-aos-duration="800"
              onClick={() => {
                navigate("/certificates/7");
              }}
            >
              <img
                src={certificate7}
                alt="certificate 7"
                className="w-full h-full object-cover"
              />
            </div>

            {/* Member 8  */}
            <div
              className="relative col-start-1 col-span-12 h-[400px] sm:col-start-3 sm:col-span-8 sm:h-[450px] md:col-start-7 md:col-span-6 md:h-[450px] lg:h-[500px] xl:col-start-5 xl:col-span-4 xl:h-[530px] 2xl:h-[600px] overflow-hidden bg-white rounded-md margin group hover:cursor-pointer"
              data-aos="fade-up"
              data-aos-duration="800"
              onClick={() => {
                navigate("/certificates/8");
              }}
            >
              <img
                src={certificate8}
                alt="certificate 8"
                className="w-full h-full object-cover"
              />
            </div>

            {/* Member 9  */}
            <div
              className="relative col-start-1 col-span-12 h-[400px] sm:col-start-3 sm:col-span-8 sm:h-[450px] md:col-start-4 md:col-span-6 md:h-[450px] lg:h-[500px] xl:col-start-9 xl:col-span-4 xl:h-[530px] 2xl:h-[600px] overflow-hidden bg-white rounded-md margin group hover:cursor-pointer"
              data-aos="fade-up"
              data-aos-duration="800"
              onClick={() => {
                navigate("/certificates/9");
              }}
            >
              <img
                src={certificate9}
                alt="certificate 9"
                className="w-full h-full object-cover"
              />
            </div>

            {/* Member 10  */}
            <div
              className="relative col-start-1 col-span-12 h-[400px] sm:col-start-3 sm:col-span-8 sm:h-[450px] md:col-start-1 md:col-span-12 md:h-[500px] lg:h-[550px] xl:col-start-1 xl:col-span-12 xl:h-[800px] 2xl:h-[800px] overflow-hidden bg-white rounded-md margin group hover:cursor-pointer"
              data-aos="fade-up"
              data-aos-duration="800"
              onClick={() => {
                navigate("/certificates/10");
              }}
            >
              <img
                src={certificate10}
                alt="certificate 10"
                className="w-full h-full object-cover"
              />
            </div>
          </div>
        </div>
      </section>

      {/* Instagram Section  */}
      <section className="center">
        <div className="container">
          <div className="grid grid-cols-12 gap-2 ">
            <h1
              className="header col-start-1 col-span-12 text-center"
              data-aos="fade-up"
              data-aos-duration="800"
            >
              Our Instagram
            </h1>
            <a
              href="https://www.instagram.com/white.expedition/"
              target="_blank"
              rel="noopener noreferrer"
              className="col-start-1 col-span-12 text-center"
            >
              <h1 className="tagline  text-primary">@white.expedition</h1>
            </a>
            <div className="col-start-1 col-span-12 rounded-md overflow-hidden margin">
              <InstaFeeds />
            </div>
          </div>
        </div>
      </section>

      {/* Footer section  */}
      <section
        className="relative bg-center bg-cover"
        style={{ backgroundImage: `url(${expedition})` }}
      >
        <div className="absolute inset-0 bg-primary opacity-80 z-10"></div>
        <div className="container relative z-20">
          <div className="grid grid-cols-12 gap-2">
            <div className="col-start-1 col-span-12">
              <Footer />
            </div>
          </div>
        </div>
      </section>

      <Whatsapp />

      {isVisible && <UpcomingTours close={() => setIsVisible(false)} />}

      <UpcomingToursIcon
        open={() => {
          setIsVisible(true);
        }}
      />
    </>
  );
};

export default Certificates;
