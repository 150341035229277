import React, { useEffect, useState } from "react";
import HeroSection from "../components/HeroSection";
import { AchievementsRecordsHeroSection } from "../data/Home/dataStorage";
import NavigationBar from "../components/NavigationBar";

import InstaFeeds from "../components/InstaFeeds";

import achievementsRecords1 from "../assets/achievements-Records/1.webp";
import achievementsRecords2 from "../assets/achievements-Records/2.webp";
import achievementsRecords3 from "../assets/achievements-Records/3.webp";
import achievementsRecords4 from "../assets/achievements-Records/4.webp";
import achievementsRecords5 from "../assets/achievements-Records/5.webp";

import expedition from "../assets/home/hero/expedition.webp";

import AOS from "aos";
import "aos/dist/aos.css";
import Footer from "../components/Footer";
import { useNavigate } from "react-router-dom";
import Whatsapp from "../components/Whatsapp";
import { Helmet } from "react-helmet";
import UpcomingTours from "../components/UpcomingTours";
import UpcomingToursIcon from "../components/UpcomingToursIcon";

const AchievementsRecords = () => {
  useEffect(() => {
    AOS.init();
  }, []);

  const [isVisible, setIsVisible] = useState(false);

  const navigate = useNavigate();
  return (
    <>
      <Helmet>
        <title>White Expedition - Achievements & Records</title>
        <meta
          name="description"
          content="Celebrating success! White Expedition's 'Achievements & Records' page showcases our team's climbing triumphs and milestones."
        />
      </Helmet>

      {/* Hero Section  */}
      <HeroSection carouselData={AchievementsRecordsHeroSection} />

      {/* Navigation bar Section  */}
      <section className="center">
        <NavigationBar />
      </section>

      {/* Achievements & Record Section  */}
      <section className="center bg-black">
        <div className="container">
          <div className="grid grid-cols-12 gap-2">
            {/* Achievments & Record 1  */}
            <div
              className="relative col-start-1 col-span-12 h-[400px] sm:col-start-3 sm:col-span-8 sm:h-[450px] md:col-start-1 md:col-span-6 md:h-[450px] lg:h-[500px] xl:col-start-1 xl:col-span-4 xl:h-[530px] 2xl:h-[600px] overflow-hidden bg-white rounded-md margin group hover:cursor-pointer"
              data-aos="fade-up"
              data-aos-duration="800"
              onClick={() => {
                navigate("/AchievementsRecords/1");
              }}
            >
              <img
                src={achievementsRecords1}
                alt="achievementsRecords 1"
                className="w-full h-full object-cover"
              />
            </div>

            {/* Achievments & Record 2  */}
            <div
              className="relative col-start-1 col-span-12 h-[400px] sm:col-start-3 sm:col-span-8 sm:h-[450px] md:col-start-7 md:col-span-6 md:h-[450px] lg:h-[500px] xl:col-start-5 xl:col-span-4 xl:h-[530px] 2xl:h-[600px] overflow-hidden bg-white rounded-md margin group hover:cursor-pointer"
              data-aos="fade-up"
              data-aos-duration="800"
              onClick={() => {
                navigate("/AchievementsRecords/2");
              }}
            >
              <img
                src={achievementsRecords2}
                alt="achievementsRecords 2"
                className="w-full h-full object-cover"
              />
            </div>

            {/* Achievments & Record 3  */}
            <div
              className="relative col-start-1 col-span-12 h-[400px] sm:col-start-3 sm:col-span-8 sm:h-[450px] md:col-start-4 md:col-span-6 md:h-[450px] lg:h-[500px] xl:col-start-9 xl:col-span-4 xl:h-[530px] 2xl:h-[600px] overflow-hidden bg-white rounded-md margin group hover:cursor-pointer"
              data-aos="fade-up"
              data-aos-duration="800"
              onClick={() => {
                navigate("/AchievementsRecords/3");
              }}
            >
              <img
                src={achievementsRecords3}
                alt="achievementsRecords 3"
                className="w-full h-full object-cover"
              />
            </div>

            {/* Achievments & Record 4  */}
            <div
              className="relative col-start-1 col-span-12 h-[400px] sm:col-start-3 sm:col-span-8 sm:h-[450px] md:col-start-1 md:col-span-6 md:h-[450px] lg:h-[500px] xl:col-start-1 xl:col-span-4 xl:h-[530px] 2xl:h-[600px] overflow-hidden bg-white rounded-md margin group hover:cursor-pointer"
              data-aos="fade-up"
              data-aos-duration="800"
              onClick={() => {
                navigate("/AchievementsRecords/4");
              }}
            >
              <img
                src={achievementsRecords4}
                alt="achievementsRecords 4"
                className="w-full h-full object-cover"
              />
            </div>

            {/* Achievments & Record 5  */}
            <div
              className="relative col-start-1 col-span-12 h-[400px] sm:col-start-3 sm:col-span-8 sm:h-[450px] md:col-start-7 md:col-span-6 md:h-[450px] lg:h-[500px] xl:col-start-5 xl:col-span-4 xl:h-[530px] 2xl:h-[600px] overflow-hidden bg-white rounded-md margin group hover:cursor-pointer"
              data-aos="fade-up"
              data-aos-duration="800"
              onClick={() => {
                navigate("/AchievementsRecords/5");
              }}
            >
              <img
                src={achievementsRecords5}
                alt="achievementsRecords 5"
                className="w-full h-full object-cover"
              />
            </div>
          </div>
        </div>
      </section>

      {/* Instagram Section  */}
      <section className="center">
        <div className="container">
          <div className="grid grid-cols-12 gap-2 ">
            <h1
              className="header col-start-1 col-span-12 text-center"
              //   data-aos="fade-up"
              //   data-aos-duration="800"
            >
              Our Instagram
            </h1>
            <a
              href="https://www.instagram.com/white.expedition/"
              target="_blank"
              rel="noopener noreferrer"
              className="col-start-1 col-span-12 text-center"
            >
              <h1 className="tagline  text-primary">@white.expedition</h1>
            </a>
            <div className="col-start-1 col-span-12 rounded-md overflow-hidden margin">
              <InstaFeeds />
            </div>
          </div>
        </div>
      </section>

      {/* Footer section  */}
      <section
        className="relative bg-center bg-cover"
        style={{ backgroundImage: `url(${expedition})` }}
      >
        <div className="absolute inset-0 bg-primary opacity-80 z-10"></div>
        <div className="container relative z-20">
          <div className="grid grid-cols-12 gap-2">
            <div className="col-start-1 col-span-12">
              <Footer />
            </div>
          </div>
        </div>
      </section>

      <Whatsapp />

      {isVisible && <UpcomingTours close={() => setIsVisible(false)} />}

      <UpcomingToursIcon
        open={() => {
          setIsVisible(true);
        }}
      />
    </>
  );
};

export default AchievementsRecords;
