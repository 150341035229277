import {
  adventureTour,
  expedition5000M,
  expedition6000M,
  expedition7000M,
  pureAdrenaline,
  trekking,
} from "../../data/Expeditions/dataStorage";
import {
  adventureToursHeroSection,
  expedition5000MHeroSection,
  expedition6000MHeroSection,
  expedition7000MHeroSection,
  expeditionPureAdrenalineHeroSection,
  expeditionTrekkingHeroSection,
} from "../../data/Home/dataStorage";

export const expeditionCategory = [
  {
    expeditionType: "5000m-expedition",
    expeditionArray: expedition5000M,
    carouselData: expedition5000MHeroSection,
    aboutExpeditionType:
      "This is your journey of self-discovery with 5000 M, a haven for beginners venturing into the realm of high mountains. Elevate your spirit and skills as you embrace the challenge, taking those crucial initial steps toward conquering the peaks. The adventure of a lifetime awaits as you commence this thrilling ascent",
    expeditionPackageName: "Over 5000 Meters Packages",
  },
  {
    expeditionType: "6000m-expedition",
    expeditionArray: expedition6000M,
    carouselData: expedition6000MHeroSection,
    aboutExpeditionType:
      "At 6000 M, intermediate adventurers hone their skills, chiselling themselves to perfection amidst towering heights.Sharpen your resolve and expertise, when navigating the challenges of higher altitudes. This is the crucible where self-transformation unfolds, forging a path of growth and triumph. Embrace the journey, sculpting your capabilities with each ascent.",
    expeditionPackageName: "Over 6000 Meters Packages",
  },
  {
    expeditionType: "7000m-expedition",
    expeditionArray: expedition7000M,
    carouselData: expedition7000MHeroSection,
    aboutExpeditionType:
      "Ascend to 7000 M, where advanced mountaineers push limits and test their mettle against formidable peaks. Here, the mountains become a stage to showcase the indomitable spirit within. Declare to the world what you're made of, conquering heights that speak volumes about your resilience and unwavering determination.",
    expeditionPackageName: "Over 7000 Meters Packages",
  },
  {
    expeditionType: "pure-adrenaline-expedition",
    expeditionArray: pureAdrenaline,
    carouselData: expeditionPureAdrenalineHeroSection,
    aboutExpeditionType:
      "Ascend to 7000 M, where advanced mountaineers push limits and test their mettle against formidable peaks. Here, the mountains become a stage to showcase the indomitable spirit within. Declare to the world what you're made of, conquering heights that speak volumes about your resilience and unwavering determination.",
    expeditionPackageName: "Pure Addrenaline Packages",
  },
  {
    expeditionType: "adventure-tours",
    expeditionArray: adventureTour,
    carouselData: adventureToursHeroSection,
    aboutExpeditionType:
      "Adventure tours offer a thrilling escape from the ordinary, immersing you in breathtaking landscapes and exhilarating activities. From scaling mountain peaks and whitewater rafting to exploring ancient ruins and diving into vibrant coral reefs, these tours cater to the adrenaline junkie and the curious explorer alike. Prepare to push your limits, create unforgettable memories, and discover the hidden wonders of the world.",
    expeditionPackageName: "Adventure Tours Packages",
  },
  {
    expeditionType: "trekking-expedition",
    expeditionArray: trekking,
    carouselData: expeditionTrekkingHeroSection,
    aboutExpeditionType:
      "Ascend to 7000 M, where advanced mountaineers push limits and test their mettle against formidable peaks. Here, the mountains become a stage to showcase the indomitable spirit within. Declare to the world what you're made of, conquering heights that speak volumes about your resilience and unwavering determination.",
    expeditionPackageName: "Trekking Packages",
  },
];
