import achievementsRecords1 from "../../assets/achievements-Records/1.webp";
import achievementsRecords2 from "../../assets/achievements-Records/2.webp";
import achievementsRecords3 from "../../assets/achievements-Records/3.webp";
import achievementsRecords4 from "../../assets/achievements-Records/4.webp";
import achievementsRecords5 from "../../assets/achievements-Records/5.webp";

export const IndividualAchievements = [
  {
    index: 1,
    img: achievementsRecords1,
  },
  {
    index: 2,
    img: achievementsRecords2,
  },
  {
    index: 3,
    img: achievementsRecords3,
  },
  {
    index: 4,
    img: achievementsRecords4,
  },
  {
    index: 5,
    img: achievementsRecords5,
  },
];
