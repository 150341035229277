import React, { useEffect, useState } from "react";

// Components for page structure
import NavigationBar from "../components/NavigationBar";
import HeroSection from "../components/HeroSection";
import InstaFeeds from "../components/InstaFeeds";
import Footer from "../components/Footer";
import Whatsapp from "../components/Whatsapp";

// Data for HeroSection
import { OurTeamHeroSection } from "../data/Home/dataStorage";

// Images for team members
import rickyMountaineer from "../assets/home/Members/Ricky.webp";
import nikitaThakur from "../assets/home/Members/Nikita_Thakur.webp";
import shubhamBisht from "../assets/home/Members/Shubham.webp";
import rameshKhadka from "../assets/team-member/Ramesh_karhka.webp";
import ishaniKuthiala from "../assets/team-member/Ishani.webp";
import abhishekSaha from "../assets/team-member/Abhishek_Saha.webp";
import AronSherpa from "../assets/team-member/Aron_Sherpa.webp";
import sahilMalik from "../assets/team-member/Sahil_Malik.webp";

// Other assets
import expedition from "../assets/home/hero/expedition.webp";

// Library for smooth scroll animations
import AOS from "aos";
import "aos/dist/aos.css";
import { Helmet } from "react-helmet";
import UpcomingTours from "../components/UpcomingTours";
import UpcomingToursIcon from "../components/UpcomingToursIcon";

const OurTeam = () => {
  useEffect(() => {
    // Initialize AOS library for animations
    AOS.init();
  }, []);

  const teamMembers = [
    {
      id: 1,
      imageSrc: rickyMountaineer, // Replace with actual image path
      name: "Ricky Mountaineer",
      title: "Director and Exped Leader",
      mdColStart: 1,
      xlColStart: 1,
    },
    {
      id: 2,
      imageSrc: nikitaThakur, // Replace with actual image path
      name: "Nikita Thakur",
      title: "Auditor & Exped Leader",
      mdColStart: 7,
      xlColStart: 5,
    },
    {
      id: 3,
      imageSrc: shubhamBisht, // Replace with actual image path
      name: "Shubham Bisht",
      title: "Managing Director & Exped Leader",
      mdColStart: 1,
      xlColStart: 9,
    },
    {
      id: 4,
      imageSrc: sahilMalik, // Replace with actual image path
      name: "Sahil Malik",
      title: "Exped Leader",
      mdColStart: 7,
      xlColStart: 1,
    },
    {
      id: 5,
      imageSrc: ishaniKuthiala, // Replace with actual image path
      name: "Ishani Kuthiala",
      title: "Mountain Guide and Ski Instructor",
      mdColStart: 1,
      xlColStart: 5,
    },
    {
      id: 6,
      imageSrc: abhishekSaha, // Replace with actual image path
      name: "Abhishek Saha",
      title: "Adventure Photographer",
      mdColStart: 7,
      xlColStart: 9,
    },
    {
      id: 7,
      imageSrc: AronSherpa, // Replace with actual image path
      name: "Aron Sherpa",
      title: "Mountaineer",
      mdColStart: 1,
      xlColStart: 1,
    },
    {
      id: 8,
      imageSrc: rameshKhadka, // Replace with actual image path
      name: "Ramesh Khadka",
      title: "HMI Instructor & Exped Leader",
      mdColStart: 7,
      xlColStart: 5,
    },
  ];

  const [isVisible, setIsVisible] = useState(false);

  return (
    <>
      <Helmet>
        <title>White Expedition - Our Team</title>
        <meta
          name="description"
          content="Climb with confidence! Get to know the certified and experienced team behind White Expedition on our 'Our Team' page."
        />
      </Helmet>

      {/* Hero Section with Carousel Data from OurTeamHeroSection */}
      <HeroSection carouselData={OurTeamHeroSection} />

      {/* Navigation Bar */}
      <section className="center">
        <NavigationBar />
      </section>

      {/* Team Member Section  */}
      <section className="team-section center bg-black">
        <div className="container">
          <div className="grid grid-cols-12 gap-2">
            {/* Team description */}
            <h1
              className="text-center paragraph col-span-full margin"
              data-aos="fade-up"
              data-aos-duration="800"
            >
              Our passionate team is led by the experienced mountaineer, Ricky.
              With over 20 conquered peaks and 50 successful expeditions under
              his belt, Ricky's expertise extends far beyond reaching summits.
              Joined by a diverse group of guides, photographers, and
              sustainability experts, we share a common goal: to create
              unforgettable adventures in the Himalayas that are safe,
              thrilling, and leave a positive impact on the region.
            </h1>

            {/* Team member grid */}
            {teamMembers.map((member) => (
              <div
                key={member.id}
                className={`relative col-start-1 col-span-12 h-[400px] sm:col-start-3 sm:col-span-8 sm:h-[450px] md:col-start-${member.mdColStart} md:col-span-6 md:h-[450px] lg:h-[500px] xl:col-start-${member.xlColStart} xl:col-span-4 xl:h-[530px] 2xl:h-[600px] overflow-hidden bg-white rounded-md margin group hover:cursor-pointer`}
                data-aos="fade-up"
                data-aos-duration="800"
              >
                <img
                  src={member.imageSrc}
                  alt={member.name}
                  className="w-full h-full object-cover"
                />
                <div className="absolute left-0 bottom-0 w-full h-24 transition-transform duration-300  bg-[#2C2C2C] flex flex-col justify-center items-start px-4 py-2 text-textColor">
                  <h1 className="mediumHeader text-primary">{member.name}</h1>
                  <h1 className="paragrph xl:extraSmall">{member.title}</h1>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* Instagram Section */}
      <section className="instagram-section center">
        <div className="container">
          <div className="grid grid-cols-12 gap-2">
            <h1 className="text-center header col-span-full">Our Instagram</h1>
            <a
              href="https://www.instagram.com/white.expedition/"
              target="_blank"
              rel="noopener noreferrer"
              className="text-center col-span-full"
            >
              <h1 className="tagline text-primary">@white.expedition</h1>
            </a>
            <div className="instagram-feed col-span-full rounded-md overflow-hidden margin">
              <InstaFeeds />
            </div>
          </div>
        </div>
      </section>

      {/* Footer Section */}
      <section
        className="footer-section relative bg-cover bg-center"
        style={{ backgroundImage: `url(${expedition})` }}
      >
        <div className="overlay absolute inset-0 bg-primary opacity-80 z-10"></div>
        <div className="container relative z-20">
          <div className="grid grid-cols-12 gap-2">
            <div className="col-span-full">
              <Footer />
            </div>
          </div>
        </div>
      </section>

      {/* WhatsApp Button */}
      <Whatsapp />

      {isVisible && <UpcomingTours close={() => setIsVisible(false)} />}

      <UpcomingToursIcon
        open={() => {
          setIsVisible(true);
        }}
      />
    </>
  );
};

export default OurTeam;
