import React, { useEffect, useState } from "react";
import HeroSection from "../components/HeroSection"; // Component for the hero section
import { WhyWithUsHeroSection } from "../data/Home/dataStorage"; // Data for the "Why with us?" section
import NavigationBar from "../components/NavigationBar"; // Component for the navigation bar
import Helmet from "react-helmet";

// Import icons from Font Awesome
import {
  FaHiking,
  FaMountain,
  FaTools,
  FaGlobeAsia,
  FaUsers,
  FaLeaf,
  FaStar,
  FaCalendarCheck,
} from "react-icons/fa";

import InstaFeeds from "../components/InstaFeeds"; // Component for displaying Instagram feeds
import Footer from "../components/Footer"; // Component for the footer
import expedition from "../assets/home/hero/expedition.webp"; // Image for the hero section

import AOS from "aos"; // Library for smooth scroll animations
import "aos/dist/aos.css"; // AOS stylesheet import
import Whatsapp from "../components/Whatsapp"; // Component for the WhatsApp link
import UpcomingTours from "../components/UpcomingTours";
import UpcomingToursIcon from "../components/UpcomingToursIcon";

const WhyWithUs = () => {
  useEffect(() => {
    // Initialize AOS library for animations
    AOS.init();
  }, []);

  const servicesData = [
    {
      id: 1,
      icon: <FaHiking className="text-primary" size={40} />,
      title: "Experienced Guides",
      description:
        "Our team consists of experienced and certified guides who prioritize safety while providing an unforgettable climbing experience.",
      mdColStart: 1,
      xlColStart: 1,
    },
    {
      id: 2,
      icon: <FaMountain className="text-primary" size={40} />,
      title: "Variety of Routes",
      description:
        "We offer a wide range of climbing routes suitable for beginners to experienced climbers, ensuring there's something for everyone.",
      mdColStart: 7,
      xlColStart: 5,
    },
    {
      id: 3,
      icon: <FaTools className="text-primary" size={40} />,
      title: "Equipment Provided",
      description:
        "We provide all necessary climbing equipment, so you can focus on enjoying the climb without worrying about gear.",
      mdColStart: 1,
      xlColStart: 9,
    },
    {
      id: 4,
      icon: <FaGlobeAsia className="text-primary" size={40} />,
      title: "Stunning Locations",
      description:
        "Our climbing destinations are chosen for their breathtaking scenery and unique challenges, providing a memorable outdoor adventure.",
      mdColStart: 7,
      xlColStart: 1,
    },
    {
      id: 5,
      icon: <FaCalendarCheck className="text-primary" size={40} />,
      title: "Customizable Trips",
      description:
        "Whether you're planning a solo adventure, a group outing, or a corporate retreat, we can tailor a climbing trip to suit your preferences and needs.",
      mdColStart: 1,
      xlColStart: 5,
    },
    {
      id: 6,
      icon: <FaLeaf className="text-primary" size={40} />,
      title: "Environmental Stewardship",
      description:
        "We are committed to responsible outdoor practices and minimizing our impact on the environment during climbs.",
      mdColStart: 7,
      xlColStart: 9,
    },
    {
      id: 7,
      icon: <FaStar className="text-primary" size={40} />,
      title: "Positive Reviews",
      description:
        "Don't just take our word for it! Check out the positive reviews from past climbers who have enjoyed their experiences with us.",
      mdColStart: 1,
      xlColStart: 3,
    },
    {
      id: 8,
      icon: <FaUsers className="text-primary" size={40} />,
      title: "Small Group Sizes",
      description:
        "We keep our climbing groups small to ensure personalized attention and a more intimate experience.",
      mdColStart: 7,
      xlColStart: 7,
    },
  ];

  const [isVisible, setIsVisible] = useState(false);

  return (
    <>
      <Helmet>
        <title>White Expedition - Why With Us ?</title>
        <meta
          name="description"
          content="Scale new heights with White Expedition! Led by the renowned Ricky Mountaineer, we're Himachal Pradesh's premier climbing company, guiding adventurers from India and globally to conquer challenging peaks. Join us and experience the thrill of the climb, reaching summits and maximizing your mountain adventure."
        />
      </Helmet>

      {/* Hero Section with Carousel Data from WhyWithUsHeroSection */}
      <HeroSection carouselData={WhyWithUsHeroSection} />

      {/* Navigation Bar */}
      <section className="center">
        <NavigationBar />
      </section>

      {/* About Us Section */}
      <section className="center">
        <div className="container">
          <div className="grid grid-cols-12 gap-2">
            <h1
              className="paragraph col-start-1 col-span-12 text-start margin"
              data-aos="fade-up"
              data-aos-duration="800"
            >
              Embark on an extraordinary Himalayan adventure with White
              Expedition! Led by the indomitable Ricky, a mountaineer with over
              20 conquered peaks and 50 successful expeditions, we offer more
              than just reaching the summit. We curate diverse experiences –
              from challenging treks to wildlife photography tours – all focused
              on safety, sustainability, and igniting your sense of adventure.
              Join us and explore the Himalayas responsibly, creating
              unforgettable memories and a deep connection with this
              awe-inspiring region.
            </h1>
          </div>
        </div>
      </section>

      {/* Key Statistics Section */}
      <section className="center">
        <div className="container bg-black">
          <div className="grid grid-cols-12 gap-2">
            <div
              className="col-start-1 col-span-12 sm:col-start-1 sm:col-span-6 xl:col-start-1 xl:col-span-3 text-center"
              data-aos="fade-up"
              data-aos-duration="800"
            >
              <h1 className="header text-primary">99%</h1>
              <h1 className="subHeader xl:paragraph">
                Expedition completion rate
              </h1>
            </div>
            <div
              className="col-start-1 col-span-12 sm:col-start-7 sm:col-span-6 xl:col-start-4 xl:col-span-3 text-center margin sm:m-0"
              data-aos="fade-up"
              data-aos-duration="800"
            >
              <h1 className="header text-primary">700+</h1>
              <h1 className="subHeader xl:paragraph">Climbers On The Top</h1>
            </div>
            <div
              className="col-start-1 col-span-12 sm:col-start-1 sm:col-span-6 xl:col-start-7 xl:col-span-3 text-center margin xl:m-0"
              data-aos="fade-up"
              data-aos-duration="800"
            >
              <h1 className="header text-primary">90%</h1>
              <h1 className="subHeader xl:paragraph">Climber summit rate</h1>
            </div>
            <div
              className="col-start-1 col-span-12 sm:col-start-7 sm:col-span-6 xl:col-start-10 xl:col-span-3 text-center margin xl:m-0"
              data-aos="fade-up"
              data-aos-duration="800"
            >
              <h1 className="header text-primary">100+</h1>
              <h1 className="subHeader xl:paragraph">Total Expedition</h1>
            </div>
          </div>
        </div>
      </section>

      {/* Service Card Section */}
      <section className="center">
        {/* Container for climbing services section */}
        <div className="container">
          <div className="grid grid-cols-12 gap-2">
            {/* Section Header (replace with actual title) */}
            <h1
              className="header col-start-1 col-span-12 text-center"
              data-aos="fade-up"
              data-aos-duration="800"
            >
              Our Climbing Services
            </h1>
            {/* Service Cards (repeated for each service) */}
            {servicesData.map((service) => (
              <div
                key={service.id}
                className={`col-start-1 col-span-12 flex flex-col justify-start items-center margin text-center md:col-start-${service.mdColStart} md:col-span-6 xl:col-start-${service.xlColStart} xl:col-span-4`}
                data-aos="fade-up"
                data-aos-duration="800"
              >
                {service.icon} {/* Font Awesome icon */}
                <h1 className="subHeader text-black mt-2">{service.title}</h1>
                <h1 className="paragraph">{service.description}</h1>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* Instagram Section  */}
      <section className="center bg-black">
        {/* Container for Instagram feed section */}
        <div className="container">
          <div className="grid grid-cols-12 gap-2">
            {/* Section Header (remove commented-out animations) */}
            <h1 className="header col-start-1 col-span-12 text-center">
              Our Instagram
            </h1>

            {/* Instagram Username Link */}
            <a
              href="https://www.instagram.com/white.expedition/"
              target="_blank"
              rel="noopener noreferrer"
              className="col-start-1 col-span-12 text-center"
            >
              <h1 className="tagline text-primary">@white.expedition</h1>
            </a>

            {/* Instagram Feed (replace with your actual Instagram feed component) */}
            <div className="col-start-1 col-span-12 rounded-md overflow-hidden margin">
              <InstaFeeds />
              {/* Comment: Replace InstaFeeds with your actual Instagram feed component */}
            </div>
          </div>
        </div>
      </section>

      {/* Footer section with background image */}
      <section
        className="relative bg-center bg-cover"
        style={{ backgroundImage: `url(${expedition})` }}
      >
        {/* Dark overlay on top of background image */}
        <div className="absolute inset-0 bg-primary opacity-80 z-10"></div>

        {/* Container for footer content */}
        <div className="container relative z-20">
          <div className="grid grid-cols-12 gap-2">
            {/* Footer component (replace with your actual footer component) */}
            <div className="col-start-1 col-span-12">
              <Footer />
              {/* Comment: Replace Footer with your actual footer component */}
            </div>
          </div>
        </div>
      </section>

      {/* WhatsApp integration (replace with your implementation) */}
      <Whatsapp />
      {/* Comment: Replace Whatsapp with your actual WhatsApp integration */}

      {isVisible && <UpcomingTours close={() => setIsVisible(false)} />}

      <UpcomingToursIcon
        open={() => {
          setIsVisible(true);
        }}
      />
    </>
  );
};

export default WhyWithUs;
