import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom"; // Navigation hook for routing
import AOS from "aos"; // AOS library for animations
import "aos/dist/aos.css"; // AOS stylesheet import

// Components
import NavigationBar from "../components/NavigationBar";
import HeroSection from "../components/HeroSection";
import InstaFeeds from "../components/InstaFeeds";
import Footer from "../components/Footer";
import Whatsapp from "../components/Whatsapp";

// Data
import { heroSectionCarousel } from "../data/Home/dataStorage";

// Assets (grouped for better organization)
import logoMedium from "../assets/WHITEEXPED LOGO - Medium.webp";
import expedition from "../assets/home/hero/expedition.webp";
import rickyMountaineer from "../assets/home/Members/Ricky.webp";
import nikitaThakur from "../assets/home/Members/Nikita_Thakur.webp";
import shubhamBisht from "../assets/home/Members/Shubham.webp";
import m5000 from "../assets/home/Cover Photo/5000M.webp";
import m6000 from "../assets/home/Cover Photo/6000M.webp";
import m7000 from "../assets/home/Cover Photo/7000M.webp";
import pureAdrenaline from "../assets/home/Cover Photo/Pure_Adrenaline.webp";
import wildlifePhotography from "../assets/home/Other Adventures/Wildlife_Photography.webp";
import adventureTour from "../assets/home/Other Adventures/Adventure_Tours.avif";

// Icons (grouped for better organization)
import { MdAddAPhoto } from "react-icons/md";
import { GiBarracksTent } from "react-icons/gi";
import { FaQuoteLeft } from "react-icons/fa";
import { FaArrowLeftLong } from "react-icons/fa6";
import { FaArrowRightLong } from "react-icons/fa6";
import { collection, getDocs, getFirestore } from "firebase/firestore";
import { Helmet } from "react-helmet";
import UpcomingTours from "../components/UpcomingTours";
import UpcomingToursIcon from "../components/UpcomingToursIcon";

const Home = () => {
  const navigate = useNavigate();

  const [testimonialsArray, setTestimonialArray] = useState([
    {
      fullName: "Prit Shah",
      selectedTrip: "Friendship Peak",
      message:
        "I recently embarked on an unforgettable mountain expedition organized by Ricky's team. The level of professionalism and attention to detail was exceptional. From the breathtaking views to the well-planned itinerary, every moment felt like an adventure. The guides were knowledgeable and made the experience truly enriching.",
    },
  ]);

  useEffect(() => {
    const fetchTestimonials = async () => {
      try {
        const db = getFirestore();
        const testimonialQuery = collection(db, "reviews");
        const querySnapshot = await getDocs(testimonialQuery);

        const fetchedTestimonials = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        setTestimonialArray(fetchedTestimonials);
      } catch (error) {
        console.log("Error fetching testimonials:", error);
      }
    };
    fetchTestimonials();
  }, []);

  const [currentIndex, setCurrentIndex] = useState(0);

  const handleNext = () => {
    // Wrap around to the beginning if reaching the end
    setCurrentIndex((prevIndex) => (prevIndex + 1) % testimonialsArray.length);
  };

  const handlePrev = () => {
    // Handle negative index by adding testimonials length
    setCurrentIndex(
      (prevIndex) =>
        (prevIndex - 1 + testimonialsArray.length) % testimonialsArray.length
    );
  };

  useEffect(() => {
    // Initialize AOS library for animations
    AOS.init();
  }, []);

  const leaders = [
    {
      name: "Ricky Mountaineer",
      linkName: "Ricky",
      title: "Director and Exped Leader",
      imageUrl: rickyMountaineer, // Replace with your image path
      link: "/whiteExpeditionLeader/rickyMountaineer",
      mdColStart: 1,
      xlColStart: 1,
      index: 0,
    },
    {
      name: "Nikita Thakur",
      linkName: "Nikita",
      title: "Auditor & Exped Leader",
      imageUrl: nikitaThakur, // Replace with your image path
      link: "/whiteExpeditionLeader/nikitaThakur",
      mdColStart: 7,
      xlColStart: 5,
      index: 1,
    },
    {
      name: "Shubham Bisht",
      linkName: "Shubham",
      title: "Managing Director & Exped leader",
      imageUrl: shubhamBisht, // Replace with your image path
      link: "/whiteExpeditionLeader/shubhamBisht",
      mdColStart: 4,
      xlColStart: 9,
      index: 2,
    },
  ];

  const upcomingExpeditions = [
    {
      title: "5000 M",
      imageSrc: m5000,
      description: "Begin Your High Altitude Journey",
      link: "/5000m-expedition",
      mdColStart: 1,
      lgColStart: 1,
      index: 0,
    },
    {
      title: "6000 M",
      imageSrc: m6000,
      description: "Aim Higher, Transcend The Limitations",
      link: "/6000m-expedition",
      mdColStart: 7,
      lgColStart: 4,
      index: 1,
    },
    {
      title: "7000 M", // Replace with your expedition title
      imageSrc: m7000, // Replace with image source
      description: "Prepare For The Mighty 8000ers", // Replace with description
      link: "/7000m-expedition", // Replace with link
      mdColStart: 7,
      lgColStart: 7,
      index: 2,
    },
    {
      title: "Pure Adrenaline", // Replace with your expedition title
      imageSrc: pureAdrenaline, // Replace with image source
      description: "Become One With The Mountains, Find The Flow", // Replace with description
      link: "/pure-adrenaline-expedition", // Replace with link
      mdColStart: 1,
      lgColStart: 10,
      index: 3,
    },
  ];

  const [isVisible, setIsVisible] = useState(true);

  return (
    <>
      <Helmet>
        <title>White Expedition - Home</title>
        <meta
          name="description"
          content="Scale new heights with White Expedition! Experience the thrill of mountain climbing in Himachal Pradesh. Explore our guided climbs and reach your summit dreams."
        />
      </Helmet>

      <HeroSection carouselData={heroSectionCarousel} />

      <section className="center">
        <NavigationBar />
      </section>

      {/* About Company Section */}
      <section className="center">
        <div className="container">
          <div className="grid grid-cols-12 gap-2 overflow-hidden">
            <div className="invisible hidden lg:flex lg:visible lg:col-start-1 lg:col-span-4 justify-center items-center">
              <img
                src={logoMedium}
                alt="White Expeditions Logo"
                className="aspect-auto"
                data-aos="fade-right"
                data-aos-duration="800"
                data-aos-offset="300"
                width={480}
              />
            </div>
            <h1
              className="subHeader lg:tagline col-span-full lg:col-start-5 lg:col-span-8 flex items-center"
              data-aos="fade-left"
              data-aos-duration="800"
              data-aos-offset="300"
            >
              White Expedition is a leading climbing company based out of
              Himachal Pradesh. Under the able leadership of Ricky Mountaineer,
              White Expedition aims to make the most exciting and challenging
              peaks accessible to climbers of India and the world. It is our aim
              to help you conquer the challenges and reach the summit. Needless
              to say, we specialize in helping you get the best out of your
              mountain adventure.
            </h1>
          </div>
        </div>
      </section>

      {/* Leadership Section  */}
      <section className="center bg-black">
        <div className="container">
          <div className="grid grid-cols-12 gap-2">
            {/* Leadership section title */}
            <h1
              className="header col-start-1 col-span-12 text-primary text-center"
              data-aos="fade-up"
              data-aos-duration="800"
            >
              White Exped Team Leadership
            </h1>

            {/* Leadership section description */}

            <h1
              className="paragraph col-start-1 col-span-12 text-center margin"
              data-aos="fade-up"
              data-aos-duration="800"
            >
              In our climbing team, leadership is not just about reaching the
              summit but also about fostering trust, safety, and camaraderie
              among team members. Our leader is not only experienced in
              technical skills but also excels in communication and
              decision-making, ensuring that everyone is informed and involved
              in the climbing process. They prioritize safety above all else,
              conducting thorough risk assessments and promoting responsible
              climbing practices. Our team leader inspires us to push our limits
              while respecting our boundaries, creating a culture of continuous
              improvement and mutual support on the mountainside.
            </h1>

            {/* Loop through leader objects and render leader cards */}
            {leaders.map((leader) => (
              <div
                className={`relative col-start-1 col-span-12 h-[400px] sm:col-start-3 sm:col-span-8 sm:h-[450px] md:col-start-${leader.mdColStart} md:col-span-6 md:h-[450px] lg:h-[500px] xl:col-start-${leader.xlColStart} xl:col-span-4 xl:h-[530px] 2xl:h-[600px] overflow-hidden bg-white rounded-md margin group hover:cursor-pointer`}
                data-aos="fade-up"
                data-aos-duration="800"
                data-aos-offset="400"
                onClick={() => {
                  window.scrollTo(0, 0);
                  navigate(leader.link);
                }}
                key={leader.index}
              >
                {/* Leader image */}
                <img
                  src={leader.imageUrl}
                  alt="Director"
                  className="w-full h-full object-cover"
                />

                {/* Leader information overlay */}
                <div className="absolute left-0 bottom-0 w-full h-36 transition-transform duration-300 transform translate-y-12 group-hover:translate-y-0 bg-[#2C2C2C] flex flex-col justify-center items-start px-4 py-2 text-textColor">
                  <h1 className="mediumHeader text-primary">{leader.name}</h1>
                  <h1 className="paragrph xl:extraSmall">{leader.title}</h1>

                  {/* "LEARN MORE" button */}
                  <a
                    href={leader.link}
                    className="text-primary font-bold underline mt-2 tracking-wide"
                  >
                    Learn more about {leader.linkName}
                  </a>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* Upcoming Expedition Section */}
      <section className="center">
        <div className="container">
          <div className="grid grid-cols-12 gap-2">
            <h1
              className="header col-start-1 col-span-12 text-center"
              data-aos="fade-up"
              data-aos-duration="800"
            >
              Upcoming Expeditions
            </h1>
            {upcomingExpeditions.map((upcomingExpedition) => (
              <div
                className={`relative col-start-1 col-span-12 h-96 sm:col-start-3 sm:col-span-8 sm:h-[400px] md:col-start-${upcomingExpedition.mdColStart} md:col-span-6 md:h-[420px] lg:col-start-${upcomingExpedition.lgColStart} lg:col-span-3 lg:h-[300px] xl:col-span-3 xl:h-[350px] 2xl:h-[400px] overflow-hidden bg-slate-300 rounded-md margin group hover:cursor-pointer`}
                data-aos="fade-up"
                data-aos-duration="800"
                data-aos-offset="400"
                onClick={() => {
                  window.scrollTo(0, 0);
                  navigate(upcomingExpedition.link);
                }}
                key={upcomingExpedition.index}
              >
                <img
                  src={upcomingExpedition.imageSrc}
                  alt="Upcoming Expedition"
                  className="w-full h-full object-cover"
                />
                <div className="absolute top-0 left-0 w-full h-full bg-black opacity-50 transition-all duration-300 ease-in-out group-hover:opacity-75"></div>
                <div className="absolute w-full h-full top-0 left-0 flex flex-col justify-center items-center px-4">
                  <h1 className="mediumHeader text-primary">
                    {upcomingExpedition.title}
                  </h1>
                  <h1 className="paragraph text-center text-white lg:text-[15px]">
                    {upcomingExpedition.description}
                  </h1>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* Why With Us Section */}
      <section className="center bg-black">
        <div className="container">
          <div className="grid grid-cols-12 gap-2">
            <h1
              className="header col-start-1 col-span-12 text-center text-primary"
              data-aos="fade-up"
              data-aos-duration="800"
            >
              Why With Us?
            </h1>
            <h1
              className="paragraph col-start-1 col-span-12 text-center margin"
              data-aos="fade-up"
              data-aos-duration="800"
            >
              The mountaineering world has many amazing companies and climbers.
              We are confident and proud to say that we are one of the best in
              India. What makes us the best is our experience. We started from
              scratch and learnt everything. We know our mountains well and
              constantly strive to discover the unclimbed gems of the Indian
              Himalayas. We have qualified and certified leaders aiming to give
              you an unparalleled high altitude experience. We are young
              mountain climbers and adventure lovers who wish to make a
              difference when it comes to high altitude climbing.
            </h1>
          </div>
        </div>
      </section>

      {/* Other Activities Section */}
      <section className="center">
        <div className="container">
          <div className="grid grid-cols-12 gap-2">
            {/* Section header */}
            <h1
              className="header text-center col-start-1 col-span-12"
              data-aos="fade-up"
              data-aos-duration="800"
            >
              Explore Other Adventures
            </h1>

            {/* Activity Card: Wildlife Photography */}
            <div
              className="relative col-start-1 col-span-12 h-96 sm:col-start-3 sm:col-span-8 sm:h-[400px] md:col-start-1 md:col-span-6 md:h-[420px] lg:col-start-3 lg:col-span-4 lg:h-[300px] xl:col-start-3 xl:col-span-4 xl:h-[350px] 2xl:h-[400px] overflow-hidden bg-slate-300 rounded-md margin group hover:cursor-pointer"
              data-aos="fade-up"
              data-aos-duration="800"
              data-aos-offset="400"
              onClick={() => {
                window.scrollTo(0, 0);
                navigate("/wildlifePhotography/snowLeopardWinterSpiti");
              }}
            >
              <img
                src={wildlifePhotography}
                alt="Other Activities"
                className="w-full h-full object-cover"
              />
              <div className="absolute top-0 left-0 w-full h-full bg-black opacity-50 transition-all duration-300 ease-in-out group-hover:opacity-75"></div>
              <div className="absolute w-full h-full top-0 left-0 flex flex-col justify-center items-center px-4">
                <MdAddAPhoto className="text-primary w-10 h-10 sm:w-12 sm:h-12" />
                <h1 className="mediumHeader text-center text-white">
                  Wildlife Photography
                </h1>
              </div>
            </div>

            {/* Activity Card: Adventure Tours */}
            <div
              className="relative col-start-1 col-span-12 h-96 sm:col-start-3 sm:col-span-8 sm:h-[400px] md:col-start-7 md:col-span-6 md:h-[420px] lg:col-start-7 lg:col-span-4 lg:h-[300px] xl:col-start-7 xl:col-span-4 xl:h-[350px] 2xl:h-[400px] overflow-hidden bg-slate-300 rounded-md margin group hover:cursor-pointer"
              data-aos="fade-up"
              data-aos-duration="800"
              // data-aos-delay="300"
              data-aos-offset="400"
              onClick={() => {
                window.scrollTo(0, 0);
                navigate("/adventure-tours");
              }}
            >
              <img
                src={adventureTour}
                alt="Director"
                className="w-full h-full object-cover"
              />
              <div className="absolute top-0 left-0 w-full h-full bg-black opacity-50 transition-all duration-300 ease-in-out group-hover:opacity-75"></div>
              <div className="absolute w-full h-full top-0 left-0 flex flex-col justify-center items-center px-4">
                <GiBarracksTent className="text-primary w-10 h-10 sm:w-12 sm:h-12" />
                <h1 className="mediumHeader text-center text-white">
                  Adventure Tours
                </h1>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Personalize Your Trip Section */}
      <section className="center bg-black">
        <div className="container">
          <div className="grid grid-cols-12 gap-2">
            <h1
              className="mediumHeader col-start-1 col-span-12 lg:col-start-1 lg:col-span-8 lg:flex lg:justify-center lg:items-center text-center"
              data-aos="fade-up"
              data-aos-duration="800"
            >
              Want to <span className="text-primary lg:mx-2">Personalize</span>{" "}
              Your Own Adventure?
            </h1>
            <h1
              className="paragraph col-start-2 col-span-10 sm:col-start-4 sm:col-span-6 lg:col-start-9 lg:col-span-4 2xl:col-start-9 2xl:col-span-4 flex justify-center items-center px-2 py-2 rounded-md text-black font-bold text-center bg-primary margin lg:m-0 hover:cursor-pointer"
              data-aos="fade-up"
              data-aos-duration="800"
              onClick={() => {
                window.scrollTo(0, 0);
                navigate("/planYourTrip");
              }}
            >
              PERSONALIZE YOUR TRIP
            </h1>
          </div>
        </div>
      </section>

      {/* Achievements & Records Section */}
      <section className="center">
        <div className="container">
          <div className="grid grid-cols-12 gap-2">
            <h1
              className="header col-start-1 col-span-12 text-center"
              data-aos="fade-up"
              data-aos-duration="800"
            >
              Achievements & Records
            </h1>
            <h1
              className="paragraph col-start-1 col-span-12 text-center margin"
              data-aos="fade-up"
              data-aos-duration="800"
            >
              We are glad to tell you with confidence that we have one of the
              best high altitude teams in India. We are a bunch of youngsters
              with a zeal to explore the unexplored mountains of the Indian
              Himalayas. Below are the records set by our founder and other team
              members that we would like to share with you.
            </h1>
            <h1
              className="px-4 py-2 bg-primary paragraph col-start-2 col-span-10 sm:col-start-3 sm:col-span-8 md:col-start-4 md:col-span-6 xl:col-start-5 xl:col-span-4 margin rounded-lg flex justify-center items-center text-black hover:cursor-pointer"
              onClick={() => {
                window.scrollTo(0, 0);
                navigate("/AchievementsRecords");
              }}
            >
              See Achievements & Records
            </h1>
          </div>
        </div>
      </section>

      {/* Our Blogs Section */}
      <section className="center bg-black">
        <div className="container">
          <div className="grid grid-cols-12 gap-2">
            <h1
              className="header col-start-1 col-span-12 text-center text-primary"
              data-aos="fade-up"
              data-aos-duration="800"
            >
              Our Blogs
            </h1>

            <h1
              className="paragraph col-start-1 col-span-12 text-center  margin"
              data-aos="fade-up"
              data-aos-duration="800"
            >
              Stay tune for upcoming blogs
            </h1>

            {/* Blog 1  */}
            {/* <div
              className="relative col-start-1 col-span-12 h-96 sm:col-start-3 sm:col-span-8 sm:h-[400px] md:col-start-1 md:col-span-6 md:h-[420px] lg:col-start-1 lg:col-span-6 lg:h-[300px] xl:col-start-1 xl:col-span-6 xl:h-[350px] 2xl:h-[400px] overflow-hidden bg-slate-300 rounded-md margin group hover:cursor-pointer"
              data-aos="fade-up"
              data-aos-duration="800"
              data-aos-offset="400"
            >
              <img
                src={ricky}
                alt="Director"
                className="w-full h-full object-cover"
              />
              <div className="absolute top-0 left-0 w-full h-full bg-black opacity-50 transition-all duration-300 ease-in-out group-hover:opacity-75"></div>
              <div className="absolute w-full h-full top-0 left-0 flex flex-col justify-end items-start px-4 pb-8">
                <h1 className="paragraph text-start text-white">
                  January 5, 2024
                </h1>
                <h1 className="mediumHeader text-start text-white margin group-hover:text-primary">
                  Your Blog heading will go here - Your blog title 1
                </h1>
              </div>
            </div> */}

            {/* Blog 2  */}
            {/* <div
              className="relative col-start-1 col-span-12 h-96 sm:col-start-3 sm:col-span-8 sm:h-[400px] md:col-start-7 md:col-span-6 md:h-[420px] lg:col-start-7 lg:col-span-6 lg:h-[300px] xl:col-start-7 xl:col-span-6 xl:h-[350px] 2xl:h-[400px] overflow-hidden bg-slate-300 rounded-md margin group hover:cursor-pointer"
              data-aos="fade-up"
              data-aos-duration="800"
              data-aos-offset="400"
            >
              <img
                src={ricky}
                alt="Director"
                className="w-full h-full object-cover"
              />
              <div className="absolute top-0 left-0 w-full h-full bg-black opacity-50 transition-all duration-300 ease-in-out group-hover:opacity-75"></div>
              <div className="absolute w-full h-full top-0 left-0 flex flex-col justify-end items-start px-4 pb-8">
                <h1 className="paragraph text-start text-white">
                  January 5, 2024
                </h1>
                <h1 className="mediumHeader text-start text-white margin group-hover:text-primary">
                  Your Blog heading will go here - Your blog title 2
                </h1>
              </div>
            </div> */}
            {/* <div
              className="col-start-1 col-span-12 margin flex justify-center items-center space-x-2 hover:cursor-pointer"
              data-aos="fade-up"
              data-aos-duration="800"
            >
              <FaArrowCircleRight className="text-primary w-5 h-5 sm:w-6 sm:h-6 md:w-7 md:h-7 lg:w-8 lg:h-8" />
              <h1 className="tagline text-center text-primary">View all</h1>
            </div> */}
          </div>
        </div>
      </section>

      {/* Testimonials Section */}
      <section className="center">
        <div className="container">
          <div className="grid grid-cols-12 gap-2">
            <h1
              className="header col-start-1 col-span-12 text-center"
              data-aos="fade-up"
              data-aos-duration="800"
            >
              Testimonials
            </h1>
            <div
              className="relative col-start-1 col-span-12 sm:col-start-3 sm:col-span-8 md:col-start-2 md:col-span-10 lg:col-start-3 lg:col-span-8 2xl:col-start-2 2xl:col-span-10 rounded-md border border-textColor overflow-hidden h-96 margin"
              data-aos="fade-up"
              data-aos-duration="800"
            >
              <FaQuoteLeft className="mt-2 ms-3 text-primary w-8 h-8 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 xl:w-12 xl:h-12 2xl:w-13 2xl:h-13" />
              <h1 className="paragraph mx-3 my-1 w-auto h-[250px] sm:h-[230px] lg:h-[210px] 2xl:h-[195px] overflow-y-scroll custom-scrollbar pe-3">
                {testimonialsArray[currentIndex].message}
              </h1>
              <div className="bottom-2 absolute w-full">
                <hr className="my-2 mx-3 border-t-2 border-gray-300" />
                <h1 className="mediumHeader mx-3">
                  {testimonialsArray[currentIndex].fullName}
                </h1>
                <h1 className="tagline mx-3">
                  {testimonialsArray[currentIndex].selectedTrip}
                </h1>
              </div>
            </div>

            <div
              className="col-start-1 col-span-12 margin flex justify-center items-center space-x-2 hover:cursor-pointer"
              data-aos="fade-up"
              data-aos-duration="800"
            >
              <FaArrowLeftLong
                className="text-primary w-5 h-5 sm:w-6 sm:h-6 md:w-7 md:h-7 lg:w-8 lg:h-8"
                onClick={handlePrev}
              />
              <FaArrowRightLong
                className="text-primary w-5 h-5 sm:w-6 sm:h-6 md:w-7 md:h-7 lg:w-8 lg:h-8"
                onClick={handleNext}
              />
            </div>
          </div>
        </div>
      </section>

      {/* Instagram Section */}
      <section className="center">
        <div className="container bg-black">
          <div className="grid grid-cols-12 gap-2 ">
            <h1
              className="header col-start-1 col-span-12 text-center"
              data-aos="fade-up"
              data-aos-duration="800"
            >
              Our Instagram
            </h1>
            <a
              href="https://www.instagram.com/white.expedition/"
              target="_blank"
              rel="noopener noreferrer"
              className="col-start-1 col-span-12 text-center"
            >
              <h1 className="tagline  text-primary">@white.expedition</h1>
            </a>
            <div className="col-start-1 col-span-12 rounded-md overflow-hidden margin">
              <InstaFeeds />
            </div>
          </div>
        </div>
      </section>

      {/* Footer section */}
      <section
        className="relative bg-center bg-cover"
        style={{ backgroundImage: `url(${expedition})` }}
      >
        <div className="absolute inset-0 bg-primary opacity-80 z-10"></div>
        <div className="container relative z-20">
          <div className="grid grid-cols-12 gap-2">
            <div className="col-start-1 col-span-12">
              <Footer />
            </div>
          </div>
        </div>
      </section>

      {/* WhatsApp Section */}
      <Whatsapp />

      {isVisible && <UpcomingTours close={() => setIsVisible(false)} />}

      <UpcomingToursIcon
        open={() => {
          setIsVisible(true);
        }}
      />
    </>
  );
};

export default Home;
