import React from "react";
import { useParams } from "react-router-dom";
import { IndividualAchievements } from "./dataStorage";

const IndividualAchievementsRecords = () => {
  const { individualAchievementsRecords } = useParams();

  const FilterIndividualAchievement = IndividualAchievements.find(
    (exp) => Number(exp.index) === Number(individualAchievementsRecords)
  );

  return (
    <div className="w-screen h-screen flex justify-center items-center bg-black">
      <img
        src={FilterIndividualAchievement.img}
        alt="Achievements & Records"
        className="h-4/5 w-auto"
      />
    </div>
  );
};

export default IndividualAchievementsRecords;
