import React, { useRef, useEffect } from "react";
import AffiliateCard from "../utils/AffiliateCard";
import { affiliation } from "../data/Home/dataStorage";

import visa from "../assets/Footer/visa-payment-card.webp";
import masterCard from "../assets/Footer/MasterCard_Logo.webp";
import upi from "../assets/Footer/Upi-Payment-Logo-white.webp";
import logoSmall from "../assets/WHITEEXPED LOGO - Small.webp";
import rickyMountaineer from "../assets/home/Members/Ricky.webp";
import nikitaThakur from "../assets/home/Members/Nikita_Thakur.webp";
import shubhamBisht from "../assets/home/Members/Shubham.webp";

import { IoCall } from "react-icons/io5";
import { IoLogoWhatsapp } from "react-icons/io";
import { MdEmail } from "react-icons/md";
import { FaLocationDot } from "react-icons/fa6";

import "../index.css";

import AOS from "aos";
import "aos/dist/aos.css";

const Footer = () => {
  const containerRef = useRef(null);

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (containerRef.current) {
        const maxScrollLeft =
          containerRef.current.scrollWidth - containerRef.current.clientWidth;

        if (containerRef.current.scrollLeft >= maxScrollLeft) {
          containerRef.current.scrollLeft = 0;
        } else {
          containerRef.current.scrollLeft += 154;
        }
      }
    }, 3000);

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  useEffect(() => {
    // Initialize AOS library for animations
    AOS.init();
  }, []);

  return (
    <div className="overflow-hidden">
      <div
        className="overflow-y-hidden no-scrollbar flex space-x-5"
        ref={containerRef}
      >
        {affiliation.map((item) => (
          <AffiliateCard key={item.index} image={item.image} />
        ))}
      </div>
      <div className="center">
        <div className="container p-0">
          <div className="grid grid-cols-12 gap-2 ">
            <div className="col-start-1 col-span-8 max-w-40 margin h-20">
              <img
                src={logoSmall}
                alt="Small Brand Logo"
                className="w-full h-full object-cover rounded-lg transition-opacity duration-500 ease-in-out opacity-100 hover:opacity-90 aspect-auto"
              />
            </div>
            <h1
              className="header col-start-1 col-span-12 text-start margin text-black"
              data-aos="fade-up"
              data-aos-duration="800"
            >
              We Accept
            </h1>
            <div className="col-start-1 h-fit col-span-12 flex items-center space-x-4 overflow-auto hidden-scrollbar">
              <div className="w-1/3 max-w-28">
                <img
                  src={visa}
                  width={100}
                  alt="Visa"
                  className="rounded-lg transition-opacity duration-500 ease-in-out opacity-100 hover:opacity-90 aspect-auto"
                />
              </div>
              <div className="w-1/3 max-w-28">
                <img
                  src={masterCard}
                  width={100}
                  alt="Master Card"
                  className="transition-opacity duration-500 ease-in-out opacity-100 hover:opacity-90 aspect-auto"
                />
              </div>
              <div className="w-1/3 max-w-28">
                <img
                  src={upi}
                  width={100}
                  alt="Upi"
                  className="rounded-lg transition-opacity duration-500 ease-in-out opacity-100 hover:opacity-90 aspect-auto"
                />
              </div>
            </div>
            <div className="col-start-1 col-span-12 sm:col-start-1 sm:col-span-6 margin">
              <h1
                className="header col-start-1 col-span-12 text-start margin text-black"
                data-aos="fade-up"
                data-aos-duration="800"
              >
                Important Links
              </h1>
              <h1
                className="subHeader col-start-1 col-span-12 text-start py-0.5 margin text-black"
                data-aos="fade-up"
                data-aos-duration="800"
              >
                <a href="/5000m-expedition">Expeditions</a>
              </h1>
              <h1
                className="subHeader col-start-1 col-span-12 text-start py-0.5 text-black"
                data-aos="fade-up"
                data-aos-duration="800"
              >
                <a href="/trekking-expedition">Trekkings</a>
              </h1>
              <h1
                className="subHeader col-start-1 col-span-12 text-start py-0.5 text-black"
                data-aos="fade-up"
                data-aos-duration="800"
              >
                <a href="/pure-adrenaline-expedition">Pure Adrenaline</a>
              </h1>
              <h1
                className="subHeader col-start-1 col-span-12 text-start py-0.5 text-black"
                data-aos="fade-up"
                data-aos-duration="800"
              >
                <a href="/adventure-tours">Adventure Tours</a>
              </h1>
              <h1
                className="subHeader col-start-1 col-span-12 text-start py-0.5 text-black"
                data-aos="fade-up"
                data-aos-duration="800"
              >
                <a href="/wildlifePhotography/snowLeopardWinterSpiti">
                  Wildlife Photography
                </a>
              </h1>
              <h1
                className="subHeader col-start-1 col-span-12 text-start py-0.5 text-black"
                data-aos="fade-up"
                data-aos-duration="800"
              >
                <a href="/getInTouch">Personalize Adventure</a>
              </h1>
            </div>
            <div className="col-start-1 col-span-12 sm:col-start-7 sm:col-span-6 margin">
              <h1
                className="header col-start-1 col-span-12 text-start margin text-black"
                data-aos="fade-up"
                data-aos-duration="800"
              >
                Quick Links
              </h1>
              <h1
                className="subHeader col-start-1 col-span-12 text-start py-0.5 margin text-black"
                data-aos="fade-up"
                data-aos-duration="800"
              >
                <a href="/leadership/rickyMountaineer">About me</a>
              </h1>
              <h1
                className="subHeader col-start-1 col-span-12 text-start py-0.5 text-black"
                data-aos="fade-up"
                data-aos-duration="800"
              >
                <a href="/leadership/rickyMountaineer">Experiance</a>
              </h1>
              <h1
                className="subHeader col-start-1 col-span-12 text-start py-0.5 text-black"
                data-aos="fade-up"
                data-aos-duration="800"
              >
                <a href="/certificates">Certificates</a>
              </h1>
              <h1
                className="subHeader col-start-1 col-span-12 text-start py-0.5 text-black"
                data-aos="fade-up"
                data-aos-duration="800"
              >
                <a href="/getInTouch">Get in touch</a>
              </h1>
              <h1
                className="subHeader col-start-1 col-span-12 text-start py-0.5 text-black"
                data-aos="fade-up"
                data-aos-duration="800"
              >
                <a href="/">Blogs</a>
              </h1>
              <h1
                className="subHeader col-start-1 col-span-12 text-start py-0.5 text-black"
                data-aos="fade-up"
                data-aos-duration="800"
              >
                <a href="/ourTeam">Team member</a>
              </h1>
            </div>

            <div className="col-start-1 col-span-12 margin">
              <h1
                className="header col-start-1 col-span-12 text-start margin text-black"
                data-aos="fade-up"
                data-aos-duration="800"
              >
                Talk to Experts
              </h1>
            </div>

            {/* Expert 1  */}
            <div
              className="col-start-1 col-span-12 sm:col-start-3 sm:col-span-8 lg:col-start-1 lg:col-span-6 xl:col-start-1 xl:col-span-5 2xl:col-start-1 2xl:col-span-4 margin"
              data-aos="fade-up"
              data-aos-duration="800"
            >
              <div className="w-full h-28 md:h-32 2xl:h-36 rounded-md bg-white bg-opacity-40 my-1 overflow-hidden flex">
                <div className="aspect-square m-2 rounded-md bg-blue-400">
                  <img
                    src={rickyMountaineer}
                    alt="Ricky Mountaineer"
                    className="w-full h-full object-cover rounded-lg transition-opacity duration-500 ease-in-out opacity-100 hover:opacity-90"
                  />
                </div>
                <div className="flex flex-col my-2 mx-0">
                  <h1 className="subHeader text-start py-0.5">
                    Ricky Mountainner
                  </h1>
                  <h1 className="subHeader text-start py-0.5">
                    +91 8894412884
                  </h1>
                  <div className="flex space-x-2">
                    <IoLogoWhatsapp size={30} className="text-[#25D366]" />
                    <IoCall size={30} className="text-[#3498db]" />
                  </div>
                </div>
              </div>
            </div>

            {/* Expert 2  */}
            <div
              className="col-start-1 col-span-12 sm:col-start-3 sm:col-span-8 lg:col-start-7 lg:col-span-6 xl:col-start-7 xl:col-span-5 2xl:col-start-5 2xl:col-span-4 margin"
              data-aos="fade-up"
              data-aos-duration="800"
            >
              <div className="w-full h-28 md:h-32 2xl:h-36 rounded-md bg-white bg-opacity-40 my-1 overflow-hidden flex">
                <div className="aspect-square m-2 rounded-md bg-blue-400">
                  <img
                    src={nikitaThakur}
                    alt="Nikita Thakur"
                    className="w-full h-full object-cover rounded-lg transition-opacity duration-500 ease-in-out opacity-100 hover:opacity-90"
                  />
                </div>
                <div className="flex flex-col my-2 mx-0">
                  <h1 className="subHeader text-start py-0.5">Nikita Thakur</h1>
                  <h1 className="subHeader text-start py-0.5">
                    +91 7807360003
                  </h1>
                  <div className="flex space-x-2">
                    <IoLogoWhatsapp size={30} className="text-[#25D366]" />
                    <IoCall size={30} className="text-[#3498db]" />
                  </div>
                </div>
              </div>
            </div>

            {/* Expert 3  */}
            <div
              className="col-start-1 col-span-12 sm:col-start-3 sm:col-span-8 lg:col-start-4 lg:col-span-6 xl:col-start-4 xl:col-span-5 2xl:col-start-9 2xl:col-span-4 margin"
              data-aos="fade-up"
              data-aos-duration="800"
            >
              <div className="w-full h-28 md:h-32 2xl:h-36 rounded-md bg-white bg-opacity-40 my-1 overflow-hidden flex">
                <div className="aspect-square m-2 rounded-md bg-blue-400">
                  <img
                    src={shubhamBisht}
                    alt="Shubham Bisht"
                    className="w-full h-full object-cover rounded-lg transition-opacity duration-500 ease-in-out opacity-100 hover:opacity-90"
                  />
                </div>
                <div className="flex flex-col my-2 mx-0">
                  <h1 className="subHeader text-start py-0.5">Shubham Bisht</h1>
                  <h1 className="subHeader text-start py-0.5">
                    +91 9878136133
                  </h1>
                  <div className="flex space-x-2">
                    <IoLogoWhatsapp size={30} className="text-[#25D366]" />
                    <IoCall size={30} className="text-[#3498db]" />
                  </div>
                </div>
              </div>
            </div>

            {/* Office details  */}
            <div
              className="col-start-1 col-span-12 sm:col-start-3 sm:col-span-8 lg:col-start-1 lg:col-span-6 2xl:col-start-1 2xl:col-span-4 margin"
              data-aos="fade-up"
              data-aos-duration="800"
            >
              <div className="w-full h-20 md:h-24 rounded-md bg-white bg-opacity-40 my-1 overflow-hidden flex">
                <div className="aspect-square m-2 rounded-md flex justify-center items-center">
                  <IoCall size={40} className="text-[#3498db]" />
                </div>
                <div className="flex flex-col justify-center my-2 mx-2">
                  <h1 className="paragraph text-start py-0.5">
                    White Expedition Office
                  </h1>
                  <h1 className="paragraph text-start py-0.5">
                    +91 8580659518
                  </h1>
                </div>
              </div>
            </div>
            <div
              className="col-start-1 col-span-12 sm:col-start-3 sm:col-span-8 lg:col-start-7 lg:col-span-6 2xl:col-start-5 2xl:col-span-4 margin"
              data-aos="fade-up"
              data-aos-duration="800"
            >
              <div className="w-full h-20 md:h-24 rounded-md bg-white bg-opacity-40 my-1 overflow-hidden flex pe-2">
                <div className="aspect-square m-2 rounded-md flex justify-center items-center">
                  <MdEmail size={40} className="text-[#d32f2f]" />
                </div>
                <div className="flex w-[70%] flex-col justify-center  overflow-wrap break-words my-2 mx-2">
                  <h1 className="paragraph text-wrap text-start py-0.5">
                    whiteexpeditionoffice@gmail.com
                  </h1>
                </div>
              </div>
            </div>
            <div
              className="col-start-1 col-span-12 sm:col-start-3 sm:col-span-8 lg:col-start-4 lg:col-span-6 2xl:col-start-9 2xl:col-span-4 margin"
              data-aos="fade-up"
              data-aos-duration="800"
            >
              <div className="w-full h-20 md:h-24 rounded-md bg-white bg-opacity-40 my-1 overflow-hidden flex">
                <div className="aspect-square m-2 rounded-md flex justify-center items-center">
                  <FaLocationDot size={40} className="text-[#795548]" />
                </div>
                <div className="flex w-[70%] flex-col justify-center text-wrap overflow-wrap break-words my-2 mx-2">
                  <h1 className="paragraph text-start py-0.5">
                    VPO Junga Shimla, 171218
                  </h1>
                </div>
              </div>
            </div>

            {/* News letter  */}
            <h1
              className="header col-start-1 col-span-12 text-center margin text-black"
              // data-aos="fade-up"
              // data-aos-duration="800"
            >
              Newsletter
            </h1>
            <h1
              className="tagline col-start-1 col-span-12 text-center text-black"
              // data-aos="fade-up"
              // data-aos-duration="800"
            >
              Subscribe for latest updates on Expeditions and trips by white
              expeditions
            </h1>
            <div
              className="col-start-1 col-span-12 sm:col-start-3 sm:col-span-8 md:col-start-4 md:col-span-6 xl:col-start-5 xl:col-span-4 sm:bg-white h-fit rounded-md overflow-hidden flex flex-col justify-center items-center sm:flex-row"
              // data-aos="fade-up"
              // data-aos-duration="800"
            >
              <input
                type="text"
                className="h-full w-full bg-white py-3 px-2 font-sintony rounded-md sm:rounded-r-none focus:outline-none"
                placeholder="Your Email"
              />
              <button className="mt-2 sm:mt-0 sm:me-0.5 w-fit p-2 bg-black text-white rounded-md">
                SUBSCRIBE
              </button>
            </div>

            <div className="col-start-1 col-span-12 border-t-2 border-black"></div>

            <h1 className="paragraph col-start-1 col-span-12 text-end sm:col-start-1 sm:col-span-4 sm:text-center">
              <a href="/termsAndConditions">Terms & Conditions</a>
            </h1>
            <h1 className="paragraph col-start-1 col-span-12 text-end sm:col-start-5 sm:col-span-4 sm:text-center">
              <a href="/privacyPolicy">Privacy Policy</a>
            </h1>
            <h1 className="paragraph col-start-1 col-span-12 text-end sm:col-start-9 sm:col-span-4 sm:text-center">
              <a href="/cancellationPolicy">cancellations Policy</a>
            </h1>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
