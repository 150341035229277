import { wildllifePhotographyHeroSection } from "../../data/Home/dataStorage";

export const thingstocarray = [
  {
    sectionTitle: "Layered Clothing",
    content: [
      "Thermal innerwear: Base layers to keep you warm.",
      "Insulating layers: Fleece or down jackets for insulation.",
      "Waterproof and windproof outer shell: Protects against snow and wind.",
    ],
  },
  {
    sectionTitle: "Headgear",
    content: [
      "Insulated hat or balaclava: Covers the head and ears.",
      "Sunglasses with UV protection: Protects eyes from snow glare.",
    ],
  },
  {
    sectionTitle: "Hand Protection",
    content: [
      "Insulated gloves or mittens: Keep hands warm and dry.",
      "Hand warmers: Disposable heat packs for extra warmth.",
    ],
  },
  {
    sectionTitle: "Footwear",
    content: [
      "Insulated and waterproof boots: Provide warmth and protection.",
      "Gaiters: Cover the lower leg and prevent snow from entering the boots.",
      "Extra pairs of moisture-wicking socks: Keep feet dry and warm.",
    ],
  },
  {
    sectionTitle: "Backpack",
    content: [
      "Large, sturdy backpack: To carry all your essentials.",
      "Rain cover or waterproof liners: Protects gear from snow and moisture.",
      "Day bag (20-30 ltr)",
    ],
  },
  {
    sectionTitle: "Nutrition",
    content: [
      "High-energy snacks: Nuts, energy bars, and chocolate.",
      "Hydration system: Insulated water bottles or a hydration pack.",
    ],
  },
  {
    sectionTitle: "Emergency Equipment",
    content: ["Personal medicines if required."],
  },
  {
    sectionTitle: "Lighting",
    content: [
      "Headlamp or flashlight: Essential for visibility in the dark.",
      "Extra batteries: Ensure you have enough power.",
    ],
  },
  {
    sectionTitle: "Communication",
    content: [
      "Fully charged cell phone: Keep it in a warm place to preserve battery life.",
      "Portable charger: Extra power for electronic devices.",
    ],
  },
  {
    sectionTitle: "Personal Items",
    content: [
      "Sunscreen: Even in winter, UV rays can be intense at high altitudes.",
      "Lip balm: Prevents chapped lips in cold, dry conditions.",
      "Personal hygiene items: Toothbrush, toothpaste, etc.",
    ],
  },
  {
    sectionTitle: "Documentation & Cash",
    content: [
      "ID, permits, and emergency contact information.",
      "Remote areas don’t have ATMs, so please carry enough cash.",
    ],
  },
];

export const individualExpedition = [
  {
    individualExpeditionType: "snowLeopardWinterSpiti",
    individualExpeditionName: "Snow Leopard Winter Spiti",
    carouselData: wildllifePhotographyHeroSection,
    location: "Spiti Himachal Pradesh",
    duration: "8 Days",
    grade: "Any",
    maxAltitude: "4587m (above)",
    bestSeason: "Jan, Feb and March",
    experienceRequired: "Don’t want to do it in a group? Get it customised.",
    mainExpeditionContent:
      "Snow leopards—one of the world’s most elusive cats—are perfectly equipped to thrive in extreme, high-elevation habitats. Snow leopards can be found throughout high mountain ranges, including the Himalayas and the southern Siberian mountains in Russia. They can also be found in the Tibetan Plateau and across a range that stretches from China to the mountains of Central Asia. They prefer steep, rugged terrain with rocky outcrops where prey can be hard to come by. That’s why these carnivores require an enormous amount of space to roam: Male leopards require up to 80 square miles while females have ranges of up to 48 square miles.",
    aboutExpeditionExperienced:
      "The Spiti River, which originates from the Kunzum Range, flows through the valley, and the region is known for its stark landscapes, barren mountains, and vast expanses of barren land. The region is also home to several rare and endangered wildlife species, including the snow leopard, ibex, and blue sheep.It's not easy to spot these enigmatic cats. It is important to be at the right place at the right time!",
    itineraryData: [
      {
        day: "1",
        title: "Shimla to Kalpa",
        details: [
          "Distance: Approximately 225 km",
          "Altitude: Shimla (2,276 m) to Kalpa (2,960 m)",
          "Driving Time: Around 8-9 hours",
          "Shimla: The Ridge, Mall Road, Jakhu Temple",
          "Kalpa: Kalpa Monastery, Suicide Point, Roghi Village",
          "Lunch not included",
        ],
      },
      {
        day: "2",
        title: "Kalpa to Nako to Tabo",
        details: [
          "Distance: Kalpa to Nako (110 km), Nako to Tabo (63 km)",
          "Altitude: Kalpa to Nako to Tabo (3,050 m to 3,662 m to 3,280 m)",
          "Driving Time: Kalpa to Nako (4-5 hours), Nako to Tabo (2-3 hours)",
          "Nako: Nako Lake, Nako Monastery",
          "Tabo: Tabo Monastery, Tabo Caves",
          "Lunch not included",
        ],
      },
      {
        day: "3",
        title: "Tabo to Dhankar to Pin Valley",
        details: [
          "Distance: Tabo to Dhankar (22 km), Dhankar to kaza (32 km)",
          "Altitude: Tabo (3280 m) to Dhankar (3894 m) to kaza (3650 m)",
          "Driving Time: Tabo to Dhankar (2-3 hours), Dhankar to kaza (2-3 hours)",
          "Dhankar: Dhankar Monastery",
          "Lunch not included",
        ],
      },
      {
        day: "4",
        title: "Kaza to Langza to Komic to Hikkim (Spotting Day)",
        details: [
          "Distance: Kaza to Langza (14 km), Langza to Komic (7 km), Komic to Hikkim (4 km)",
          "Altitude: Kaza (3800 m) to Langza (4400 m) to Komic (4587 m) to Hikkim (4400 m)",
          "Driving Time: Kaza to Langza (1 hour), Langza to Komic (30 minutes), Komic to Hikkim (20 minutes)",
          "Langza: Buddha Statue, Fossil Park",
          "Komic: Komic Monastery, the World’s Highest Motorable Village",
          "Hikkim: World's Highest Post Office",
          "Lunch not included",
        ],
      },
      {
        day: "5",
        title: "Kaza to Chicham to Kibber-ki-tabo (Spotting Day)",
        details: [
          "Distance: Kaza to Chicham (20 km), Chicham to Kibber (15 km)",
          "Altitude: Kaza (3800 m) to Chicham (4000 m) to Kibber (4205 m)",
          "Driving Time: Kaza to Chicham (1 hour), Chicham to Kibber (40 minutes)",
          "Chicham: Chicham Bridge (Highest Bridge in Asia)",
          "Kibber: Kibber Monastery, Kibber Wildlife Sanctuary Ki Monastery",
          "Lunch not included",
        ],
      },
      {
        day: "6",
        title: "Tabo to Rampur",
        details: [
          "Distance: Approximately 190 km",
          "Altitude: Kaza (3800 m) to Rampur (2712 m)",
          "Driving Time: Around 6-7 hours",
          "Lunch not included",
        ],
      },
      {
        day: "7",
        title: "Rampur to Shimla Drop",
        details: [
          "Distance: Approximately 212 km",
          "Altitude: Rampur (2612 m) to Shimla (2276 m)",
          "Driving Time: Around 7-8 hours",
          "Only Breakfast Included",
        ],
      },
    ],
    inclusion: [
      {
        sectionTitle: "Transportation as per program for 8 days.",
        content: [],
      },
      {
        sectionTitle: "4x4 vehicle",
        content: [],
      },
      {
        sectionTitle: "Team Leader(s)",
        content: [],
      },
      {
        sectionTitle: "Accommodation will be a hotel for days 1 & 7.",
        content: [],
      },
      {
        sectionTitle: "Accommodation will be Homestay for days 2, 3, 4,  5 & 6",
        content: [],
      },
      {
        sectionTitle: "Meals as mentioned",
        content: [],
      },
      {
        sectionTitle: "Highly-Experienced driver cum guide",
        content: [],
      },
      {
        sectionTitle: "Sightseeing as per itinerary",
        content: [],
      },
      {
        sectionTitle: "Inner Line permit",
        content: [],
      },
    ],
    exclusion: [
      {
        sectionTitle:
          "The cost incurred due to change in itinerary due to bad weather, road closure/block, heavy snowfall, or factors beyond control.",
        content: [],
      },
      {
        sectionTitle: "Tips to guides/drivers.",
        content: [],
      },
      {
        sectionTitle: "Camera and Time Village Fees",
        content: [],
      },
      {
        sectionTitle: "Personal Gear/Specialized clothing for cold weather.",
        content: [],
      },
      {
        sectionTitle: "Medical or any other emergency evacuation costs.",
        content: [],
      },
      {
        sectionTitle:
          "Cameras/batteries any other props Like Binoculars, Lenses, etc.",
        content: [],
      },
      {
        sectionTitle: "Sanctuary and Monastery fees.",
        content: [],
      },
      {
        sectionTitle: "Any item not mentioned in the “Inclusions” list.",
        content: [],
      },
    ],
    youtubeLink:
      "",
    expeditionDate: [
      {
        id: 1,
        duration: "7 Days",
        startDate: "",
        endDate: "",
        bookedSeat: "",
        availableSeat: "",
      },
      {
        id: 2,
        duration: "7 Days",
        startDate: "",
        endDate: "",
        bookedSeat: "",
        availableSeat: "",
      },
      {
        id: 3,
        duration: "7 Days",
        startDate: "",
        endDate: "",
        bookedSeat: "",
        availableSeat: "",
      },
      {
        id: 4,
        duration: "7 Days",
        startDate: "",
        endDate: "",
        bookedSeat: "",
        availableSeat: "",
      },
      {
        id: 5,
        duration: "7 Days",
        startDate: "",
        endDate: "",
        bookedSeat: "",
        availableSeat: "",
      },
    ],
  },
];
