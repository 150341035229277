import React from "react";
import { useParams } from "react-router-dom";
import { IndividualCerti } from "./dataStorage";

const IndividualCertificate = () => {
  const { individualCertificate } = useParams();

  const FilterIndividualCertificate = IndividualCerti.find(
    (exp) => Number(exp.index) === Number(individualCertificate)
  );

  return (
    <div className="w-screen h-screen flex justify-center items-center bg-black">
      <img
        src={FilterIndividualCertificate.img}
        alt="Company leagal documents & certificates"
        className="h-full w-auto"
      />
    </div>
  );
};

export default IndividualCertificate;
